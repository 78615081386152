import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  Button,
  TextInput,
  Modal,
  Portal,
  Text,
  ActivityIndicator,
  Checkbox,
} from "react-native-paper";
import { Dropdown } from "react-native-element-dropdown";
import MyWebDatePicker from "../../components/MyDatePicker";
import { validate } from "../../libs/form.validate";
import { convertToFloat } from "../../libs/shared.services";
import { useTranslation } from "react-i18next";
import colors from "../../styles/colors";

const createProjectQuery = gql`
  mutation createProject($input: createProjectInput) {
    createProject(input: $input) {
      status
      message
    }
  }
`;
const getAllAreaManager = gql`
  query findUserByRole($input: findUserByRoleInput) {
    findUserByRole(input: $input) {
      id
      user {
        first_name
        last_name
      }
    }
  }
`;
const getAllProjectManager = gql`
  query findUserByRole($input: findUserByRoleInput) {
    findUserByRole(input: $input) {
      id
      user {
        first_name
        last_name
      }
    }
  }
`;
const getAllArea = gql`
  query getAllAreas {
    getAllAreas {
      id
      name
    }
  }
`;
const getClientDetails = gql`
  query getClientByID($input: getAllProjectInput) {
    getClientByID(input: $input) {
      address
      id
      name
    }
  }
`;
const getProjectDetailsQuery = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      address
      client_id
      area_manager {
        id
        user {
          first_name
          last_name
        }
      }
      billing_time
      budget
      client_note
      description
      end_date
      event_date
      id
      invoice_id
      name
      note
      offer_id
      project_area {
        id
        name
      }
      project_created_by {
        user {
          first_name
          last_name
        }
      }
      project_id
      project_manager {
        id
        user {
          first_name
          last_name
        }
      }
      start_date
      state_code
      year
      project_reference
      client_reference
      status_code
      contract_value
      margin
      fee
      payment_check
      reporting_file
      check_planning
      quality_plan
      expeses_to_be_reimbursed
      expeses_included
      status
      client_group
      client_manager
      invoice_details
      billing_time
      project_sector {
        id
      }
      note_compilatore
      note_check_pm
      note_resp_area
      contract_activity
      sending_customer_invoice
      sending_invoice_made_by
      invoice_paid
    }
  }
`;
const EDIT_PROJECT_DETAILS = gql`
  mutation updateProject($input: updateProjectInput) {
    updateProject(input: $input) {
      status
      message
    }
  }
`;
const GET_ALL_SECTORS = gql`
  query getAllSectors {
    getAllSectors {
      id
      name
    }
  }
`;

function CreateNewProject({ navigation, route }) {
  const [clientid] = useState(route.params.id);
  const [projectid] = useState(route?.params?.projectID);
  const [validationErrors, setValidationErrors] = useState({});
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);

  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      disabled: 'white',
      placeholder: 'white',
    },
  };

  const [showProjectStatusDropdown, setShowProjectStatusDropdown] =
    useState(false);

  const REQUIRED_FIELDS = [
    "name",
    "client_manager",
    "client_group",
    "project_id",
    "area_id",
    "sector_id",
    "project_manager_id",
    "project_reference",
    "contract_value",
    "contract_activity",
  ];

  const PROJECT_STATUS = [
    { label: "Da Confermare", value: "Da_confermare" },
    { label: "In Programmazione", value: "In_Programmazione" },
    { label: "Da Fatturare", value: "Da_Fatturare" },
    { label: "Aperta", value: "Aperta" },
    { label: "Annuale", value: "Annuale" },
    { label: "Chiusa", value: "Chiusa" },
    { label: "Annullata", value: "Annullata" },
  ];

  const [showSectorDropDown, setShowSectorDropDown] = useState(false);

  const { data: sectorList, refetch: refetchSectorList } =
    useQuery(GET_ALL_SECTORS);

  let formattedSectorList = [];

  if (sectorList?.getAllSectors?.length) {
    formattedSectorList = sectorList?.getAllSectors?.map((sector) => {
      return {
        label: sector.name,
        value: sector.id,
      };
    });
  }

  let { data: projectData, refetch: refetchProjectData } = useQuery(
    getProjectDetailsQuery,
    {
      variables: { input: { id: projectid } },
    }
  );

  const [
    editProject,
    {
      data: editProjectData,
      loading: editProjectLoading,
      error: editProjectError,
    },
  ] = useMutation(EDIT_PROJECT_DETAILS);

  const { data: clientData, refetch: refetchClient } = useQuery(
    getClientDetails,
    {
      variables: {
        input: {
          client_id: clientid || projectData?.getProjectById?.client_id,
        },
      },
    }
  );

  const [createProject, { data, loading, error }] =
    useMutation(createProjectQuery);

  // area manager dropdown logic
  const [showAMDropDown, setshowAMDropDown] = useState(false);

  const {
    loading: areaManagerloading,
    error: areaManagererror,
    data: areaManagerdata,
  } = useQuery(getAllAreaManager, {
    variables: { input: { role: "AREA_MANAGER" } },
  });

  let formattedAreaManagers = [];

  if (areaManagerdata?.findUserByRole?.length) {
    formattedAreaManagers = areaManagerdata?.findUserByRole.map((manager) => {
      return {
        label: `${manager.user?.first_name} ${manager?.user?.last_name}`,
        value: manager?.id,
      };
    });
  }
  // ends here
  // Project manager dropdown logic
  const [showPMDropDown, setshowPMDropDown] = useState(false);

  const {
    loading: projectManagerloading,
    error: projectManagerrerror,
    data: projectManagerdata,
  } = useQuery(getAllProjectManager, {
    variables: { input: { role: "PROJECT_MANAGER" } },
  });

  let formattedProjectManagers = [];

  if (projectManagerdata?.findUserByRole?.length) {
    formattedProjectManagers = projectManagerdata?.findUserByRole.map(
      (manager) => {
        return {
          label: `${manager.user?.first_name} ${manager?.user?.last_name}`,
          value: manager?.id,
        };
      }
    );
  }
  // ends here

  // all areas dropdown logic
  const [showAreasDropDown, setshowAreasDropDown] = useState(false);

  const {
    loading: areasloading,
    error: areaserrerror,
    data: areasdata,
  } = useQuery(getAllArea);

  let formattedAreas = [];

  if (areasdata?.getAllAreas?.length) {
    formattedAreas = areasdata?.getAllAreas.map((area) => {
      return {
        label: area?.name,
        value: area?.id,
      };
    });
  }
  // ends here

  const [isEventDatePickerVisible, setisEventDatePickerVisible] =
    useState(false);
  const [isStartDatePickerVisible, setisStartDatePickerVisible] =
    useState(false);
  const [isEndDatePickerVisible, setisEndDatePickerVisible] = useState(false);

  const containerStyle = {
    backgroundColor: colors.grey,
    padding: 20,
    alignSelf: "center",
  };

  const submitData = async (values) => {
    values.budget = 0;
    convertToFloat(values, "budget");
    convertToFloat(values, "fee");
    convertToFloat(values, "margin");
    convertToFloat(values, "contract_value");

    setValidationErrors({});

    let isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    const isValidField = (fieldValue) => {
      return fieldValue !== null && fieldValue !== undefined && fieldValue !== '';
    };

    if (!isValidField(values.fee) || parseFloat(values.fee) < 0 || parseFloat(values.fee) > 100) {
      isValidForm = false;
      setValidationErrors({
        ...validationErrors,
        fee: "Fee percentage cannot be greater than 100 or negative",
      });
    }

    if (!isValidField(values.margin) || parseFloat(values.margin) < 0 || parseFloat(values.margin) > 100) {
      isValidForm = false;
      setValidationErrors({
        ...validationErrors,
        margin: "Margin percentage cannot be greater than 100 or negative",
      });
    }

    try {
      if (isValidForm) {
        const contractValue = parseFloat(values.contract_value) || 0;
        const marginPercentage = parseFloat(values.margin) || 0;
        const feePercentage = parseFloat(values.fee) || 0;

        const marginValue = contractValue * (marginPercentage / 100);
        const feeValue = contractValue * (feePercentage / 100);

        const calculatedBudget = contractValue - marginValue - feeValue;
        values.budget = calculatedBudget;
        if (projectid) {
          await editProject({
            variables: { input: { ...values, id: projectid } },
          });
          navigation.navigate("ProjectList", {
            id: projectData?.getProjectById?.client_id,
          });
        } else {
          await createProject({
            variables: {
              input: {
                ...values,
                latitude: coordinates?.latitude
                  ? String(coordinates?.latitude)
                  : null,
                longitude: coordinates?.latitude
                  ? String(coordinates?.latitude)
                  : null,
              },
            },
          });
          navigation.navigate("ProjectList", {
            id: clientid,
          });
        }
      }
    } catch (error) {
      setValidationErrors({ backendError: `${error?.message}*` });
    }
  };

  useEffect(() => {
    if (clientData?.getClientByID) {
      navigation.setOptions({
        headerRight: () => {
          return (
            <View className="justify-end items-end pr-2">
              <Text className="text-white">
                {clientData?.getClientByID?.name}
              </Text>
              <Text className="text-white">
                {" "}
                {clientData &&
                  clientData.getClientByID &&
                  clientData.getClientByID.address}
              </Text>
            </View>
          );
        },
      });
    }
  }, [clientData]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    client_id: clientid,
    project_id: "",
    address: "",
    description: "",
    area_id: "",
    state_code: "",
    offer_id: "",
    invoice_id: "",
    area_manager_id: "",
    project_manager_id: "",
    client_note: "",
    note: "",
    project_reference: "",
    client_reference: "",
    status_code: "",
    contract_value: 0,
    margin: 0,
    fee: 0,
    budget: 0,
    payment_check: "",
    reporting_file: false,
    check_planning: "",
    quality_plan: "",
    expeses_to_be_reimbursed: false,
    expeses_included: false,
    status: "In_Programmazione",
    client_manager: "",
    client_group: "",
    sector_id: "",
    event_date: "",
    start_date: new Date(),
    end_date: new Date(),
    invoice_details: "",
    billing_time: "",
    note_compilatore: "",
    note_check_pm: "",
    note_resp_area: "",
    contract_activity: "",
    sending_customer_invoice: "",
    sending_invoice_made_by: "",
    invoice_paid: "",
  });

  useEffect(() => {
    if (projectData) {
      setInitialValues({
        name: projectData?.getProjectById?.name || "",
        client_id: projectData?.getProjectById?.client_id || "",
        project_id: projectData?.getProjectById?.project_id || "",
        address: projectData?.getProjectById?.address || "",
        description: projectData?.getProjectById?.description || "",
        area_id: projectData?.getProjectById?.project_area?.id || "",
        state_code: projectData?.getProjectById?.state_code || "",
        offer_id: projectData?.getProjectById?.offer_id || "",
        invoice_id: projectData?.getProjectById?.invoice_id || "",
        area_manager_id: projectData?.getProjectById?.area_manager?.id || "",
        project_manager_id:
          projectData?.getProjectById?.project_manager?.id || "",
        client_note: projectData?.getProjectById?.client_note || "",
        note: projectData?.getProjectById?.note || "",
        event_date: projectData?.getProjectById?.event_date || "",
        start_date:
          (projectData?.getProjectById?.start_date &&
            new Date(parseInt(projectData?.getProjectById?.start_date))) ||
          new Date(),
        end_date:
          (projectData?.getProjectById?.end_date &&
            new Date(parseInt(projectData?.getProjectById?.end_date))) ||
          new Date(),
        project_reference: projectData?.getProjectById?.project_reference || "",
        client_reference: projectData?.getProjectById?.client_reference || "",
        status_code: projectData?.getProjectById?.status_code || "",
        contract_value:
          parseFloat(projectData?.getProjectById?.contract_value) || 0,
        margin: parseFloat(projectData?.getProjectById?.margin) || 0,
        fee: parseFloat(projectData?.getProjectById?.fee) || 0,
        budget: parseFloat(projectData?.getProjectById?.budget) || 0,
        payment_check: projectData?.getProjectById?.payment_check || "",
        reporting_file: projectData?.getProjectById?.reporting_file || false,
        check_planning: projectData?.getProjectById?.check_planning || "",
        quality_plan: projectData?.getProjectById?.quality_plan || "",
        expeses_to_be_reimbursed:
          projectData?.getProjectById?.expeses_to_be_reimbursed || false,
        expeses_included:
          projectData?.getProjectById?.expeses_included || false,
        status: projectData?.getProjectById?.status || "ACTIVE",
        client_manager: projectData?.getProjectById?.client_manager || "",
        client_group: projectData?.getProjectById?.client_group || "",
        sector_id: projectData?.getProjectById?.project_sector?.id || "",
        invoice_details: projectData?.getProjectById?.invoice_details || "",
        billing_time: projectData?.getProjectById?.billing_time || "",
        note_compilatore: projectData?.getProjectById?.note_compilatore || "",
        note_check_pm: projectData?.getProjectById?.note_check_pm || "",
        note_resp_area: projectData?.getProjectById?.note_resp_area || "",
        contract_activity: projectData?.getProjectById?.contract_activity || "",
        sending_customer_invoice: projectData?.getProjectById?.sending_customer_invoice || "",
        sending_invoice_made_by: projectData?.getProjectById?.sending_invoice_made_by || "",
        invoice_paid: projectData?.getProjectById?.invoice_paid || "",
      });
    }
  }, [projectData, projectid]);

  const styles = StyleSheet.create({
    titleContainer: {
      alignItems: 'flex-start',
      paddingTop: 25,
      paddingBottom: 15,
    },
    titleText: {
      color: 'white',
      fontSize: 30,
      fontWeight: 'bold',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  });

  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      bounces={false}
      showsHorizontalScrollIndicator={false}
    >
      <View>
        <View className="flex flex-col p-2 pt-8 h-full pb-24" style={{ backgroundColor: colors.background }}>
          <Text
            variant="displayMedium"
            className="text-white pl-2 pb-4 font-semibold"
          >
            {projectid ? "Modifica progetto" : "Nuovo progetto"}
          </Text>

          {validationErrors["backendError"] && (
            <View>
              <Text
                className="text-red-400 text-sm p-2"
                style={{ color: "red" }}
              >
                {validationErrors["backendError"]}
              </Text>
            </View>
          )}

          <Formik
            initialValues={initialValues}
            onSubmit={submitData}
            enableReinitialize
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              setFieldValue,
            }) => (
              <View className="flex flex-col w-full p-2 gap-4">
                <View style={styles.titleContainer}>
                  <Text style={styles.titleText}>Anagrafica commessa:</Text>
                </View>
                <View>
                  <TextInput
                    label={t("Client manager*")}
                    onChangeText={handleChange("client_manager")}
                    onBlur={handleBlur("client_manager")}
                    value={values.client_manager}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                  {validationErrors["client_manager"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["client_manager"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Gruppo cliente*")}
                    onChangeText={handleChange("client_group")}
                    onBlur={handleBlur("client_group")}
                    value={values.client_group}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                  {validationErrors["client_group"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["client_group"]}
                    </Text>
                  )}
                </View>
                <View>
                  <TextInput
                    label={t("Commessa*")}
                    onChangeText={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["name"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["name"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Codice interno commessa*")}
                    onChangeText={handleChange("project_id")}
                    onBlur={handleBlur("project_id")}
                    value={values.project_id}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["project_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["project_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Offer ID")}
                    onChangeText={handleChange("offer_id")}
                    onBlur={handleBlur("offer_id")}
                    value={values.offer_id}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["offer_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["offer_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <Dropdown
                    style={{
                      backgroundColor: colors.grey,
                      borderTopStartRadius: 4,
                      borderTopEndRadius: 4,
                      padding: 15,
                      paddingHorizontal: 30,
                      //width: '100%',
                      justifyContent: 'flex-end'
                    }}
                    containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={formattedAreas}
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={values.area_id}
                    placeholder="Seleziona l'area"
                    onChange={(item) => setFieldValue("area_id", item.value)}
                    selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                        {item.label}
                      </Text>
                    )}
                  />
                  {validationErrors["area_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["area_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <Dropdown
                    style={{
                      backgroundColor: colors.grey,
                      borderTopStartRadius: 4,
                      borderTopEndRadius: 4,
                      padding: 15,
                      paddingHorizontal: 30,
                      //width: '100%',
                      justifyContent: 'flex-end'
                    }}
                    containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={formattedSectorList}
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={values.sector_id}
                    placeholder="Seleziona il settore"
                    onChange={(item) => setFieldValue("sector_id", item.value)}
                    selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ color: colors.white, padding: 10, fontWeight: 'bold' }}>
                        {item.label}
                      </Text>
                    )}
                  />
                  {validationErrors["sector_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["sector_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <Dropdown
                    style={{
                      backgroundColor: colors.grey,
                      borderTopStartRadius: 4,
                      borderTopEndRadius: 4,
                      padding: 15,
                      paddingHorizontal: 30,
                      //width: '100%',
                      justifyContent: 'flex-end'
                    }}
                    containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={formattedAreaManagers}
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={values.area_manager_id}
                    placeholder="Seleziona l'area manager"
                    onChange={(item) => setFieldValue("area_manager_id", item.value)}
                    selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ color: colors.white, padding: 10, fontWeight: 'bold' }}>
                        {item.label}
                      </Text>
                    )}
                  />
                  {validationErrors["area_manager_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["area_manager_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <Dropdown
                    style={{
                      backgroundColor: colors.grey,
                      borderTopStartRadius: 4,
                      borderTopEndRadius: 4,
                      padding: 15,
                      paddingHorizontal: 30,
                      //width: '100%',
                      justifyContent: 'flex-end'
                    }}
                    containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={formattedProjectManagers}
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={values.project_manager_id}
                    placeholder="Seleziona il project manager"
                    onChange={(item) => setFieldValue("project_manager_id", item.value)}
                    selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ color: colors.white, padding: 10, fontWeight: 'bold' }}>
                        {item.label}
                      </Text>
                    )}
                  />
                  {validationErrors["project_manager_id"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["project_manager_id"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Resp. commessa*")}
                    onChangeText={handleChange("project_reference")}
                    onBlur={handleBlur("project_reference")}
                    value={values.project_reference}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                  {validationErrors["project_reference"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["project_reference"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Attività oggetto del contratto*")}
                    onChangeText={handleChange("contract_activity")}
                    onBlur={handleBlur("contract_activity")}
                    value={values.contract_activity}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                  {validationErrors["contract_activity"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["contract_activity"]}
                    </Text>
                  )}
                </View>

                {/* Start date picker with modal starts */}
                <View>
                  <TextInput
                    label={t("Data inizio commessa*")}
                    showSoftInputOnFocus={false}
                    value={values.start_date.toDateString()}
                    right={<TextInput.Icon icon="calendar" iconColor="white" />}
                    onFocus={() =>
                      setisStartDatePickerVisible(!isStartDatePickerVisible)
                    }
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />

                  <Portal>
                    <Modal
                      visible={isStartDatePickerVisible}
                      onDismiss={() => setisStartDatePickerVisible(false)}
                      // @ts-ignore
                      contentContainerStyle={containerStyle}
                    >
                      {isStartDatePickerVisible && (
                        <MyWebDatePicker
                          fieldName={"start_date"}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Modal>
                  </Portal>
                  {validationErrors["start_date"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["start_date"]}
                    </Text>
                  )}
                </View>

                {/* Start date picker with modal Ends */}

                {/* End date picker with modal starts */}
                <TextInput
                  label={t("Data fine lavori")}
                  showSoftInputOnFocus={false}
                  value={values.end_date.toDateString()}
                  right={<TextInput.Icon icon="calendar" iconColor="white" />}
                  onFocus={() =>
                    setisEndDatePickerVisible(!isEndDatePickerVisible)
                  }
                  theme={{
                    colors: {
                      onSurfaceVariant: "white",
                    },
                  }}
                  style={{ backgroundColor: colors.grey }}
                  activeUnderlineColor="white"
                  underlineColor="white"
                  underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                />

                <Portal>
                  <Modal
                    visible={isEndDatePickerVisible}
                    onDismiss={() => setisEndDatePickerVisible(false)}
                    // @ts-ignore
                    contentContainerStyle={containerStyle}
                  >
                    {isEndDatePickerVisible && (
                      <MyWebDatePicker
                        fieldName={"end_date"}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Modal>
                </Portal>
                {/* End date picker with modal Ends */}

                <View>
                  <TextInput
                    label={t("Data evento o commissione o audit")}
                    onChangeText={handleChange("event_date")}
                    onBlur={handleBlur("event_date")}
                    value={values.event_date}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Referente del cliente")}
                    onChangeText={handleChange("client_reference")}
                    onBlur={handleBlur("client_reference")}
                    value={values.client_reference}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Location")}
                    multiline={true}
                    onChangeText={handleChange("address")}
                    onBlur={handleBlur("address")}
                    value={values.address}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["address"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["address"]}
                    </Text>
                  )}
                </View>

                {/* {!projectid ? (
                  <View className="mt-1">
                    <SearchableDropdown
                      setCoordinates={setCoordinates} // Continui a passare setCoordinates per altri usi
                      onPlaceSelected={(selectedTitle) => {
                        setFieldValue("address", selectedTitle); // Imposta l'indirizzo con il titolo del luogo selezionato
                      }}
                    />
                  </View>
                ) : null} */}

                <View>
                <Dropdown
                    style={{
                      backgroundColor: colors.grey,
                      borderTopStartRadius: 4,
                      borderTopEndRadius: 4,
                      padding: 15,
                      paddingHorizontal: 30,
                      //width: '100%',
                      justifyContent: 'flex-end'
                    }}
                    containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={PROJECT_STATUS}
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={values.status}
                    placeholder="Stato progetto"
                    onChange={(item) => setFieldValue("status", item.value)}
                    selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ color: colors.white, padding: 10, fontWeight: 'bold' }}>
                        {item.label}
                      </Text>
                    )}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Check pianificazione ")}
                    onChangeText={handleChange("check_planning")}
                    onBlur={handleBlur("check_planning")}
                    value={values.check_planning}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Obbligo piano qualità a cura PM")}
                    onChangeText={handleChange("quality_plan")}
                    onBlur={handleBlur("quality_plan")}
                    value={values.quality_plan}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                {/* <View className="flex flex-row w-full">
                  <View className="flex flex-col w-1/2">
                    <TouchableOpacity
                      onPress={() => {
                        setFieldValue("check_planning", !values.check_planning);
                      }}
                      className="flex flex-row w-fit items-center justify-start"
                    >
                      <Checkbox.Android
                        status={values.check_planning ? "checked" : "unchecked"}
                        color="white"
                        underlayColor="white"
                      />
                      <Text className="text-white">{t("Check Planning")}</Text>
                    </TouchableOpacity>
                  </View>
                  <View className="flex flex-col w-1/2">
                    <TouchableOpacity
                      onPress={() => {
                        setFieldValue("quality_plan", !values.quality_plan);
                      }}
                      className="flex flex-row w-fit items-center justify-start"
                    >
                      <Checkbox.Android
                        status={values.quality_plan ? "checked" : "unchecked"}
                        color="white"
                        underlayColor="white"
                      />
                      <Text className="text-white">{t("Quality Plan")}</Text>
                    </TouchableOpacity>
                  </View>
                </View> */}


                <View style={styles.titleContainer}>
                  <Text style={styles.titleText}>Informazioni economiche commessa:</Text>
                </View>

                <View>
                  <TextInput
                    label={t("Importo contratto*")}
                    onChangeText={handleChange("contract_value")}
                    onBlur={handleBlur("contract_value")}
                    value={values.contract_value.toString()}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                    keyboardType="numeric"
                  />
                  {validationErrors["contract_value"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["contract_value"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label="Fee ( in % )*"
                    onChangeText={handleChange("fee")}
                    onBlur={handleBlur("fee")}
                    value={values.fee.toString()}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                    keyboardType="numeric"
                  />
                  {validationErrors["fee"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["fee"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Margine ECS ( in % )*")}
                    onChangeText={handleChange("margin")}
                    onBlur={handleBlur("margin")}
                    value={values.margin.toString()}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                    keyboardType="numeric"
                  />
                  {validationErrors["margin"] && (
                    <Text className="text-red-400 p-1" style={{ color: "red" }}>
                      {validationErrors["margin"]}
                    </Text>
                  )}
                </View>

                <View>
                  <TextInput
                    label={t("Budget*")}
                    onChangeText={handleChange("budget")}
                    onBlur={handleBlur("budget")}
                    value={values.budget.toString()}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                        text: "white"
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    editable={false}
                    textColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                    keyboardType="numeric"
                  />
                </View>

                <View style={styles.titleContainer}>
                  <Text style={styles.titleText}>Informazioni chiusura commessa:</Text>
                </View>

                <View>
                  <TextInput
                    label={t("Tempistiche fatturazione (indicare se a fine commessa, mensile, bimestrale, trimestrale, semestrale, annuale) a cura PM")}
                    onChangeText={handleChange("billing_time")}
                    onBlur={handleBlur("billing_time")}
                    value={values.billing_time}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Numero e descrizione della fattura a cura di coord.")}
                    onChangeText={handleChange("invoice_details")}
                    onBlur={handleBlur("invoice_details")}
                    value={values.invoice_details}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Invio fattura al cliente si/no data")}
                    onChangeText={handleChange("sending_customer_invoice")}
                    onBlur={handleBlur("sending_customer_invoice")}
                    value={values.sending_customer_invoice}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Invio effettuato da")}
                    onChangeText={handleChange("sending_invoice_made_by")}
                    onBlur={handleBlur("sending_invoice_made_by")}
                    value={values.sending_invoice_made_by}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Fattura saldata si/no")}
                    onChangeText={handleChange("invoice_paid")}
                    onBlur={handleBlur("invoice_paid")}
                    value={values.invoice_paid}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View>
                  <TextInput
                    label={t("Data saldo")}
                    onChangeText={handleChange("payment_check")}
                    onBlur={handleBlur("payment_check")}
                    value={values.payment_check}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                </View>

                <View style={styles.titleContainer}>
                  <Text style={styles.titleText}>Rendicontazione commessa:</Text>
                </View>

                <View className="flex flex-row w-full">
                  <View className="flex flex-col w-1/2">
                    <TouchableOpacity
                      onPress={() => {
                        setFieldValue(
                          "expeses_to_be_reimbursed",
                          !values.expeses_to_be_reimbursed
                        );
                      }}
                      className="flex flex-row w-fit items-center justify-start"
                    >
                      <Checkbox.Android
                        status={
                          values.expeses_to_be_reimbursed
                            ? "checked"
                            : "unchecked"
                        }
                        color="white"
                        underlayColor="white"
                      />
                      <Text className="text-white" style={{ fontSize: 17 }}>
                        {t("Presenza spese da riaddebitare al cliente si/no a cura PM")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View className="flex flex-col w-1/2">
                    <TouchableOpacity
                      onPress={() => {
                        setFieldValue(
                          "expeses_included",
                          !values.expeses_included
                        );
                      }}
                      className="flex flex-row w-fit items-center justify-start"
                    >
                      <Checkbox.Android
                        status={
                          values.expeses_included ? "checked" : "unchecked"
                        }
                        color="white"
                        underlayColor="white"
                      />
                      <Text className="text-white" style={{ fontSize: 17 }}>
                        {t(" Presenza di spese comprese nel contratto si/no a cura PM")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View style={styles.titleContainer}>
                  <Text style={styles.titleText}>Note:</Text>
                </View>

                <TextInput
                  label={t("Note check PM")}
                  onChangeText={handleChange("note_check_pm")}
                  onBlur={handleBlur("note_check_pm")}
                  value={values.note_check_pm}
                  theme={{
                    colors: {
                      onSurfaceVariant: "white",
                    },
                  }}
                  style={{ backgroundColor: colors.grey }}
                  activeUnderlineColor="white"
                  underlineColor="white"
                  underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                />

                <TextInput
                  label="Note resp area"
                  onChangeText={handleChange("note_resp_area")}
                  onBlur={handleBlur("note_resp_area")}
                  value={values.note_resp_area}
                  theme={{
                    colors: {
                      onSurfaceVariant: "white",
                    },
                  }}
                  style={{ backgroundColor: colors.grey }}
                  activeUnderlineColor="white"
                  underlineColor="white"
                  underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                />

                <TextInput
                  label={t("Note")}
                  onChangeText={handleChange("note")}
                  onBlur={handleBlur("note")}
                  value={values.note}
                  theme={{
                    colors: {
                      onSurfaceVariant: "white",
                    },
                  }}
                  style={{ backgroundColor: colors.grey }}
                  activeUnderlineColor="white"
                  underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                />

                <View className="py-2 w-full items-center justify-center">
                  <Button
                    mode="contained"
                    onPress={handleSubmit as (e: unknown) => void}
                    className="p-2"
                    buttonColor="#7d0001"
                    style={{ borderRadius: 10 }}
                  >
                    {loading || editProjectLoading ? (
                      <ActivityIndicator animating={true} color={"red"} />
                    ) : (
                      <Text className="text-white font-bold">
                        Salva progetto
                      </Text>
                    )}
                  </Button>
                </View>

              </View>
            )}
          </Formik>
        </View>
      </View>
    </ScrollView>
  );
}

export default CreateNewProject;