import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { Button, IconButton, Text, TextInput } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import AnalysisChartTwo from "../../components/AnalysisChartTwo";
import DataSectionTwo from "../../components/DataSectionTwo";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Dropdown } from "react-native-element-dropdown";
import Loader from "../../components/Loader";
import colors from "../../styles/colors";

const GET_ALL_OVERVIEW_FIELD = gql`
  query getAllOverviewFields {
    getAllOverviewFields {
      id
      name
    }
  }
`;

const getLastUpdate = gql`
  query getLastUpdate {
    getLastUpdate {
      lastUpdate
    }
  }
`;

const GET_DASHBOARD_DATA = gql`
  query getAllOverviewGeneralDashboardData(
    $input: getAllOverviewGeneralDashboardDataInput
  ) {
    getAllOverviewGeneralDashboardData(input: $input) {
      data {
        contract_value
        margin
        name
        clientID
        project_id
      }
      totalContractValue
      totalMargin
      projects {
        name
        hasOutdatedTasks
        contract_value
        margin
        fee
        budget
        compensation
        project_sector {
          name
        }
        area_manager {
          user {
            first_name
            last_name
          }
        }
        project_manager {
          user {
            first_name
            last_name
          }
        }
        expeses_to_be_reimbursed
        client_name
        client_group
        client_id
        project_id
        cost
        notUsedBudget
        compensatedBudget
        compensationsReceived {
          projectId
          amount
        }
        compensationsSent {
          projectId
          amount
        }
      }
    }
  }
`;

const EXPORT_DATA_TO_CSV = gql`
  query generateDashboard_2_CSV($input: generateDashboard_2_CSVInput) {
    generateDashboard_2_CSV(input: $input) {
      fileName
      fileUrl 
    }
  }
`;

function DashboardTwo() {
  const [isFieldDropDownVisible, setIsFieldDropDownVisible] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const { data: overviewField, refetch: refetchOverviewField } = useQuery(
    GET_ALL_OVERVIEW_FIELD
  );

  const [marginTitle, setMarginTitle] = useState('');
  const [contractValueTitle, setContractValueTitle] = useState('');

  const allField = [
    { field: "area_id", margin: "aree con margine più alto", value: "aree con fatturato più alto" },
    { field: "client_id", margin: "10 commesse con il margine più alto", value: "10 commesse con il fatturato più alto" },
    { field: "client_group", margin: "10 commesse con il margine più alto", value: "10 commesse con il fatturato più alto" },
    { field: "client_manager", margin: "10 client manager con margine più alto", value: "10 client manager con fatturato più alto" },
    { field: "project_manager_id", margin: "10 P.M. con margine più alto", value: "10 P.M. con fatturato più alto" },
    { field: "sector_id", margin: "settori con margine più alto", value: "settori con fatturato più alto" },
  ];

  useEffect(() => {
    if (selectedField) {
      const selectedFieldData = allField.find(field => field.field === selectedField);
      setMarginTitle(selectedFieldData?.margin || '');
      setContractValueTitle(selectedFieldData?.value || '');
    }
  }, [selectedField]);

  let formattedOverviewFieldList = [];

  if (overviewField?.getAllOverviewFields?.length) {
    formattedOverviewFieldList = overviewField?.getAllOverviewFields?.map(
      (field) => {
        return {
          label: field.id,
          value: field.name,
        };
      }
    ).sort((a, b) => a.label.localeCompare(b.label));
  }

  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [open, setOpen] = useState(false);

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
  };

  const [getLastUpdateData, { data: lastUpdateData, loading: lastUpdateLoading }] = useLazyQuery(getLastUpdate);

  useEffect(() => {
    getLastUpdateData();
  }, [getLastUpdateData]);

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    loading: loadingDashboardData,
  } = useQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        field: selectedField,
        fromDate: range.startDate,
        toDate: range.endDate,
      },
    },
  });

  const [getFile, { data: file, loading: fileLoading, error: fileDownloadError }] = useLazyQuery(
    EXPORT_DATA_TO_CSV,
    {
      fetchPolicy: "network-only",
      variables: {
        input: {
          field: selectedField,
          box_one_fromDate: range?.startDate,
          box_one_toDate: range?.endDate,
          box_two_fromDate: range?.startDate,
          box_two_toDate: range?.endDate,
        },
      },
    }
  );

  const exportDataToCsv = async () => {
    const filedata = await getFile();
    console.log("called")
    window.open(filedata?.data?.generateDashboard_2_CSV?.fileUrl, "_blank");
  };

  useEffect(() => {
    refetchDashboardData();
  }, [range, selectedField]);

  return (
    <>
      <View className="flex flex-auto h-screen p-2" style={{ backgroundColor: colors.background }}>
        <Loader loading={loadingDashboardData} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-row w-full mb-10">
            <Text
              variant="displayMedium"
              className="text-white mb-4 w-full font-semibold"
            >
              Dashboard Framinia ECS Overview
            </Text>

            <View className="items-center text-center flex-row justify-center">
              <Text
                variant="headlineSmall"
                className="text-white font-semibold"
              >
                Intervallo :{" "}
              </Text>
              <Button
                onPress={() => setOpen(true)}
                uppercase={false}
                mode="outlined"
                textColor={colors.white}
                style={{ borderRadius: 4, borderWidth: 2, borderColor: colors.grey }}
              >
                {range.startDate && range.endDate ? (
                  <Text
                    variant="labelLarge"
                    className="text-white w-full font-semibold"
                  >
                    {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                      }`}
                    {` - `}
                    {`${new Date(range?.endDate).getDate()}/${new Date(range?.endDate).getMonth() + 1
                      }`}
                  </Text>
                ) : null}

                {range.startDate && !range.endDate ? (
                  <Text
                    variant="labelLarge"
                    className="text-white w-full font-semibold"
                  >
                    {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                      }`}
                  </Text>
                ) : null}

                {!range.startDate && !range.endDate ? (
                  <Text className="text-white w-full font-semibold">
                    Seleziona Intervallo
                  </Text>
                ) : null}
              </Button>
              {(range.startDate || range.endDate) && (
                <IconButton
                  icon="undo-variant"
                  mode="contained"
                  iconColor={"black"}
                  containerColor={"white"}
                  size={16}
                  onPress={() =>
                    setRange({
                      startDate: null,
                      endDate: null,
                    })
                  }
                  className="ml-4"
                />
              )}
              <DatePickerModal
                locale="it"
                mode="range"
                visible={open}
                onDismiss={onDismiss}
                startDate={range.startDate}
                endDate={range.endDate}
                onConfirm={onConfirm}
              />
            </View>
          </View>
          <View className="flex flex-row w-full justify-end">
            {selectedField && (
              <View style={{ alignItems: "flex-start" }}>
                <Button
                  icon="file-move-outline"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  onPress={exportDataToCsv}
                  className="items-center justify-center my-2 mr-4"
                  style={{ borderRadius: 10, height: 52, marginTop: 5 }}
                  loading={fileLoading}
                >
                  <Text className="text-white font-bold">
                    Esporta in CSV
                  </Text>
                </Button>
              </View>
            )}
            <Dropdown
              style={{
                backgroundColor: colors.background,
                borderTopStartRadius: 10,
                borderTopEndRadius: 10,
                borderWidth: 2,
                borderColor: colors.grey,
                padding: 15,
                paddingHorizontal: 30,
                //width: '100%',
                justifyContent: 'flex-end'
              }}
              containerStyle={{ backgroundColor: colors.background, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
              data={formattedOverviewFieldList}
              //search
              //searchPlaceholder="Trova progetto..."
              labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
              valueField="value" // Definisce il campo del valore per gli elementi della lista
              value={selectedField}
              placeholder='Seleziona un campo...'
              onChange={(item) => setSelectedField(item.value)}
              selectedTextStyle={{ color: colors.white }}
              placeholderStyle={{ color: colors.white }}
              itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, }}
              inputSearchStyle={{
                backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                overflow: 'hidden',
              }}

              renderItem={(item) => (
                <Text style={{ color: colors.white, padding: 10 }}>
                  {item.label}
                </Text>
              )}
            />
          </View>

          {selectedField ? (
            <>
              <AnalysisChartTwo
                dashboardData={
                  dashboardData?.getAllOverviewGeneralDashboardData
                }
                marginTitle={marginTitle}
                contractValueTitle={contractValueTitle}
              />
              <DataSectionTwo
                dashboardData={
                  dashboardData?.getAllOverviewGeneralDashboardData
                }
              />
            </>
          ) : (
            <View className="flex items-center justify-center h-full w-full">
              <Text
                variant="displayMedium"
                className="text-white w-full text-center font-semibold"
              >
                Seleziona un campo per i dettagli
              </Text>
            </View>
          )}
        </ScrollView>

        {selectedField ? (
          <View style={{ padding: 10 }}>
            <Text>
              Ultimo aggiornamento :{" "}
              {lastUpdateData ? new Date(lastUpdateData.getLastUpdate.lastUpdate).toLocaleString('it-IT', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              }) : "No data available"}
            </Text>
            {dashboardData?.getAllOverviewGeneralDashboardData?.projects.some(project => project.hasOutdatedTasks) && (
              <View style={{ paddingTop: 10 }}>
                <Text style={{ color: 'yellow' }}>
                  Attenzione: Non tutti i dati sono aggiornati
                </Text>
              </View>
            )}
          </View>
        ) : null}
      </View>


    </>
  );
}

export default DashboardTwo;
