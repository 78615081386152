import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import colors from '../styles/colors';

const window = Dimensions.get('window');

const AnalysisCharts = ({ ProjectData, Cost }) => {
  const [keys, setKeys] = useState(["Fee", "Margine ECS", "Costi", "Budget Residuo"]);

  const fee = ProjectData?.getCostMarginBudgetDashboardDetails?.fee || 0;
  const fixedCost = ProjectData?.getCostMarginBudgetDashboardDetails?.margin || 0;
  const totalCost = ProjectData?.getCostMarginBudgetDashboardDetails?.cost || 0;
  const compensation = ProjectData?.getCostMarginBudgetDashboardDetails?.compensation || 0;
  const totalBudget = ProjectData?.getCostMarginBudgetDashboardDetails?.budget + compensation;
  const notUsedBudget = totalBudget > totalCost ? totalBudget - totalCost : 0;
  const isOverBudget = totalCost > totalBudget;
  const overBudgetCost = isOverBudget ? totalCost - totalBudget : 0;

  const maxValueLine = totalBudget + notUsedBudget + 3000;

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const barData = [
    {
      "projectName": `${ProjectData?.getCostMarginBudgetDashboardDetails?.name}`,
      "Fee": parseFloat(fee.toFixed(2)),
      "Margine ECS": parseFloat(fixedCost.toFixed(2)),
      "Costi": isOverBudget ? totalBudget : parseFloat(totalCost.toFixed(2)),
      "Budget Residuo": !isOverBudget && compensation === 0 ? parseFloat(notUsedBudget.toFixed(2)) : 0,
      "Budget Compensato": compensation < 0 ? (-compensation).toFixed(2).toString() : compensation.toFixed(2).toString(),
    },
  ];

  const filteredBarData = barData.map(item => {
    const newItem = { projectName: item.projectName };
    Object.keys(item).forEach(key => {
      if (item[key] !== 0) {
        newItem[key] = item[key];
      }
    });
    return newItem;
  });

  useEffect(() => {
    const updatedKeys = compensation !== 0
      ? ["Fee", "Margine ECS", "Costi", "Budget Compensato"]
      : ["Fee", "Margine ECS", "Costi", "Budget Residuo"];

    setKeys(updatedKeys);
  }, [compensation]);

  const CustomLayer = ({ xScale, height }) => {
    let linePosition;
    if (overBudgetCost > 0) {
      linePosition = xScale(totalBudget + fee + fixedCost - overBudgetCost);
    } else {
      linePosition = xScale(notUsedBudget + fee + fixedCost + totalCost);
    }

    const yPosition = height / 2;

    return (
      <g transform={`translate(${linePosition}, 0)`}>
        <line
          y1={0}
          y2={yPosition + 24}
          stroke={colors.red}
          strokeWidth={2}
        />
        <text
          textAnchor="middle"
          fill="#ffffff"
          fontSize={12}
          fontWeight={'bold'}
          y={-5}
        >
          Linea Over-Budget
        </text>
      </g>
    );
  };

  const pieData = [
    {
      "id": "Costo Lavoratore",
      "label": "Costo Lavoratore",
      "value": ProjectData?.getCostMarginBudgetDashboardDetails?.totalCostOfWorker || 0,
    },
    {
      "id": "Spese Risorse",
      "label": "Spese Risorse",
      "value": ProjectData?.getCostMarginBudgetDashboardDetails?.totalValueOfReceipt || 0,
    },
    {
      "id": "Spese Forniture",
      "label": "Spese Forniture",
      "value": Cost?.getProjectDataByDate?.totalCostOfExpences || 0,
    }
  ];

  const filteredPieData = pieData.map(item => {
    if (item.value === null || item.value === undefined || item.value === 0) {
      return null;
    }
    return item;
  }).filter(Boolean);

  const getColors = {
    "Fee": "#7B8C96", 
    "Margine ECS": "#5D737E", 
    "Costi": "#A68A64", 
    "overBudget": "#595959",
    "Budget Residuo": colors.green,
    "Budget Compensato": compensation > 0 ? colors.green : colors.red,
  };

  const getBarColor = bar => {
    if (bar.id === 'Budget Compensato') {
      return compensation > 0 ? colors.green : colors.red;
    } else if (bar.id === 'overBudget') {
      return colors.blue;
    }
    return getColors[bar.id] || '#000';
  };

  const pieColors = {
    "Costo Lavoratore": "#C97C5D ",
    "Spese Risorse": "#6A93AD ",
    "Spese Forniture": "#8A7B74 ",
  };

  const getPieColor = pie => {
    return pieColors[pie.id] || '#000';
  };

  const HorizontalGrid = ({ xScale, height, margin, max }) => {
    let tickInterval = Math.pow(10, Math.floor(Math.log10(max)));

    const maxTicks = 10;
    if (max / tickInterval > maxTicks) {
      while (max / tickInterval > maxTicks) {
        tickInterval *= 2;
      }
    } else {
      while (max / tickInterval < maxTicks / 2) {
        tickInterval /= 2;
      }
    }

    tickInterval = niceNumber(tickInterval);

    const ticks = [];
    for (let i = 0; i <= max; i += tickInterval) {
      ticks.push(i);
    }

    return (
      <g transform={`translate(0, ${height - margin.bottom})`}>
        {ticks.map((tick, index) => (
          <g key={index} transform={`translate(${xScale(tick)}, 0)`}>
            <line
              y1={0}
              y2={235}
              stroke="#ffffff"
              strokeWidth={1}
              opacity={0.7}
            />
            <text
              y={250}
              x={0}
              textAnchor="middle"
              fill="#ffffff"
              fontSize={12}
            >
              {tick}
            </text>
          </g>
        ))}
      </g>
    );
  };

  function niceNumber(number) {
    const exponent = Math.floor(Math.log10(number));
    const fraction = number / Math.pow(10, exponent);
    let niceFraction;

    if (fraction < 1.5) {
      niceFraction = 1;
    } else if (fraction < 3) {
      niceFraction = 2;
    } else if (fraction < 7) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }

    return niceFraction * Math.pow(10, exponent);
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderWidth: 1,
      backgroundColor: colors.grey,
      paddingBottom: 30,
      borderColor: colors.white,
      borderRadius: 10
    },
    chartContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: window.width * 0.77,
      height: window.height / 2,
    },
    containerText: {
      flexDirection: 'row',
      paddingLeft: 25,
      paddingRight: 25,
    },
    button: {
      backgroundColor: colors.amaranto,
      color: 'white',
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: colors.white,
      borderTopLeftRadius: 10,
      shadowOpacity: 0.3,
      shadowRadius: 5,
      elevation: 3,
      width: "10vw",
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    graphContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    graph: {
      height: 380,
      width: '45%',
      flexDirection: 'column',
    },
    titleContainer: {
      alignItems: 'flex-start',
      paddingTop: 25,
      paddingLeft: 50,
    },
    titleContainer2: {
      alignItems: 'flex-end',
      paddingTop: 25,
      paddingLeft: 400,
    },
    titleText: {
      color: 'white',
      fontSize: 20,
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
    },
    prova: {
      flexDirection: 'column',
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.button}>
        <Button
          mode={activeTab === 1 ? "contained" : "outlined"}
          buttonColor={colors.amaranto}
          onPress={() => handleTabClick(1)}
        >
          <Text style={styles.buttonText}>Overview</Text>
        </Button>
      </View>
      <View style={styles.containerText}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>Budget Totale del Progetto: {parseFloat(totalBudget.toFixed(2))}€</Text>
          <Text style={styles.titleText}>Margine: {parseFloat(notUsedBudget.toFixed(2))}€</Text>
          <Text style={styles.titleText}>Over Budget: {parseFloat(overBudgetCost.toFixed(2))}€</Text>
        </View>
        <View style={styles.titleContainer2}>
          <Text style={styles.titleText}>Distribuzione dei Costi:</Text>
        </View>
      </View>
      <View style={styles.graphContainer}>
        <View style={styles.graph}>
          <ResponsiveBar
            data={filteredBarData}
            keys={keys}
            indexBy="projectName"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={getBarColor}
            layers={[props => <HorizontalGrid {...props} margin={15} max={maxValueLine} />, 'grid', 'bars', 'axes', 'markers', 'legends', CustomLayer]}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            layout="horizontal"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            borderWidth={1}
            borderColor={'black'}
            borderRadius={5}
            labelSkipWidth={5}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'row',
                justify: false,
                translateX: 20,
                translateY: 25,
                itemWidth: 105,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                symbolShape: "circle",
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    }
                  }
                ]
              }
            ]}
            theme={{
              legends: {
                text: {
                  fill: '#ffffff',
                  fontWeight: 'bold',
                }
              },
              labels: {
                text: {
                  fill: '#ffffff',
                  fontWeight: 'bold',
                }
              },
            }}
          />
        </View>
        <View style={styles.graph}>
          <ResponsivePie
            data={filteredPieData}
            margin={{ top: 40, right: 80, bottom: 80, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            sortByValue={true}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            sliceLabelsSkipAngle={10}
            colors={getPieColor}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateY: 56,
                itemWidth: 130,
                itemHeight: 18,
                itemTextColor: '#ffffff',
                itemsSpacing: 4,
                symbolSize: 20,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    }
                  }
                ]
              }
            ]}
            theme={{
              background: colors.grey,
              axis: {
                ticks: {
                  text: {
                    fill: '#ffffff',
                    fontSize: 16,
                    fontWeight: 'bold',
                  }
                },
              },
              legends: {
                text: {
                  fill: '#ffffff',
                  fontWeight: 'bold',
                }
              },
              labels: {
                text: {
                  fill: '#ffffff',
                  fontWeight: 'bold',
                }
              },
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default AnalysisCharts;