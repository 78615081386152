import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { Button, Text } from "react-native-paper";
import { ResponsivePie } from '@nivo/pie';
import colors from "../styles/colors";

function WorkerAnalysisCharts({ data, tasktime, workerType }) {
  const [activeTab, setActiveTab] = useState(1);
  const [colorMap, setColorMap] = useState({});
  const colorScheme = ["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb", "#97e3d5", "#f94144", "#f3722c", "#f9c74f", "#90be6d"];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  const pieChartData = data?.projectGroupByHours
    .filter((element) => element.total_hours > 0) // Filtra gli elementi con total_hours > 0
    .map((element, index) => {
      const color = colorScheme[index % colorScheme.length];
      colorMap[element.project_id] = color; // Aggiungi il colore alla mappa
      return {
        id: element.project_id,
        label: element.project_id,
        value: element.total_hours.toFixed(2),
        color: color,
      };
    }) || [];

  const legendData = [...pieChartData].sort((a, b) => b.value - a.value).slice(0, 10);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const { width, height } = Dimensions.get('window');

  const totalHours = data?.totalActualWorkingHours + data?.allTasksOvertimeTotal;

  const styles = StyleSheet.create({
    container: {
      flexDirection: "column", // Use 'column' for the main container
      backgroundColor: colors.grey,
      alignItems: "flex-start",
      justifyContent: "flex-start",
      borderWidth: 1,
      borderColor: colors.white,
      borderRadius: 10,
    },
    buttonContainer: {
      width: "10%", // Make button container take full width
      backgroundColor: colors.amaranto,
      borderTopLeftRadius: 10,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: colors.white,
    },
    button: {
      width: "100%", // Full width button
    },
    buttonText: {
      color: colors.white,
      //textAlign: 'left',
      fontSize: 16,
      fontWeight: "bold",
    },
    contentContainer: {
      flexDirection: "row", // Use 'row' for content
      width: "100%", // Full width content
      alignItems: "center",
      justifyContent: "space-between", // Space between items
    },
    chartContainer: {
      width: width * 0.2, // Adjust width as needed
      height: height * 0.6,
      backgroundColor: colors.grey,
    },
    chartContainerFreelance: {
      flex: 1,
      justifyContent: "center",
      width: width * 0.2, // Adjust width as needed
      height: height * 0.6,
    },
    titleText: {
      textAlign: "center",
      fontSize: 28,
      color: colors.white,
      paddingTop: 10,
    },
    valueText: {
      textAlign: "center",
      fontSize: 32,
      fontWeight: "bold",
      color: colors.white,
      paddingBottom: 10,
    },
    titleText2: {
      textAlign: "center",
      fontSize: 28,
      color: colors.white,
      paddingTop: 30,
    },
    valueText2: {
      textAlign: "center",
      fontSize: 32,
      fontWeight: "bold",
      color: colors.white,
      paddingBottom: 30,
    },
    textContainer: {
      flexDirection: "column",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 10,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={styles.buttonContainer}>
          <Button
            mode={activeTab === 1 ? "contained" : "outlined"}
            buttonColor={activeTab === 1 ? colors.amaranto : null}
            onPress={() => handleTabClick(1)}
          >
            <Text style={styles.buttonText}>Overview</Text>
          </Button>
        </View>

        <View style={styles.contentContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.titleText2}>Ore Totali</Text>
            <Text style={styles.valueText2}>{totalHours.toFixed(2) || 0}</Text>
            <Text style={styles.titleText2}>Ore Totali Ordinarie</Text>
            <Text style={styles.valueText2}>{data?.totalActualWorkingHours.toFixed(2) || 0}</Text>
            <Text style={styles.titleText2}>Ore Totali non Ordinarie</Text>
            <Text style={styles.valueText2}>{data?.allTasksOvertimeTotal.toFixed(2) || 0}</Text>
          </View>

          <View style={styles.textContainer}>
            {data?.allTasksOvertime.length &&
              data?.allTasksOvertime.map((item) => {
                if (workerType !== "FREELANCE") {
                  return (
                    <View key={item?.overtimePercent} style={styles.textContainer}>
                      <Text variant="headlineLarge" style={styles.titleText}>
                        Ore non Ordinarie {item?.overtimePercent || 0} %
                      </Text>
                      <Text variant="displayLarge" style={styles.valueText}>
                        {item?.overtimeHours.toFixed(2)}
                      </Text>
                    </View>
                  );
                }
                return null;
              })}
          </View>

          <View style={[styles.chartContainer, { width: width / 3 }]}>
            <ResponsivePie
              data={pieChartData}
              margin={{ top: 40, right: 170, bottom: 80, left: 0 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={(d) => colorMap[d.id]}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              enableArcLinkLabels={false}
              legends={[
                {
                  data: legendData.map((item) => ({
                    id: item.id,
                    label: truncateText(item.id, 20),
                    color: colorMap[item.id],
                  })),
                  anchor: "right",
                  direction: "column",
                  translateX: 110,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 110,
                  itemHeight: 22,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 15,
                  symbolShape: "circle",
                },
              ]}
              theme={{
                background: colors.grey,
                legends: {
                  text: {
                    fill: "#ffffff",
                    fontWeight: "bold",
                  },
                },
                labels: {
                  text: {
                    fill: "#ffffff",
                    fontWeight: "bold",
                  },
                },
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
}

export default WorkerAnalysisCharts;
