import { gql } from "@apollo/client";

export const USER_PROFILE_QUERY = gql`
  query GetUserProfile {
    getUserProfile {
      email
      profile_id
      role
    }
  }
`;

export const USER_PROFILE_NAME = gql`
  query getUserProfile {
    getUserProfile {
      last_name
      first_name
      profile_id
    }
  }
`;

export const USER_PROFILE_ROLE = gql`
  query getUserProfileRole {
    getUserProfileRole {
      last_name
      first_name
      profile_id
      otherProfiles {
        areas {
          id
          name
        }
      }
    }
  }
`;

export const GET_WORKER_PROJECTS = gql`
  query getWorkerProjects {
    getWorkerProjects {
      projects {
        id
        end_date
        description
        name
        project_id
        start_date
        client_name
        status
      }
    }
  }
`;

export const DOWNLOAD_REPORTS_QUERY = gql`
  query downloadReports {
    downloadReports {
      fileUrl
      fileName
    }
  }
`;

export const DOWNLOAD_ELENCO_REPORT = gql`
  query ElencoCommessedownloadReports {
    ElencoCommessedownloadReports {
      fileUrl
      fileName
    }
  }
`;

export const DOWNLOAD_CONTROLLO_REPORT = gql`
  query ControlloCommessedownloadReports {
    ControlloCommessedownloadReports {
      fileUrl
      fileName
    }
  }
`;
