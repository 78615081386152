import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { Button, TextInput, Text, Checkbox } from "react-native-paper";
import { Dropdown } from "react-native-element-dropdown";
import { ActivityIndicator } from "react-native-paper";
import { validate } from "../../libs/form.validate";
import SalaryCard from "../../components/SalaryCard";
import colors from "../../styles/colors";

// const createWorkerQuery = gql`
//   mutation signup($input: signupInput) {
//     signup(input: $input) {
//       status
//     }
//   }
// `;
const getAllSkills = gql`
  query getAllSkills {
    getAllSkills {
      name
      id
    }
  }
`;
const GET_WORKER_DATA = gql`
  query userProfile($input: userProfileInput) {
    userProfile(input: $input) {
      first_name
      last_name
      email
      password
      id
      profile {
        id
        worker_type
        OVERTIME_ALLOWED
        hourly_wages
        qualification
        description
        skills
        overtimePercent
        latestHourlyWage
        city
      }
    }
  }
`;

const EDIT_WORKER = gql`
  mutation updateWorker($input: updateWorkerInput) {
    updateWorker(input: $input) {
      message
      status
    }
  }
`;

const GET_SALARY_DATA = gql`
  query getAllSalaryByProfileID($input: getAllSalaryByProfileIDInput) {
    getAllSalaryByProfileID(input: $input) {
      id
      profile_id
      created_at
      amount
    }
  }
`;

function CreateNewWorker({ navigation, route }: any) {
  const [WorkerId] = useState(route?.params?.workerId);
  const [userID] = useState(route?.params?.userID);
  const [WORKER_TYPE, setWorkerType] = useState("");
  const [isPasswordHidden, setisPasswordHidden] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [showCityDropDown, setShowCityDropDown] = useState(false);
  const [CITY, setCity] = useState("");

  const workerTypes = [
    { label: "FREELANCE", value: "FREELANCE" },
    { label: "EMPLOYEE", value: "EMPLOYEE" },
  ];

  const City = [
    { label: "Milano", value: "Milano" },
    { label: "Roma", value: "Roma" },
  ]
  const [showWorkerTypeDropDown, setShowWorkerTypeDropDown] = useState(false);
  let REQUIRED_FIELDS = [];
  if (WORKER_TYPE === "EMPLOYEE") {
    REQUIRED_FIELDS = [
      //"email",
      //"password",
      //"skills",
      "first_name",
      "last_name",
      "worker_type",
      "qualification",
      "hourly_wages",
      "city",
    ];
  } else {
    REQUIRED_FIELDS = [
      //"email",
      //"password",
      //"skills",
      "first_name",
      "last_name",
      "worker_type",
      "qualification",
      "hourly_wages",
      "overtimePercent",
      "city",
    ];
  }

  const [validationErrors, setValidationErrors] = useState({});

  const { data: skillsList, refetch: skillsListrefetch } =
    useQuery(getAllSkills);

  const { data: workerData, loading } = useQuery(GET_WORKER_DATA, {
    variables: { input: { id: WorkerId } },
  });

  const {
    data: salaryList,
    refetch: refetchSalarylist,
    error: salaryListError,
  } = useQuery(GET_SALARY_DATA, {
    variables: { input: { profile_id: userID } },
  });

  const [
    editWorker,
    {
      data: editWorkerData,
      loading: editWorkerLoading,
      error: editWorkerErroe,
    },
  ] = useMutation(EDIT_WORKER);

  const [showSkillsDropDown, setshowSkillsDropDown] = useState(false);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    //email: "",
    skills: "",
    description: "",
    //password: "framinia2024!",
    //role: "CONSULTANT",
    OVERTIME_ALLOWED: false,
    qualification: "",
    hourly_wages: 0,
    worker_type: "",
    overtimePercent: 30,
    city: "",
  });

  useEffect(() => {
    if (workerData) {
      setInitialValues({
        first_name: workerData?.userProfile?.first_name,
        last_name: workerData?.userProfile?.last_name,
        //email: workerData?.userProfile?.email,
        skills: workerData?.userProfile?.profile?.skills || "",
        description: workerData?.userProfile?.profile?.description || "",
        //password: workerData?.userProfile?.password || "framinia2024!",
        //role: "CONSULTANT",
        OVERTIME_ALLOWED:
          workerData?.userProfile?.profile?.OVERTIME_ALLOWED || false,
        qualification: workerData?.userProfile?.profile?.qualification || "",
        hourly_wages: workerData?.userProfile?.profile?.hourly_wages || 0,
        worker_type: workerData?.userProfile?.profile?.worker_type || "",
        overtimePercent: workerData?.userProfile?.profile?.overtimePercent || 30,
        city: workerData?.userProfile?.profile?.city || ""
      });
    }
  }, [workerData]);

  let formattedSkills = [];

  if (skillsList?.getAllSkills?.length) {
    formattedSkills = skillsList?.getAllSkills.map((skill) => {
      return {
        label: skill?.name,
        value: skill?.id,
      };
    });
  }

  // const [createWorker, { data, loading, error }] =
  //   useMutation(createWorkerQuery);

  const submitData = async (values) => {
    values.hourly_wages = parseFloat(values.hourly_wages);
    values.overtimePercent = parseInt(values.overtimePercent);
    setValidationErrors({});
    const isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    try {
      if (isValidForm) {
        if (WorkerId) {
          await editWorker({
            variables: {
              input: { ...values, id: workerData?.userProfile?.profile?.id },
            },
          });
          refetchSalarylist();
          //navigation.navigate("WorkersList");
        }
        //await createWorker({ variables: { input: values } });
        //navigation.navigate("WorkersList");
      }
    } catch (error) {
      setValidationErrors({ backendError: `${error?.message}*` });
    }
  };

  return (
    <>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <Text
              variant="displayMedium"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {WorkerId ? "Modifica Lavoratore" : " Nuovo Lavoratore"}
            </Text>

            {validationErrors["backendError"] && (
              <View>
                <Text
                  className="text-red-400 text-sm p-2"
                  style={{ color: "red" }}
                >
                  {validationErrors["backendError"]}
                </Text>
              </View>
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSaving(true); // Imposta lo stato del salvataggio su true
                await submitData(values); // Esegui la funzione di salvataggio

                // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                setTimeout(() => {
                  setIsSaving(false);
                  setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
              }}
              enableReinitialize
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <View className="flex flex-col w-full p-2 gap-2">
                  <View>
                    <TextInput
                      label="Nome"
                      onChangeText={handleChange("first_name")}
                      onBlur={handleBlur("first_name")}
                      value={values.first_name}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      className="mb-3"
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["first_name"] && (
                      <View>
                        <Text
                          className="text-red-400 text-sm p-2"
                          style={{ color: "red" }}
                        >
                          {validationErrors["first_name"]}
                        </Text>
                      </View>
                    )}
                  </View>

                  <View>
                    <TextInput
                      label="Cognome"
                      onChangeText={handleChange("last_name")}
                      onBlur={handleBlur("last_name")}
                      value={values.last_name}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      className=" mb-3"
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["last_name"] && (
                      <View>
                        <Text
                          className="text-red-400 text-sm p-2"
                          style={{ color: "red" }}
                        >
                          {validationErrors["last_name"]}
                        </Text>
                      </View>
                    )}
                  </View>

                  <TextInput
                    label="Descrizione"
                    onChangeText={handleChange("description")}
                    onBlur={handleBlur("description")}
                    value={values.description}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    className=" mb-3"
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                    multiline={true}
                    numberOfLines={4}
                  />

                  <View>
                    <TextInput
                      label="Qualifica"
                      onChangeText={handleChange("qualification")}
                      onBlur={handleBlur("qualification")}
                      value={values.qualification}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      className=" mb-3"
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["qualification"] && (
                      <Text className="text-red-400" style={{ color: "red" }}>
                        {validationErrors["qualification"]}
                      </Text>
                    )}
                  </View>

                  <View>
                    <Dropdown
                      style={{
                        backgroundColor: colors.grey,
                        borderTopStartRadius: 4,
                        borderTopEndRadius: 4,
                        padding: 15,
                        paddingHorizontal: 30,
                        //width: '100%',
                        justifyContent: 'flex-end'
                      }}
                      containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                      data={workerTypes}
                      labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                      valueField="value" // Definisce il campo del valore per gli elementi della lista
                      value={values.worker_type}
                      placeholder="Tipologia lavoratore"
                      onChange={(item) => [setFieldValue("worker_type", item.value),
                      setWorkerType(item.value)]}
                      selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                      inputSearchStyle={{
                        backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                        overflow: 'hidden',
                      }}

                      renderItem={(item) => (
                        <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                          {item.label}
                        </Text>
                      )}
                    />
                    {validationErrors["worker_type"] && (
                      <Text
                        className="text-red-400 p-1"
                        style={{ color: "red" }}
                      >
                        {validationErrors["worker_type"]}
                      </Text>
                    )}
                  </View>

                  {WORKER_TYPE === "FREELANCE" && (
                    <View>
                      <TextInput
                        label="Percentuale di Overtime"
                        onChangeText={handleChange("overtimePercent")}
                        onBlur={handleBlur("overtimePercent")}
                        value={values.overtimePercent.toString()}
                        theme={{
                          colors: {
                            onSurfaceVariant: "white",
                          },
                        }}
                        className=" mb-3"
                        style={{ backgroundColor: colors.grey }}
                        activeUnderlineColor="white"
                        underlineStyle={{
                          borderWidth: 1,
                          borderColor: "#53555e",
                        }}
                      >
                      </TextInput>
                    </View>
                  )}

                  <View>
                    <Dropdown
                      style={{
                        backgroundColor: colors.grey,
                        borderTopStartRadius: 4,
                        borderTopEndRadius: 4,
                        padding: 15,
                        paddingHorizontal: 30,
                        //width: '100%',
                        justifyContent: 'flex-end'
                      }}
                      containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                      data={formattedSkills}
                      labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                      valueField="value" // Definisce il campo del valore per gli elementi della lista
                      value={values.skills}
                      placeholder="Skills"
                      onChange={(item) => setFieldValue("skills", item.value)}
                      selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                      inputSearchStyle={{
                        backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                        overflow: 'hidden',
                      }}

                      renderItem={(item) => (
                        <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                          {item.label}
                        </Text>
                      )}
                    />
                    {validationErrors["skills"] && (
                      <Text className="text-red-400" style={{ color: "red" }}>
                        {validationErrors["skills"]}
                      </Text>
                    )}
                  </View>

                  <View>
                    <Dropdown
                      style={{
                        backgroundColor: colors.grey,
                        borderTopStartRadius: 4,
                        borderTopEndRadius: 4,
                        padding: 15,
                        paddingHorizontal: 30,
                        justifyContent: 'flex-end'
                      }}
                      containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                      data={City}
                      labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                      valueField="value" // Definisce il campo del valore per gli elementi della lista
                      value={values.city}
                      placeholder="Sede"
                      onChange={(item) => [setFieldValue("city", item.value), setCity(item.value)]}
                      selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                      itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                      inputSearchStyle={{
                        backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                        overflow: 'hidden',
                      }}

                      renderItem={(item) => (
                        <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                          {item.label}
                        </Text>
                      )}
                    />
                  </View>

                  <View>
                    <TextInput
                      label="Salario Orario"
                      onChangeText={handleChange("hourly_wages")}
                      onBlur={handleBlur("hourly_wages")}
                      value={values.hourly_wages.toString()}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      className=" mb-3"
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                      keyboardType="numeric"
                    />
                    {validationErrors["hourly_wages"] && (
                      <Text className="text-red-400" style={{ color: "red" }}>
                        {validationErrors["hourly_wages"]}
                      </Text>
                    )}
                  </View>

                  <View className="p-2">
                    <TouchableOpacity
                      onPress={() => {
                        setFieldValue(
                          "OVERTIME_ALLOWED",
                          !values.OVERTIME_ALLOWED
                        );
                      }}
                      className="flex flex-row w-fit my-4 items-center justify-start"
                    >
                      <Checkbox.Android
                        status={
                          values.OVERTIME_ALLOWED ? "checked" : "unchecked"
                        }
                        color="white"
                        underlayColor="white"
                      />
                      <Text className="text-white">Consenti paga overtime</Text>
                    </TouchableOpacity>
                  </View>

                  <View className="py-2 w-full items-center justify-center">
                    <Button
                      mode="contained"
                      onPress={handleSubmit as (e: unknown) => void}
                      className="p-2"
                      buttonColor="#7d0001"
                      disabled={isSaving}
                      style={{ borderRadius: 10 }}
                    >
                      {loading ? (
                        <ActivityIndicator animating={true} color={"red"} />
                      ) : (
                        <Text className="text-white font-bold">Conferma</Text>
                      )}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
            {WorkerId && (
              <View>
                <Text variant="displayMedium"
                  className="text-white pl-2 pb-4 font-semibold"> Lista dei salari</Text>
                <ScrollView className="mb-80">
                  {salaryList?.getAllSalaryByProfileID && salaryList?.getAllSalaryByProfileID?.length > 0 ? (
                    salaryList?.getAllSalaryByProfileID?.map((salary) => {
                      return (
                        <SalaryCard
                          key={salary.id}
                          data={salary}
                          navigation={navigation}
                          refetchSalarylist={refetchSalarylist}
                          noNavigate
                          noEdit
                        />
                      );
                    })
                  ) : (
                    <Text className="text-xl font-bold text-white pl-1">
                      Nessun salario disponibile*
                    </Text>
                  )}
                </ScrollView>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default CreateNewWorker;
