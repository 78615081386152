import React, {
  createElement,
  useState,
  ChangeEvent,
  KeyboardEvent,
  CSSProperties,
} from "react";
import colors from "../styles/colors";

interface MyWebDatePickerProps {
  fieldName: string;
  setFieldValue: (fieldName: string, value: Date) => void;
  dateFill?: any; 
}

const MyWebDatePicker: React.FC<MyWebDatePickerProps> = ({
  fieldName,
  setFieldValue,
  dateFill,
}) => {
  const [date, setDate] = useState<Date>(
    (dateFill ? new Date(parseInt(dateFill)) : false) || new Date(Date.now())
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Allow backspace, tab, escape, and enter
    if (event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Escape' || event.key === 'Enter' ||
        // Allow Ctrl/cmd combinations (copy, paste)
        event.ctrlKey === true || event.metaKey === true ||
        // Allow navigation keys (arrows)
        event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      return; // skip preventDefault to allow the keypress
    }
    // Prevent non-numeric characters
    if (/^\d$/.test(event.key) === false) {
      event.preventDefault();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const newDate = new Date(event.target.value);
      if (!isNaN(newDate.getTime())) { // Valid date check
        setDate(newDate);
        setFieldValue(fieldName, newDate);
      }
    } catch (error) {
      console.error("Invalid date input", error);
    }
  };

  return React.createElement("input", {
    type: "date",
    value: date.toISOString().split("T")[0],
    onChange: handleChange,
    onKeyDown: handleKeyDown, 
    onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
      // Optionally handle paste
      event.preventDefault(); 
      const pasteData = event.clipboardData.getData('text/plain');
      const newDate = new Date(pasteData);
      if (!isNaN(newDate.getTime())) {
        setDate(newDate);
        setFieldValue(fieldName, newDate);
      }
    },
    style: {
      height: 30,
      padding: 5,
      border: "2px solid",
      borderColor: colors.white,
      borderRadius: 10,
      color: colors.white,
      width: 250,
      margin: 2,
      backgroundColor: colors.grey
    } as CSSProperties,
  });
};

export default MyWebDatePicker;