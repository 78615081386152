import * as React from "react";
import { View, SafeAreaView, ScrollView } from "react-native";
import {
  Button,
  TextInput,
  HelperText,
  Text,
  ActivityIndicator,
} from "react-native-paper";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { getStorageData, storeData } from "../libs/storage";
import { USER_TOKEN_KEY } from "../constants/App.constants";
import * as SplashScreen from "expo-splash-screen";
import { useCallback, useEffect, useState, useContext } from "react";
import { useAuth } from './AuthContext';
import colors from "../styles/colors";

SplashScreen.preventAutoHideAsync();

const LOGIN_MUTATION = gql`
  mutation login($input: loginInput) {
    login(input: $input) { 
      token
      changePasswordRequired
    }
  }
`;

const getUserProfileQuery = gql`
  query getUserProfile {
    getUserProfile {
      profile_id
      email
      role
    }
  }
`;

const CHECK_PASSWORD_EXPIRATION = gql`
  query CheckPasswordExpiration($input: CheckPasswordExpirationInput) {
    CheckPasswordExpiration(input: $input) {
      last_password_change
      isExpired
      daysUntilExpiration
    }
  }
`;

export default function Login({ navigation }: any) {
  const { setIsAuthenticated } = useAuth();
  const [appIsReady, setAppIsReady] = useState(false);
  const [isPasswordHidden, setisPasswordHidden] = useState(true);
  const [isProfileId, setProfileId] = useState("");
  const [isEmail, setEmail] = useState("");

  const navigateToRoleDashboards = async () => {
    let profileData;
    if (USER_TOKEN_KEY) {
      profileData = await userProfilerefetch();
    }
    if (profileData?.data?.getUserProfile?.profile_id) {
      await storeData("role", profileData?.data?.getUserProfile?.role);
      await storeData("profileID", profileData?.data?.getUserProfile?.profile_id);
      setProfileId(profileData?.data?.getUserProfile?.profile_id);
    }
    const ADMIN_ROLES = ["ADMIN", "AREA_MANAGER", "PROJECT_MANAGER"];
    if (
      profileData?.data?.getUserProfile?.role &&
      ADMIN_ROLES.includes(profileData?.data?.getUserProfile?.role)
    ) {
      setIsAuthenticated(true);
      navigation.push("ManagersDashboard");
    } else if (
      profileData?.data?.getUserProfile?.role &&
      profileData?.data?.getUserProfile?.role === "CONSULTANT"
    ) {
      setIsAuthenticated(true);
      navigation.push("WorkersDashboard");
    }
  };

  const { data: userProfileData, refetch: userProfilerefetch } = useQuery(getUserProfileQuery, {
    fetchPolicy: "network-only",
  },);

  const { data: checkExpiration, refetch: refetchExpiration } = useQuery(CHECK_PASSWORD_EXPIRATION, {
    variables: { input: { profileId: isProfileId } },
    skip: !isProfileId,
  });

  const [loginUser, { data, loading, error }] = useMutation(LOGIN_MUTATION);

  const checkIsTokenPresent = async () => {
    await getStorageData(USER_TOKEN_KEY)
      .then(async (tokenCurrent) => {
        setAppIsReady(true);
        if (tokenCurrent) {
          //setIsAuthenticated(true);
          await navigateToRoleDashboards();
        }
      })
      .catch(() => {
        console.log(error);
      });
  };

  useEffect(() => {
    checkIsTokenPresent();
    if (data) {
      storeData(USER_TOKEN_KEY, data.login.token)
        .then(async () => {
          //setIsAuthenticated(true); 
          await navigateToRoleDashboards();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (error) {
      console.log(error.graphQLErrors, "login error");
    }
  }, [data]);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  async function login(values) {
    try {
      setIsAuthenticated(false);
      const response = await loginUser({ variables: { input: values } });
      const { data } = response;
      await storeData("email", values.email);
      setEmail(values.email);

      if (data.login.changePasswordRequired) {
        //await storeData("isExpired", checkExpiration.isExpired);
        await storeData("changePasswordRequired", data?.login?.changePasswordRequired);
        alert('È necessario cambiare la password prima di procedere.');
        navigation.navigate('ChangePassword');
      } else if (data.login.token) {
        await storeData(USER_TOKEN_KEY, data.login.token);
        await navigateToRoleDashboards();
      }
      else {
        console.log('Errore di login: Token non disponibile.');
      }
    } catch (error) {
      console.log(error, "error in login");
    }
  }

  return (
    <View onLayout={onLayoutRootView}>
      <View className="flex flex-col h-screen items-center justify-start" style={{backgroundColor: colors.background}}>
        <Text
          className="text-white font-bold text-8xl my-20"
          //style={{ fontFamily: "Lato" }}
        >
          ReportOne
        </Text>

        <View className="w-2/5 p-10 first-letter: rounded-xl border-2 border-bordercolor">
          <Text
            variant="displayMedium"
            className="text-white pb-4 font-semibold text-center"
          >
            Login
          </Text>
          <SafeAreaView>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={login}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <View className="gap-10">
                  <TextInput
                    label="Email"
                    right={<TextInput.Icon icon="email" iconColor="white" />}
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey}}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />

                  <TextInput
                    label="Password"
                    secureTextEntry={isPasswordHidden}
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    right={
                      <TextInput.Icon
                        icon="eye"
                        iconColor="white"
                        onPress={() => setisPasswordHidden(!isPasswordHidden)}
                      />
                    }
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey}}
                    activeUnderlineColor="white"
                    underlineStyle={{
                      borderWidth: 1,
                      borderColor: "#53555e",
                    }}
                  />
                  <Text
                    onPress={() => {
                      navigation.navigate('ForgotPassword');
                    }}
                    className="text-white mt-4 text-start"
                    style={{ textDecorationLine: 'underline' }} // Style as needed
                  >
                    Ho dimenticato la password
                  </Text>

                  {error?.graphQLErrors &&
                    error.graphQLErrors.map((err, idx) => {
                      return (
                        <HelperText
                          type="error"
                          visible={!!error?.graphQLErrors.length}
                          key={idx}
                        >
                          {err.message}
                        </HelperText>
                      );
                    })}

                  <View className="py-2 items-center justify-center">
                    <Button
                      mode="contained"
                      onPress={handleSubmit as (e: unknown) => void}
                      //className="p-1 bg-yellow"
                      style={{backgroundColor: colors.amaranto, borderRadius: 10}}
                    >
                      {loading ? (
                        <ActivityIndicator animating={true} color={"red"} />
                      ) : (
                        <Text className="text-white text-2xl font-bold">
                          Login
                        </Text>
                      )}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
          </SafeAreaView>
        </View>
      </View>
    </View>
  );
}
