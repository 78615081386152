import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, TouchableOpacity, View, StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  Button,
  TextInput,
  Text,
  Dialog,
  Portal,
  ActivityIndicator,
  Checkbox,
} from "react-native-paper";
import { Dropdown } from "react-native-element-dropdown";
import { validate } from "../../libs/form.validate";
import { useFocusEffect } from "@react-navigation/native";
import SalaryCard from "../../components/SalaryCard";
import MultiSelect from 'react-native-multiple-select';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Loader from "../../components/Loader";
import colors from "../../styles/colors";

const CREATE_NEW_USER = gql`
  mutation Signup($input: signupInput) {
    signup(input: $input) {
      status
      message
    }
  } 
`;
const GET_ALL_SKILLS = gql`
  query getAllSkills {
    getAllSkills {
      name
      id
    }
  }
`;
const GET_USER_DATA = gql`
  query getUserProfileForAdmin($input: getUserProfileForAdminInput) {
    getUserProfileForAdmin(input: $input) {
      user {
        first_name
        last_name
        email
        password
      }
      qualification
      hourly_wages
      worker_type
      OVERTIME_ALLOWED
      overtimePercent
      latestHourlyWage
      description
      areas {
        id
        name
      }
      role {
        id
        role
      }
      skills
      skillsString
    }
  }
`;

const UPDATE_USER_DETAILS = gql`
  mutation updateUserForAdmin($input: updateUserForAdminInput) {
    updateUserForAdmin(input: $input) {
      message
      status
    }
  }
`;

const GET_SALARY_DATA = gql`
  query getAllSalaryByProfileID($input: getAllSalaryByProfileIDInput) {
    getAllSalaryByProfileID(input: $input) {
      id
      profile_id
      created_at
      amount
    }
  }
`;

const getAllArea = gql`
  query getAllAreas {
    getAllAreas {
      id
      name
    }
  }
`;

function CreateNewUser({ navigation, route }) {
  const [userID] = useState(route?.params?.userId);
  const [roleName] = useState(route?.params?.role);
  const [isPasswordHidden, setisPasswordHidden] = useState(true);
  const [ROLE, setROLE] = useState("");
  const [isAddAreaModalVisible, setisAddAreaModalVisible] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [allAreas, setAllAreas] = useState([]);

  const { loading: areasLoading, error: areasError, data: areasData, refetch } = useQuery(getAllArea);

  let REQUIRED_FIELDS = [];
  const [isSaving, setIsSaving] = useState(false);

  if (ROLE === "AREA_MANAGER") {
    REQUIRED_FIELDS = [
      "first_name",
      "last_name",
      "email",
      "password",
      "role",
      "areaIds",
    ];
  } else {
    REQUIRED_FIELDS = [
      "first_name",
      "last_name",
      "email",
      "password",
      "role",
    ];
  }

  const [validationErrors, setValidationErrors] = useState({});

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: userID ? "" : "framinia2024!",
    role: "",
    areaIds: [],
  });

  const [showRolesDropDown, setShowRolesDropDown] = useState(false);
  const [showSkillsDropDown, setshowSkillsDropDown] = useState(false);

  const roles = [
    { label: "AREA MANAGER", value: "AREA_MANAGER" },
    { label: "PROJECT MANAGER", value: "PROJECT_MANAGER" },
    { label: "WORKER", value: "CONSULTANT" },
  ];

  useEffect(() => {
    if (areasData?.getAllAreas) {
      setAllAreas(areasData.getAllAreas);
    }
  }, [areasData]);

  useEffect(() => {
    setSelectedAreas(initialValues.areaIds);
  }, [initialValues.areaIds]);

  const { data: skillsList, refetch: skillsListrefetch } = useQuery(GET_ALL_SKILLS);

  const {
    data: userData,
    refetch: refetchUserData,
    error: userDataError,
  } = useQuery(GET_USER_DATA, {
    variables: { input: { profile_id: userID } },
  });

  const {
    data: salaryList,
    refetch: refetchSalarylist,
    error: salaryListError,
  } = useQuery(GET_SALARY_DATA, {
    variables: { input: { profile_id: userID } },
  });


  useFocusEffect(
    React.useCallback(() => {
      refetchUserData();
      skillsListrefetch();
    }, [])
  );

  useEffect(() => {
    if (userData && userID) {
      setInitialValues({
        first_name: userData?.getUserProfileForAdmin?.user?.first_name,
        last_name: userData?.getUserProfileForAdmin?.user?.last_name,
        email: userData?.getUserProfileForAdmin?.user?.email,
        role: userData?.getUserProfileForAdmin?.role?.role,
        password: "",
        areaIds: userData?.getUserProfileForAdmin?.areas?.map(area => area.id) || [],
      });
      setSelectedAreas(userData?.getUserProfileForAdmin?.areas?.map(area => area.id) || []);
    }
  }, [userData, userID]);

  let formattedSkills = [];
  if (skillsList?.getAllSkills?.length) {
    formattedSkills = skillsList?.getAllSkills.map((skill) => {
      return {
        label: skill?.name,
        value: skill?.id,
      };
    });
  }

  const nonAllocatedAreas = allAreas.filter(area => !selectedAreas.includes(area.id));

  const [createNewUser, { data, loading, error }] = useMutation(CREATE_NEW_USER);

  const [
    editUserDetails,
    { data: editedUserData, loading: editUserLoading, error: editUserError },
  ] = useMutation(UPDATE_USER_DETAILS);

  const submitData = async (values) => {
    setValidationErrors({});
    const fieldsToValidate = userID ? REQUIRED_FIELDS.filter(field => field !== "password") : REQUIRED_FIELDS;

    const isValidForm = await validate(values, setValidationErrors, fieldsToValidate);

    try {
      if (isValidForm) {
        if (userData && userID) {
          await editUserDetails({
            variables: {
              input: {
                profile_id: userID,
                first_name: values.first_name,
                last_name: values.last_name,
                areaIds: values.areaIds,
                password: values.password ? values.password : undefined,
              },
            },
          });
          navigation.goBack();
        } else {
          await createNewUser({ variables: { input: values } });
          navigation.goBack();
        }
        refetchSalarylist();
      }
    } catch (error) {
      setValidationErrors({ backendError: `${error?.message}*` });
    }
  };

  const styles = StyleSheet.create({
    box: {
      flex: 1,
      borderColor: colors.white,
      borderWidth: 1,
      backgroundColor: '#2A2C38',
    },
    removeIcon: {
      padding: 5,
    },
    multiselect: {
      backgroundColor: colors.grey,
      borderColor: '#53555e',
      borderWidth: 1,
      borderRadius: 5,
      padding: 10,
      marginBottom: 10,
    },
    multiselectItem: {
      color: 'white',
    },
    dropdown: {
      backgroundColor: colors.grey,
      borderColor: '#53555e',
      borderWidth: 1,
      borderRadius: 5,
    },
    areaTag: {
      backgroundColor: colors.grey,
      padding: 5,
      margin: 2,
      borderRadius: 5,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    areaText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#FFF',
      paddingLeft: 5,
    },
  });

  return (
    <>
      <Loader loading={areasLoading} />
      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <Text
              variant="displayMedium"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {userID ? "Modifica Utente" : "Nuovo Utente"}
            </Text>

            {validationErrors["backendError"] && (
              <View>
                <Text
                  className="text-red-400 text-sm p-2"
                  style={{ color: "red" }}
                >
                  {validationErrors["backendError"]}
                </Text>
              </View>
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSaving(true);
                await submitData(values);
                setTimeout(() => {
                  setIsSaving(false);
                  setSubmitting(false);
                }, 1000);
              }}
              enableReinitialize
            >
              {({ handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
                const handleAddArea = (areaId) => {
                  const updatedAreas = [...selectedAreas, areaId];
                  setSelectedAreas(updatedAreas);
                  setFieldValue("areaIds", updatedAreas);
                  setisAddAreaModalVisible(false);
                };

                const handleRemoveArea = (areaId) => {
                  const updatedAreas = selectedAreas.filter(id => id !== areaId);
                  setSelectedAreas(updatedAreas);
                  setFieldValue("areaIds", updatedAreas);
                };

                return (
                  <View className="flex flex-col w-full p-2 gap-2">
                    <View>
                      <TextInput
                        label="Nome"
                        onChangeText={handleChange("first_name")}
                        onBlur={handleBlur("first_name")}
                        value={values.first_name}
                        theme={{ colors: { onSurfaceVariant: "white" } }}
                        style={{ backgroundColor: colors.grey }}
                        activeUnderlineColor="white"
                        underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                      />
                      {validationErrors["first_name"] && (
                        <Text className="text-red-400 p-1" style={{ color: "red" }}>
                          {validationErrors["first_name"]}
                        </Text>
                      )}
                    </View>

                    <View>
                      <TextInput
                        label="Cognome"
                        onChangeText={handleChange("last_name")}
                        onBlur={handleBlur("last_name")}
                        value={values.last_name}
                        theme={{ colors: { onSurfaceVariant: "white" } }}
                        style={{ backgroundColor: colors.grey }}
                        activeUnderlineColor="white"
                        underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                      />
                      {validationErrors["last_name"] && (
                        <Text className="text-red-400 p-1" style={{ color: "red" }}>
                          {validationErrors["last_name"]}
                        </Text>
                      )}
                    </View>

                    {!userID && (
                      <View>
                        <TextInput
                          label="Email"
                          onChangeText={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                          theme={{ colors: { onSurfaceVariant: "white" } }}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="white"
                          underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                        />
                        {validationErrors["email"] && (
                          <Text className="text-red-400 p-1" style={{ color: "red" }}>
                            {validationErrors["email"]}
                          </Text>
                        )}
                      </View>
                    )}

                    {/* {!userID && ( */}
                    <View>
                      <TextInput
                        label={userID ? "Nuova Password" : "Imposta Password (DEFAULT)"}
                        onChangeText={handleChange("password")}
                        secureTextEntry={isPasswordHidden}
                        onBlur={handleBlur("password")}
                        value={values.password}
                        right={<TextInput.Icon icon={isPasswordHidden ? "eye" : "eye-off"} onPress={() => setisPasswordHidden(!isPasswordHidden)} />}
                        theme={{ colors: { onSurfaceVariant: "white" } }}
                        style={{ backgroundColor: colors.grey }}
                        activeUnderlineColor="white"
                        underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                      />
                      {validationErrors["password"] && (
                        <Text className="text-red-400 p-1" style={{ color: "red" }}>
                          {validationErrors["password"]}
                        </Text>
                      )}
                    </View>
                    {/* )} */}

                    {!userID && (
                      <View>
                        <Dropdown
                          style={{
                            backgroundColor: colors.grey,
                            borderTopStartRadius: 4,
                            borderTopEndRadius: 4,
                            padding: 15,
                            paddingHorizontal: 30,
                            //width: '100%',
                            justifyContent: 'flex-end'
                          }}
                          containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                          data={roles}
                          //search
                          //searchPlaceholder="Trova progetto..."
                          labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                          valueField="value" // Definisce il campo del valore per gli elementi della lista
                          value={ROLE}
                          placeholder='Seleziona ruolo'
                          onChange={(item) => setROLE(item.value)}
                          selectedTextStyle={{ color: colors.white }}
                          placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, }}
                          inputSearchStyle={{
                            backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                            overflow: 'hidden',
                          }}

                          renderItem={(item) => (
                            <Text style={{ color: colors.white, padding: 10 }}>
                              {item.label}
                            </Text>
                          )}
                        />
                        {validationErrors["role"] && (
                          <Text className="text-red-400 p-1" style={{ color: "red" }}>
                            {validationErrors["role"]}
                          </Text>
                        )}
                      </View>
                    )}

                    <View className="flex flex-row w-full p-2">
                      {values.role === "CONSULTANT" || values.role === "AREA_MANAGER" ? (
                        <Text variant="displaySmall" className="text-white font-semibold w-full">
                          Aree di Competenza
                        </Text>
                      ) : null}
                      {values.role === "CONSULTANT" || values.role === "AREA_MANAGER" ? (
                        <View>
                          <Button
                            icon="plus"
                            mode="contained"
                            buttonColor={colors.amaranto}
                            textColor={colors.white}
                            onPress={() => setisAddAreaModalVisible(true)}
                            className="flex flex-col justify-end my-2"
                            style={{ borderRadius: 10 }}
                          >
                            <Text className="text-white font-bold">Aggiungi Area di Competenza</Text>
                          </Button>
                        </View>
                      ) : null}

                      <Portal>
                        <Dialog
                          visible={isAddAreaModalVisible}
                          onDismiss={() => setisAddAreaModalVisible(false)}
                          style={{ height: 360, width: 360, padding: 2, alignSelf: "center", backgroundColor: colors.background }}
                        >
                          <Dialog.ScrollArea>
                            <ScrollView showsHorizontalScrollIndicator={false}>
                              <View className="flex flex-col w-full gap-2 items-start">
                                {nonAllocatedAreas.map((area, index) => (
                                  <Button
                                    key={index}
                                    mode="contained"
                                    onPress={() => handleAddArea(area.id)}
                                    style={{ backgroundColor: colors.grey, borderRadius: 4, padding: 5 }}
                                    className="w-full border-b border-secondary items-start"
                                  >
                                    <Text variant="titleMedium" className="text-white pb-4 font-semibold">
                                      {area?.name}
                                    </Text>
                                  </Button>
                                ))}
                              </View>
                            </ScrollView>
                          </Dialog.ScrollArea>
                        </Dialog>
                      </Portal>
                    </View>

                    <View className="flex flex-col w-full p-2">
                      {selectedAreas.map((areaId, index) => {
                        const area = allAreas.find((area) => area.id === areaId);

                        return (
                          <View key={index} style={styles.areaTag}>
                            <Text style={styles.areaText}>{area.name}</Text>
                            <TouchableOpacity onPress={() => handleRemoveArea(area.id)} style={styles.removeIcon}>
                              <Icon name="close" size={20} color="white" />
                            </TouchableOpacity>
                          </View>
                        );
                      })}
                    </View>

                    <View className="py-4 my-8 w-full items-center justify-center">
                      <Button
                        mode="contained"
                        onPress={handleSubmit as (e: unknown) => void}
                        className="p-2"
                        buttonColor={colors.amaranto}
                        disabled={isSaving}
                        style={{ borderRadius: 10 }}
                      >
                        {loading ? (
                          <ActivityIndicator animating={true} color={"red"} />
                        ) : (
                          <Text className="text-white font-bold">
                            Salva utente
                          </Text>
                        )}
                      </Button>
                    </View>
                  </View>
                );
              }}
            </Formik>
          </View>
        </ScrollView >
      </View >
    </>
  );
}

export default CreateNewUser;
