import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { Badge, Text } from "react-native-paper";
import { GET_WORKER_TASKS_BY_PROJECT_ID } from "../../../graphql/project.resolver";
import {
  GET_WORKER_PROJECTS,
  USER_PROFILE_NAME,
} from "../../../graphql/user.resolver";
import TaskCard from "../../../components/TaskCard";
import Loader from "../../../components/Loader";
import { useFocusEffect } from "@react-navigation/native";
import colors from "../../../styles/colors";

function WorkerTimelineScreen({ navigation }) {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const {
    data: projectList,
    loading,
    refetch: refetchProjectList,
    error: projectListError,
  } = useQuery(GET_WORKER_PROJECTS, { nextFetchPolicy: "network-only" });

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_NAME);

  const [
    getWorkerTasksByProject,
    {
      data: tasksData,
      refetch: refetchTasklist,
      loading: tasksLoading,
      error: taskError,
    },
  ] = useLazyQuery(GET_WORKER_TASKS_BY_PROJECT_ID, {
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (projectList?.getWorkerProjects?.projects && !tasksData) {
      // Filter the projects to only include open ones
      const openProjects = projectList?.getWorkerProjects?.projects.filter(
        project => project.status !== "Chiusa"
      );

      const firstOpenProject = openProjects[0];

      setSelectedProjectId(firstOpenProject?.id);
      getWorkerTasksByProject({
        variables: { input: { project_id: firstOpenProject?.id } },
      });
    }
  }, [projectList]);

  useFocusEffect(
    React.useCallback(() => {
      refetchProjectList();
      refetchUserProfile();
      refetchTasklist();
    }, [])
  );

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <Loader loading={loading || profileLoading || tasksLoading} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4 gap-6">
            <View>
              <Text
                variant="headlineLarge"
                className="text-white font-semibold"
              >
                Timeline
              </Text>
            </View>
 
            <ScrollView
              horizontal={true}
              className="flex flex-row p-2 gap-4"
              showsHorizontalScrollIndicator={true}
            >
              {projectList?.getWorkerProjects &&
              projectList?.getWorkerProjects?.projects &&
              projectList?.getWorkerProjects?.projects?.length ? (
                projectList?.getWorkerProjects?.projects
                .filter(project => project.status !== "Chiusa")
                .map((project, idx) => {
                  return (
                    <Badge
                      key={idx}
                      style={{
                        backgroundColor: colors.grey,
                        color: colors.white,
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        height: 50,
                        fontWeight: "500",
                        opacity: selectedProjectId === project?.id ? 1 : 0.5,
                        borderWidth: 1,
                        borderRadius: 10,
                        borderColor: colors.white,
                      }}
                      size={30}
                      onPress={() => {
                        console.log("Selected");
                        setSelectedProjectId(project.id);

                        getWorkerTasksByProject({
                          variables: { input: { project_id: project?.id } },
                        });
                      }}
                    >
                      {project.project_id.substring(0, 10)}
                    </Badge>
                  );
                })
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono Progetti disponibili*
                </Text>
              )}
            </ScrollView>

            <ScrollView className="mb-80">
              {tasksData?.getWorkerTasksOfProject?.tasks?.length ? (
                tasksData?.getWorkerTasksOfProject?.tasks.map((task, idx) => {
                  return (
                    <TaskCard
                      key={idx}
                      data={task}
                      navigation={navigation}
                      refetchTasklist={refetchTasklist}
                    />
                  );
                })
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono Task disponibili*
                </Text>
              )}
            </ScrollView>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkerTimelineScreen;
