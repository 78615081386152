import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Button } from "react-native-paper";
import { ResponsivePie } from '@nivo/pie';
import colors from '../styles/colors';

function AnalysisChartThree({ projectList, refetchProjectList }) {
  const [positiveMargin, setPositiveMargin] = useState([]);
  const [negativeMargin, setNegativeMargin] = useState([]);
  const [legendPositiveMargin, setLegendPositiveMargin] = useState([]);
  const [legendNegativeMargin, setLegendNegativeMargin] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [colorMap, setColorMap] = useState({});
  const colorScheme = ["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb", "#97e3d5", "#f94144", "#f3722c", "#f9c74f", "#90be6d"];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  useEffect(() => {
    let _positiveMargin = [];
    let _negativeMargin = [];
    let _colorMap = {};

    projectList.forEach((element, index) => {
      const totalCost = element.cost || 0;
      const totalBudget = element.compensatedB || 0;

      const operativeMargin = element.compensatedBudget || 0; // Calcola il margine operativo
      const color = colorScheme[index % colorScheme.length];
      if (element.project_id !== "clpsgs12q000vnx0h8zrsthw0") {
        const dataEntry = { id: element.project_id, value: Math.abs(operativeMargin).toFixed(1), color: color };
        if (operativeMargin >= 0) {
          // Usa _positiveMargin per l'inserimento temporaneo
          _positiveMargin.push(dataEntry);
        } else {
          // Usa _negativeMargin per l'inserimento temporaneo
          _negativeMargin.push(dataEntry);
        }
        _colorMap[element.project_id] = color;
      }
    });

    // Aggiorna lo stato con le variabili temporanee
    setPositiveMargin(_positiveMargin);
    setNegativeMargin(_negativeMargin);
    setColorMap(_colorMap);

    const sortedPositive = [..._positiveMargin].sort((a, b) => b.value - a.value).slice(0, 10);
    const sortedNegative = [..._negativeMargin].sort((a, b) => b.value - a.value).slice(0, 10);
    setLegendPositiveMargin(sortedPositive);
    setLegendNegativeMargin(sortedNegative);
  }, [projectList]); // Dipendenze di useEffect

  const negativePadAngle = negativeMargin.length < 3 ? 2 : 0.7;
  const negativeInnerRadius = negativeMargin.length < 3 ? 2 : 0.5;
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    refetchProjectList();
  }, [projectList]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderColor: colors.white,
      borderWidth: 1,
      backgroundColor: colors.grey, 
      paddingBottom: 30,
      borderRadius: 10,
    },
    tabContainer: {
      flexDirection: 'row',
    },
    tabButton: {
      flex: 1,
      borderRadius: 0,
      borderColor: colors.white,
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    tabButtonTextActive: {
      color: colors.white,
      backgroundColor: '#3b8af7',
    },
    tabButtonTextInactive: {
      color: colors.white,
      backgroundColor: 'transparent',
    },
    chartContainer: {
      flexDirection: 'row',
      alignItems: "center",
    },
    chartSection: {
      flex: 1,
    },
    primaryTextWhiteBold: {
      color: colors.white,
      fontSize: 35,
      fontWeight: 'bold',
      textAlign: "center",
    },
    textWhiteBold: {
      color: colors.white,
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: "center",
      marginLeft: -100,
    },
    labelContainer: {
      alignItems: 'center', 
    },
    graphContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around', 
    },
    graph: {
      height: 450, 
      width: '45%', 
      flexDirection: 'column',
    },
    label: {
      color: colors.white,
      fontWeight: 'bold',
      textAlign: 'center',
      alignItems: "center",
      paddingVertical: 4,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.primaryTextWhiteBold}>Margine</Text>
        <View style={styles.graphContainer}>
          <View style={styles.graph}>
            <Text style={styles.textWhiteBold}>Positivo</Text>
            <ResponsivePie
              data={positiveMargin}
              margin={{ top: 40, right: 90, bottom: 80, left: -20 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor="#333333"
              radialLabelsLinkColor={{ from: 'color' }}
              sliceLabelsSkipAngle={10}
              sortByValue={true}
              colors={d => colorMap[d.id]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              enableArcLinkLabels={false}
              legends={[
                {
                  data: legendPositiveMargin.map(item => ({
                    id: item.id,
                    label: truncateText(item.id, 20),
                    color: colorMap[item.id]
                  })),
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateY: 15,
                  translateX: 25,
                  itemWidth: 85,
                  itemHeight: 18,
                  itemTextColor: '#ffffff',
                  itemsSpacing: 4,
                  symbolSize: 20,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      }
                    }
                  ]
                }
              ]}
              theme={{
                background: colors.grey,
                axis: {
                  ticks: {
                    text: {
                      fill: '#ffffff',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  },
                },
                legends: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
                labels: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
              }}
            />
          </View>
          <View style={styles.graph}>
            <Text style={styles.textWhiteBold}>Negativo</Text>
            <ResponsivePie
              data={negativeMargin}
              margin={{ top: 40, right: 90, bottom: 80, left: 0 }}
              innerRadius={negativeMargin.length <= 2 ? 0.3 : 0.5} 
              padAngle={negativePadAngle} 
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor="#333333"
              radialLabelsLinkColor={{ from: 'color' }}
              sliceLabelsSkipAngle={10}
              colors={d => colorMap[d.id]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              enableArcLinkLabels={false}
              legends={[
                {
                  data: legendNegativeMargin.map(item => ({
                    id: item.id,
                    label: truncateText(item.id, 20),
                    color: colorMap[item.id],
                  })),
                  anchor: 'right',
                  direction: 'column',
                  translateY: 15,
                  translateX: 25,
                  itemWidth: 75,
                  itemHeight: 18,
                  itemTextColor: '#ffffff',
                  itemsSpacing: 4,
                  symbolSize: 20,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      }
                    }
                  ]
                }
              ]}
              theme={{
                background: colors.grey,
                axis: {
                  ticks: {
                    text: {
                      fill: '#ffffff',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  },
                },
                legends: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
                labels: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
}

export default AnalysisChartThree;