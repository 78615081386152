import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useState } from "react";
import { ScrollView, View, StyleSheet, Modal } from "react-native";
import { Button, TextInput, Text, ActivityIndicator, IconButton, Snackbar } from "react-native-paper";
import { validate } from "../libs/form.validate";
import colors from "../styles/colors";

const createArea = gql`
  mutation createArea($input: createAreaInput!) {
    createArea(input: $input) {
      id
      name
    }
  }
`;

const updateArea = gql`
  mutation updateArea($input: updateAreaInput!) {
    updateArea(input: $input) {
      id
      name
    }
  }
`;

const deleteArea = gql`
  mutation deleteArea($input: deleteAreaInput!) {
    deleteArea(input: $input) {
      id
      name
    }
  }
`;

const getAllArea = gql`
  query getAllAreas {
    getAllAreas {
      id
      name
    }
  }
`;

function AddNewAreas({ navigation }) {
    const [selectedArea, setSelectedArea] = useState(null);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [areaToDelete, setAreaToDelete] = useState(null);

    const { loading: areasLoading, error: areasError, data: areasData, refetch } = useQuery(getAllArea);

    const [createAreaMutation] = useMutation(createArea);
    const [updateAreaMutation] = useMutation(updateArea);
    const [deleteAreaMutation] = useMutation(deleteArea);

    let formattedAreas = [];

    if (areasData?.getAllAreas?.length) {
        formattedAreas = areasData?.getAllAreas.map((area) => ({
            label: area?.name,
            value: area?.id,
        }));
    }

    const checkIfAreaNameExists = (name) => {
        return formattedAreas.some(area => area.label.toLowerCase() === name.toLowerCase());
    };

    const handleCreateOrUpdateArea = async (values, { resetForm }) => {
        setValidationErrors({});
        const isValidForm = await validate(values, setValidationErrors, ['name']);

        if (isValidForm) {
            if (checkIfAreaNameExists(values.name) && (!selectedArea || selectedArea.name.toLowerCase() !== values.name.toLowerCase())) {
                setValidationErrors({ name: 'Il nome dell\'area esiste già.' });
                return;
            }

            try {
                if (selectedArea) {
                    await updateAreaMutation({ variables: { input: { id: selectedArea.id, name: values.name } } });
                } else {
                    await createAreaMutation({ variables: { input: { name: values.name } } });
                }
                refetch();
                resetForm();
                setSelectedArea(null);
                setShowSuccess(true); // Mostra il popup di successo
                setTimeout(() => setShowSuccess(false), 3000); // Nascondi il popup dopo 3 secondi
            } catch (error) {
                console.error("Errore durante il salvataggio:", error);
                setValidationErrors({ backendError: error.message });
            }
        }
    };

    const handleDeleteArea = async (id) => {
        try {
            await deleteAreaMutation({ variables: { input: { id } } });
            refetch();
            setShowDeleteConfirm(false);
            setAreaToDelete(null);
        } catch (error) {
            console.error("Errore durante l'eliminazione:", error);
            setValidationErrors({ backendError: error.message });
        }
    };

    const handleEditArea = (area) => {
        setSelectedArea(area);
    };

    const openDeleteConfirm = (area) => {
        setAreaToDelete(area);
        setShowDeleteConfirm(true);
    };

    const closeDeleteConfirm = () => {
        setAreaToDelete(null);
        setShowDeleteConfirm(false);
    };

    return (
        <View style={styles.container}>
            <ScrollView contentInsetAdjustmentBehavior="automatic" bounces={false} showsHorizontalScrollIndicator={false}>
                <View style={styles.innerContainer}>
                    <View style={styles.header}>
                        <Text variant="displayMedium" style={styles.headerText}>
                            Aree di competenza
                        </Text>
                    </View>

                    <Formik
                        initialValues={{ name: selectedArea ? selectedArea.name : '' }}
                        onSubmit={async (values, { setSubmitting }) => {
                            setIsSaving(true);
                            await handleCreateOrUpdateArea(values, { resetForm: () => setSubmitting(false) });
                            setTimeout(() => {
                                setIsSaving(false);
                                setSubmitting(false);
                            }, 1000);
                        }}
                        enableReinitialize
                    >
                        {({ handleChange, handleBlur, handleSubmit, values }) => (
                            <View style={styles.formContainer}>
                                <TextInput
                                    mode="outlined"
                                    label="Nome area"
                                    placeholderTextColor="white"
                                    onChangeText={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    value={values.name}
                                    style={styles.input}
                                    outlineColor="white"
                                    textColor="white"
                                    theme={{
                                        colors: {
                                            text: 'white',
                                            primary: 'white',
                                            placeholder: 'white',
                                            background: colors.grey,
                                            outline: 'white',
                                            onSurface: 'white',
                                        },
                                    }}
                                />
                                {validationErrors.name && <Text style={styles.errorText}>{validationErrors.name}</Text>}

                                <View style={styles.buttonContainer}>
                                    <Button
                                        mode="contained"
                                        onPress={handleSubmit as (e: unknown) => void}
                                        style={styles.button}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? (
                                            <ActivityIndicator animating={true} color={"red"} />
                                        ) : (
                                            <Text style={styles.buttonText}>
                                                {selectedArea ? 'Aggiorna Area' : 'Crea Area'}
                                            </Text>
                                        )}
                                    </Button>
                                </View>

                                {validationErrors.backendError && (
                                    <View>
                                        <Text style={styles.errorText}>
                                            {validationErrors.backendError}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )}
                    </Formik>

                    <View style={styles.listContainer}>
                        <ScrollView>
                            {formattedAreas.map((item) => (
                                <View key={item.value} style={styles.listItem}>
                                    <Text style={styles.listItemText}>{item.label}</Text>
                                    <View style={styles.listItemButtons}>
                                        <IconButton icon="pencil" size={25} iconColor="white" style={{ backgroundColor: colors.yellow, borderRadius: 4}} onPress={() => handleEditArea({ id: item.value, name: item.label })} />
                                        <IconButton icon="delete" size={25} iconColor="white" style={{ backgroundColor: colors.red, borderRadius: 4}} onPress={() => openDeleteConfirm({ id: item.value, name: item.label })} />
                                    </View>
                                </View>
                            ))}
                        </ScrollView>
                    </View>
                </View>
            </ScrollView>

            <Modal
                visible={showDeleteConfirm}
                transparent={true}
                animationType="slide"
                onRequestClose={closeDeleteConfirm}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalText}>Sei sicuro di voler eliminare l'area {areaToDelete?.name}?</Text>
                        <View style={styles.modalButtons}>
                            <Button
                                mode="contained"
                                onPress={() => handleDeleteArea(areaToDelete.id)}
                                style={styles.confirmButton}
                            >
                                <Text style={styles.buttonText}>Conferma</Text>
                            </Button>
                            <Button
                                mode="contained"
                                onPress={closeDeleteConfirm}
                                style={styles.cancelButton}
                            >
                                <Text style={styles.buttonText}>Annulla</Text>
                            </Button>
                        </View>
                    </View>
                </View>
            </Modal>

            <Snackbar
                visible={showSuccess}
                onDismiss={() => setShowSuccess(false)}
                duration={4000}
                style={styles.snackbar}
            >
                <Text style={styles.snackbarText}>Operazione completata con successo!</Text>
            </Snackbar>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        marginVertical: 10,
        borderColor: colors.white,
        borderWidth: 1,
        borderRadius: 10,
    },
    innerContainer: {
        flex: 1,
        padding: 10,
    },
    header: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    headerText: {
        color: colors.white,
        fontSize: 35,
    },
    formContainer: {
        padding: 10,
    },
    input: {
        backgroundColor: colors.grey,
        color: colors.white,
    },
    errorText: {
        color: 'red',
        padding: 10,
    },
    buttonContainer: {
        paddingVertical: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        backgroundColor: colors.amaranto,
        borderRadius: 10,
    },
    buttonText: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 16
    },
    listContainer: {
        maxHeight: 200,  // Limita l'altezza massima della lista
    },
    listItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: colors.grey,
        padding: 5,
        borderRadius: 5,
    },
    listItemText: {
        color: colors.white,
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: 15,
    },
    listItemButtons: {
        flexDirection: 'row',
    },
    icon: {
        color: colors.white
    },
    snackbar: {
        backgroundColor: colors.amaranto,
        position: 'absolute',
        bottom: 20,
        width: '80%',
        alignSelf: 'center',
    },
    snackbarText: {
        color: colors.white,
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: colors.background,
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    modalText: {
        color: colors.white,
        fontSize: 18,
        marginBottom: 20,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    confirmButton: {
        backgroundColor: colors.amaranto,
        flex: 1,
        marginRight: 10,
        color: colors.white,
        borderRadius: 10
    },
    cancelButton: {
        backgroundColor: colors.grey,
        flex: 1,
        borderRadius: 10
    },
});

export default AddNewAreas;
