import * as React from "react";
import ClientLayout from "./managers_navigation/ManagersDashboard";
import Foundation from "react-native-vector-icons/Foundation";
import Workerlayout from "./managers_navigation/ManagersWorkerDashboard";
import UserSettingPage from "../managers_view/UserSetting";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import { gql, useQuery } from "@apollo/client";
import AdminDashboard from "./managers_navigation/AdminDashboard";
import Dashboard from "../managers_view/dashboard";
import DashboardTwo from "../managers_view/dashboard_two";
import DashboardThree from "../managers_view/dashboard_three";
import WorkerDashboard from "../workers_view/worker_dashboard";
import toolsManager from "../managers_view/toolsManager";
import colors from "../../styles/colors";

const getUserProfileQuery = gql`
  query getUserProfile {
    getUserProfile {
      role
    }
  }
`;

export default function ManagersLayout({ navigation }) {
  const Stack = createNativeStackNavigator();
  const [selectedSection, setSelectedSection] = React.useState("Client");

  const { data: userProfileData, refetch: userProfilerefetch } =
    useQuery(getUserProfileQuery);

  const sections = [
    { name: "DashboardTwo", label: "Dashboard Framinia ECS Overview", icon: "graph-pie", roles: ["ADMIN", "AREA_MANAGER", "PROJECT_MANAGER"] },
    { name: "DashboardThree", label: "Compensation Dashboard", icon: "graph-horizontal", roles: ["ADMIN", "AREA_MANAGER"] },
    { name: "Dashboard", label: "Dashboard Project Overview", icon: "graph-bar", roles: ["ADMIN", "AREA_MANAGER", "PROJECT_MANAGER"] },
    { name: "Workers Dashboard", label: "Workers Dashboard", icon: "results-demographics", roles: ["ADMIN", "AREA_MANAGER", "PROJECT_MANAGER"], params: { role: "admin" } },
    { name: "Client", label: "Clients", icon: "thumbnails" },
    { name: "Worker", label: "Workers", icon: "torso-business" },
    { name: "Users", label: "Users", icon: "torsos-all", roles: ["ADMIN"] },
    { name: "toolsManager", label: "Tools", icon: "wrench", roles: ["ADMIN"] },
    { name: "Setting", label: "Settings", icon: "widget" },
  ];

  return (
    <View className="flex-1 flex-row bg-secondary">
      <View style={{ flex: 0.2, flexDirection: 'column', backgroundColor: colors.amaranto }}>
        <View className="w-full h-20 items-center justify-center" style={{ backgroundColor: colors.amaranto }}>
          <Text
            className="font-bold text-3xl"
            style={{ color: colors.white, fontSize: 32 }}
          >
            ReportOne
          </Text>
        </View>
        <ScrollView style={{ backgroundColor: colors.amaranto }}>
          {sections.map((section) => (
            (userProfileData?.getUserProfile?.role &&
              (!section.roles || section.roles.includes(userProfileData?.getUserProfile?.role))) ? (
              <TouchableOpacity
                key={section.name}
                onPress={() => {
                  setSelectedSection(section.name);
                  navigation.navigate(section.name, section.params);
                }}
                className="flex flex-col w-full items-start justify-center p-4"
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  paddingVertical: 16,
                  paddingHorizontal: 12,
                  backgroundColor: selectedSection === section.name ? colors.background : colors.amaranto,
                  borderTopLeftRadius: selectedSection === section.name ? 15 : 0,
                  borderBottomLeftRadius: selectedSection === section.name ? 15 : 0,
                  marginLeft: selectedSection === section.name ? 10 : 0,
                  marginBottom: 2
                }}
              >
                <View className="flex flex-row items-center">
                  <View style={{ width: 40 }}>
                    <Foundation name={section.icon} size={35} color={colors.white} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      className="font-bold text-lg"
                      style={{ color: colors.white, fontSize: 18, marginLeft: 7 }}
                    >
                      {section.label}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : null
          ))}
        </ScrollView>
      </View>

      <View className="flex-1 p-10" style={{ backgroundColor: colors.background }}>
        <Stack.Navigator
          initialRouteName="Client"
          screenOptions={({ route }) => ({
            headerShown: false,
            title: "",
            headerBackVisible: false,
          })}
        >
          <Stack.Screen name="Dashboard" component={Dashboard} />
          <Stack.Screen name="DashboardTwo" component={DashboardTwo} />
          <Stack.Screen name="Workers Dashboard" component={WorkerDashboard} />
          <Stack.Screen name="DashboardThree" component={DashboardThree} />
          <Stack.Screen name="Client" component={ClientLayout} />
          <Stack.Screen name="Worker" component={Workerlayout} />
          <Stack.Screen name="Setting" component={UserSettingPage} />
          <Stack.Screen name="Users" component={AdminDashboard} />
          <Stack.Screen name="toolsManager" component={toolsManager} />
        </Stack.Navigator>
      </View>
    </View >
  );
}
