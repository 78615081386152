import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { View } from "react-native";
import { Button, Dialog, IconButton, Portal, Text } from "react-native-paper";
import colors from "../styles/colors";

const DELETE_USER = gql`
  mutation deleteUserForAdmin($input: deleteUserForAdminInput) {
    deleteUserForAdmin(input: $input) {
      message
      status
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function UserCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [deleteUser, { data, error }] = useMutation(DELETE_USER);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const navigate = () => {
    props.navigation.push("UserDetails", { userId: props?.data?.id });
  };

  const deleteCard = async () => {
    await deleteUser({ variables: { input: { profile_id: props?.data?.id } } });
    await props.refetch();
    setIsDeleteModalVisible(false)
  };

  const editUser = () => {
    props.navigation.push("CreateNewUser", { userId: props?.data?.id, role: props?.data?.role?.role });
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" style={{color: colors.white, fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare l'utente{" "}
              {props?.data?.user?.first_name} {props?.data?.user?.last_name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{fontFamily: "Roboto"}}>
              Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteCard}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{borderColor: colors.white, borderRadius: 10, borderWidth: 1, backgroundColor: colors.grey}}>
          <View className="flex flex-col p-2 w-1/2 gap-4">
            <Text className="text-xl" style={{color: colors.white, fontFamily: "Roboto"}}>
              {props?.data?.user?.first_name} {props?.data?.user?.last_name}
            </Text>
            <Text className="text-sm" style={{color: colors.white, fontFamily: "Roboto"}}>
              Email : {props?.data?.user?.email}
            </Text>
            <Text className="text-sm" style={{color: colors.white, fontFamily: "Roboto"}}>
              Ruolo : {props?.data?.role?.role}
            </Text>
          </View>

          <View className="flex flex-row w-1/2 p-4 items-center gap-6 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={navigate}
            />
            <IconButton
              icon="trash-can-outline"
              containerColor={colors.red}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={() => setIsDeleteModalVisible(true)}
            />
            <IconButton
              icon="account-edit-outline"
              containerColor={colors.yellow}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={editUser}
            />
          </View>
        </View>
      </View>
    </>
  );
}

export default UserCard;
