// Disclaimer.tsx
import React from 'react';
import { useDisclaimer } from './disclaimerContext';
import { View, StyleSheet, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { useAuth } from '../views/AuthContext';
import { black } from 'react-native-paper/lib/typescript/src/styles/themes/v2/colors';
import colors from '../styles/colors';

const Disclaimer = () => {
    const { disclaimerAccepted, handleAcceptDisclaimer, profileId } = useDisclaimer();
    const { isAuthenticated } = useAuth();
 
    if (!isAuthenticated) {
        return null;
    }

    if (disclaimerAccepted) {
        return null;
    }

    const styles = StyleSheet.create({
        smallRectangle: {
            position: 'absolute',
            bottom: 30,
            right: 20,
            width: '90%',  // Percentuale per una migliore responsività
            maxWidth: 290,  // Limite massimo di larghezza
            backgroundColor: colors.grey,
            borderColor: '#FFFFFF',
            borderWidth: 1,
            borderRadius: 9,
            padding: 20, // Spaziatura interna
            opacity: 0.95, // Leggermente meno trasparente per leggibilità
            alignSelf: 'center', // Centrare all'interno della View padre
            alignItems: 'center', // Centrare gli elementi figli (testo e pulsante)
        },
        button: {
            width: '100%', // Prendere tutta la larghezza disponibile
            height: 45,
            justifyContent: 'center', // Centrare il testo verticalmente
            alignItems: 'center', // Centrare il testo orizzontalmente
            backgroundColor: colors.amaranto, // Colore oro
            borderRadius: 10, // Angoli più arrotondati
            paddingVertical: 10,
            paddingHorizontal: 20,
            marginTop: 10, // Spazio dal testo al bottone
            // Elimina proprietà non necessarie come paddingTop, textAlign, etc.
        },
        text: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: 10, // Aggiunge spazio prima del bottone
        },
        buttonText: {
            fontWeight: 'bold',
            color: 'white', // Testo nero per contrastare con il background del bottone
            // Altre proprietà di stile per il testo del bottone se necessarie
        }
    });

    return (
        <View style={styles.smallRectangle}>
            <Text style={styles.text}>La tua privacy è importante per noi. Per continuare, devi accettare le nostre politiche di privacy.</Text>
            <Button
                onPress={handleAcceptDisclaimer}
                textColor={colors.white}
                style={styles.button}
            >
                <Text style={styles.buttonText}>
                    Accetta
                </Text>
            </Button>
        </View>
    );
};

export default Disclaimer;
