import React, { useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, TextInput } from "react-native";
import { Button, Text } from "react-native-paper";
import colors from "../styles/colors";

function DataSectionTwo({ dashboardData }) {
  const [activeTab, setActiveTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const nomiDaEscludere = [
    "Framinia ECS - Gestione Permessi Dipendenti",
    "Framinia ECS - Gestione Ferie Dipendenti",
    "Framinia ECS - Gestione Malattia Dipendenti"
  ];

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    headerRow: {
      flexDirection: 'row',
      backgroundColor: colors.grey,
      padding: 5,
      marginBottom: 3,
      borderWidth: 3,
      borderColor: "grey",
      borderRadius: 2,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: 'grey',
      padding: 3,
      marginLeft: 3,
    },
    cell: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 300,
    },
    cellNumber: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 200,
    },
    lastCell: {
      padding: 2,
      width: 300,
    },
    lastCellNumber: {
      padding: 2,
      width: 200,
    },
    headerText: {
      fontWeight: 'bold',
      color: 'white',
      fontSize: 22,
      textAlign: 'center',
    },
    cellText: {
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
    },
    cellTextButton: {
      color: 'white',
      fontSize: 18,
      alignContent: "center",
    },
    content: {
      flex: 1,
    },
    actionButton: {
      justifyContent: "center",
      alignItems: 'center',
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    button: {
      backgroundColor: colors.amaranto,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: colors.white,
      borderTopLeftRadius: 10,
    },
    searchInput: {
      backgroundColor: colors.grey,
      color: colors.white,
      padding: 10,
      margin: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: colors.white,
    }
  });

  const filteredProjects = dashboardData?.projects
  ?.filter(project => {
    const isExcluded = nomiDaEscludere.includes(project.name);
    const matchesSearchQuery = project.project_id.toLowerCase().includes(searchQuery.trim().toLowerCase());

    return !isExcluded && matchesSearchQuery;
  })
  .sort((a, b) => a.project_id.localeCompare(b.project_id));

  return (
    <>
      <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderColor: colors.white, borderRadius: 10, borderWidth: 1 }}>
        <View className="flex flex-row w-full ">
          <View style={styles.button}>
            <Button
              mode={activeTab === 1 ? "contained" : "outlined"}
              buttonColor={activeTab === 1 ? colors.amaranto : null}
              onPress={() => handleTabClick(1)}
            >
              <Text style={styles.buttonText}> Progetti</Text>
            </Button>
          </View>
        </View>
        <View className="flex flex-col w-full h-96 my-2 overflow-scroll">
          <TextInput
            style={styles.searchInput}
            placeholder="Cerca per codice commessa"
            placeholderTextColor={colors.white}
            value={searchQuery}
            onChangeText={text => setSearchQuery(text.trim())}
          />
          <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
            <View style={styles.container}>
              <View style={styles.headerRow}>
                <View style={[styles.cell]}><Text style={styles.headerText}>Codice commessa</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Nome progetto</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Cliente</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Gruppo cliente</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Area manager</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Project manager</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Settore</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Importo contratto</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Fee</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine ECS</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Budget</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Costi</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine</Text></View>
                <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine con compensazione</Text></View>
                <View style={[styles.cell]}><Text style={styles.headerText}>Compensato da</Text></View>
                <View style={[styles.lastCell]}><Text style={styles.headerText}>Compensato a</Text></View>
              </View>
              <ScrollView style={styles.content}>
                {filteredProjects && filteredProjects.map((project) => {
                  const textColor = project.hasOutdatedTasks ? 'yellow' : 'white';
                  return (
                    <View key={project.project_id} style={styles.row}>
                      <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_id}</Text></View>
                      <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.name}</Text></View>
                      <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.client_name}</Text></View>
                      <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.client_group}</Text></View>
                      <View style={[styles.cell]}>
                        <Text style={[styles.cellText, { color: textColor }]}>
                          {project?.area_manager?.user?.first_name || project?.area_manager?.user?.last_name
                            ? `${project?.area_manager?.user?.first_name} ${project?.area_manager?.user?.last_name}`
                            : "N/A"}
                        </Text>
                      </View>
                      <View style={[styles.cell]}>
                        <Text style={[styles.cellText, { color: textColor }]}>
                          {project?.project_manager?.user?.first_name || project?.project_manager?.user?.last_name
                            ? `${project?.project_manager?.user?.first_name} ${project?.project_manager?.user?.last_name}`
                            : "N/A"}
                        </Text>
                      </View>
                      <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_sector?.name}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.contract_value).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.fee).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.margin).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.budget).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.cost).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.notUsedBudget).toFixed(2)}</Text></View>
                      <View style={[styles.cellNumber]}>
                        <Text style={[styles.cellText, { color: textColor }]}>
                          {project?.compensatedBudget !== project?.notUsedBudget ? parseFloat(project?.compensatedBudget).toFixed(2) : "N/A"}
                        </Text>
                      </View>
                      <View style={[styles.cell]}>
                        {project.compensationsReceived.length > 0 ? project.compensationsReceived.map((comp, index) => (
                          <Text key={index} style={[styles.cellText, { color: textColor }]}>{comp.projectId}: {parseFloat(comp.amount).toFixed(2)}</Text>
                        )) : <Text style={[styles.cellText, { color: textColor }]}>"N/A"</Text>}
                      </View>
                      <View style={[styles.lastCell]}>
                        {project.compensationsSent.length > 0 ? project.compensationsSent.map((comp, index) => (
                          <Text key={index} style={[styles.cellText, { color: textColor }]}>{comp.projectId}: {parseFloat(comp.amount).toFixed(2)}</Text>
                        )) : <Text style={[styles.cellText, { color: textColor }]}>"N/A"</Text>}
                      </View>
                    </View>
                  );
                })}
              </ScrollView>
            </View>
          </ScrollView>
        </View>
        <View className="flex flex-row w-full mb-2 p-2">
          <View className="mx-4">
            <Text variant="headlineSmall" className="text-white">
              Costo totale contratti : {dashboardData?.totalContractValue}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
}

export default DataSectionTwo;
