import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import WorkerTimelineScreen from "./workers_navigation/WorkersTimeline";
import WorkerDashboardLayout from "./workers_navigation/WorkersDashboard";
import UserSettingPage from "../managers_view/UserSetting";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Foundation from "react-native-vector-icons/Foundation";
import FontAwesome6 from "react-native-vector-icons/FontAwesome5";
import WorkerDashboard from "../workers_view/worker_dashboard";
import WorkerCreateNewTask from "../workers_view/create_new_task";
import { APP_SLIDE_ANIMATION } from "../../constants/App.constants";
import colors from "../../styles/colors";

const Stack = createNativeStackNavigator();

function WorkersLayout({ navigation }) {
  const sections = [
    { name: "Dashboard", label: "Dashboard", icon: "graph-pie" },
    { name: "Projects", label: "Projects", icon: "clipboard" },
    { name: "WorkerTimeline", label: "Timeline", icon: "clock" },
    { name: "Setting", label: "Settings", icon: "widget" },
  ];

  const [selectedSection, setSelectedSection] = React.useState("Dashboard");

  return (
    <View className="flex-1 flex-row bg-secondary">
      <View style={{ flex: 0.2, flexDirection: 'column', backgroundColor: colors.amaranto }}>
        <View className="w-full h-20 items-center justify-center" style={{ backgroundColor: colors.amaranto }}>
          <Text
            className="font-bold text-3xl"
            style={{ color: colors.white }}
          >
            ReportOne
          </Text>
        </View>
        <ScrollView style={{ flex: 1, backgroundColor: colors.amaranto }}>
          {sections.map((section) => (
            <TouchableOpacity
              key={section.name}
              onPress={() => {
                setSelectedSection(section.name);
                navigation.navigate(section.name);
              }}
              style={{
                width: '100%',
                padding: 16,
                backgroundColor: selectedSection === section.name ? colors.background : colors.amaranto,
                borderTopLeftRadius: selectedSection === section.name ? 15 : 0,
                borderBottomLeftRadius: selectedSection === section.name ? 15 : 0,
                marginLeft: selectedSection === section.name ? 10 : 0,
                marginBottom: 2
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <View style={{ width: 40 }}>
                  <Foundation name={section.icon} size={35} color={colors.white} />
                </View>
                <Text
                  style={{ marginLeft: 7, fontSize: 18, color: colors.white, fontWeight: 'bold' }}
                >
                  {section.label}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>

      <View className="flex-1 p-10" style={{ backgroundColor: colors.background }}>
        <Stack.Navigator
          screenOptions={({ route }) => ({
            headerShown: false,
            title: "",
            headerBackVisible: false,
          })}
        >
          <Stack.Screen name="Dashboard" component={WorkerDashboard} />
          <Stack.Screen name="Projects" component={WorkerDashboardLayout} />
          <Stack.Screen name="WorkerTimeline" component={WorkerTimelineScreen} />
          <Stack.Screen name="Setting" component={UserSettingPage} />
          <Stack.Screen
            name="EditTask"
            component={WorkerCreateNewTask}
            options={{
              headerBackTitleVisible: false,
              headerTintColor: "#FFFFFF",
              headerShadowVisible: false,
              animation: APP_SLIDE_ANIMATION,
              headerTitle: () => <></>,
            }}
          />
        </Stack.Navigator>
      </View>
    </View>
  );
}

export default WorkersLayout;
