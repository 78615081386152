import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { Dialog, Text, Portal, Button, IconButton } from "react-native-paper";
import {
  USER_PROFILE_NAME,
} from "../graphql/user.resolver";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import colors from "../styles/colors";

const DELETE_TASK_MUTATION = gql`
  mutation deleteActivityTask($input: deleteActivityTaskInput) {
    deleteActivityTask(input: $input) {
      message
      status
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function TaskCard(props: any) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const [DeleteTask, { data: taskData, error: taskDeleteError }] =
    useMutation(DELETE_TASK_MUTATION);

  const navigate = () => {

    props.navigation.push("TaskDetails", { id: props.data.id });

  };

  const deleteTask = async () => {
    await DeleteTask({
      variables: { input: { task_id: props?.data?.id } },
    });
    props?.refetchTasklist();
    setIsDeleteModalVisible(false);
  };

  const Name = `${props.data.name}`;

  const date = new Date(parseInt(props.data.end_time));

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_NAME);

  let full_name = userProfile?.getUserProfile?.first_name + " " + userProfile?.getUserProfile?.last_name;
  //let project_creator = userProfile?.getUserProfile?.profile_id;

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-white" style={{fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare il Task?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className=" text-white" style={{fontFamily: "Roboto"}}>
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteTask}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className=" text-white" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View>
        <View className="flex flex-row w-full bg-white h-fit rounded-lg my-1" style={{ borderRadius: 10 }}>
          <View className="flex flex-col w-1/5 bg-yellow rounded-l-lg items-center justify-center">
            <View className="items-center justify-center h-20">
              <Text className="text-white text-4xl" style={{fontFamily: "Roboto"}}>
                {date.getDate()}
              </Text>
              <Text className="text-white " style={{fontFamily: "Roboto"}}>
                {date.toLocaleString("default", { month: "long" })}
              </Text>
            </View>
          </View>
          <View className="flex flex-row w-full" style={{ backgroundColor: colors.grey }}>
            <TouchableOpacity
              className="flex flex-col w-7/12 justify-center items-start p-2 gap-1"
              onPress={navigate}
            >
              <View className="flex flex-col p-2">
                <Text className=" mt-1 text-xl text-white  px-2" style={{fontFamily: "Roboto"}}>
                  Task : {Name.length > 60 && !isExpanded ? Name.substring(0, 60) + "..." : Name}
                </Text>
                {isExpanded ? (
                  <>
                    {props?.data?.total_hrs && (
                      <Text className=" mt-1 text-xl text-white p-2" style={{fontFamily: "Roboto"}}>
                        Ore Totali : {props?.data?.total_hrs}
                      </Text>
                    )}
                    {props?.data?.created_by && (
                      <Text className=" mt-1 text-xl text-white px-2" style={{fontFamily: "Roboto"}}>
                        Creato da : {props?.data?.created_by}
                      </Text>
                    )}
                    {props?.data?.client_name && (
                      <Text className=" mt-1 text-xl text-white p-2" style={{fontFamily: "Roboto"}}>
                        Codice commessa : {props?.data?.client_name}
                      </Text>
                    )}
                  </>
                ) : null}
              </View>
            </TouchableOpacity>

            <View className="flex-row w-2/12 items-center justify-end">
              <TouchableOpacity
                className="w-10 items-center justify-center"
                onPress={() => setIsExpanded(!isExpanded)}
              >
                <Icon
                  name={isExpanded ? "chevron-up" : "chevron-down"}
                  size={15}
                  color={colors.white}
                />
              </TouchableOpacity>

              {(props.data.created_by === full_name) && (
                <IconButton
                  icon="pencil"
                  containerColor={colors.yellow}
                  style={{ borderRadius: 4 }}
                  iconColor={colors.white}
                  size={28}
                  onPress={() => {
                    props.navigation.push("EditTask", {
                      TaskId: props?.data?.id,
                    });
                  }}
                />
              )}

              {(props.data.created_by === full_name) && (
                <IconButton
                  icon="trash-can-outline"
                  containerColor={colors.red}
                  style={{ borderRadius: 4 }}
                  iconColor={colors.white}
                  size={28}
                  onPress={() => setIsDeleteModalVisible(true)}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default TaskCard;
