import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  Text,
  Portal,
  Button,
  Dialog,
  IconButton,
  Checkbox,
  Searchbar
} from "react-native-paper";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AddworkerToActivityCard from "../../components/AddworkerToActivityCard";
import * as DocumentPicker from "expo-document-picker";
import PdfBadge from "../../components/PdfBadge";
import TaskCard from "../../components/TaskCard";
import { useFocusEffect } from "@react-navigation/native";
import { validate } from "../../libs/form.validate";
import { Formik } from "formik";
import Loader from "../../components/Loader";
import ReciptCard from "../../components/ReciptCard";
import { uploadFileToS3 } from "../../libs/common.functions";
import { getStorageData } from "../../libs/storage";
import {
  USER_PROFILE_ROLE,
} from "../../graphql/user.resolver";
import colors from "../../styles/colors";

const addWorkerMutation = gql`
  mutation addWorkerToActivity($input: addWorkerToActivity) {
    addWorkerToActivity(input: $input) {
      activity_id
    }
  }
`;

const deleteWorkerMutation = gql`
  mutation deleteWorkerToActivity($input: deleteWorkerToActivity) {
    deleteWorkerToActivity(input: $input) {
      activity_id
      worker_id
    }
  }
`;

const deleteAttachmentMutation = gql`
  mutation deleteAttachment($input: deleteAttachmentInput) {
    deleteAttachment(input: $input) {
      status
      message
    }
  }
`;

const getWorkersOfActivity = gql`
  query getWorkersofActivity($input: getWorkersInput) {
    getWorkersofActivity(input: $input) {
      worker_id
      project_worker_id {
        user_id
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_SIGNED_URL = gql`
  query getPresignedURL($input: getPresignedURLInput) {
    getPresignedURL(input: $input) {
      message
      presignedURL
    }
  }
`;

const getActivityDetails = gql`
  query getActivitDetails($input: getActivitDetails) {
    getActivitDetails(input: $input) {
      id
      name
      description
      created_by
    }
  }
`;

const getNotAllocatedWorkers = gql`
  query getNotAllocatedWorkers($input: getNotAllocatedWorkersInput) {
    getNotAllocatedWorkers(input: $input) {
      id
      qualification
      user {
        first_name
        last_name
      }
    }
  }
`;

const addAttachmentToActivityMutation = gql`
  mutation addAttachmentToActivity($input: addAttachmentToActivity) {
    addAttachmentToActivity(input: $input) {
      status
      message
    }
  }
`;

const getDocumentsByActivityId = gql`
  query getDocumentsAndRecieptByActivityId(
    $input: getDocumentsAndRecieptByActivityId
  ) {
    getDocumentsAndRecieptByActivityId(input: $input) {
      id
      type
      km_routes
      file_name
      fileUrl
    }
  }
`;

const getReciptsByActivityId = gql`
  query getDocumentsAndRecieptByActivityId(
    $input: getDocumentsAndRecieptByActivityId
  ) {
    getDocumentsAndRecieptByActivityId(input: $input) {
      id
      type
      file_name
      fileUrl
      receipt_title
      receipt_date 
      receipt_value
      receipt_city
      receipt_expenditure_method
      km_routes
      receipt_type
      receipt_type_id
      details
      created_by
      worker_name
      reimbursed
    }
  }
`;

const getTaskList = gql`
  query getTasksByActivityIdInput($input: getTasksByActivityIdInput) {
    getTasksByActivityId(input: $input) {
      id
      name
      start_time
      end_time
      created_by
      total_hrs
      client_name
    }
  }
`;

const getAllRecieptTypes = gql`
  query getAllRecieptTypes {
    getAllRecieptTypes {
      id
      type
    }
  }
`;

const EDIT_RECEIPT = gql`
  mutation updateAttachment($input: updateAttachmentInput) {
    updateAttachment(input: $input) {
      message
      status
    }
  }
`;

const getProjectDetails = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      id
      name
      end_date
      project_area {
        name
        id
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

function ActivityDetails({ route, navigation }: any) {
  const [projectID] = useState(route.params.projectID);
  const [activityid] = useState(route.params.id);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAttachment, setIsExpandedAttachment] = useState(false);
  const [isAddWorkerModalVisible, setisAddWorkerModalVisible] = useState(false);
  const [isReciptUploadModalVisible, setIsReciptUploadModalVisible] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedReciept, setselectedReciept] = useState(null);
  const [isDeleteWorkerModalVisible, setIsDeleteWorkerModalVisible] = useState(false);
  const [isDeleteAttachmentModalVisible, setIsDeleteAttachmentModalVisible] = useState(false);
  const handelWorkerModalClose = () => setIsDeleteWorkerModalVisible(false);
  const handelAttachmentModalClose = () => setIsDeleteAttachmentModalVisible(false);
  const [selectedWorker, setWorkerToDelete] = useState("");
  const [selectedAttachment, setAttachmentToDelete] = useState("");
  const [sortedWorkerList, setSortedWorkerList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleDeleteWorker = (workerId) => {
    setWorkerToDelete(workerId);
    setIsDeleteWorkerModalVisible(true);
  };

  const handleDeleteAttachment = (attachmentID) => {
    setAttachmentToDelete(attachmentID);
    setIsDeleteAttachmentModalVisible(true);
  };

  useFocusEffect(
    React.useCallback(() => {
      activityWorkersListRefetch();
      getAllAttachmentsrefetch();
      getAllReciptsRefetch();
      getAlltasksrefetch();
    }, [])
  );

  const [role, setRole] = useState(null);
  //const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  const [
    addattachmentToActivity,
    { data: attachmentToActivityData, error: attachmentToActivityError },
  ] = useMutation(addAttachmentToActivityMutation);

  const [addWorkerToActivity, { data: addedWorker, loading, error }] =
    useMutation(addWorkerMutation);

  const [deleteWorkerToActivity, { data: deletedWorker }] =
    useMutation(deleteWorkerMutation);

  const [deleteAttachmentToActivity, { data: deletedAttachment }] =
    useMutation(deleteAttachmentMutation);

  const [editReciept, { data: editRecieptData }] = useMutation(EDIT_RECEIPT);

  const [getSignedUrl, { data: signedUrlData }] = useLazyQuery(GET_SIGNED_URL);

  const { data: ActivityDetails, loading: loadingActivityDetails } = useQuery(
    getActivityDetails,
    {
      fetchPolicy: "network-only",
      variables: { input: { activity_id: activityid } },
    }
  );

  const {
    data: nonAllocatedworkersList,
    refetch: nonAllocatedworkersListRefetch,
  } = useQuery(getNotAllocatedWorkers, {
    variables: { input: { activity_id: activityid } },
  });

  const {
    data: activityWorkersList,
    refetch: activityWorkersListRefetch,
    loading: loadingActivityWorkersList,
  } = useQuery(getWorkersOfActivity, {
    fetchPolicy: "no-cache",
    variables: { input: { activity_id: activityid } },
  });

  const {
    data: getAllAttachments,
    refetch: getAllAttachmentsrefetch,
    loading: loadingAllAttachments,
  } = useQuery(getDocumentsByActivityId, {
    variables: { input: { activity_id: activityid, type: "DOCUMENTS" } },
  });

  const {
    data: getAllRecipts,
    refetch: getAllReciptsRefetch,
    loading: loadingAllRecipts,
  } = useQuery(getReciptsByActivityId, {
    variables: { input: { activity_id: activityid, type: "RECEIPT" } },
  });

  const {
    data: getAlltasks,
    refetch: getAlltasksrefetch,
    loading: loadingAllTasks,
  } = useQuery(getTaskList, {
    variables: { input: { activity_id: activityid } },
  });

  useEffect(() => {
    if (nonAllocatedworkersList?.getNotAllocatedWorkers) {
      const filteredAndSortedWorkers = nonAllocatedworkersList.getNotAllocatedWorkers
        .filter(worker =>
          `${worker.user.first_name} ${worker.user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
          // Combina nome e cognome per un confronto completo
          let fullNameA = `${a.user.first_name} ${a.user.last_name}`.toLowerCase();
          let fullNameB = `${b.user.first_name} ${b.user.last_name}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        });

      setSortedWorkerList(filteredAndSortedWorkers);
    }
  }, [nonAllocatedworkersList, searchTerm]);


  const addnewWorker = async (id) => {
    await addWorkerToActivity({
      variables: { input: { worker_id: id, activity_id: activityid } },
    });
    activityWorkersListRefetch();
    setisAddWorkerModalVisible(false);
  };

  //attachments upload to aws logic
  const pickAttachment = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/*"],
    });

    if (result.type === "cancel") {
      console.log("Document picking was cancelled");
      return;
    }

    //@ts-ignore
    const response = await fetch(result?.uri);
    const blob = await response.blob();

    let FILENAME = `${Date.now()}_${result["name"]}`;
    FILENAME = FILENAME.trim()
      .split(" ")
      .filter((word) => word)
      .join("");

    const getUrl = await getSignedUrl({
      variables: {
        input: { fileName: FILENAME },
      },
    });

    if (getUrl?.data) {
      const fileUploadStatus = await uploadFileToS3(
        getUrl?.data?.getPresignedURL?.presignedURL,
        blob
      );

      if (fileUploadStatus.status == "SUCCESS") {
        try {
          const saveDataToDb = {
            type: "DOCUMENTS",
            file_name: FILENAME,
            details: "test",
            activity_id: activityid,
          };
          await addattachmentToActivity({ variables: { input: saveDataToDb } });
          console.log(`File uploaded successfully`);
          getAllAttachmentsrefetch();
        } catch (error) {
          console.error(`Error uploading file`);
        }
      }
    }
  };


  const { data: ReciptTypes } = useQuery(getAllRecieptTypes, {
    fetchPolicy: "network-only",
  });

  let formattedReciptsTypes = [];

  let KMValue;

  if (ReciptTypes?.getAllRecieptTypes?.length) {
    formattedReciptsTypes = ReciptTypes?.getAllRecieptTypes?.map((receipt) => {
      return {
        label: receipt?.type,
        value: receipt?.id,
      };
    });

    formattedReciptsTypes.forEach((item) => {
      if (item.label == "KM") {
        KMValue = item;
      }
    });
  }

  const pickReceipt = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    //@ts-ignore
    const response = await fetch(result?.uri);
    const blob = await response.blob();

    let FILENAME = `${Date.now()}_${result["name"]}`;
    FILENAME = FILENAME.trim()
      .split(" ")
      .filter((word) => word)
      .join("");

    const getUrl = await getSignedUrl({
      variables: {
        input: { fileName: FILENAME },
      },
    });

    if (getUrl?.data) {
      const fileUploadStatus = await uploadFileToS3(
        getUrl?.data?.getPresignedURL?.presignedURL,
        blob
      );

      if (fileUploadStatus.status == "SUCCESS") {
        setSelectedFile(FILENAME);
      }
    }
  };

  const [showExpenditureMethod, setShowExpenditureMethod] = useState(false);
  const [showReciptTypeDropdown, setShowReciptTypeDropdown] = useState(false);
  const [initialReceiptData, setInitialReceiptData] = useState({
    // receipt_title: "",
    // receipt_date: "",
    // receipt_type: "",
    // expenditure_type: "",
    // km_routes: "",
    // city: "",
    // details: "",
    // receipt_value: 0,
    reimbursed: false,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const REQUIRED_FIELDS = [];

  useEffect(() => {
    if (selectedReciept) {
      setInitialReceiptData({
        // receipt_title: selectedReciept?.receipt_title,
        // receipt_date: selectedReciept?.receipt_date,
        // receipt_type: selectedReciept?.receipt_type_id,
        // expenditure_type: selectedReciept?.receipt_expenditure_method,
        // km_routes: "",
        // city: selectedReciept?.receipt_city,
        // details: selectedReciept?.details,
        // receipt_value: selectedReciept?.receipt_value,
        reimbursed: selectedReciept?.reimbursed,
      });
      setIsReciptUploadModalVisible(true);
    }
  }, [selectedReciept]);

  const handelReciptModal = async () => {
    setInitialReceiptData({
      // receipt_title: "",
      // receipt_date: "",
      // receipt_type: "",
      // expenditure_type: "",
      // km_routes: "",
      // city: "",
      // details: "",
      // receipt_value: 0,
      reimbursed: false,
    });
    setselectedReciept(null);
    setValidationErrors({});
    await setSelectedFile("");
    setIsReciptUploadModalVisible(false);
    await getAllReciptsRefetch();
  };

  const saveReciptToDb = async (values) => {
    values = { ...values, selectedFile };
    let isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    if (isValidForm) {
      try {
        if (selectedReciept) {
          await editReciept({
            variables: {
              input: {
                attachment_id: selectedReciept.id,
                activity_id: activityid,
                // details: values.details,
                // receipt_city: values.city,
                // receipt_date:
                //   values.receipt_date && typeof values.receipt_date == "string"
                //     ? new Date(parseInt(values.receipt_date))
                //     : values.receipt_date,
                // receipt_expenditure_method: values.expenditure_type,
                // receipt_title: values.receipt_title,
                // receipt_type_id: values.receipt_type,
                // receipt_value: parseFloat(values.receipt_value),
                reimbursed: values.reimbursed,
              },
            },
          });
        } else {
          const saveDataToDb = {
            type: "RECEIPT",
            // receipt_title: values.receipt_title,
            // receipt_date: values.receipt_date || new Date(),
            file_name: selectedFile,
            // details: values.details,
            activity_id: activityid,
            // receipt_type_id: values.receipt_type,
            // receipt_value: parseFloat(values.receipt_value),
            // receipt_expenditure_method: values.expenditure_type,
            // receipt_city: values.city,
            reimbursed: values.reimbursed || false,
          };

          await addattachmentToActivity({ variables: { input: saveDataToDb } });
        }
        setValidationErrors({});
        handelReciptModal();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const deleteWorker = async () => {
    try {
      await deleteWorkerToActivity({
        variables: { input: { worker_id: selectedWorker, activity_id: activityid } },
      });
      activityWorkersListRefetch();
      setIsDeleteWorkerModalVisible(false);
    } catch (error) {
      console.log("errore nel cancellare il worker");
    }
  };

  const deleteAttachment = async () => {
    try {
      await deleteAttachmentToActivity({
        variables: { input: { attachment_id: selectedAttachment } },
      });
      getAllAttachmentsrefetch();
      setIsDeleteAttachmentModalVisible(false);
    } catch (error) {
      console.log("errore nel cancellare l'attachment");
    }
  };

  const { data: projectData, refetch: refetchproject, loading: loadingProjectData } = useQuery(
    getProjectDetails,
    { variables: { input: { id: projectID } } }
  );

  const containerStyle = {
    margin: 20,
    backgroundColor: colors.grey,
    padding: 10,
    alignSelf: "center",
    borderRadius: 10,
  };

  const icona = {
    alignSelf: "center"
  };

  //verifica projectManager
  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;

  const isProjectManager = () => {
    const projectManagerName = `${projectData?.getProjectById?.project_manager?.user?.first_name} ${projectData?.getProjectById?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = projectData?.getProjectById?.project_area?.id;

  const isAreaManager = () => {
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isReciptUploadModalVisible}
          onDismiss={handelReciptModal}
          // @ts-ignore
          style={{
            backgroundColor: "white",
            alignSelf: "center",
            maxHeight: "80%",
          }}
        >
          <Dialog.Title style={{ color: "black" }}>
            Dettagli Spesa Risorse
          </Dialog.Title>

          <Dialog.ScrollArea className="flex flex-col p-2">
            <ScrollView contentContainerStyle={{ paddingHorizontal: 18 }}>
              <View>
                <Formik
                  initialValues={initialReceiptData}
                  onSubmit={saveReciptToDb}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                  }) => (
                    <View className="flex flex-col w-full gap-8">
                      <View className="w-full">
                        <Checkbox
                          status={values.reimbursed ? 'checked' : 'unchecked'}
                          onPress={() => {
                            // Aggiorna il valore di 'reimbursed' al cambio dello stato del checkbox
                            setFieldValue('reimbursed', !values.reimbursed);
                          }}
                        />
                        <Text style={{ marginLeft: 8 }}>Spesa da Rimborsare</Text>
                      </View>
                      <View className="flex flex-row w-full justify-end gap-4">
                        <Button
                          onPress={() => {
                            values = initialReceiptData;
                            handelReciptModal();
                          }}
                          buttonColor="#f50c2f"
                          textColor="white"
                          style={{ borderRadius: 4 }}
                        >
                          Annulla
                        </Button>
                        <Button
                          onPress={handleSubmit as (e: unknown) => void}
                          buttonColor="#3b8af7"
                          textColor="white"
                          style={{ borderRadius: 4 }}
                        >
                          Salva spesa
                        </Button>
                      </View>
                    </View>
                  )}
                </Formik>
              </View>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <Portal>
        <Dialog
          visible={isDeleteWorkerModalVisible}
          onDismiss={handelWorkerModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-black font-bold">
              Sei sicuro di voler eliminare il Lavoratore?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelWorkerModalClose}
              buttonColor="#3b8af7"
              textColor="white"
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold text-white">
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteWorker}
              buttonColor="#f50c2f"
              textColor="white"
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold text-white">
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <Portal>
        <Dialog
          visible={isDeleteAttachmentModalVisible}
          onDismiss={handelAttachmentModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-black font-bold">
              Sei sicuro di voler eliminare il documento?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelAttachmentModalClose}
              buttonColor="#3b8af7"
              textColor="white"
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold text-white">
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteAttachment}
              buttonColor="#f50c2f"
              textColor="white"
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold text-white">
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>


      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <Loader
          loading={
            loadingActivityDetails ||
            loadingActivityWorkersList ||
            loadingAllAttachments ||
            loadingAllRecipts ||
            loadingAllTasks
          }
        />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <Text
                variant="displayMedium"
                className="text-white pl-2 w-full text-4xl"
              >
                {ActivityDetails?.getActivitDetails?.name}
              </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                  <Button
                    icon="playlist-edit"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor="white"
                    style={{ borderRadius: 10, margin: 5 }}
                    onPress={() =>
                      navigation.push("EditActivityDetails", {
                        activity_id: activityid,
                      })
                    }
                  >
                    <Text className="text-white font-bold">
                      Modifica attività
                    </Text>
                  </Button>
                ) : null}
                {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                  <Button
                    icon="account-plus"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor="white"
                    style={{ borderRadius: 10, margin: 5 }}
                    onPress={() => {
                      setisAddWorkerModalVisible(true);
                      nonAllocatedworkersListRefetch();
                    }}
                  >
                    <Text className="text-white font-bold">Aggiungi lavoratore</Text>
                  </Button>
                ) : null}
                {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                  <Button
                    icon="file-plus"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor="white"
                    style={{ borderRadius: 10, margin: 5 }}
                    onPress={pickAttachment}
                  >
                    <Text className="text-white font-bold">Aggiungi documento</Text>
                  </Button>
                ) : null}
              </View>
            </View>

            <Text
              variant="titleSmall"
              className="text-white pl-2 py-2 text-base font-extralight"
            >
              {ActivityDetails?.getActivitDetails?.description}
            </Text>

            <View className="flex flex-row w-full p-2 my-4">
              <Text
                variant="headlineMedium"
                className="text-white w-full font-bold"
                style={{marginLeft: 5}}
              >
                Lavoratori
              </Text>


              <Portal>
                <Dialog
                  visible={isAddWorkerModalVisible}
                  onDismiss={() => setisAddWorkerModalVisible(false)}
                  style={{
                    height: 720,
                    width: 360,
                    padding: 2,
                    alignSelf: "center",
                    backgroundColor: colors.background,
                  }}
                >
                  <View className="my-4 p-2">
                    <Searchbar
                      placeholder="Cerca lavoratore..."
                      onChangeText={setSearchTerm}
                      value={searchTerm}
                      className="m-2"
                      placeholderTextColor={colors.white}
                      iconColor={colors.white}
                      inputStyle={{ color: colors.white }}
                      style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: "Roboto" }}
                    />
                  </View>
                  <Dialog.ScrollArea>
                    <ScrollView showsHorizontalScrollIndicator={false}>
                      <View className="p-2 item-center justify-center">
                        {sortedWorkerList.length > 0 ? (
                          sortedWorkerList.map(
                            (data, idk) => (
                              <AddworkerToActivityCard
                                key={idk}
                                data={data}
                                addnewWorker={addnewWorker}
                              />
                            )
                          )
                        ) : (
                          <Text className="text-xl font-bold text-white pl-1">
                            Non ci sono lavoratori da associare*
                          </Text>
                        )}
                      </View>
                    </ScrollView>
                  </Dialog.ScrollArea>
                </Dialog>
              </Portal>
            </View>

            <ScrollView
              horizontal
              className="flex flex-row p-2 pb-2 gap-4"
              showsHorizontalScrollIndicator={true}
            >
              {activityWorkersList &&
                activityWorkersList?.getWorkersofActivity &&
                activityWorkersList?.getWorkersofActivity.length ? (
                activityWorkersList?.getWorkersofActivity.map(
                  (workerdata, idk) => (
                    //@ts-ignore
                    <View key={idk} className="flex flex-row items-center justify-between">
                      <Button
                        mode="contained-tonal"
                        onPress={() => {
                          navigation.push("WorkerDetails", {
                            id: workerdata.project_worker_id.user_id,
                            hideEdit: false,
                          });
                        }}
                        buttonColor={colors.amaranto}
                        style={{ borderRadius: 10 }}
                        className="w-fit flex-1"
                      >
                        <Text
                          variant="titleSmall"
                          className="text-white pl-2 pb-4 font-semibold"
                        >
                          {workerdata.project_worker_id.user.first_name} {workerdata.project_worker_id.user.last_name}
                        </Text>
                        {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                          <IconButton
                            icon="trash-can-outline"
                            style={{
                              width: 26,
                              height: 24,
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginLeft: 8,
                            }}
                            iconColor={"white"}
                            size={20}
                            onPress={() => handleDeleteWorker(workerdata.worker_id)}
                          />
                        ) : null}
                      </Button>
                    </View>

                  )
                )
              ) : (
                <Text className="text-sm font-bold text-white pl-1">
                  Non ci sono lavoratori associati per questa attività*
                </Text>
              )}
            </ScrollView>

            <View className="flex flex-row w-full p-2 my-4">
              <Text
                variant="headlineMedium"
                className="text-white w-full font-bold"
                style={{marginLeft: 5}}
              >
                Documenti
              </Text>

            </View>

            <ScrollView
              horizontal
              className="flex flex-row p-2 gap-2"
              showsHorizontalScrollIndicator={true}
            >
              {getAllAttachments &&
                getAllAttachments?.getDocumentsAndRecieptByActivityId &&
                getAllAttachments?.getDocumentsAndRecieptByActivityId.length ? (
                getAllAttachments.getDocumentsAndRecieptByActivityId.map((data, idk) => (
                  <View key={idk} style={{ position: 'relative', borderWidth: 1, borderRadius: 6, borderColor: colors.white, backgroundColor: colors.grey }}>
                    <PdfBadge data={data} style={{ margin: 2 }} />

                    {(isProjectManager() || role === "ADMIN" || isAreaManager()) && (
                      <>
                        <IconButton
                          icon="trash-can-outline"
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            borderColor: 'white',
                            borderRadius: 5,
                            width: 24,
                            height: 24,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          iconColor={"white"}
                          size={20}
                          onPress={() => handleDeleteAttachment(data.id)}
                        />
                      </>
                    )}
                  </View>

                ))
              ) : (
                <Text className="text-sm font-bold text-white pl-1">
                  Non ci sono documenti per questa attività*
                </Text>
              )}
            </ScrollView>


            <View className="flex flex-row w-full p-2 my-4">
              <Text
                variant="headlineMedium"
                className="text-white w-full font-bold"
                style={{marginLeft: 5}}
              >
                Spese risorse
              </Text>
            </View>

            <ScrollView
              horizontal
              className="flex flex-row p-2 gap-2"
              showsHorizontalScrollIndicator={true}
            >
              {getAllRecipts &&
                getAllRecipts?.getDocumentsAndRecieptByActivityId &&
                getAllRecipts?.getDocumentsAndRecieptByActivityId.length ? (
                getAllRecipts.getDocumentsAndRecieptByActivityId.map(
                  (data, idk) => {
                    return (
                      <ReciptCard
                        data={data}
                        key={idk}
                        getAllReciptsRefetch={getAllReciptsRefetch}
                        setselectedReciept={setselectedReciept}
                        projectId={projectID}
                      />
                    );
                  }
                )
              ) : (
                <Text className="text-sm font-bold text-white pl-1">
                  Non ci sono spese per questa attività*
                </Text>
              )}
            </ScrollView>

            <View className="flex flex-row p-2 my-4">
              <Text variant="headlineMedium" className="text-white font-bold" style={{marginLeft: 5}}>
                Tasks timeline
              </Text>
            </View>
            <ScrollView className="p-2">
              {getAlltasks &&
                getAlltasks?.getTasksByActivityId &&
                getAlltasks?.getTasksByActivityId.length ? (
                getAlltasks?.getTasksByActivityId.map((data, idk) => (
                  <TaskCard
                    data={data}
                    key={idk}
                    navigation={navigation}
                    refetchTasklist={getAlltasksrefetch}
                    ProjectManager={projectData?.getProjectById?.project_manager?.id}
                    created_by={ActivityDetails?.getActivitDetails?.created_by}
                  />
                ))
              ) : (
                <Text className="text-sm font-bold text-white pl-1">
                  Non ci sono task disponibili per questa attività*
                </Text>
              )}
            </ScrollView>
          </View>
        </ScrollView >
      </View >
    </>
  );
}

export default ActivityDetails;
