import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { gql, useQuery } from "@apollo/client";
import { Button, Text } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import WorkerCard from "../../components/WorkerCard";
import { Searchbar } from "react-native-paper";
import Loader from "../../components/Loader";
import { getStorageData } from "../../libs/storage";
import colors from "../../styles/colors";

const getAllWorkerList = gql`
  query getAllWorkers {
    getAllWorkers {
      id
      projects_count
      clients_count
      qualification
      user {
        id
        first_name
        last_name
      } 
    }
  }
`;

function WorkersList({ navigation }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState(null);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
        const fetchRole = async () => {
            const storedRole = await getStorageData("role");
            if (storedRole) {
              const role = storedRole;
                setRole(role); 
            }
        };
        fetchRole();
    });

    return unsubscribe;
}, [navigation]);
  const {
    data: workerList,
    refetch: refetchworkerList,
    loading: loadingWorkerList,
  } = useQuery(getAllWorkerList);
 
  const [filteredArray, setfilteredArray] = useState(workerList?.getAllWorkers);

  const search = (query: any) => {
    setSearchQuery(query);
    filter(query);
  };

  const filter = (query) => {
    if (query?.length > 1) {
      const filtered = workerList?.getAllWorkers.filter((filter) => {
        return (
          filter?.user?.first_name?.toLowerCase()?.includes(query.toLowerCase()) ||
          filter?.user?.last_name?.toLowerCase()?.includes(query.toLowerCase())
        );
      });
      setfilteredArray(filtered);
    } else {
      setfilteredArray(workerList?.getAllWorkers);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      refetchworkerList();
      filter(searchQuery);
    }, [workerList?.getAllWorkers])
  );

  return (
    <>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: '#2B2B2B'}}>
        <Loader loading={loadingWorkerList} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <View className="flex flex-row w-full">
                <Text
                  variant="displayMedium"
                  className="flex font-semibold w-11/12 ml-4"
                  style={{ color: colors.white}}
                >
                  Lavoratori
                </Text>

                {/* <Button
                  icon="account-plus"
                  mode="contained"
                  buttonColor="#ffdf6b"
                  textColor="black"
                  onPress={() => navigation.navigate("CreateNewWorker")}
                  className="items-center justify-center mr-3"
                >
                  <Text className="text-black font-bold">
                    Create New Worker
                  </Text>
                </Button> */}
              </View>
            </View>

            <View className="my-4 p-2">
              <Searchbar
                placeholder="Cerca lavoratori..."
                mode="bar"
                onClearIconPress={() => {
                  setfilteredArray("")
                  filter("");
                }}
                onChangeText={search}
                value={searchQuery}
                className="m-2"
                placeholderTextColor={colors.white}
                iconColor={colors.white}
                inputStyle={{ color: colors.white }}
                style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: 'Roboto' }}
              />
            </View>

            <View className="p-2 item-center justify-center">
              {filteredArray && filteredArray.length ? (
                filteredArray?.map((page, idk) => (
                  <WorkerCard
                    data={page}
                    key={idk}
                    navigation={navigation}
                    workerListRefetch={refetchworkerList}
                    role={role}
                  />
                ))
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono lavoratori disponibili*
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkersList;
