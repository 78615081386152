import * as React from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import WorkerActivityCard from "../../components/WorkerActivityCard";
import { gql, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import colors from "../../styles/colors";

const getProjectActivitiesOfWorker = gql`
  query getProjectActivitiesOfWorker($input: ProjectActivitiesOfWorkerInput) {
    getProjectActivitiesOfWorker(input: $input) {
      activities {
        id
        name
        end_date
        description
      }
    }
  }
`;

const getProjectDetailsById = gql`
query getProjectById($input: userProfileInput) {
  getProjectById(input: $input) {
    address
    longitude
    latitude
    area_manager {
      id
      user {
        first_name
        last_name
      }
    }
    billing_time
    invoice_details
    contract_activity
    budget
    client_note
    description
    end_date
    event_date
    id
    invoice_id
    name
    note
    offer_id
    project_area {
      name
    }
    project_created_by {
      user {
        first_name
        last_name
      }
    }
    project_id
    project_manager {
      user {
        first_name
        last_name
      }
    }
    start_date
    state_code
    year
    project_reference
    client_reference
    status_code
    contract_value
    margin
    fee
    payment_check
    reporting_file
    check_planning
    quality_plan
    expeses_to_be_reimbursed
    expeses_included
    status
    client_group
    client_manager
    project_sector {
      name
    }
    note_compilatore
    note_check_pm
    note_resp_area
  }
}
`;

function WorkerProjectDetailsScreen({ navigation, route }) {
  const projectId = route.params.id;
  const { t } = useTranslation();

  const { data: activitylist, loading: activityListLoading } = useQuery(
    getProjectActivitiesOfWorker,
    {
      variables: { input: { id: projectId } },
    }
  );

  const { data: projectDetails, loading: projectDetailsLoading } = useQuery(
    getProjectDetailsById,
    {
      variables: { input: { id: projectId } },
    }
  );

  const styles = StyleSheet.create({
    titleContainer: {
      alignItems: 'flex-start',
      paddingTop: 25,
      paddingBottom: 15,
      marginLeft: 9,
    },
    titleText: {
      color: 'white',
      fontSize: 30,
      fontWeight: 'bold',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  });

  return (
    <>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <Loader loading={activityListLoading || projectDetailsLoading} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <Text
              variant="displayMedium"
              className="text-white pl-2 pb-4 font-semibold"
              style={{ marginLeft: 25 }}
            >
              Dettagli progetto
            </Text>
            <View className="p-7 item-center justify-center">
              <View style={styles.titleContainer}>
                <Text style={styles.titleText}>
                  {t("Anagrafica commessa")} :{" "}
                </Text>
              </View>

              {projectDetails?.getProjectById?.client_manager && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Client manager")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.client_manager}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.client_group && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Client group")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.client_group}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.project_id && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Codice interno commessa")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.project_id}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.name && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Nome progetto")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails?.getProjectById?.name}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.project_area?.name && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Area")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.project_area.name}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.project_sector?.name && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Settore")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.project_sector?.name}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.project_manager?.user && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Project manager")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.project_manager.user.first_name}{" "}
                    {projectDetails.getProjectById.project_manager.user.last_name}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.project_reference && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Project reference")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.project_reference}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.contract_activity && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Attività oggetto del contratto")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.contract_activity}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.start_date && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <MaterialCommunityIcons
                    name={"calendar-clock-outline"}
                    size={22}
                    color={"white"}
                  />
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Data inizio")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">{`${new Date(
                    parseInt(projectDetails.getProjectById.start_date)
                  ).toLocaleDateString("en-GB")}`}</Text>
                </View>
              )}
              {projectDetails?.getProjectById?.end_date && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <MaterialCommunityIcons
                    name={"calendar-clock-outline"}
                    size={22}
                    color={"white"}
                  />
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Data fine")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">{`${new Date(
                    parseInt(projectDetails.getProjectById.end_date)
                  ).toLocaleDateString("en-GB")}`}</Text>
                </View>
              )}
              {projectDetails?.getProjectById?.event_date && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <MaterialCommunityIcons
                    name={"calendar-clock-outline"}
                    size={22}
                    color={"white"}
                  />
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Data evento")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {`${new Date(
                      parseInt(projectDetails.getProjectById.event_date)
                    ).toLocaleDateString("en-GB")}`}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.client_reference && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Referente cliente")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.client_reference}
                  </Text>
                </View>
              )}
              {projectDetails?.getProjectById?.address && (
                <View className="flex flex-row pl-2 items-center gap-2 pb-2">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    {t("Indirizzo")} :{" "}
                  </Text>
                  <Text className="flex w-1/2 text-white text-base">
                    {projectDetails.getProjectById.address}
                  </Text>
                </View>
              )}

              {/* <View className="flex flex-row pl-2 items-center gap-2 pb-6">
              <MaterialCommunityIcons
                name={"calendar-clock-outline"}
                size={25}
                color={"white"}
              />
              <Text className="font-semibold text-white">
                Due date :{" "}
                {`${new Date(
                  parseInt(projectDetails?.getProjectById?.end_date)
                ).toLocaleDateString("en-GB")}`}
              </Text>
            </View> */}

              <View className="flex flex-col w-full gap-2 p-4">
                <Text className="font-semibold text-white mb-4" style={{ left: 25 }}>
                  Timeline attività
                </Text>

                {activitylist &&
                  activitylist.getProjectActivitiesOfWorker &&
                  activitylist.getProjectActivitiesOfWorker.activities.length ? (
                  activitylist.getProjectActivitiesOfWorker.activities.map(
                    (data, idk) => (
                      <WorkerActivityCard
                        data={data}
                        key={idk}
                        navigation={navigation}
                        projectID={projectDetails?.getProjectById?.project_id}
                      />
                    )
                  )
                ) : (
                  <Text className="text-xl font-bold text-white pl-1">
                    Nessuna attività disponibile*
                  </Text>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkerProjectDetailsScreen;
