import React, { useState} from "react";
import { TouchableOpacity, View, Text } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import colors from "../styles/colors";

function AddworkerToActivityCard({ data, addnewWorker}) {
  const [isAddingWorker, setIsAddingWorker] = useState(false);
  function addWorker(e: any) {
    setIsAddingWorker(true);
    addnewWorker(data.id);
    setIsAddingWorker(false);
  }

  return (
    <View>
      <TouchableOpacity onPress={isAddingWorker ? null : addWorker}>
        <View className="justify-start items-start pb-6 pr-2">
          <View className="flex flex-row pb-2 w-full" style={{backgroundColor: colors.background}}>
            <View className="flex flex-col w-2/3">
              <Text className="text-base font-bold text-white">
                {data.user.first_name} {data.user.last_name}
              </Text>
              <Text className="text-sm text-white">
                Qualifica : {data.qualification}
              </Text>
            </View>
            <View className="flex flex-auto w-1/3 items-end pr-8 justify-center">
              <Icon name={"chevron-right"} size={15} color={"white"} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default AddworkerToActivityCard;
