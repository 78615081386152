const colors = {
    background: '#2B2B2B',
    grey: '#4F4F4F',
    white: 'white',
    amaranto: '#800000',
    blue: '#1a2a6c',
    red: '#7d0001',
    green: '#536B58',
    orange: '#b34700',
    yellow: '#b8860b',
}

export default colors