import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import colors from '../styles/colors';

const MyWebDateTimePicker = ({ fieldName, setFieldValue }) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (selectedDates, dateStr, instance) => {
    setDate(selectedDates[0]);
    setFieldValue(fieldName, selectedDates[0]);
  };

  return (
    <Flatpickr
      data-enable-time
      value={date}
      onChange={handleChange}
      locale="Italian"
      options={{
        dateFormat: "d/m/Y H:i",
        enableTime: true, // Abilita la selezione dell'ora
        allowInput: true, // Permette l'input diretto da tastiera
        clickOpens: true, // Apre il calendario al click sull'input
        time_24hr: true,
        minuteIncrement: 1,
      }}
      style={{
        height: 30,
        padding: 5,
        border: "2px solid #677788",
        borderRadius: 10,
        width: 250,
        margin: 2,
      }}
    />
  );
};

export default MyWebDateTimePicker;
