import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import Loader from "../../components/Loader";
import colors from "../../styles/colors";

const GET_TASK_DETAILS = gql`
  query getTasksByID($input: getTasksByIDInput) {
    getTasksByID(input: $input) {
      end_time
      id
      name
      start_time
      worker_name
    }
  }
`;

function TaskDetails({ route }: any) {
  const [taskId] = useState(route.params.id);

  const {
    data: taskData,
    refetch: refetchTaskData,
    loading: taskDataLoading,
    error: errorTaskData,
  } = useQuery(GET_TASK_DETAILS, {
    fetchPolicy: "network-only",
    variables: { input: { task_id: taskId } },
  });

  return (
    <>
      <View className="flex flex-1">
        <View className="p-2 pt-6 h-full" style={{backgroundColor: colors.background}}>
          <Loader loading={taskDataLoading} />
          <ScrollView>
            <SafeAreaView>
              <Text
                variant="displayMedium"
                className="text-white pl-2 pb-4 text-4xl"
              >
                Task : {taskData?.getTasksByID?.name}
              </Text>
              <View className="p-2 item-center justify-center mt-6">
                <View className="flex flex-col w-full gap-2">
                  <View className="flex flex-row items-center justify-center">
                    <Text className="flex w-full text-white text-2xl font-semibold">
                      Inizio :{"    "}
                      <Text className="text-white text-base font-semibold">
                        {`${new Date(
                          parseInt(taskData?.getTasksByID?.start_time)
                        ).toLocaleDateString("en-GB")} ${new Date(
                          parseInt(taskData?.getTasksByID?.start_time)
                        ).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`}
                      </Text>
                    </Text>
                  </View>
                  <View className="flex flex-row items-center justify-center">
                    <Text className="flex w-full text-white text-2xl font-semibold">
                      Fine :{"      "}
                      <Text className="text-white text-base font-semibold">
                        {`${new Date(
                          parseInt(taskData?.getTasksByID?.end_time)
                        ).toLocaleDateString("en-GB")} ${new Date(
                          parseInt(taskData?.getTasksByID?.end_time)
                        ).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`}
                      </Text>
                    </Text>
                  </View>

                  <View className="flex flex-row items-center justify-center">
                    <Text className="flex w-full text-white text-2xl font-semibold">
                      Creato da :{"      "}
                      <Text className="text-white text-base font-semibold">
                        {taskData?.getTasksByID?.worker_name}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            </SafeAreaView>
          </ScrollView>
        </View>
      </View>
    </>
  );
}

export default TaskDetails;
