export const uploadFileToS3 = async (presignedUrl, file) => {
  // Determinare il tipo MIME del file
  const contentType = file.type || 'application/octet-stream'; // Default to binary stream if type is not available
  
  const myHeaders = new Headers({
    "Content-Type": contentType,
    "Content-Disposition": "inline",
  });

  try {
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });

    if (response?.ok == true) {
      return { status: "SUCCESS", message: "File Uploaded Successfully" };
    }

    return { status: "FAILED", message: "File Upload Failed" };
    
  } catch (error) {
    console.log(error, "error : uploading file");
    return { status: "FAILED", message: "File Upload Failed" }; // Ensure a failed status is returned in case of an error
  }
};
