import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { ScrollView, View } from "react-native";
import { Button, Dialog, Portal, Text, TextInput } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useFocusEffect } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import TaskCard from "../../components/TaskCard";
import { validate } from "../../libs/form.validate";
import { Formik } from "formik";
import { Dropdown } from "react-native-element-dropdown";
import ReciptCard from "../../components/ReciptCard";
import MyWebDatePicker from "../../components/MyDatePicker";
import Loader from "../../components/Loader";
import { SpendingMode } from "../../constants/static-data";
import { uploadFileToS3 } from "../../libs/common.functions";
import colors from "../../styles/colors";

const getActivityTasksOfWorker = gql`
  query getActivityTasksOfWorker($input: ActivityTasksOfWorkerInput) {
    getActivityTasksOfWorker(input: $input) {
      tasks {
        id
        name
        start_time
        end_time
        created_by
        total_hrs
        client_name
      }
    }
  }
`;

const getActivityDetails = gql`
  query getActivitDetails($input: getActivitDetails) {
    getActivitDetails(input: $input) {
      name
      description
      end_date
    }
  }
`;

const GET_ALL_RECIEPT_TYPES = gql`
  query getAllRecieptTypes {
    getAllRecieptTypes {
      id
      type
    }
  }
`;

const ADD_ATTACHMENT_TO_ACTIVITY = gql`
  mutation addAttachmentToActivity($input: addAttachmentToActivity) {
    addAttachmentToActivity(input: $input) {
      status
      message
    }
  }
`;

const GET_SIGNED_URL = gql`
  query getPresignedURL($input: getPresignedURLInput) {
    getPresignedURL(input: $input) {
      message
      presignedURL
    }
  }
`;

const GET_RECIPTS_BY_ACTIVITY_ID = gql`
  query getRecieptsOfWorker($input: getRecieptsOfWorkerInput) {
    getRecieptsOfWorker(input: $input) {
      id
      type
      file_name
      fileUrl
      receipt_title
      receipt_date
      receipt_value
      km_routes
      attachment_created_by {
        user {
          first_name
          last_name
        }
      }
      receipt_city
      receipt_expenditure_method
      receipt_type
      receipt_type_id
      details
    }
  }
`;

const EDIT_RECEIPT = gql`
  mutation updateAttachment($input: updateAttachmentInput) {
    updateAttachment(input: $input) {
      message
      status
    }
  }
`;

function WorkerActivityDetailsScreen({ navigation, route }) {
  const activityId = route.params.id;
  const projectID = route.params.projectID;
  const [isSaving, setIsSaving] = useState(false);
  const {
    data: tasklist,
    refetch: tasklistRefetch,
    loading: taskListLoading,
  } = useQuery(getActivityTasksOfWorker, {
    variables: { input: { activity_id: activityId } },
  });

  const { data: ReciptTypes } = useQuery(GET_ALL_RECIEPT_TYPES, {
    fetchPolicy: "network-only",
  });

  let formattedReciptsTypes = [];

  let KMValue;

  if (ReciptTypes?.getAllRecieptTypes?.length) {
    formattedReciptsTypes = ReciptTypes?.getAllRecieptTypes?.map((receipt) => {
      return {
        label: receipt?.type,
        value: receipt?.id,
      };
    });

    formattedReciptsTypes.forEach((item) => {
      if (item.label == "KM") {
        KMValue = item;
      }
    });
  }

  const { data: activityDetail, loading: activityDetailLoading } = useQuery(
    getActivityDetails,
    {
      variables: { input: { activity_id: activityId } },
    }
  );

  const {
    data: getAllRecipts,
    refetch: getAllReciptsRefetch,
    loading: loadingAllRecipts,
  } = useQuery(GET_RECIPTS_BY_ACTIVITY_ID, {
    variables: { input: { activity_id: activityId } },
  });

  const [editReciept, { data: editRecieptData }] = useMutation(EDIT_RECEIPT);
  const [isReciptUploadModalVisible, setIsReciptUploadModalVisible] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [getSignedUrl, { data: signedUrlData }] = useLazyQuery(GET_SIGNED_URL);
  const [selectedReciept, setselectedReciept] = useState(null);
  const [initialReceiptData, setInitialReceiptData] = useState({
    receipt_title: "",
    receipt_date: new Date(),
    receipt_type: "",
    expenditure_type: "",
    details: "",
    km_routes: "",
    file_name: "",
    receipt_value: 0,
    city: "",
  });

  useEffect(() => {
    if (selectedReciept) {
      setInitialReceiptData({
        receipt_title: selectedReciept?.receipt_title,
        receipt_date: new Date(parseInt(selectedReciept?.receipt_date)),
        receipt_type: selectedReciept?.receipt_type_id,
        expenditure_type: selectedReciept?.receipt_expenditure_method,
        km_routes: selectedReciept?.km_routes,
        city: selectedReciept?.receipt_city,
        details: selectedReciept?.details,
        receipt_value: selectedReciept?.receipt_value,
        file_name: selectedReciept?.file_name,
      });
      setSelectedFile(selectedReciept?.file_name);
      setIsReciptUploadModalVisible(true);
    }
  }, [selectedReciept]);


  const [validationErrors, setValidationErrors] = useState({});
  const REQUIRED_FIELDS = ["receipt_type", "receipt_value"];
  const [
    addattachmentToActivity,
    {
      data: attachmentToActivityData,
      loading: attachmentToActivityLoading,
      error: attachmentToActivityError,
    },
  ] = useMutation(ADD_ATTACHMENT_TO_ACTIVITY);

  const pickReceipt = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    //@ts-ignore
    const response = await fetch(result?.uri);
    const blob = await response.blob();

    let FILENAME = `${Date.now()}_${result["name"]}`;
    FILENAME = FILENAME.trim()
      .split(" ")
      .filter((word) => word)
      .join("");
    const getUrl = await getSignedUrl({
      variables: {
        input: { fileName: FILENAME },
      },
    });

    if (getUrl?.data) {
      const fileUploadStatus = await uploadFileToS3(
        getUrl?.data?.getPresignedURL?.presignedURL,
        blob
      );

      if (fileUploadStatus.status == "SUCCESS") {
        setSelectedFile(FILENAME);
      }
    }
  };

  const handelReciptModal = async () => {
    setInitialReceiptData({
      receipt_title: "",
      receipt_date: new Date(),
      receipt_type: "",
      expenditure_type: "",
      details: "",
      km_routes: "",
      receipt_value: 0,
      file_name: "",
      city: "",
    });
    setValidationErrors({});
    setSelectedFile("");
    setselectedReciept(null);
    setIsReciptUploadModalVisible(false);
    await getAllReciptsRefetch();
  };

  const saveReciptToDb = async (values) => {
    values = { ...values, selectedFile };
    let isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    if (isValidForm) {
      try {
        if (selectedReciept) {
          await editReciept({
            variables: {
              input: {
                attachment_id: selectedReciept.id,
                activity_id: activityId,
                details: values.details,
                receipt_city: values.city,
                receipt_date:
                  values.receipt_date && typeof values.receipt_date == "string"
                    ? new Date(parseInt(values.receipt_date))
                    : values.receipt_date,
                receipt_expenditure_method: values.expenditure_type,
                receipt_title: values.receipt_title,
                receipt_type_id: values.receipt_type,
                receipt_value: parseFloat(values.receipt_value),
                km_routes: values.km_routes,
                file_name: selectedFile,
              },
            },
          });
        } else {
          const saveDataToDb = {
            type: "RECEIPT",
            receipt_title: values.receipt_title,
            receipt_date: values.receipt_date || new Date(),
            file_name: selectedFile,
            details: values.details,
            activity_id: activityId,
            receipt_type_id: values.receipt_type,
            receipt_value: parseFloat(values.receipt_value),
            receipt_expenditure_method: values.expenditure_type,
            receipt_city: values.city,
            km_routes: values.km_routes.toString(),
          };

          await addattachmentToActivity({ variables: { input: saveDataToDb } });
        }
        setValidationErrors({});
        handelReciptModal();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      tasklistRefetch();
      getAllReciptsRefetch();
    }, [])
  );

  return (
    <>
      <Portal>
        <Dialog
          visible={isReciptUploadModalVisible}
          onDismiss={handelReciptModal}
          // @ts-ignore
          style={{
            backgroundColor: colors.grey,
            alignSelf: "center",
            maxHeight: "80%",
          }}
        >
          <Dialog.Title style={{ color: "white" }}>
            Dettagli spesa
          </Dialog.Title>

          <Dialog.ScrollArea className="flex flex-col p-2" style={{ backgroundColor: colors.grey }}>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 18 }}>
              <View>
                <Formik
                  initialValues={initialReceiptData}
                  onSubmit={async (values, { setSubmitting }) => {
                    setIsSaving(true); // Imposta lo stato del salvataggio su true
                    await saveReciptToDb(values); // Esegui la funzione di salvataggio

                    // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                    setTimeout(() => {
                      setIsSaving(false);
                      setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                    }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
                  }}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                  }) => (
                    <View className="flex flex-col w-full gap-8">
                      <View className="w-full px-2">
                        <MyWebDatePicker
                          fieldName={"receipt_date"}
                          setFieldValue={setFieldValue}
                        />
                      </View>

                      <View className="w-full">
                        <TextInput
                          label="Descrizione"
                          mode="flat"
                          onChangeText={handleChange("details")}
                          onBlur={handleBlur("details")}
                          value={values.details}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="#53555e"
                          underlineColor="white"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                      </View>

                      <View className="w-full">
                        <TextInput
                          label="Città"
                          mode="flat"
                          onChangeText={handleChange("city")}
                          onBlur={handleBlur("city")}
                          value={values.city}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="#53555e"
                          underlineColor="white"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                      </View>

                      <View className="w-full">
                        <TextInput
                          label="Importo"
                          mode="flat"
                          onChangeText={handleChange("receipt_value")}
                          onBlur={handleBlur("receipt_value")}
                          value={values.receipt_value.toString()}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="#53555e"
                          underlineColor="white"
                          disabled={
                            values.receipt_type == KMValue.value ? true : false
                          }
                          keyboardType="numeric"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                        {validationErrors["receipt_value"] && (
                          <View>
                            <Text
                              className="text-red-400 text-sm p-2"
                              style={{ color: "red" }}
                            >
                              {validationErrors["receipt_value"]}
                            </Text>
                          </View>
                        )}
                      </View>

                      <View className="w-full">
                        <Dropdown
                          style={{
                            backgroundColor: colors.grey,
                            padding: 15,
                            paddingHorizontal: 30,
                            justifyContent: 'flex-end',
                            borderBottomWidth: 1,
                            borderBottomColor: colors.white,
                          }}
                          containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                          data={SpendingMode}
                          labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                          valueField="value" // Definisce il campo del valore per gli elementi della lista
                          value={values.expenditure_type}
                          placeholder="Metodo di spesa"
                          onChange={(item) => setFieldValue("expenditure_type", item.value)}
                          selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                          inputSearchStyle={{
                            backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                            overflow: 'hidden',
                          }}

                          renderItem={(item) => (
                            <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                              {item.label}
                            </Text>
                          )}
                        />
                      </View>

                      <View className="w-full">
                        <Dropdown
                          style={{
                            backgroundColor: colors.grey,
                            padding: 15,
                            paddingHorizontal: 30,
                            justifyContent: 'flex-end',
                            borderBottomWidth: 1,
                            borderBottomColor: colors.white,
                          }}
                          containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                          data={formattedReciptsTypes}
                          labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                          valueField="value" // Definisce il campo del valore per gli elementi della lista
                          value={values.receipt_type}
                          placeholder="Tipologia spesa"
                          onChange={(item) => setFieldValue("receipt_type", item.value)}
                          selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                          inputSearchStyle={{
                            backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                            overflow: 'hidden',
                          }}

                          renderItem={(item) => (
                            <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                              {item.label}
                            </Text>
                          )}
                        />
                        {validationErrors["receipt_type"] && (
                          <View>
                            <Text
                              className="text-red-400 text-sm p-2"
                              style={{ color: "red" }}
                            >
                              {validationErrors["receipt_type"]}
                            </Text>
                          </View>
                        )}
                      </View>

                      {values.receipt_type == KMValue.value && (
                        <View className="w-full">
                          <TextInput
                            label="KM Percorsi"
                            mode="flat"
                            onChangeText={(data) => {
                              setFieldValue("km_routes", data);
                              setFieldValue(
                                "receipt_value",
                                parseFloat(data || "0") * 0.4
                              );
                            }}
                            onBlur={handleBlur("km_routes")}
                            value={values.km_routes.toString()}
                            style={{ backgroundColor: colors.grey }}
                            activeUnderlineColor="#53555e"
                            underlineColor="#53555e"
                            keyboardType="numeric"
                            theme={{
                              colors: {
                                onSurfaceVariant: "white",
                              },
                            }}
                          />
                        </View>
                      )}

                      <View className="mt-6 mb-4 w-full">
                        {selectedFile ? (
                          <Button
                            onPress={pickReceipt}
                            buttonColor={colors.orange}
                            textColor="white"
                            style={{ borderRadius: 4 }}
                          >
                            <Text
                              variant="bodyMedium"
                              className="font-bold text-white"
                            >
                              {selectedFile}
                            </Text>
                          </Button>
                        ) : (
                          <Button
                            onPress={pickReceipt}
                            icon={"upload"}
                            buttonColor={colors.orange}
                            textColor="white"
                            style={{ borderRadius: 4 }}
                          >
                            <Text
                              variant="bodyMedium"
                              className="font-bold text-white"
                            >
                              Seleziona file
                            </Text>
                          </Button>
                        )}
                        {validationErrors["selectedFile"] && (
                          <View>
                            <Text
                              className="text-red-400 text-sm p-2"
                              style={{ color: "red" }}
                            >
                              {validationErrors["selectedFile"]}
                            </Text>
                          </View>
                        )}
                      </View>
                      {/* <View className="w-full">
                        <Checkbox
                          status={values.reimbursed ? 'checked' : 'unchecked'}
                          onPress={() => {
                            // Aggiorna il valore di 'reimbursed' al cambio dello stato del checkbox
                            setFieldValue('reimbursed', !values.reimbursed);
                          }}
                        />
                        <Text style={{ marginLeft: 8 }}>Not in Budget</Text>
                      </View> */}
                      <View className="flex flex-row w-full justify-end gap-4">
                        <Button
                          onPress={() => {
                            values = initialReceiptData;
                            handelReciptModal();
                          }}
                          buttonColor={colors.blue}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                        >
                          Annulla
                        </Button>
                        <Button
                          onPress={handleSubmit as (e: unknown) => void}
                          buttonColor={colors.red}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                          disabled={isSaving}
                        >
                          Salva spesa
                        </Button>
                      </View>
                    </View>
                  )}
                </Formik>
              </View>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <Loader
          loading={
            taskListLoading ||
            activityDetailLoading ||
            loadingAllRecipts ||
            attachmentToActivityLoading
          }
        />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4 ">
            <View className="flex flex-row w-full mb-4 gap-4" style={{ left: 6 }}>
              <Text
                variant="displayMedium"
                className="text-white w-full font-semibold"
              >
                Overview Attività
              </Text>

              <Button
                icon="note-plus"
                mode="contained"
                buttonColor={colors.amaranto}
                textColor={colors.white}
                className="items-center justify-center"
                style={{ borderRadius: 10 }}
                onPress={() =>
                  navigation.navigate("WorkerCreateNewTask", {
                    id: activityId,
                  })
                }
              >
                <Text className="text-white font-bold">Nuovo task</Text>
              </Button>

              <Button
                icon="note-plus"
                mode="contained"
                buttonColor={colors.amaranto}
                textColor={colors.white}
                className="items-center justify-center"
                onPress={() => setIsReciptUploadModalVisible(true)}
                style={{ borderRadius: 10 }}
              >
                <Text className="text-white font-bold">Nuova spesa</Text>
              </Button>
            </View>

            <Text
              variant="titleSmall"
              className="text-white mb-4 font-semibold text-justify"
              style={{ marginLeft: 10 }}
            >
              {activityDetail?.getActivitDetails?.description}
            </Text>

            <View className="flex flex-row items-center gap-2 mb-6" style={{ marginLeft: 2 }}>
              <MaterialCommunityIcons
                name={"calendar-clock-outline"}
                size={25}
                color={"white"}
              />
              <Text className="font-semibold text-white">
                {" "}
                Data Fine :{" "}
                {`${new Date(
                  parseInt(activityDetail?.getActivitDetails?.end_date)
                ).toLocaleDateString("en-GB")}`}
              </Text>
            </View>

            <Text
              variant="headlineLarge"
              className="text-white w-full font-semibold my-4"
              style={{ marginLeft: 10 }}
            >
              Spese Risorse
            </Text>

            <ScrollView
              horizontal
              className="flex flex-row p-2 gap-2"
              showsHorizontalScrollIndicator={true}
            >
              {getAllRecipts &&
                getAllRecipts?.getRecieptsOfWorker &&
                getAllRecipts?.getRecieptsOfWorker.length ? (
                getAllRecipts.getRecieptsOfWorker.map((data, idk) => {
                  return (
                    <ReciptCard
                      data={data}
                      key={idk}
                      getAllReciptsRefetch={getAllReciptsRefetch}
                      setselectedReciept={setselectedReciept}
                    />
                  );
                })
              ) : (
                <Text className="text-sm font-bold text-white pl-1">
                  Non ci sono spese per questa attività*
                </Text>
              )}
            </ScrollView>

            <Text
              variant="headlineLarge"
              className="text-white w-full font-semibold my-4"
              style={{ marginLeft: 10 }}
            >
              {activityDetail?.getActivitDetails?.name} - Task timeline
            </Text>

            <View className="flex flex-col w-full gap-2 p-2">
              {tasklist &&
                tasklist.getActivityTasksOfWorker &&
                tasklist.getActivityTasksOfWorker.tasks.map((data, idk) => (
                  <TaskCard
                    data={data}
                    key={idk}
                    navigation={navigation}
                    refetchTasklist={tasklistRefetch}
                  />
                ))}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkerActivityDetailsScreen;
function getSignedUrl(arg0: { variables: { input: { fileName: string } } }) {
  throw new Error("Function not implemented.");
}
