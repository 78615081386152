import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import {
  Button,
  Checkbox,
  Dialog,
  Portal,
  Text,
  TextInput,
} from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import { Dropdown } from "react-native-element-dropdown";
import { Formik } from "formik";
import { validate } from "../../libs/form.validate";
import Loader from "../../components/Loader";
import ExpenseCard from "../../components/ExpenseCard";
import ReceiptCard from "../../components/ReciptCard";
import MyWebDatePicker from "../../components/MyDatePicker";
import { useFocusEffect } from "@react-navigation/native";
import { uploadFileToS3 } from "../../libs/common.functions";
import { APP_CURRENCY, PERCENTAGE } from "../../constants/App.constants";
import { useTranslation } from "react-i18next";
import ProjectsWorkerCard from "../../components/ProjectsWorkerCard";
import { SpendingMode } from "../../constants/static-data";
import { getStorageData } from "../../libs/storage";
import {
  USER_PROFILE_ROLE,
} from "../../graphql/user.resolver";
import colors from "../../styles/colors";

const getProjectDetailsQuery = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      address
      longitude
      latitude
      area_manager {
        id
        user {
          first_name
          last_name
        }
      }
      billing_time
      invoice_details
      budget
      client_note
      description
      end_date
      event_date
      id
      invoice_id
      name
      note
      offer_id
      project_area {
        name
        id
      }
      project_created_by {
        user {
          first_name
          last_name
        }
      }
      project_id
      project_manager {
        user {
          first_name
          last_name
        }
      }
      start_date
      state_code
      year
      project_reference
      client_reference
      status_code
      contract_value
      margin
      fee
      payment_check
      reporting_file
      check_planning
      quality_plan
      expeses_to_be_reimbursed
      expeses_included
      status
      client_group
      client_manager
      project_sector {
        name
      }
      note_compilatore
      note_check_pm
      note_resp_area
      contract_activity
      sending_customer_invoice
      sending_invoice_made_by
      invoice_paid
      compensation
      compensationsSent {
        fromProject_id
        toProject_id
        amount
      }
      compensationsReceived {
        fromProject_id
        toProject_id
        amount
      }
    }
  }
`;

// const GET_PROJECTS_TASKS = gql`
//   query getProjectTasksOfProjectforAdmin(
//     $input: getProjectTasksOfProjectforAdminInput
//   ) {
//     getProjectTasksOfProjectforAdmin(input: $input) {
//       tasks {
//         id
//         name
//         start_time
//         created_by
//         total_hrs
//         end_time
//       }
//     }
//   }
// `;

const GET_ALL_EXPENSES_TYPES = gql`
  query getAllExpenceTypes {
    getAllExpenceTypes {
      id
      type
    }
  }
`;

const GET_ALL_RECEIPTS_TYPES = gql`
  query getAllRecieptTypes {
    getAllRecieptTypes {
      id
      type
    }
  }
`;

const CREATE_EXPENSE_ENTRY = gql`
  mutation createExpenceEntry($input: createExpenceEntryInput) {
    createExpenceEntry(input: $input) {
      message
      status
    }
  }
`;

const GET_ALL_EXPENSES = gql`
  query getExpenceDetails($input: getExpenceDetailsInput) {
    getExpenceDetails(input: $input) {
      id
      file_name
      title
      type_id
      project_id
      cost
      type
      reimbursed
      km_routes
      expense_expenditure_method
      expense_date
      fileUrl
      created_at
      created_by
      description
    }
  }
`;

const GET_ALL_RECEIPTS = gql`
query getProjectDataByDate($input: getProjectDataByDateInput) {
  getProjectDataByDate(input: $input) {
    receipts {
        id
        type
        file_name
        receipt_title
        fileUrl
        details
        created_by
        created_at
        activity_id
        receipt_value
        km_routes
        receipt_date
        receipt_city
        receipt_expenditure_method
        receipt_type_id
        receipt_type
        client
        project_id
        worker_name
        reimbursed
      }
    }
  }
`;

const getReciptsByActivityId = gql`
  query getDocumentsAndRecieptByActivityId(
    $input: getDocumentsAndRecieptByActivityId
  ) {
    getDocumentsAndRecieptByActivityId(input: $input) {
      id
      type
      file_name
      fileUrl
      receipt_title
      receipt_date 
      receipt_value
      receipt_city
      km_routes
      receipt_expenditure_method
      receipt_type
      receipt_type_id
      activity_id
      details
      created_by
      worker_name
      reimbursed
    }
  }
`;

const addAttachmentToActivityMutation = gql`
  mutation addAttachmentToActivity($input: addAttachmentToActivity) {
    addAttachmentToActivity(input: $input) {
      status
      message
    }
  }
`;

const GET_SIGNED_URL = gql`
  query getPresignedURL($input: getPresignedURLInput) {
    getPresignedURL(input: $input) {
      message
      presignedURL
    }
  }
`;

const addExpenseContainerStyle = {
  backgroundColor: colors.grey,
  maxHeight: "80%",
  padding: 2,
  alignSelf: "center",
};

const UPDATE_EXPENSE = gql`
  mutation updateExpense($input: updateExpenceInput) {
    updateExpense(input: $input) {
      message
      status
    }
  }
`;

const EDIT_RECEIPT = gql`
  mutation updateAttachment($input: updateAttachmentInput) {
    updateAttachment(input: $input) {
      message
      status
    }
  }
`;

const GET_PROJECTS_WORKERS = gql`
  query getWorkersByProjectId($input: getWorkersByProjectIdInput) {
    getWorkersByProjectId(input: $input) {
      first_name
      last_name
      qualification
    }
  }
`;

function ProjectDetails({ route, navigation }: any) {
  const [projectid] = useState(route.params.id);
  const [hideEditAndActivity] = useState(route?.params?.hideButtons);
  const [isExpenseAddModalVisible, setIsExpenseAddModalVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const REQUIRED_FIELDS = ["cost", "title", "type"];
  const { t } = useTranslation();
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");

  //RECEIPT
  const [selectedFileReceipt, setSelectedFileReceipt] = useState("");
  const [isReciptUploadModalVisible, setIsReciptUploadModalVisible] =
    useState(false);
  const REQUIRED_FIELDS_RECEIPT = [];
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [initialReceiptData, setInitialReceiptData] = useState({
    reimbursed: false,
  });
  const [isSaving, setIsSaving] = useState(false);

  const [role, setRole] = useState(null);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  const {
    data: allExpensesList,
    refetch: refetchAllExpensesList,
    loading: loadingAllExpenseList,
  } = useQuery(GET_ALL_EXPENSES, {
    fetchPolicy: "network-only",
    variables: { input: { project_id: projectid } },
  });

  const {
    data: allReceiptsList,
    refetch: refetchAllReceiptsList,
    loading: loadingAllReceiptsList,
  } = useQuery(GET_ALL_RECEIPTS, {
    fetchPolicy: "network-only",
    variables: { input: { project_id: projectid, fromDate: null, toDate: null, receipt: true } },
  });
  //console.log(allReceiptsList);
  let activity_id = allReceiptsList?.getProjectDataByDate?.receipts[0]?.activity_id;
  //console.log(activity_id,"activity");
  const {
    data: getAllRecipts,
    refetch: getAllReciptsRefetch,
    loading: loadingAllRecipts,
  } = useQuery(getReciptsByActivityId, {
    variables: { input: { activity_id: activity_id, type: "RECEIPT" } },
  });

  const {
    data: projectWorkerList,
    refetch: refetchProjectWorkerList,
    loading: loadingProjectWorkerList,
  } = useQuery(GET_PROJECTS_WORKERS, {
    fetchPolicy: "network-only",
    variables: { input: { project_id: projectid } },
  });

  const [
    addattachmentToActivity,
    { data: attachmentToActivityData, error: attachmentToActivityError },
  ] = useMutation(addAttachmentToActivityMutation);

  const [createExpense, { data: createExpenseData }] =
    useMutation(CREATE_EXPENSE_ENTRY);

  const [editExpense, { data: editExpenseData }] = useMutation(UPDATE_EXPENSE);

  const [getSignedUrl, { data: signedUrlData }] = useLazyQuery(GET_SIGNED_URL);

  const { data: expensesType, refetch: refetchExpensesType } = useQuery(
    GET_ALL_EXPENSES_TYPES,
    {
      fetchPolicy: "network-only",
    }
  );

  let formattedExpenseTypes = [];
  let KMValue;

  if (expensesType?.getAllExpenceTypes?.length) {
    formattedExpenseTypes = expensesType.getAllExpenceTypes.map((expense) => {
      return {
        label: expense?.type,
        value: expense?.id,
      };
    });

    // Ordina la lista in ordine alfabetico in base a label
    formattedExpenseTypes.sort((a, b) => a.label.localeCompare(b.label));

    // Trova il valore di KM
    KMValue = formattedExpenseTypes.find(item => item.label === 'Rimborso KM');
  }

  const [editReciept, { data: editRecieptData }] = useMutation(EDIT_RECEIPT);

  const { data: receiptsType } = useQuery(
    GET_ALL_RECEIPTS_TYPES,
    {
      fetchPolicy: "network-only",
    }
  );

  let formattedReciptsTypes = [];
  // let KMValue;

  if (receiptsType?.getAllRecieptTypes?.length) {
    formattedReciptsTypes = receiptsType?.getAllRecieptTypes?.map((receipt) => {
      return {
        label: receipt?.type,
        value: receipt?.id,
      };
    });
  }

  const [expenseDetails, setExpenseDetails] = useState({
    title: "",
    cost: 0,
    type: "",
    description: "",
    expense_date: "",
    expenditure_type: "",
    file_name: "",
    km_routes: "",
    reimbursed: false,
  });

  useEffect(() => {
    if (selectedReceipt) {
      setInitialReceiptData({
        reimbursed: selectedReceipt?.reimbursed,
      });
      setIsReciptUploadModalVisible(true);
    }
  }, [selectedReceipt]);

  const handelReciptModal = async () => {
    setInitialReceiptData({
      reimbursed: false,
    });
    setSelectedReceipt(null);
    setValidationErrors({});
    await setSelectedFileReceipt("");
    setIsReciptUploadModalVisible(false);
    await refetchAllReceiptsList();
    await getAllReciptsRefetch();
  };

  const pickReceipt = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    //@ts-ignore
    const response = await fetch(result?.uri);
    const blob = await response.blob();

    let FILENAME = `${Date.now()}_${result["name"]}`;
    FILENAME = FILENAME.trim()
      .split(" ")
      .filter((word) => word)
      .join("");

    const getUrl = await getSignedUrl({
      variables: {
        input: { fileName: FILENAME },
      },
    });

    if (getUrl?.data) {
      const fileUploadStatus = await uploadFileToS3(
        getUrl?.data?.getPresignedURL?.presignedURL,
        blob
      );

      if (fileUploadStatus.status == "SUCCESS") {
        setSelectedFileReceipt(FILENAME);
      }
    }
  };

  const saveReciptToDb = async (values) => {
    values = { ...values, selectedFileReceipt };
    let isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS_RECEIPT
    );

    if (isValidForm) {
      try {
        if (selectedReceipt) {
          await editReciept({
            variables: {
              input: {
                attachment_id: selectedReceipt.id,
                activity_id: activity_id,
                reimbursed: values.reimbursed,
              },
            },
          });
        } else {
          const saveDataToDb = {
            type: "RECEIPT",
            activity_id: activity_id,
            reimbursed: values.reimbursed || false,
          };

          await addattachmentToActivity({ variables: { input: saveDataToDb } });
        }
        setValidationErrors({});
        handelReciptModal();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  useEffect(() => {
    if (selectedExpense) {
      setExpenseDetails({
        title: selectedExpense?.title,
        cost: selectedExpense?.cost,
        type: selectedExpense?.type_id,
        file_name: selectedExpense?.file_name,
        description: selectedExpense?.description,
        expenditure_type: selectedExpense?.expense_expenditure_method,
        km_routes: selectedExpense?.km_routes,
        expense_date: String(new Date(parseInt(selectedExpense?.expense_date))),
        reimbursed: selectedExpense?.reimbursed,
      });
      setSelectedFile(selectedExpense?.file_name);
      setIsExpenseAddModalVisible(true);
    }
  }, [selectedExpense]);

  const {
    data,
    refetch,
    loading: projectDetailsLoading,
  } = useQuery(getProjectDetailsQuery, {
    fetchPolicy: "network-only",
    variables: { input: { id: projectid } },
  });
  //verifica del projectManager
  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;

  const isProjectManager = () => {
    const projectManagerName = `${data?.getProjectById?.project_manager?.user?.first_name} ${data?.getProjectById?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = data?.getProjectById?.project_area?.id;

  const isAreaManager = () => {
    // Estrai gli ID delle aree dell'utente
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  useEffect(() => {
    if (data?.getProjectById) {
      navigation.setOptions({
        headerRight: () => {
          return (
            <View className="justify-end items-end pr-2">
              <Text className="text-white">{data?.getProjectById?.name}</Text>
              {data?.getProjectById?.end_date && (
                <Text className="text-white">
                  {" "}
                  Fine :{" "}
                  {`${new Date(
                    parseInt(data?.getProjectById?.end_date)
                  ).toLocaleDateString("en-GB")}`}
                </Text>
              )}
            </View>
          );
        },
      });
    }
    refetch();
  }, [data, projectid]);

  const pickExpenseAttachment = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    //@ts-ignore
    const response = await fetch(result?.uri);
    const blob = await response.blob();

    let FILENAME = `${Date.now()}_${result["name"]}`;
    FILENAME = FILENAME.trim()
      .split(" ")
      .filter((word) => word)
      .join("");
    const getUrl = await getSignedUrl({
      variables: {
        input: { fileName: FILENAME },
      },
    });

    if (getUrl?.data) {
      const fileUploadStatus = await uploadFileToS3(
        getUrl?.data?.getPresignedURL?.presignedURL,
        blob
      );

      if (fileUploadStatus.status == "SUCCESS") {
        setSelectedFile(FILENAME);
      }
    }
  };

  const handelExpenseModal = async () => {
    setValidationErrors({});
    setSelectedFile("");
    setIsExpenseAddModalVisible(false);
    setSelectedExpense(null);
    setExpenseDetails({
      title: "",
      cost: 0,
      type: "",
      description: "",
      expense_date: "",
      expenditure_type: "",
      file_name: "",
      km_routes: "",
      reimbursed: false,
    });
  };

  const saveExpense = async (values) => {
    values = { ...values, selectedFile };
    let requiredFields = [...REQUIRED_FIELDS];
    if (values.type === KMValue?.value) {
      requiredFields.push("km_routes");
    }

    let isValidForm = await validate(
      values,
      setValidationErrors,
      requiredFields
    );

    if (values.type === KMValue?.value && (!values.km_routes || parseFloat(values.km_routes) === 0)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        km_routes: "KM Percorsi deve essere maggiore di 0",
      }));
      isValidForm = false;
    }
    if (isValidForm) {
      try {
        if (selectedExpense) {
          await editExpense({
            variables: {
              input: {
                expence_id: selectedExpense.id,
                cost: parseFloat(values.cost),
                project_id: projectid,
                reimbursed: values.reimbursed,
                title: values.title,
                km_routes: values.type === KMValue?.value ? values.km_routes : null,
                expense_expenditure_method: values.expenditure_type,
                expense_date:
                  values.expense_date && typeof values.expense_date == "string"
                    ? new Date(values.expense_date)
                    : values.expense_date,
                type_id: values.type,
                description: values.description,
                file_name: selectedFile,
              },
            },
          });
        } else {
          await createExpense({
            variables: {
              input: {
                cost: parseFloat(values.cost),
                file_name: selectedFile,
                project_id: projectid,
                reimbursed: values.reimbursed,
                expense_expenditure_method: values.expenditure_type,
                km_routes: values.type === KMValue?.value ? values.km_routes.toString() : null,
                title: values.title,
                expense_date: values.expense_date || new Date(),
                type_id: values.type,
                description: values.description,
              },
            },
          });
        }
        await refetchAllExpensesList();
        handelExpenseModal();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const handelCancelExpenseModal = async () => {
    setValidationErrors({});
    setSelectedFile("");
    setIsExpenseAddModalVisible(false);
    setSelectedExpense(null);
    setExpenseDetails({
      title: "",
      cost: 0,
      type: "",
      description: "",
      expense_date: "",
      expenditure_type: "",
      file_name: "",
      km_routes: "",
      reimbursed: false,
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      refetchAllExpensesList();
      refetchAllReceiptsList();
      getAllReciptsRefetch();
      refetchProjectWorkerList();
      // refetchTasklist();
    }, [])
  );

  const styles = StyleSheet.create({
    titleContainer: {
      alignItems: 'flex-start',
      paddingTop: 25,
      paddingBottom: 15,
    },
    titleText: {
      color: 'white',
      fontSize: 30,
      fontWeight: 'bold',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  });

  return (
    <>
      <Portal>
        <Dialog
          visible={isReciptUploadModalVisible}
          onDismiss={handelReciptModal}
          // @ts-ignore
          style={{
            backgroundColor: colors.grey,
            alignSelf: "center",
            maxHeight: "80%",
          }}
        >
          <Dialog.Title style={{ color: "white" }}>
            Dettagli spesa risorsa
          </Dialog.Title>

          <Dialog.ScrollArea className="flex flex-col p-2" style={{ backgroundColor: colors.grey }}>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 18 }}>
              <View>
                <Formik
                  initialValues={initialReceiptData}
                  onSubmit={async (values, { setSubmitting }) => {
                    setIsSaving(true); // Imposta lo stato del salvataggio su true
                    await saveReciptToDb(values); // Esegui la funzione di salvataggio

                    // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                    setTimeout(() => {
                      setIsSaving(false);
                      setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                    }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
                  }}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                  }) => (
                    <View className="flex flex-col w-full gap-8">
                      <View className="w-full">
                        <Checkbox
                          status={values.reimbursed ? 'checked' : 'unchecked'}
                          onPress={() => {
                            setFieldValue('reimbursed', !values.reimbursed);
                          }}
                        />
                        <Text style={{ marginLeft: 8 }}>Spesa da rimborsare</Text>
                      </View>
                      <View className="flex flex-row w-full justify-end gap-4">
                        <Button
                          onPress={() => {
                            values = initialReceiptData;
                            handelReciptModal();
                          }}
                          buttonColor={colors.blue}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                        >
                          Annulla
                        </Button>
                        <Button
                          onPress={handleSubmit as (e: unknown) => void}
                          buttonColor={colors.red}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                          disabled={isSaving}
                        >
                          Salva spesa
                        </Button>
                      </View>
                    </View>
                  )}
                </Formik>
              </View>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <Portal>
        <Dialog
          visible={isExpenseAddModalVisible}
          onDismiss={handelCancelExpenseModal}
          // @ts-ignore
          style={addExpenseContainerStyle}
        >
          <Dialog.Title style={{ color: "white" }}>
            Dettagli spesa fornitura
          </Dialog.Title>

          <Dialog.ScrollArea className="flex flex-col p-2" style={{ backgroundColor: colors.grey }}>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 18, backgroundColor: colors.grey }}>
              <View>
                <Formik
                  initialValues={expenseDetails}
                  onSubmit={async (values, { setSubmitting }) => {
                    setIsSaving(true); // Imposta lo stato del salvataggio su true
                    await saveExpense(values); // Esegui la funzione di salvataggio

                    // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                    setTimeout(() => {
                      setIsSaving(false);
                      setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                    }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
                  }}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                  }) => (
                    <View className="flex flex-col w-full gap-6">
                      <View className="w-full">
                        <TextInput
                          label="Titolo"
                          mode="flat"
                          value={values.title}
                          onChangeText={handleChange("title")}
                          onBlur={handleBlur("title")}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="#53555e"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                        {validationErrors["title"] && (
                          <View>
                            <Text
                              className="text-red-400 text-sm p-2"
                              style={{ color: "red" }}
                            >
                              {validationErrors["title"]}
                            </Text>
                          </View>
                        )}
                      </View>

                      <View className="w-full p-2">
                        <MyWebDatePicker
                          fieldName={"expense_date"}
                          setFieldValue={setFieldValue}
                          dateFill={
                            selectedExpense?.expense_date
                              ? selectedExpense?.expense_date
                              : null
                          }
                        />
                      </View>

                      <View className="w-full">
                        <TextInput
                          label="Descrizione"
                          multiline={true}
                          onChangeText={handleChange("description")}
                          onBlur={handleBlur("description")}
                          value={values.description}
                          style={{ backgroundColor: colors.grey }}
                          activeUnderlineColor="#53555e"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                      </View>

                      <View className="w-full">
                        <Dropdown
                          style={{
                            backgroundColor: colors.grey,
                            padding: 15,
                            paddingHorizontal: 30,
                            justifyContent: 'flex-end',
                            borderBottomWidth: 1,
                            borderBottomColor: colors.white,
                          }}
                          containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                          data={formattedExpenseTypes}
                          labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                          valueField="value" // Definisce il campo del valore per gli elementi della lista
                          value={values.type}
                          placeholder='Tipologia spesa'
                          onChange={(item) => {
                            setFieldValue("type", item.value);

                            if (item.value === KMValue?.value) {
                              setFieldValue("km_routes", values.km_routes || '');
                            }
                          }}
                          selectedTextStyle={{ color: colors.white }}
                          placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, }}
                          inputSearchStyle={{
                            backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                            overflow: 'hidden',
                          }}

                          renderItem={(item) => (
                            <Text style={{ color: colors.white, padding: 10 }}>
                              {item.label}
                            </Text>
                          )}
                        />
                        {validationErrors["type"] && (
                          <View>
                            <Text className="text-red-400 text-sm p-2" style={{ color: "red" }}>
                              {validationErrors["type"]}
                            </Text>
                          </View>
                        )}
                      </View>

                      {KMValue && values.type === KMValue.value && (
                        <View className="w-full">
                          <TextInput
                            label="KM Percorsi"
                            mode="flat"
                            onChangeText={(data) => {
                              setFieldValue("km_routes", data);
                              setFieldValue(
                                "cost",
                                (parseFloat(data || "0") * 0.4).toFixed(2)
                              );
                            }}
                            onBlur={handleBlur("km_routes")}
                            value={values.km_routes.toString()}
                            style={{ backgroundColor: colors.grey }}
                            activeUnderlineColor="#53555e"
                            underlineColor="#53555e"
                            keyboardType="numeric"
                            theme={{
                              colors: {
                                onSurfaceVariant: "white",
                              },
                            }}
                          />
                        </View>
                      )}

                      <View className="w-full">
                        <TextInput
                          label="Importo"
                          mode="flat"
                          onChangeText={handleChange("cost")}
                          onBlur={handleBlur("cost")}
                          value={values.cost.toString()}
                          style={{ backgroundColor: colors.grey }}
                          disabled={KMValue && values.type === KMValue.value}
                          activeUnderlineColor="#53555e"
                          keyboardType="numeric"
                          theme={{
                            colors: {
                              onSurfaceVariant: "white",
                            },
                          }}
                        />
                        {validationErrors["cost"] && (
                          <View>
                            <Text className="text-red-400 text-sm p-2" style={{ color: "red" }}>
                              {validationErrors["cost"]}
                            </Text>
                          </View>
                        )}
                      </View>


                      <View className="w-full">
                        <Dropdown
                          style={{
                            backgroundColor: colors.grey,
                            padding: 15,
                            paddingHorizontal: 30,
                            justifyContent: 'flex-end',
                            borderBottomWidth: 1,
                            borderBottomColor: colors.white,
                          }}
                          containerStyle={{ backgroundColor: colors.grey, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                          data={SpendingMode}
                          labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                          valueField="value" // Definisce il campo del valore per gli elementi della lista
                          value={values.expenditure_type}
                          placeholder="Metodo di spesa"
                          onChange={(item) => setFieldValue("expenditure_type", item.value)}
                          selectedTextStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          placeholderStyle={{ color: colors.white, fontWeight: 'bold', marginLeft: -13 }}
                          itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, fontWeight: 'bold' }}
                          inputSearchStyle={{
                            backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                            overflow: 'hidden',
                          }}

                          renderItem={(item) => (
                            <Text style={{ backgroundColor: colors.grey, color: colors.white, padding: 10, fontWeight: 'bold' }}>
                              {item.label}
                            </Text>
                          )}
                        />
                      </View>

                      <View className="w-full">
                        <TouchableOpacity
                          onPress={() => {
                            setFieldValue("reimbursed", !values.reimbursed);
                          }}
                          className="flex flex-row w-fit items-center justify-start"
                        >
                          <Checkbox.Android
                            status={values.reimbursed ? "checked" : "unchecked"}
                            color={values.reimbursed ? colors.background : colors.grey}
                            underlayColor="#2a2c38"
                          />
                          <Text className="text-white">
                            Spesa da rimborsare
                          </Text>
                        </TouchableOpacity>
                      </View>

                      <View className="w-full">
                        {selectedFile ? (
                          <Button
                            onPress={pickExpenseAttachment}
                            buttonColor="#3b8af7"
                            textColor="white"
                            style={{ borderRadius: 4 }}
                          >
                            <Text
                              variant="bodyMedium"
                              className="font-bold text-white"
                            >
                              {selectedFile}
                            </Text>
                          </Button>
                        ) : (
                          <Button
                            onPress={pickExpenseAttachment}
                            icon={"upload"}
                            buttonColor={colors.orange}
                            textColor="white"
                            style={{ borderRadius: 4 }}
                          >
                            <Text
                              variant="bodyMedium"
                              className="font-bold text-white"
                            >
                              Seleziona File
                            </Text>
                          </Button>
                        )}
                        {validationErrors["selectedFile"] && (
                          <View>
                            <Text
                              className="text-red-400 text-sm p-2"
                              style={{ color: "red" }}
                            >
                              {validationErrors["selectedFile"]}
                            </Text>
                          </View>
                        )}
                      </View>


                      <View className="flex flex-row w-full justify-end gap-4">
                        <Button
                          onPress={() => {
                            values = expenseDetails;
                            handelCancelExpenseModal();
                          }}
                          buttonColor={colors.blue}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                        >
                          Annulla
                        </Button>
                        <Button
                          onPress={handleSubmit as (e: unknown) => void}
                          buttonColor={colors.red}
                          textColor="white"
                          style={{ borderRadius: 4 }}
                          disabled={isSaving}
                        >
                          Salva spesa
                        </Button>
                      </View>
                    </View>
                  )}
                </Formik>
              </View>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <Loader loading={loadingAllExpenseList || projectDetailsLoading || loadingAllReceiptsList || loadingAllRecipts} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-col w-full p-2">
              <View className="flex flex-row w-full mb-6">
                <Text
                  variant="displayMedium"
                  className="flex text-white font-semibold w-11/12 ml-4"
                  style={{ fontSize: 35 }}
                >
                  Scheda progetto
                </Text>

                {!hideEditAndActivity ? (
                  <View className="flex flex-row gap-4">
                    {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                      <Button
                        icon="briefcase-edit"
                        mode="contained"
                        buttonColor={colors.amaranto}
                        textColor={colors.white}
                        className="items-center justify-center"
                        style={{ borderRadius: 10 }}
                        onPress={() =>
                          navigation.push("EditProjectDetails", {
                            projectID: projectid,
                          })
                        }
                      >
                        <Text className="text-white font-bold">Modifica progetto</Text>
                      </Button>
                    ) : null}

                    <Button
                      icon="format-list-text"
                      mode="contained"
                      buttonColor={colors.amaranto}
                      textColor={colors.white}
                      style={{ borderRadius: 10 }}
                      onPress={() =>
                        navigation.navigate("ActivityList", { id: projectid })
                      }
                      className="items-center justify-center"
                    >
                      <Text className="text-white font-bold">
                        Mostra attività
                      </Text>
                    </Button>

                    {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                      <Button
                        icon="note-plus"
                        mode="contained"
                        buttonColor={colors.amaranto}
                        textColor={colors.white}
                        style={{ borderRadius: 10 }}
                        onPress={() => setIsExpenseAddModalVisible(true)}
                        className="items-center justify-center"
                      >
                        <Text className="text-white font-bold">
                          Nuova spesa fornitura
                        </Text>
                      </Button>
                    ) : null}

                    <Button
                      icon="chart-pie"
                      mode="contained"
                      buttonColor={colors.amaranto}
                      textColor={colors.white}
                      style={{ borderRadius: 10 }}
                      onPress={() =>
                        navigation.navigate("ProjectOverview", {
                          id: projectid,
                        })
                      }
                      className="items-center justify-center"
                    >
                      <Text className="text-white font-bold">
                        Dashboard project overview
                      </Text>
                    </Button>
                  </View>
                ) : null}
              </View>

              {/* {data?.getProjectById?.latitude &&
                data?.getProjectById?.longitude ? (
                <View className="flex flex-row items-center justify-center">
                  <View className="flex w-11/12 h-full p-4">
                    <iframe
                      src={`https://www.openstreetmap.org/export/embed.html?bbox=${data?.getProjectById?.longitude}%2C${data?.getProjectById?.latitude}%2C${data?.getProjectById?.longitude}%2C${data?.getProjectById?.latitude}&amp;layer=mapnik`}
                    ></iframe>
                  </View>
                </View>
              ) : null} */}
            </View>

            <View className="p-7 item-center justify-center">
              <View style={styles.titleContainer}>
                <Text style={styles.titleText}>
                  {t("Anagrafica commessa")} :{" "}
                </Text>
              </View>
              {data && data.getProjectById ? (
                <View className="flex flex-col w-full">
                  {data?.getProjectById?.year && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Year")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.year}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.client_manager && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Client manager")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.client_manager}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.client_group && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Client Group")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.client_group}
                      </Text>
                    </View>
                  )}

                  {data?.getProjectById?.name && (
                    <View className="flex flex-row items-center justify-center">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Project Name")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_id && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Project ID")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_id}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.offer_id && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Offer ID")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.offer_id}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_area?.name && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Area")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_area.name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_sector?.name && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Settore")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_sector?.name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_manager?.user && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Project manager")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_manager.user.first_name}{" "}
                        {data.getProjectById.project_manager.user.last_name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_reference && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Project Reference")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_reference}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.contract_activity && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Attività oggetto del contratto")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.contract_activity}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.start_date && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Start Date")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">{`${new Date(
                        parseInt(data.getProjectById.start_date)
                      ).toLocaleDateString("en-GB")}`}</Text>
                    </View>
                  )}
                  {data?.getProjectById?.end_date && (
                    <View className="flex flex-row mb-4">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("End Date")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">{`${new Date(
                        parseInt(data.getProjectById.end_date)
                      ).toLocaleDateString("en-GB")}`}</Text>
                    </View>
                  )}
                  {data?.getProjectById?.event_date && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Event Date")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">{data.getProjectById.event_date}</Text>
                    </View>
                  )}
                  {data?.getProjectById?.client_reference && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Client Reference")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.client_reference}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.address && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Address")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.address}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.status && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Project Status")}:{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.status}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.reporting_file && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Reporting File")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.reporting_file ? "YES" : "NO"}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.check_planning && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Check planning")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.check_planning}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.quality_plan && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Obbligo piano qualità a cura PM")}:{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.quality_plan}
                      </Text>
                    </View>
                  )}
                  <View style={styles.titleContainer}>
                    <Text style={styles.titleText}>
                      {t("Informazioni economiche commessa")} :{" "}
                    </Text>
                  </View>
                  {data?.getProjectById?.contract_value && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {"Importo contratto"} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.contract_value}
                        {APP_CURRENCY}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Fee")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.fee}
                        {PERCENTAGE}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Margine ECS")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.margin}
                        {PERCENTAGE}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.budget && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Budget")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.budget}
                        {APP_CURRENCY}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.budget && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Compensazione")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.compensation}
                        {APP_CURRENCY}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById.compensation != 0 && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Margine compensato")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {(data.getProjectById.margin + data.getProjectById.compensation).toFixed(2)}
                        {APP_CURRENCY}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById.compensationsSent?.length > 0 && (
                    <View className="flex flex-row flex-wrap">
                      <Text className="flex w-full text-white text-xl font-semibold">
                        {t("Compensato a")} :
                      </Text>
                      {data.getProjectById.compensationsSent.map((item, index) => (
                        <Text key={index} className="flex w-full text-white text-base" style={{ color: 'red', fontWeight: "bold" }}>
                          • {item.toProject_id}
                        </Text>
                      ))}
                    </View>
                  )}
                  {data?.getProjectById.compensationsReceived?.length > 0 && (
                    <View className="flex flex-row flex-wrap">
                      <Text className="flex w-full text-white text-xl font-semibold">
                        {t("Compensato da")} :
                      </Text>
                      {data.getProjectById.compensationsReceived.map((item, index) => (
                        <Text key={index} className="flex w-full text-white text-base" style={{ color: 'green', fontWeight: "bold" }}>
                          • {item.fromProject_id}
                        </Text>
                      ))}
                    </View>
                  )}
                  <View style={styles.titleContainer}>
                    <Text style={styles.titleText}>
                      {t("Informazioni chiusura commessa")} :{" "}
                    </Text>
                  </View>
                  {data?.getProjectById?.billing_time && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Billing Time")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.billing_time}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.invoice_details && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("numero e descrizione della fattura a cura di coord.")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.invoice_details}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.sending_customer_invoice && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Invio fattura al cliente  si/no data")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.sending_customer_invoice}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.sending_invoice_made_by && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Invio effettuato da")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.sending_invoice_made_by}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.invoice_paid && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("fattura saldata si no")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.invoice_paid}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.payment_check && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Data saldo")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.payment_check}
                      </Text>
                    </View>
                  )}
                  <View style={styles.titleContainer}>
                    <Text style={styles.titleText}>
                      {t("Rendicontazione commessa")} :{" "}
                    </Text>
                  </View>
                  {data?.getProjectById?.description && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Descrizione")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.description}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.state_code && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("State code")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.state_code}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.area_manager?.user && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Area manager")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.area_manager.user.first_name}{" "}
                        {data.getProjectById.area_manager.user.last_name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.project_created_by?.user && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Creato da")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.project_created_by.user.first_name}{" "}
                        {data.getProjectById.project_created_by.user.last_name}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.client_note && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Client note")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.client_note}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.note_compilatore && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Note compilatore")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.note_compilatore}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.status_code && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Status code")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.status_code}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.invoice_id && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Invoice id")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.invoice_id}
                      </Text>
                    </View>
                  )}
                  {/* {data?.getProjectById?.payment_check && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Payment Check")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.payment_check}
                      </Text>
                    </View>
                  )} */}
                  {data?.getProjectById?.expeses_to_be_reimbursed && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        Spesa da rimborsare: {" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.expeses_to_be_reimbursed
                          ? "YES"
                          : "NO"}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.expeses_included && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Expeses Included")}:{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.expeses_included ? "YES" : "NO"}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.note_check_pm && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Notes Check PM")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.note_check_pm}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.note_resp_area && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Note resp area")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.note_resp_area}
                      </Text>
                    </View>
                  )}
                  {data?.getProjectById?.note && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        {t("Note")} :{" "}
                      </Text>
                      <Text className="flex w-1/2 text-white text-base">
                        {data.getProjectById.note}
                      </Text>
                    </View>
                  )}
                  <View className="mb-6">
                    <View className="flex flex-row my-4">
                      <Text
                        variant="headlineMedium"
                        className="text-white font-bold"
                      >
                        {t("Lavoratori associati")}
                      </Text>
                    </View>

                    <ScrollView
                      horizontal
                      showsHorizontalScrollIndicator={true}
                    >
                      {projectWorkerList?.getWorkersByProjectId &&
                        projectWorkerList?.getWorkersByProjectId?.length ? (
                        projectWorkerList?.getWorkersByProjectId?.map(
                          (data, idk) => {
                            return <ProjectsWorkerCard key={idk} data={data} />;
                          }
                        )
                      ) : (
                        <Text className="text-xl font-bold text-white pl-1">
                          Non ci sono lavoratori associati a questo progetto*
                        </Text>
                      )}
                    </ScrollView>
                  </View>

                  <View className="mb-6">
                    <View className="flex flex-row my-4">
                      <Text
                        variant="headlineMedium"
                        className="text-white font-bold"
                      >
                        {t("Spese forniture")}
                      </Text>
                    </View>

                    <ScrollView
                      horizontal
                      showsHorizontalScrollIndicator={true}
                    >
                      {allExpensesList?.getExpenceDetails &&
                        allExpensesList?.getExpenceDetails?.length ? (
                        allExpensesList?.getExpenceDetails.map((data, idk) => {
                          return (
                            <ExpenseCard
                              data={data}
                              key={idk}
                              refetchAllExpensesList={refetchAllExpensesList}
                              setSelectedExpense={setSelectedExpense}
                              projectId={projectid}
                              role={role}
                            />
                          );
                        })
                      ) : (
                        <Text className="text-xl font-bold text-white pl-1">
                          Non ci sono spese per questo progetto*
                        </Text>
                      )}
                    </ScrollView>
                  </View>
                  <View className="mb-6">
                    <View className="flex flex-row my-4">
                      <Text
                        variant="headlineMedium"
                        className="text-white font-bold"
                      >
                        {t("Spese risorse")}
                      </Text>
                    </View>

                    <ScrollView
                      horizontal
                      showsHorizontalScrollIndicator={true}
                    >
                      {allReceiptsList?.getProjectDataByDate?.receipts?.length > 0 ? (
                        allReceiptsList?.getProjectDataByDate?.receipts?.map((data, idk) => {
                          return (
                            <ReceiptCard
                              data={data}
                              key={idk}
                              getAllReciptsRefetch={refetchAllReceiptsList}
                              setselectedReciept={setSelectedReceipt}
                              projectId={projectid}
                            />
                          );
                        })
                      ) : (
                        <Text className="text-xl font-bold text-white pl-1">
                          Non ci sono spese per questo progetto*
                        </Text>
                      )}
                    </ScrollView>
                  </View>
                  {/* <View className="flex flex-col">
                    <Text
                      variant="headlineMedium"
                      className="text-white my-4 font-bold"
                    >
                      {data.getProjectById.name} : Timeline
                    </Text>
                    <View>
                      {tasklist &&
                      tasklist.getProjectTasksOfProjectforAdmin &&
                      tasklist.getProjectTasksOfProjectforAdmin.tasks.length ? (
                        tasklist.getProjectTasksOfProjectforAdmin.tasks.map(
                          (data, idk) => {
                            return (
                              <TaskCard
                                data={data}
                                key={idk}
                                navigation={navigation}
                                refetchTasklist={refetchTasklist}
                              />
                            ); 
                          }
                        )
                      ) : (
                        <Text className="text-xl font-bold text-white">
                          No Tasks available to display*
                        </Text>
                      )}
                    </View>
                  </View> */}
                </View>
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default ProjectDetails;
