import React from "react";
import { View, Image } from "react-native";

function Loader({ loading }) {
  return (
    <>
      {loading ? (
        <View className="fixed left-0 right-0 opacity-40 h-full items-center justify-center">
          <View className="flex justify-center items-center">
            <Image
              className="h-16 w-16"
              source={require("../../Public/Assets/loading.gif")}
              alt="Caricamento..."
            />
          </View>
        </View>
      ) : null}
    </>
  );
}

export default Loader;
