import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState, useRef } from "react";
import { ScrollView, View, StyleSheet, TouchableOpacity } from "react-native";
import { Button, IconButton, Text } from "react-native-paper";
import { SpendingMode } from "../constants/static-data";
import isEqual from 'lodash/isEqual';
import '../../customCheckbox.css';
import colors from "../styles/colors";

const PROJECT_DATA = gql`
  query getProjectDataByDate($input: getProjectDataByDateInput) {
    getProjectDataByDate(input: $input) {
      workers {
        name
        id
        totalCost
        totalTime
        ordinary_hours
        ordinary_day_man
        overtime_day_man
        overtime_hours
        total_day_man
        hasOutdatedTasks
      }
      receipts {
        id
        type
        file_name
        receipt_title
        fileUrl
        details
        created_by
        created_at
        km_routes
        activity_id
        receipt_value
        receipt_date
        receipt_city
        receipt_expenditure_method
        receipt_type
        client
        project_id
        worker_name
      }
      expences {
        id
        file_name
        reimbursed
        fileUrl
        created_by
        cost
        project_id
        created_at
        title
        type_id
        description
        expense_date
        expense_city
        expense_expenditure_method
        expense_type
        client
      }
      totalCostOfWorker
      totalCostOfExpences
    }
  }
`;

function DataSection({ ProjectID, range, project }) {
  const [activeTab, setActiveTab] = useState(1);
  const { data, refetch } = useQuery(PROJECT_DATA, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        project_id: ProjectID,
        fromDate: range.startDate,
        toDate: range.endDate,
        receipt: false,
      },
    },
  });
  // console.log(data, "dataaa");
  //const { updateRefetchHandlers } = useReceiptContext();
  const prevProjectIDRef = useRef(ProjectID);
  const prevRangeRef = useRef(range);

  useEffect(() => {
    if (prevProjectIDRef.current !== ProjectID || !isEqual(prevRangeRef.current, range)) {
      refetch();
      // Aggiorna i riferimenti con i valori attuali
      prevProjectIDRef.current = ProjectID;
      prevRangeRef.current = range;
    }
    //updateRefetchHandlers((prev) => ({ ...prev, refetchNotInBudget: refetch }));
  }, [ProjectID, range]); //, updateRefetchHandlers

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const getExpenditureType = (ExpenditureMethode): string => {
    let methode = "Not Defined";
    SpendingMode.forEach((element) => {
      if (element.value == ExpenditureMethode) {
        methode = element.label;
      }
    });
    return methode;
  };
  //let projectName = '';
  const pro = project;

  const projectName = pro?.allProjectList.find(project => project.id === ProjectID)?.name || '';

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    headerRow: {
      flexDirection: 'row',
      backgroundColor: colors.grey,
      padding: 5,
      marginBottom: 3,
      borderWidth: 3,
      borderColor: "grey",
      borderRadius: 2,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: 'grey',
      padding: 3,
      marginLeft: 3,
    },
    cell: {
      //flex: 1,
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 300,
    },
    cellNumber: {
      //flex: 1,
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 200,
    },
    headerText: {
      fontWeight: 'bold',
      color: 'white',
      fontSize: 22,
      textAlign: 'center',
    },
    cellText: {
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
    },
    cellTextButton: {
      color: 'white',
      fontSize: 18,
      alignContent: "center",
    },
    content: {
      flex: 1,
    },
    actionButton: {
      justifyContent: "center",
      alignItems: 'center',
    },
    button: {
      borderColor: colors.white,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderTopLeftRadius: 10,
      backgroundColor: colors.amaranto,
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
  });

  return (
    <>
      <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderWidth: 1, borderRadius: 10, borderColor: colors.white }}>
        <View className="flex flex-row w-full">
          <View style={styles.button}>
            <Button
              buttonColor={colors.amaranto}
              onPress={() => handleTabClick(1)}
              style={{ borderTopLeftRadius: 10, backgroundColor: colors.amaranto }}
            >
              <Text style={styles.buttonText}>Lavoratori</Text>
            </Button>
          </View>
          <Button
            buttonColor={colors.amaranto}
            style={{ borderRadius: 0, backgroundColor: colors.amaranto, borderColor: colors.white, borderBottomWidth: 1, borderRightWidth: 1 }}
            onPress={() => handleTabClick(2)}
          >
            <Text style={styles.buttonText}>Spese risorse</Text>
          </Button>
          <Button
            buttonColor={colors.amaranto}
            style={{ borderRadius: 0, backgroundColor: colors.amaranto, borderColor: colors.white, borderBottomWidth: 1, borderRightWidth: 1 }}
            onPress={() => handleTabClick(3)}
          >
            <Text style={styles.buttonText}>Spese forniture</Text>
          </Button>
        </View>
        <View className="flex flex-col w-full h-96 my-2 overflow-scroll p-2">
          {activeTab == 1 ? (
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <View style={styles.cell}><Text style={styles.headerText}>Lavoratore</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Ore ordinarie</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Giorno/uomo ordinario</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Ore non ordinarie</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Giorno/uomo non ordinario</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Giorno/uomo totale</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Costo totale</Text></View>
                </View>

                <ScrollView style={styles.content}>
                  {data?.getProjectDataByDate?.workers.map((worker) => {
                    const textColor = worker.hasOutdatedTasks ? 'yellow' : 'white';
                    return (
                      <View key={worker.id} style={styles.row}>
                        <View style={styles.cell}><Text style={[styles.cellText, { color: textColor }]}>{worker?.name}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(worker?.ordinary_hours).toFixed(2)}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(`${worker?.ordinary_day_man}`).toFixed(2)}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(worker?.overtime_hours).toFixed(2)}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(`${worker?.overtime_day_man}`).toFixed(2)}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(`${worker?.total_day_man}`).toFixed(2)}</Text></View>
                        <View style={styles.cellNumber}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(worker?.totalCost).toFixed(2)}</Text></View>
                      </View>
                    );
                  })}
                </ScrollView>
              </View>
            </ScrollView>
          ) : null}
          {activeTab == 2 ? (
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <View style={styles.cell}><Text style={styles.headerText}>Codice commessa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Cliente</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Lavoratore</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Città</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Data</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Tipologia spesa</Text></View>
                  <View style={[styles.cellNumber]}><Text style={styles.headerText}>Km percorsi</Text></View>
                  <View style={[styles.cell]}><Text style={styles.headerText}>Descrizione</Text></View>
                  <View style={[styles.cellNumber]}><Text style={styles.headerText}>Importo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Metodo di pagamento</Text></View>
                  <View style={[styles.cell]}><Text style={styles.headerText}>Allegato</Text></View>
                </View>

                <ScrollView style={styles.content}>
                  {data?.getProjectDataByDate?.receipts.sort((a, b) => {
                    return a.project_id.localeCompare(b.project_id);
                  }).map((receipt) => (
                    <View key={receipt.id} style={styles.row}>
                      <View style={styles.cell}><Text style={styles.cellText}>{receipt.project_id}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{receipt.client}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{receipt.worker_name}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{receipt.receipt_city || 'City not selected'}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{new Date(parseInt(receipt.receipt_date)).toLocaleDateString("en-GB")}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{receipt.receipt_type}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.cellText}>{receipt.km_routes ? receipt.km_routes : "N/A"}</Text></View>
                      <View style={[styles.cell]}><Text style={styles.cellText}>{receipt.details}</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.cellText}>{parseFloat(receipt.receipt_value).toFixed(2)}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{getExpenditureType(receipt.receipt_expenditure_method)}</Text></View>
                      <View style={[styles.cell, styles.actionButton]}>{receipt?.fileUrl && (
                        <IconButton
                          icon="download-box-outline"
                          iconColor={"#3b8af7"}
                          size={40}
                          onPress={() => window.open(receipt?.fileUrl, "_blank")}
                        />)}
                      </View>
                    </View>
                  ))}
                </ScrollView>
              </View>
            </ScrollView>
          ) : null}
          {activeTab == 3 ? (
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <View style={styles.cell}><Text style={styles.headerText}>Titolo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Cliente</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Codice commessa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Data</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Descrizione</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Tipologia spesa</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Importo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Metodo di pagamento</Text></View>
                </View>

                <ScrollView style={styles.content}>
                  {data?.getProjectDataByDate?.expences.map((expense) => (
                    <View key={expense.id} style={styles.row}>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.title}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.client}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.project_id}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{new Date(parseInt(expense?.expense_date)).toLocaleDateString("en-GB")}
                      </Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.description}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.expense_type}</Text></View>
                      <View style={styles.cellNumber}><Text style={styles.cellText}>{expense.cost}</Text></View>
                      <View style={styles.cell}><Text style={styles.cellText}>{expense.expense_expenditure_method}</Text></View>
                    </View>
                  ))}
                </ScrollView>
              </View>
            </ScrollView>
          ) : null}
        </View>

        {activeTab == 1 ? (
          <View className="flex flex-row w-full mb-2 p-2">
            <View className="mx-4">
              <Text variant="headlineSmall" className="text-white">
                Costo totale dei lavoratori : {" "}
              </Text>
            </View>
            <View className="w-3/12 ">
              <Text variant="headlineSmall" className="text-white">
                {data?.getProjectDataByDate?.totalCostOfWorker}
              </Text>
            </View>
          </View>
        ) : null}

        {activeTab == 3 ? (
          <View className="flex flex-row w-full mb-2 p-2">
            <View className="mx-4">
              <Text variant="headlineSmall" className="text-white">
                Costo totale spese forniture : {" "}
              </Text>
            </View>
            <View className="w-3/12 ">
              <Text variant="headlineSmall" className="text-white">
                {data?.getProjectDataByDate?.totalCostOfExpences}
              </Text>
            </View>
          </View>
        ) : null}
      </View >
    </>
  );
}

export default DataSection;
