import React, { useState, useEffect, useCallback } from 'react';
import { View, SafeAreaView, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { getStorageData, removeStorageData, storeData } from "../libs/storage";
import { useNavigation, useRoute } from '@react-navigation/native';
import { Formik } from 'formik';
import { useAuth } from '../views/AuthContext';
import { USER_TOKEN_KEY } from "../constants/App.constants";
import colors from '../styles/colors';

const EMAIL_SEND = gql`
  mutation SendEmailMutation($input: SendEmailInput) {
    SendEmailMutation(input: $input) {
      success
      message
    }
  }
`;

const VERIFY_OTP = gql`
  mutation verifyOtp($input: VerifyOtpInput!) {
    verifyOtp(input: $input) {
      success
      message
    }
  }
`;

const ForgotPassword = () => {
    const client = useApolloClient();
    const [feedback, setFeedback] = useState('');
    const [sendLoading, setSendLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [EmailToCheck, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const navigation = useNavigation();

    const [sendEmail, { data: sendEmailData, error: sendError }] = useMutation(EMAIL_SEND);
    const [verifyOtp, { data: otpData, error: otpError }] = useMutation(VERIFY_OTP);

    const handleVerifyOtp = async () => {
        try {
            const numberOTP = parseInt(otp, 10);
            const response = await verifyOtp({
                variables: {
                    input: {
                        email: EmailToCheck,
                        otp: numberOTP
                    },
                },
            });
            const { success, message } = response.data.verifyOtp;
            if (success) {
                setFeedback(message || "Otp Verificato");
                console.log("Otp Verificato");
                navigation.navigate("ChangePassword");
            } else {
                setFeedback(message || "Otp non Verificato");
                console.log("Otp non Verificato");
            }
            if (otpError) {
                console.error('Errore GraphQL durante la verifica dell\'OTP:', otpError);
                setFeedback(otpError.message || "Errore durante la verifica dell'OTP.");
                return;
            }
        } catch (e) {
            console.error('Errore durante la verifica dell\'OTP:', e);
        }
    };

    const handleSendEmail = async (values) => {
        setSendLoading(true);

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(values.email)) {
            setFeedback("Formato email non valido.");
            setSendLoading(false);
            console.log("Formato email non valido");
            return;
        }

        setEmail(values.email);

        try {
            const response = await sendEmail({
                variables: {
                    input: {
                        email: values.email,
                    },
                },
            });
            await storeData("email", values.email);
            const { success, message } = response.data.SendEmailMutation;
            if (success) {
                setFeedback(message || "Invio riuscito.");
            } else {
                setFeedback(message || "Errore nell'invio.");
            }
        } catch (error) {
            setFeedback(error.message || "Errore sconosciuto durante l'invio.");
        }
        setTimeout(() => {
            setEmailSent(true);
            //setSubmitting(false);
        }, 1000);
        setSendLoading(false);
    };

    const validateEmailForm = values => {
        let errors = {};
        if (!values.email) {
            errors.email = 'Email necessaria.';
        }
        return errors;
    };

    if (emailSent) {
        return (
            <View className="flex flex-col h-screen items-center justify-center" style={{backgroundColor: colors.background}}>
                <View className="w-2/5 p-10 first-letter: rounded-xl border-2 border-bordercolor">
                    <Text
                        variant="displayMedium"
                        className="text-white pb-4 font-semibold text-center"
                        style={styles.text}
                    >
                        Cambia Password
                    </Text>
                    <Text className="text-white mt-4 text-center">Email con OTP inviata. Controlla la tua casella di posta.</Text>
                    <TextInput
                        label="OTP"
                        onChangeText={setOtp}
                        value={otp}
                        placeholder="Inserisci OTP"
                        style={{ backgroundColor: colors.grey}}
                        theme={{ colors: { onSurfaceVariant: "white" } }}
                        style={{ color: 'white', borderBottomWidth: 1, borderBottomColor: "#53555e" }}
                    />
                    <View className="py-2 items-center justify-center">
                        <Button
                            title="Verifica OTP"
                            onPress={handleVerifyOtp}
                            className="p-1 bg-yellow"
                            style={{ borderRadius: 10, marginTop: 10 }}
                        >
                            <Text className="text-black text-2xl font-bold">
                                Verifica OTP
                            </Text>
                        </Button>
                    </View>
                </View >
            </View >
        );
    }

    //form changePassword e form Email per veirifica OTP
    return (
        <View className="flex flex-col h-screen items-center justify-center" style={{backgroundColor: colors.background}}>
            <View className="w-2/5 p-10 first-letter: rounded-xl border-2 border-bordercolor">
                <Text
                    variant="displayMedium"
                    className="text-white pb-4 font-semibold text-center"
                    style={styles.text}
                >
                    Inserisci Email per invio OTP
                </Text>
                <SafeAreaView>
                    <Formik
                        initialValues={{ email: '' }}
                        validate={validateEmailForm}
                        onSubmit={handleSendEmail}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                        }) => (
                            <View>
                                <View>
                                    <TextInput
                                        label="Email"
                                        onChangeText={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        value={values.email}
                                        theme={{ colors: { onSurfaceVariant: "white" } }}
                                        style={{ backgroundColor: colors.grey}}
                                        activeUnderlineColor="white"
                                        underlineStyle={{ borderWidth: 1, borderRadius: 10, borderColor: "#53555e" }}
                                    />
                                    {touched.email && errors.email && <Text style={{ color: 'red', marginTop: 4 }}>{errors.email}</Text>}

                                    <View className="py-2 items-center justify-center">
                                        <Button
                                            //title="Update Password"
                                            onPress={handleSubmit as (e: unknown) => void}
                                            disabled={isSubmitting || sendLoading}
                                            className="p-1"
                                            style={{ borderRadius: 10, marginTop: 10, backgroundColor: colors.amaranto }}
                                        >
                                            <Text className="text-white text-2xl font-bold">
                                                {sendLoading ? 'Inviando...' : 'Invia Email'}
                                            </Text>
                                        </Button>
                                    </View>
                                </View>
                                <Text
                                    onPress={() => navigation.navigate('Login')}
                                    className="text-white mt-4 text-center"
                                    style={{ textDecorationLine: 'underline' }}
                                >
                                    Ritorna al Login
                                </Text>
                            </View>
                        )}
                    </Formik>
                </SafeAreaView>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20,
    },
    input: {
        marginBottom: 20,
    },
    warning: {
        color: 'red',
        marginBottom: 10,
    },
    error: {
        color: 'red',
        marginTop: 10,
    },
    expiredWarning: {
        color: 'red',
        marginBottom: 15,
    },
    text: {
        fontSize: 24,
        fontWeight: "bold",
    }
});

export default ForgotPassword;