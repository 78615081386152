import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { Button, Text } from "react-native-paper";
import { DAY_PER_MAN } from "../constants/App.constants";
import colors from "../styles/colors";

function WokerDataSectionTwo({ data, refetchDashboard, lastUpdateData }) {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    refetchDashboard();
  }, [data]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    headerRow: {
      flexDirection: 'row',
      backgroundColor: colors.grey,
      padding: 5,
      marginBottom: 3,
      borderWidth: 3,
      borderColor: "grey",
      borderRadius: 2,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: 'grey',
      padding: 3,
      marginLeft: 3,
    },
    cell: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 300,
    },
    lastCell: {
      padding: 2,
      width: 300,
    },
    cellNumber: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 200,
    },
    cellNumberLast: {
      padding: 2,
      borderColor: "grey",
      width: 200,
    },
    headerText: {
      fontWeight: 'bold',
      color: 'white',
      fontSize: 22,
      textAlign: 'center',
    },
    cellText: {
      color: 'white',
      fontSize: 22,
      textAlign: 'center',
    },
    cellTextButton: {
      color: 'white',
      fontSize: 18,
      alignContent: "center",
    },
    content: {
      flex: 1,
    },
    actionButton: {
      justifyContent: "center",
      alignItems: 'center',
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    button: {
      backgroundColor: colors.amaranto,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: colors.white,
      borderTopLeftRadius: 10,
    }
  });

  return (
    <>
      <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderRadius: 10, borderWidth: 1, borderColor: colors.white }}>
        <View className="flex flex-row w-full">
          <View style={styles.button}>
            <Button
              mode={activeTab === 1 ? "contained" : "outlined"}
              buttonColor={activeTab === 1 ? colors.amaranto : null}

              onPress={() => handleTabClick(1)}
            >
              <Text style={styles.buttonText}> Sintesi progetti</Text>
            </Button>
          </View>
        </View>
        <View className="flex flex-col w-full p-2 ">
          <View className="flex flex-col w-full h-96 my-2 overflow-scroll">
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              {activeTab == 1 ? (
                <>
                  <View style={styles.container}>
                    <View style={styles.headerRow}>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Codice commessa</Text></View>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Cliente</Text></View>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Progetto</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Ore ordinarie</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Giorno/uomo ordinario</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Ore non ordinarie</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Giorno/uomo non ordinario</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Giorno/uomo totale</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Costo totale non ordinario</Text></View>
                      <View style={[styles.cellNumber]}><Text style={styles.headerText}>Costo totale ordinario</Text></View>
                      <View style={[styles.cellNumberLast]}><Text style={styles.headerText}>Costo totale</Text></View>
                    </View>

                    <ScrollView style={styles.content}>
                      {data?.project_details &&
                        data?.project_details?.sort((a, b) => {
                          // Ordina in base al project_id
                          return a.project_id.localeCompare(b.project_id);
                        })?.map((project, key) => {
                          // Verifica se ci sono task più recenti rispetto a lastUpdateData
                          const isProjectOutdated = data?.tasks?.some(
                            (task) => task.projectId === project.project_id && lastUpdateData && new Date(parseInt(task.created_at)) > new Date(lastUpdateData.getLastUpdate.lastUpdate)
                          );

                          const textColor = isProjectOutdated ? 'yellow' : 'white'; // Usa il colore giallo se isProjectOutdated è true, altrimenti nero

                          return (
                            <View key={key} style={styles.row}>
                              <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_id}</Text></View>
                              <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.client}</Text></View>
                              <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_name}</Text></View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.ordinary_hours).toFixed(2)}</Text></View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(
                                `${project?.ordinary_hours / DAY_PER_MAN}`
                              ).toFixed(2)}</Text>
                              </View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{(project?.overtime_hours).toFixed(2)}</Text></View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(
                                `${project?.overtime_hours / DAY_PER_MAN}`
                              ).toFixed(2)}</Text>
                              </View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(
                                `${project?.ordinary_hours / DAY_PER_MAN +
                                project?.overtime_hours / DAY_PER_MAN
                                }`
                              ).toFixed(2)}</Text>
                              </View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.totalCostOvertime).toFixed(2) || 0}</Text></View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.totalCostOrdinary).toFixed(2) || 0}</Text></View>
                              <View style={[styles.cellNumberLast]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.totalCost).toFixed(2) || 0}</Text></View>
                            </View>
                          );
                        })}
                    </ScrollView>
                  </View>
                </>
              ) : null}
            </ScrollView>
          </View>
        </View>
      </View >
    </>
  );
}

export default WokerDataSectionTwo;
