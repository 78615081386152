import * as React from "react";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { gql, useQuery } from "@apollo/client";
import { GET_WORKER_PROJECTS } from "../../graphql/user.resolver";
import { Text, Searchbar } from "react-native-paper";
import WorkerProjectCard from "../../components/ProjectCard.Worker";
import colors from "../../styles/colors";

const getUserProfileQuery = gql`
  query getUserProfile {
    getUserProfile {
      last_name
      first_name
    }
  }
`;

function WorkerProjectListScreen({ navigation }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedProjectList, setSortedProjectList] = useState([]);

  const {
    data: projectList,
    loading,
    error: projectListError,
  } = useQuery(GET_WORKER_PROJECTS);

  const { data: userprofile } = useQuery(getUserProfileQuery);

  useEffect(() => {
    if (projectList?.getWorkerProjects?.projects && Array.isArray(projectList.getWorkerProjects?.projects)) {
      const filteredAndSortedProjects = projectList.getWorkerProjects?.projects
        .filter(project => {
          const searchTermLowercased = searchTerm.toLowerCase();
          const matchesProjectName = project.name.toLowerCase().includes(searchTermLowercased);
          const matchesProjectId = project.project_id.toLowerCase().includes(searchTermLowercased);
          const matchesClientName = project.client_name.toLowerCase().includes(searchTermLowercased);
  
          // Controllo se lo stato del progetto è diverso da "Chiusa"
          const isOpen = project.status !== "Chiusa";
  
          // Include il progetto se corrisponde ad almeno una delle condizioni di ricerca ed è ancora aperto
          return (matchesProjectName || matchesProjectId || matchesClientName) && isOpen;
        })
        .sort((a, b) => {
          const nameComparison = a.name.localeCompare(b.name);
          if (nameComparison !== 0) return nameComparison;
          // Se vuoi mantenere un ordinamento basato sulla data di fine come criterio secondario, puoi lasciare queste righe.
          // Altrimenti, rimuovile se non necessario.
          const aEndDateTimestamp = a.end_date ? Number(a.end_date) : Infinity;
          const bEndDateTimestamp = b.end_date ? Number(b.end_date) : Infinity;
          return aEndDateTimestamp - bEndDateTimestamp;
        });
  
      setSortedProjectList(filteredAndSortedProjects);
    }
  }, [projectList, searchTerm]);

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <View className="flex flex-col p-2 ">
          <View className="flex flex-col p-2">
            <Text variant="headlineSmall" className="text-white font-semibold">
              Bentornato,
            </Text>
            <Text
              variant="displayMedium"
              className="text-white font-semibold pb-6"
            >
              {userprofile?.getUserProfile?.first_name}{" "}
              {userprofile?.getUserProfile?.last_name}
            </Text>
          </View>

          <View className="my-4 p-2">
              <Searchbar
                placeholder="Cerca progetti..."
                onChangeText={setSearchTerm}
                value={searchTerm}
                className="m-2"
                placeholderTextColor={colors.white}
                iconColor={colors.white}
                inputStyle={{ color: colors.white }}
                style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: 'Roboto' }}
              />
            </View>

          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={true}
            contentInsetAdjustmentBehavior="automatic"
            bounces={false}
          >
            <View className="flex flex-row p-2 pl-2 pb-6">
              {sortedProjectList.length > 0 ? (
                sortedProjectList.map((project, idx) => {
                  return (
                    <View key={idx} className="mr-2">
                      <WorkerProjectCard
                        project={project}
                        navigation={navigation}
                      />
                    </View>
                  );
                })
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono progetti disponibili*
                </Text>
              )}
            </View>
          </ScrollView>
        </View>
      </View>
    </>
  );
}

export default WorkerProjectListScreen;
