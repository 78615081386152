import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { gql, useQuery } from "@apollo/client";
import { Button, Text, Searchbar } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import ProjectCard from "../../components/ProjectCard";
import Loader from "../../components/Loader";
import { getStorageData } from "../../libs/storage";
import colors from "../../styles/colors";

const getProjectList = gql`
  query getAllProject($input: getAllProjectInput) {
    getAllProject(input: $input) {
      name
      year
      id
      end_date
      start_date
      workers_count
      acivities_count
      project_area {
        id
        name
      }
      project_id
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const getClientDetails = gql`
  query getClientByID($input: getAllProjectInput) {
    getClientByID(input: $input) {
      address
      id
      name
    }
  }
`;

function ProjectList({ navigation, route }: any) {
  const [sortedProjectList, setSortedProjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [role, setRole] = useState(null);
  const [clientid] = useState(route.params.id);

  const {
    data: ProjectList,
    refetch: refetchProjectList,
    loading: loadingProjectlist,
  } = useQuery(getProjectList, {
    fetchPolicy: "network-only",
    variables: { input: { client_id: clientid } },
  });

  const {
    data: clientData,
    refetch: refetchClient,
    loading: loadingClientData,
  } = useQuery(getClientDetails, {
    variables: { input: { client_id: clientid } },
  });

  
  useEffect(() => {
    if (clientData?.getClientByID) {
      navigation.setOptions({
        headerRight: () => {
          return (
            <View className="justify-end items-end pr-2">
              <Text className="text-white">
                {clientData?.getClientByID?.name}
              </Text>
              <Text className="text-white">
                {" "}
                {clientData &&
                  clientData.getClientByID &&
                  clientData.getClientByID.address.substring(0, 25)}
                .....
              </Text>
            </View>
          );
        },
      });
    }
  }, [clientData]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  useFocusEffect(
    React.useCallback(() => {
      refetchProjectList();
      refetchClient();
    }, [])
  );

  useEffect(() => {
    if (ProjectList?.getAllProject) { 
      const filteredAndSortedProjects = ProjectList.getAllProject
        .filter(project =>
          project.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setSortedProjectList(filteredAndSortedProjects);
    }
  }, [ProjectList, searchTerm]);

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <Loader loading={loadingProjectlist || loadingClientData} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full p-2">
              <View className="flex flex-row w-full gap-4 p-2">
                <Text
                  variant="displayMedium"
                  className="flex text-white font-semibold w-11/12"
                >
                  Progetti
                </Text>

                <Button
                  icon="account-edit"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  className="items-center justify-center"
                  onPress={() =>
                    navigation.push("EditClientDetail", { id: clientid })
                  }
                  style={{borderRadius: 10}}
                >
                  <Text className="text-white font-bold">
                    Modifica cliente
                  </Text>
                </Button>

                <Button
                  icon="briefcase-plus"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  onPress={() =>
                    navigation.navigate("CreateNewProject", { id: clientid })
                  }
                  className="items-center justify-center"
                  style={{borderRadius: 10}}
                >
                  <Text className="text-white font-bold">
                    Nuovo progetto
                  </Text>
                </Button>
              </View>
            </View>

            <View className="my-4 p-2">
              <Searchbar
                placeholder="Cerca progetti..."
                onChangeText={setSearchTerm}
                value={searchTerm}
                className="m-2"
                placeholderTextColor={colors.white}
                iconColor={colors.white}
                inputStyle={{ color: colors.white }}
                style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: 'Roboto' }}
              />
            </View>

            <View className="p-2 item-center justify-center">
              {sortedProjectList.length > 0 ? (
                sortedProjectList.map((page, idk) => (
                  <ProjectCard
                    data={page}
                    key={idk}
                    navigation={navigation}
                    refetchProjectList={refetchProjectList}
                    clientID={clientid}
                    role={role}
                  />
                ))
              ) : (
                <Text className="text-xl font-bold text-white">
                  Non ci sono progetti disponibili*
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default ProjectList;
