import React, { useState, useEffect, useCallback } from 'react';
import { View, SafeAreaView, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { getStorageData, removeStorageData } from "../libs/storage";
import { useNavigation, useRoute } from '@react-navigation/native';
import { Formik } from 'formik';
import { useAuth } from '../views/AuthContext';
import { USER_TOKEN_KEY } from "../constants/App.constants";
import colors from '../styles/colors';

// const CHECK_PASSWORD_EXPIRATION = gql`
//   query CheckPasswordExpiration($input: CheckPasswordExpirationInput) {
//     CheckPasswordExpiration(input: $input) {
//       last_password_change
//       isExpired
//       daysUntilExpiration
//     }
//   }
// `;

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput) {
    UpdatePassword(input: $input) {
      success
      message
    }
  }
`;

function useBlockPageReload(blockReload) {
    useEffect(() => {
        const handler = (event) => {
            if (blockReload) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        // Aggiungi l'evento
        window.addEventListener('beforeunload', handler);

        // Rimuovi l'evento al cleanup
        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, [blockReload]);
}

const ChangePassword = () => {
    const client = useApolloClient();
    const { setIsAuthenticated } = useAuth();
    const [profileEmail, setProfileEmail] = useState(null);
    const [passStatus, setPassStatus] = useState(null);
    //const [passChange, setPassChange] = useState(null);
    const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [isPasswordHidden, setisPasswordHidden] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [updateLoading, setUpdateLoading] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [feedback, setFeedback] = useState('');
    const navigation = useNavigation();
    const block = true;

    useBlockPageReload(block);

    useEffect(() => {
        const unsubscribe = navigation.addListener('state', e => {
            const fetchProfileEmail = async () => {
                const storedProfileEmail = await getStorageData("email");
                if (storedProfileEmail) {
                    setProfileEmail(storedProfileEmail);
                }
            };

            const fetchPassStatus = async () => {
                const storedPassStatus = await getStorageData("isExpired");
                if (storedPassStatus) {
                    setPassStatus(storedPassStatus);
                }
            };
            // const fetchPassChange = async () => {
            //     const storedPassChange = await getStorageData("changePasswordRequired");
            //     if (storedPassChange) {
            //         setPassChange(storedPassChange);
            //     }
            // };
            setIsAuthenticated(false);
            //fetchPassChange();
            fetchPassStatus();
            fetchProfileEmail();
        });

        return unsubscribe;
    }, [navigation]);

    const [updatePassword, { data: updateData, error: updateError }] = useMutation(UPDATE_PASSWORD);

    const checkPasswordStrength = useCallback((password) => {
        let strength = 'Debole';
        if (password.length >= 8 && /\d/.test(password) && /[a-zA-Z]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
            strength = 'Forte';
        } else if (password.length >= 8) {
            strength = 'Media';
        }
        setPasswordStrength(strength);
    }, []);

    // useEffect(() => {
    //     if (data?.CheckPasswordExpiration) {
    //         const { isExpired, daysUntilExpiration } = data.CheckPasswordExpiration;

    //         setIsPasswordExpired(isExpired);

    //         if (!isExpired && daysUntilExpiration <= 10) {
    //             setFeedback(`Attenzione: la tua password scadrà in ${daysUntilExpiration} giorni.`);
    //         } else {
    //             setFeedback('');
    //         }
    //     }
    // }, [data]);

    async function logout() {
        await removeStorageData(USER_TOKEN_KEY);
        await removeStorageData("role");
        await removeStorageData("profileID");
        await removeStorageData("email");
        //resetDisclaimerStatus(false);
        setIsAuthenticated(false);
        client.clearStore();
        navigation.navigate("Login");
    }

    const handleUpdatePassword = async (values) => {
        setUpdateLoading(true);
        try {
            const response = await updatePassword({
                variables: {
                    input: {
                        email: profileEmail,
                        newPassword: values.newPassword,
                    },
                },
            });

            const { success, message } = response.data.UpdatePassword;
            if (success) {
                logout()
            } else {
                setFeedback(message || "Errore nell'aggiornamento della password.");
            }
        } catch (error) {
            setFeedback(error.message || "Errore sconosciuto durante l'aggiornamento della password.");
        }
        setUpdateLoading(false);
    };

    const validateForm = values => {
        let errors = {};
        if (!values.newPassword) {
            errors.newPassword = 'Richiesto';
        } else if (values.newPassword.length < 8) {
            errors.newPassword = 'La password deve essere almeno di 8 caratteri';
        }

        if (!values.passwordConfirm) {
            errors.passwordConfirm = 'Richiesto';
        } else if (values.newPassword !== values.passwordConfirm) {
            errors.passwordConfirm = 'Le password non corrispondono';
        }

        return errors;
    };

    //form changePassword e form Email per veirifica OTP
    return (
        <View className="flex flex-col h-screen items-center justify-center" style={{backgroundColor: colors.background}}>
            <View className="w-2/5 p-10 first-letter: rounded-xl border-2 border-bordercolor">
                <Text
                    variant="displayMedium"
                    className="text-white pb-4 font-semibold text-center"
                    style={styles.text}
                >
                    Cambia Password
                </Text>
                {passStatus === true ? (
                    <Text
                        variant="displayMedium"
                        className="text-white pb-4 font-semibold text-center"
                    //style = {styles.text}
                    >
                        Password Scaduta, Prego scegliere una nuova password.
                    </Text>
                ) : null}
                <SafeAreaView>
                    <Formik
                        initialValues={{ newPassword: '', passwordConfirm: '' }}
                        validate={validateForm}
                        onSubmit={handleUpdatePassword}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                        }) => (
                            <View>
                                <View>
                                    <TextInput
                                        label="Nuova Password"
                                        secureTextEntry={isPasswordHidden}
                                        onChangeText={(text) => {
                                            handleChange('newPassword')(text);
                                            checkPasswordStrength(text);
                                        }}
                                        onBlur={handleBlur('newPassword')}
                                        value={values.newPassword}
                                        theme={{ colors: { onSurfaceVariant: "white" } }}
                                        style={{ backgroundColor: colors.grey}}
                                        activeUnderlineColor="white"
                                        underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                                        right={
                                            <TextInput.Icon
                                                icon="eye"
                                                iconColor="white"
                                                onPress={() => setisPasswordHidden(!isPasswordHidden)}
                                            />
                                        }
                                    />
                                    {touched.newPassword && errors.newPassword && (
                                        <Text style={{ color: 'red', marginTop: 4 }}>{errors.newPassword}</Text>
                                    )}

                                    <TextInput
                                        label="Conferma Password"
                                        secureTextEntry={isPasswordHidden}
                                        onChangeText={handleChange('passwordConfirm')}
                                        onBlur={handleBlur('passwordConfirm')}
                                        value={values.passwordConfirm}
                                        theme={{ colors: { onSurfaceVariant: "white" } }}
                                        style={{ backgroundColor: colors.grey}}
                                        activeUnderlineColor="white"
                                        underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                                    />
                                    {touched.passwordConfirm && errors.passwordConfirm && <Text style={{ color: 'red', marginTop: 4 }}>{errors.passwordConfirm}</Text>}

                                    <Text className="text-white mt-4">
                                        Robustezza: {passwordStrength}
                                    </Text>
                                    <View className="py-2 items-center justify-center">
                                        <Button
                                            //title="Update Password"
                                            onPress={handleSubmit as (e: unknown) => void}
                                            disabled={isSubmitting || updateLoading}
                                            className="p-1"
                                            style={{ backgroundColor: colors.amaranto, borderRadius: 10 }}
                                        >
                                            <Text className="text-white text-2xl font-bold">
                                                {updateLoading ? 'Aggiornando...' : 'Aggiorna Password'}
                                            </Text>
                                        </Button>
                                    </View>
                                    {feedback && (
                                        <Text className="text-red-500 mt-4 text-center"
                                        style={{ color: 'red' }}>
                                            {feedback}
                                        </Text>
                                    )}
                                </View>
                                <Text
                                    onPress={() => navigation.navigate('Login')}
                                    className="text-white mt-4 text-center"
                                    style={{ textDecorationLine: 'underline' }}
                                >
                                    Ritorna al Login
                                </Text>
                            </View>
                        )}
                    </Formik>
                </SafeAreaView>
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20,
    },
    input: {
        marginBottom: 20,
    },
    warning: {
        color: 'red',
        marginBottom: 10,
    },
    error: {
        color: 'red',
        marginTop: 10,
    },
    expiredWarning: {
        color: 'red',
        marginBottom: 15,
    },
    text: {
        fontSize: 24,
        fontWeight: "bold",
    }
});

export default ChangePassword;