import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import {
  Button,
  TextInput,
  Text,
  Portal,
  Modal,
  ActivityIndicator,
} from "react-native-paper";
import MyWebDatePicker from "../../components/MyDatePicker";
import { validate } from "../../libs/form.validate";
import colors from "../../styles/colors";

const getProjectDetails = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      id
      name
      end_date
    }
  }
`;

const createActivityQuery = gql`
  mutation createActivity($input: projectActivityInput) {
    createActivity(input: $input) {
      name
    }
  }
`;

const getActivityDetails = gql`
  query getActivitDetails($input: getActivitDetails) {
    getActivitDetails(input: $input) {
      id
      name
      description
      end_date
      start_date
      projectId
    }
  }
`;

const EDIT_ACTIVITY_DETAILS = gql`
  mutation updateActivity($input: updateActivityInput) {
    updateActivity(input: $input) {
      message
      status
    }
  }
`;

function CreateNewActivity({ navigation, route }) {
  const [ProjectId] = useState(route.params.id);
  const [activityId] = useState(route?.params?.activity_id);
  const REQUIRED_FIELDS = ["name", "start_date", "end_date"];
  const [validationErrors, setValidationErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const { data: ActivityDetails } = useQuery(getActivityDetails, {
    variables: { input: { activity_id: activityId } },
  });

  const [createActivity, { data, loading, error }] =
    useMutation(createActivityQuery);

  const [isStartDatePickerVisible, setisStartDatePickerVisible] =
    useState(false);
  const [isEndDatePickerVisible, setisEndDatePickerVisible] = useState(false);

  const containerStyle = {
    backgroundColor: colors.grey,
    padding: 20,
    alignSelf: "center",
  };

  const { data: projectData, refetch: refetchproject } = useQuery(
    getProjectDetails,
    {
      variables: { input: { id: ProjectId } },
    }
  );

  useEffect(() => {
    if (projectData?.getProjectById) {
      navigation.setOptions({
        headerRight: () => {
          return (
            <View className="justify-end items-end pr-2">
              <Text className="text-white">
                {projectData?.getProjectById?.name}
              </Text>
              <Text className="text-white">
                {" "}
                {projectData &&
                  projectData.getProjectById &&
                  `Data Fine ${new Date(
                    parseInt(projectData?.getProjectById?.end_date)
                  ).toLocaleDateString("en-GB")}`}
              </Text>
            </View>
          );
        },
      });
    }
  }, [projectData]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    start_date: new Date(),
    end_date: new Date(),
    projectId: ProjectId,
  });

  useEffect(() => {
    if (ActivityDetails) {
      setInitialValues({
        name: ActivityDetails?.getActivitDetails?.name,
        description: ActivityDetails?.getActivitDetails?.description,
        start_date: new Date(
          parseInt(ActivityDetails?.getActivitDetails?.start_date)
        ),
        end_date: new Date(
          parseInt(ActivityDetails?.getActivitDetails?.end_date)
        ),
        projectId: ActivityDetails?.getActivitDetails?.projectId,
      });
    }
  }, [ActivityDetails]);

  const [
    editActivity,
    {
      data: editActivityData,
      loading: editActivityLoading,
      error: editActivityError,
    },
  ] = useMutation(EDIT_ACTIVITY_DETAILS);

  const submitData = async (values) => {
    setValidationErrors({});

    const isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    try {
      if (isValidForm) {
        if (activityId) {
          await editActivity({
            variables: {
              input: { ...values, id: ActivityDetails?.getActivitDetails?.id },
            },
          });
          navigation.navigate("ActivityList", {
            id: ActivityDetails?.getActivitDetails?.projectId,
          });
        } else {
          await createActivity({ variables: { input: values } });
          navigation.navigate("ActivityList", { id: ProjectId });
        }
      }
    } catch (error) {
      setValidationErrors({ backendError: `${error?.message}*` });
    }
  };

  return (
    <View>
      <View className="flex flex-col p-2 pt-8 h-screen pb-24" style={{backgroundColor: colors.background}}>
        <SafeAreaView>
          <Text
            variant="displayMedium"
            className="text-white pl-2 pb-4 font-semibold"
          >
            {activityId ? "Modifica attività" : "Nuova attività"}
          </Text>

          {validationErrors["backendError"] && (
            <View>
              <Text
                className="text-red-400 text-sm p-2"
                style={{ color: "red" }}
              >
                {validationErrors["backendError"]}
              </Text>
            </View>
          )}

          <ScrollView>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSaving(true); // Imposta lo stato del salvataggio su true
                await submitData(values); // Esegui la funzione di salvataggio
              
                // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                setTimeout(() => {
                  setIsSaving(false);
                  setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
              }}
              enableReinitialize
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <View className="flex flex-col w-full p-2 gap-2">
                  <View>
                    <TextInput
                      label="Nome"
                      onChangeText={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      style={{ backgroundColor: colors.grey}}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["name"] && (
                      <Text
                        className="text-red-400 p-1"
                        style={{ color: "red" }}
                      >
                        {validationErrors["name"]}
                      </Text>
                    )}
                  </View>

                  <View>
                    <TextInput
                      label="Descrizione"
                      onChangeText={handleChange("description")}
                      onBlur={handleBlur("description")}
                      value={values.description}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      style={{ backgroundColor: colors.grey}}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["description"] && (
                      <Text
                        className="text-red-400 p-1"
                        style={{ color: "red" }}
                      >
                        {validationErrors["description"]}
                      </Text>
                    )}
                  </View>

                  {/* Start date picker with modal starts */}
                  <View>
                    <TextInput
                      label="Data inizio attività"
                      showSoftInputOnFocus={false}
                      value={values.start_date.toDateString()}
                      right={
                        <TextInput.Icon icon="calendar" iconColor="white" />
                      }
                      onFocus={() =>
                        setisStartDatePickerVisible(!isStartDatePickerVisible)
                      }
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      style={{ backgroundColor: colors.grey}}
                      activeUnderlineColor="white"
                      underlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />

                    <Portal>
                      <Modal
                        visible={isStartDatePickerVisible}
                        onDismiss={() => setisStartDatePickerVisible(false)}
                        //@ts-ignore
                        contentContainerStyle={containerStyle}
                      >
                        {isStartDatePickerVisible && (
                          <MyWebDatePicker
                            fieldName={"start_date"}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </Modal>
                    </Portal>

                    {validationErrors["start_date"] && (
                      <Text
                        className="text-red-400 p-1"
                        style={{ color: "red" }}
                      >
                        {validationErrors["start_date"]}
                      </Text>
                    )}
                  </View>

                  {/* Start date picker with modal Ends */}

                  {/* End date picker with modal starts */}
                  <TextInput
                    label="Data fine attività"
                    showSoftInputOnFocus={false}
                    value={values.end_date.toDateString()}
                    right={<TextInput.Icon icon="calendar" iconColor="white" />}
                    onFocus={() =>
                      setisEndDatePickerVisible(!isEndDatePickerVisible)
                    }
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey}}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />

                  <Portal>
                    <Modal
                      visible={isEndDatePickerVisible}
                      onDismiss={() => setisEndDatePickerVisible(false)}
                      contentContainerStyle={containerStyle}
                    >
                      {isEndDatePickerVisible && (
                        <MyWebDatePicker
                          fieldName={"end_date"}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Modal>
                  </Portal>
                  {/* End date picker with modal Ends */}

                  <View className="py-2 w-full items-center justify-center">
                    <Button
                      mode="contained"
                      onPress={handleSubmit as (e: unknown) => void}
                      className="p-2"
                      buttonColor={colors.amaranto}
                      style={{ borderRadius: 10 }}
                      disabled={isSaving}
                    >
                      {loading ? (
                        <ActivityIndicator animating={true} color={"red"} />
                      ) : (
                        <Text className="text-white font-bold">
                          Salva attività
                        </Text>
                      )}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
          </ScrollView>
        </SafeAreaView>
      </View>
    </View>
  );
}

export default CreateNewActivity;
