import React, { useState } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { Button, Text } from "react-native-paper";
import colors from "../styles/colors";

function DataSectionThree({ projectList, refetchProjectList, transactionList, refetchTransactionList }) {
    const [activeTab, setActiveTab] = useState(1);
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        refetchTransactionList();
    };

    const nomiDaEscludere = ["Framinia ECS - Gestione Permessi Dipendenti",
        "Framinia ECS - Gestione Ferie Dipendenti",
        "Framinia ECS - Gestione Malattia Dipendenti"];

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        headerRow: {
            flexDirection: 'row',
            backgroundColor: colors.grey,
            padding: 5,
            marginBottom: 3,
            borderWidth: 3,
            borderColor: "grey",
            borderRadius: 2,
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 2,
            borderBottomColor: 'grey',
            padding: 3,
            marginLeft: 3,
        },
        cell: {
            padding: 2,
            borderRightWidth: 1,
            borderColor: "grey",
            width: 300,
        },
        cellNumber: {
            padding: 2,
            borderRightWidth: 1,
            borderColor: "grey",
            width: 200,
        },
        lastCell: {
            padding: 2,
            width: 300,
        },
        lastCellNumber: {
            padding: 2,
            width: 200,
        },
        headerText: {
            fontWeight: 'bold',
            color: 'white',
            fontSize: 22,
            textAlign: 'center',
        },
        cellText: {
            color: 'white',
            fontSize: 18,
            textAlign: 'center',
        },
        cellTextButton: {
            color: 'white',
            fontSize: 18,
        },
        content: {
            flex: 1,
        },
        actionButton: {
            justifyContent: "center",
            alignItems: 'center',
        },
        button: {
            borderColor: colors.white,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderTopLeftRadius: 10,
            backgroundColor: colors.amaranto,
        },
        buttonText: {
            color: colors.white,
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 'bold',
        },
    });

    return (
        <>
            <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderWidth: 1, borderRadius: 10, borderColor: colors.white }}>
                <View className="flex flex-row w-full">
                    <View style={styles.button}>
                        <Button
                            //mode={activeTab === 1 ? "contained" : "outlined"}
                            buttonColor={colors.amaranto}
                            onPress={() => handleTabClick(1)}
                            style={{ borderTopLeftRadius: 10, backgroundColor: colors.amaranto }}
                        >
                            <Text style={styles.buttonText}>Progetti</Text>
                        </Button>
                    </View>
                    <Button
                        // mode={activeTab === 2 ? "contained" : "outlined"}
                        buttonColor={colors.amaranto}
                        style={{ borderRadius: 0, backgroundColor: colors.amaranto, borderColor: colors.white, borderBottomWidth: 1, borderRightWidth: 1 }}
                        onPress={() => handleTabClick(2)}
                    >
                        <Text style={styles.buttonText}>Timeline</Text>
                    </Button>
                </View>
                <View className="flex flex-col w-full h-96 my-2 overflow-scroll">
                    {activeTab == 1 ? (
                        <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
                            <View style={styles.container}>
                                <View style={styles.headerRow}>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Codice commessa</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Nome progetto</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Cliente</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Gruppo cliente</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Area manager</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Project manager</Text></View>
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Settore</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Importo contratto</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Fee</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine ECS</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Budget</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Costi</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine</Text></View>
                                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Margine con compensazione</Text></View>
                                    {/* <View style={[styles.cell, { flex: 1.65 }]}><Text style={styles.headerText}>Compensazione</Text></View> */}
                                    <View style={[styles.cell]}><Text style={styles.headerText}>Compensato da</Text></View>
                                    <View style={[styles.lastCell]}><Text style={styles.headerText}>Compensato a</Text></View>
                                </View>
                                <ScrollView style={styles.content}>
                                    {projectList &&
                                        projectList.filter(project => !nomiDaEscludere.includes(project.name)).sort((a, b) => {
                                            // Ordina in base al project_id
                                            return a.project_id.localeCompare(b.project_id);
                                        }).map((project) => {
                                            const textColor = project.hasOutdatedTasks ? 'yellow' : 'white';

                                            return (
                                                <View key={project.name} style={styles.row}>
                                                    <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_id}</Text></View>
                                                    <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.name}</Text></View>
                                                    <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.client?.name}</Text></View>
                                                    <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.client_group}</Text></View>
                                                    <View style={[styles.cell]}>
                                                        <Text style={[styles.cellText, { color: textColor }]}>
                                                            {project?.area_manager?.user?.first_name || project?.area_manager?.user?.last_name
                                                                ? `${project?.area_manager?.user?.first_name} ${project?.area_manager?.user?.last_name}`
                                                                : "N/A"}
                                                        </Text>
                                                    </View>
                                                    <View style={[styles.cell]}>
                                                        <Text style={[styles.cellText, { color: textColor }]}>
                                                            {project?.project_manager?.user?.first_name || project?.project_manager?.user?.last_name
                                                                ? `${project?.project_manager?.user?.first_name} ${project?.project_manager?.user?.last_name}`
                                                                : "N/A"}
                                                        </Text>
                                                    </View>
                                                    <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{project?.project_sector?.name}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.contract_value).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.fee).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.margin).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.budget).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.cost).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(project?.notUsedBudget).toFixed(2)}</Text></View>
                                                    <View style={[styles.cellNumber]}>
                                                        <Text style={[styles.cellText, { color: textColor }]}>
                                                            {project?.compensatedBudget !== project?.notUsedBudget ? parseFloat(project?.compensatedBudget).toFixed(2) : "N/A"}
                                                        </Text>
                                                    </View>
                                                    {/* <View style={[styles.cell, { flex: 1.65 }]}>
                                                    <Text style={styles.cellText}>
                                                        {project?.compensation !== 0 ? parseFloat(project?.compensation).toFixed(2) : "N/A"}
                                                    </Text>
                                                </View> */}
                                                    <View style={[styles.cell]}>
                                                        {project.compensationsReceived.length > 0 ? project.compensationsReceived.map((comp, index) => (
                                                            <Text key={index} style={[styles.cellText, { color: textColor }]}>{comp.projectId}: {parseFloat(comp.amount).toFixed(2)}</Text>
                                                        )) : <Text style={styles.cellText}>"N/A"</Text>}
                                                    </View>
                                                    <View style={[styles.lastCell]}>
                                                        {project.compensationsSent.length > 0 ? project.compensationsSent.map((comp, index) => (
                                                            <Text key={index} style={[styles.cellText, { color: textColor }]}>{comp.projectId}: {parseFloat(comp.amount).toFixed(2)}</Text>
                                                        )) : <Text style={styles.cellText}>"N/A"</Text>}
                                                    </View>
                                                </View>
                                            );
                                        })}
                                </ScrollView>
                            </View>
                        </ScrollView>
                    ) : null}
                    {activeTab == 2 ? (
                        <View style={styles.container}>
                            <View style={styles.headerRow}>
                                <View style={[styles.cell]}><Text style={styles.headerText}>Dal progetto</Text></View>
                                <View style={[styles.cell]}><Text style={styles.headerText}>Al progetto</Text></View>
                                <View style={[styles.cell]}><Text style={styles.headerText}>Data</Text></View>
                                <View style={[styles.lastCellNumber]}><Text style={styles.headerText}>Compensazione</Text></View>
                            </View>
                            <ScrollView style={styles.content}>
                                {transactionList &&
                                    transactionList?.getAllTransaction.map((transaction) => (
                                        <View key={transaction.id} style={styles.row}>
                                            <View style={[styles.cell]}><Text style={styles.cellText}>{transaction?.fromProject_id}</Text></View>
                                            <View style={[styles.cell]}><Text style={styles.cellText}>{transaction?.toProject_id}</Text></View>
                                            <View style={[styles.cell]}><Text style={styles.cellText}>{new Date(parseInt(transaction.created_at)).toLocaleString("en-GB", {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}</Text></View>
                                            <View style={[styles.lastCellNumber]}><Text style={styles.cellText}>{transaction?.amount}</Text></View>
                                        </View>
                                    ))
                                }
                            </ScrollView>
                        </View>
                    ) : null}
                </View>
            </View>
        </>
    );
}

export default DataSectionThree;
