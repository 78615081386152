export const validate = async (
  values,
  setValidationErrors,
  REQUIRED_FIELDS
) => {
  let numreg = /^[0-9]+(\.[0-9]+)?$/;
  const errors = {};
  let foundError = false;
  let startDate, enddate, startTime, endTime, receiptValue;

  REQUIRED_FIELDS.forEach((field) => {
    if (!values[field] || String(values[field]) === "undefined" || String(values[field]).trim() === "") {
      foundError = true;
      errors[field] = `${field} is required*`;
    }

    if (field === "receipt_value") {
      receiptValue = values[field];
    }

    if (field === "start_date") {
      startDate = values[field];
    }

    if (field === "end_date") {
      enddate = values[field];
    }

    if (field === "start_time") {
      startTime = values[field];
    }

    if (field === "end_time") {
      endTime = values[field];
    }
  });

  if (startDate && enddate && new Date(enddate).getTime() < new Date(startDate).getTime()) {
    foundError = true;
    errors["start_date"] = "Start date cannot exceed the End date*";
  }

  if (startTime && endTime && new Date(endTime).getTime() < new Date(startTime).getTime()) {
    foundError = true;
    errors["start_time"] = "Start Time cannot exceed the End Time*";
  }

  if (receiptValue) {
    if (!numreg.test(receiptValue)) {
      foundError = true;
      errors["receipt_value"] = `Value Should Be Numeric only*`;
    }
  }

  if (foundError) {
    setValidationErrors(errors);
    return false;
  }
  return true;
};
