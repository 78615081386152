import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { View } from "react-native";
import {
  Badge,
  Button,
  Dialog,
  IconButton,
  Portal,
  Text,
} from "react-native-paper";
import {
  USER_PROFILE_ROLE,
} from "../graphql/user.resolver";
import { getStorageData } from "../libs/storage";
import { useNavigation } from '@react-navigation/native';
import colors from "../styles/colors";

const DELETE_ACTIVITY = gql`
  mutation deleteActivity($input: deleteActivityInput) {
    deleteActivity(input: $input) {
      status
      message
    }
  }
`;

const getProjectDetails = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      id
      name
      end_date
      project_area {
        name
        id
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function ActivityCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const handelModalClose = () => setIsDeleteModalVisible(false);
  const [role, setRole] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [props.navigation]);

  const { data: projectData, refetch: refetchproject, loading: loadingProjectData } = useQuery(
    getProjectDetails,
    { variables: { input: { id: props.projectID } } }
  );

  const [deleteActivity, { data, loading, error }] =
    useMutation(DELETE_ACTIVITY);

  const navigate = () => {
    props.navigation.push("ActivityDetails", { id: props.data.id, projectID: props.projectID });
  };

  const deleteCard = async () => {
    await deleteActivity({
      variables: { input: { activity_id: props.data.id } },
    });
    await props.refetchActivityList();
    setIsDeleteModalVisible(false)
  };

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;

  const isProjectManager = () => {
    const projectManagerName = `${projectData?.getProjectById?.project_manager?.user?.first_name} ${projectData?.getProjectById?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = projectData?.getProjectById?.project_area?.id;
  
  const isAreaManager = () => {
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
          <Text variant="bodyMedium" className="text-white" style={{fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare l'attività {props.data.name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold">
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteCard}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold">
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{backgroundColor: colors.grey, borderColor: colors.white, borderRadius: 10, borderWidth: 1}}>
          <View className="flex flex-col w-1/3 gap-4 p-2">
            <Text className="text-base font-bold text-white " style={{fontFamily: "Roboto"}}>
              {props.data.name}
            </Text>

            {props.data.end_date && (
              <Text className="text-sm text-white" style={{ fontFamily: "Roboto"}}>
                Data Fine :{" "}
                {`${new Date(parseInt(props.data.end_date)).toLocaleDateString(
                  "en-GB"
                )}`}
              </Text>
            )}
          </View>

          <View className="flex flex-row items-center justify-center w-1/3 p-2">
            <View className="flex flex-row items-center  w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}>
                {props.data?.workers_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14}}>Lavoratori</Text>
            </View>
          </View>

          <View className="flex flex-row w-2/5 p-4 items-center gap-10 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={navigate}
            />
            {isProjectManager() || props.role === "ADMIN" || isAreaManager() ? (
              <IconButton
                icon="trash-can-outline"
                containerColor={colors.amaranto}
                style={{ borderRadius: 4 }}
                iconColor={colors.white}
                size={25}
                onPress={() => setIsDeleteModalVisible(true)}
              />
            ) : null}
          </View>
        </View>
      </View>
    </>
  );
}

export default ActivityCard;
