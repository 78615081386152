import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import { Button, TextInput, Text, ActivityIndicator } from "react-native-paper";
import { validate } from "../../libs/form.validate";
import colors from "../../styles/colors";

const createClientQuery = gql`
  mutation createClient($input: createClient) {
    createClient(input: $input) {
      address
      id
      name
    }
  }
`;
const GET_CLIENT_DATA = gql`
  query getClientByID($input: getAllProjectInput) {
    getClientByID(input: $input) {
      name
      address
    }
  }
`;
const EDIT_CLIENT_DETAILS = gql`
  mutation updateClient($input: updateClientInput) {
    updateClient(input: $input) {
      status
      message
    }
  }
`;

function CreateNewClient({ navigation, route }) {
  const [clientid] = useState(route?.params?.id);
  const [validationErrors, setValidationErrors] = useState({});
  const REQUIRED_FIELDS = ["name", "address"];
  const [isSaving, setIsSaving] = useState(false);

  const { data: clientData, refetch: refetchClientData } = useQuery(
    GET_CLIENT_DATA,
    {
      variables: { input: { client_id: clientid } },
    }
  );

  const [
    createClient,
    {
      data: createClientData,
      loading: createClientLoading,
      error: createClientError,
    },
  ] = useMutation(createClientQuery);

  const [
    editClient,
    {
      data: editClientData,
      loading: editClientLoading,
      error: editClientError,
    },
  ] = useMutation(EDIT_CLIENT_DETAILS);

  const submitData = async (values) => {
    setValidationErrors({});
    const isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    try {
      if (isValidForm) {
        if (clientid) {
          const data = await editClient({
            variables: {
              input: {
                client_id: clientid,
                address: values?.address,
                name: values?.name,
              },
            },
          });
          navigation.goBack();
        } else {
          const data = await createClient({ variables: { input: values } });
          navigation.goBack();
        }
      }
    } catch (error) {
      setValidationErrors({ backendError: `${error?.message}*` });
    }
  };
  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      bounces={false}
      showsHorizontalScrollIndicator={false}
    >
      <View>
        <View className="flex flex-col p-2 pt-8 h-screen pb-24" style={{backgroundColor: colors.background}}>
          <SafeAreaView>
            <Text
              variant="displayMedium"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {clientid ? "Modifica Cliente" : "Nuovo Cliente"}
            </Text>

            {validationErrors["backendError"] && (
              <View>
                <Text
                  className="text-red-400 text-sm p-2"
                  style={{ color: "red" }}
                >
                  {validationErrors["backendError"]}
                </Text>
              </View>
            )}

            <Formik
              initialValues={{
                name: clientData?.getClientByID?.name,
                address: clientData?.getClientByID?.address,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSaving(true); // Imposta lo stato del salvataggio su true
                await submitData(values); // Esegui la funzione di salvataggio

                setTimeout(() => {
                  setIsSaving(false);
                  setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <View className="flex flex-col w-full p-2 gap-2">
                  <TextInput
                    label="Nome Cliente"
                    onChangeText={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey}}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["name"] && (
                    <Text className="text-red-400 p-2" style={{ color: "red" }}>
                      {validationErrors["name"]}
                    </Text>
                  )}

                  <TextInput
                    label="Indirizzo Cliente"
                    onChangeText={handleChange("address")}
                    onBlur={handleBlur("address")}
                    value={values.address}
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey}}
                    activeUnderlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />
                  {validationErrors["address"] && (
                    <Text className="text-red-400 p-2" style={{ color: "red" }}>
                      {validationErrors["address"]}
                    </Text>
                  )}

                  <View className="py-2 w-full items-center justify-center">
                    <Button
                      mode="contained"
                      onPress={handleSubmit as (e: unknown) => void}
                      className="p-2"
                      buttonColor="#7d0001"
                      disabled={isSaving}
                    >
                      {createClientLoading || editClientLoading ? (
                        <ActivityIndicator animating={true} color={"red"} />
                      ) : (
                        <Text className="text-white font-bold">
                          {" "}
                          Salva cliente
                        </Text>
                      )}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
          </SafeAreaView>
        </View>
      </View>
    </ScrollView>
  );
}

export default CreateNewClient;
