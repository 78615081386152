import React, { useState, useEffect } from "react";
import { SafeAreaView, TouchableOpacity, View, StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text, Button, Portal, Modal, Dialog, IconButton } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import WorkerProjectCard from "../../components/WorkerProjectCard";
import { APP_CURRENCY } from "../../constants/App.constants";
import TaskCard from "../../components/TaskCard";
import Loader from "../../components/Loader";
import { getStorageData } from "../../libs/storage";
import {
  USER_PROFILE_ROLE,
} from "../../graphql/user.resolver";
import colors from "../../styles/colors";
//import { useRoute } from "@react-navigation/native";

const workerProfileQuery = gql`
  query userProfile($input: userProfileInput) {
    userProfile(input: $input) {
      first_name
      last_name
      email
      id
      profile {
        id
        hourly_wages
        qualification
        description
        worker_type
        overtimePercent
        areas {
          id
          name
        }
      }
    }
  }
`;

const getWorkerSkills = gql`
  query getWorkerSkillByProfileId($input: getWorkerSkillByProfileIdInput) {
    getWorkerSkillByProfileId(input: $input) {
      skill_id
      worker_id
      worker_skill_id {
        id
        name
      }
    }
  }
`;

const getNonAllocatedSkills = gql`
  query getNotAllocatedSkills($input: getWorkerSkillByProfileIdInput) {
    getNotAllocatedSkills(input: $input) {
      id
      name
    }
  }
`;

const allocateSkillToWorker = gql`
  mutation addSkillToWorker($input: addSkillToWorkerInput) {
    addSkillToWorker(input: $input) {
      status
      message
    }
  }
`;

const removeSkillToWorker = gql`
  mutation removeSkillFromWorker($input: removeSkillFromWorkerInput) {
    removeSkillFromWorker(input: $input) {
      status
      message
    }
  }
`;

const getWorkersProjects = gql`
  query getProjectsByWorkerId($input: getProjectsByWorkerIdInput) {
    getProjectsByWorkerId(input: $input) {
      workers_count
      activities_count
      project_activity_id {
        project_id {
          id
          name
          year
          end_date
        }
      }
    }
  }
`;
const GET_WORKERS_TASKS = gql`
  query getWorkerTasksOfProjectforAdmin(
    $input: getWorkerTasksOfProjectforAdminInput
  ) {
    getWorkerTasksOfProjectforAdmin(input: $input) {
      tasks {
        id
        name
        start_time
        end_time
        created_by
        total_hrs
        client_name
      }
    }
  }
`;

const getLatestSalary = gql`
  query getLatestSalaryByProfileID($input: getLatestSalaryByProfileIDInput) {
    getLatestSalaryByProfileID(input: $input) {
      id
      created_at
      amount
      profile_id
    }
  }
`;

function WorkerDetails({ route, navigation }: any) {
  //const route = useRoute();
  const fromPage = route.params?.route;
  const [Workerid] = useState(route.params.id);
  const [userID] = useState(route.params.workerID);
  useFocusEffect(() => {
    refetchWorkerProfile();
    refetchworkersSkills();
    refetchworkerprojects();
    refetchnonAlocatedSkills();
    refetchTasklist();
    refetchLatestSalary();
  });

  const [isAddSkillModalVisible, setisAddSkillModalVisible] = useState(false);

  const {
    data: workerProfile,
    refetch: refetchWorkerProfile,
    loading: loadingWorkerProfile,
  } = useQuery(workerProfileQuery, {
    variables: { input: { id: Workerid } },
  });
  
  const {
    data: workerSkills,
    refetch: refetchworkersSkills,
    loading: loadingWorkerSkills,
  } = useQuery(getWorkerSkills, {
    variables: { input: { id: workerProfile?.userProfile?.profile?.id } },
  });

  const {
    data: workerprojects,
    refetch: refetchworkerprojects,
    loading: loadingWokerProjects,
  } = useQuery(getWorkersProjects, {
    variables: {
      input: { worker_id: workerProfile?.userProfile?.profile?.id },
    },
  });

  const {
    data: latestSalaryData,
    refetch: refetchLatestSalary,
    loading: latestSalaryLoading,
  } = useQuery(getLatestSalary, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        profile_id: workerProfile?.userProfile?.profile?.id,
      },
    },
  });

  const {
    data: tasklist,
    refetch: refetchTasklist,
    loading: loadingTaskList,
  } = useQuery(GET_WORKERS_TASKS, {
    fetchPolicy: "network-only",
    variables: {
      input: { worker_id: workerProfile?.userProfile?.profile?.id },
    },
  });

  const { data: nonAlocatedSkills, refetch: refetchnonAlocatedSkills } =
    useQuery(getNonAllocatedSkills, {
      variables: { input: { id: workerProfile?.userProfile?.profile?.id } },
    });

  const [linkSkillToWorker, { data, loading, error }] = useMutation(
    allocateSkillToWorker
  );

  const [unlinkSkillToWorker, { data: removeDataSkill, loading: loadingDeleteSkill, error: errorDeleteSkill }] = useMutation(
    removeSkillToWorker
  );

  const [role, setRole] = useState(null);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  const handleDeleteSkill = async (workerSkillId, workerID) => {
    await unlinkSkillToWorker({
      variables:
      {
        input: {
          worker_id: workerID,
          skill_id: workerSkillId
        }
      },
    })
      .then((response) => {
        console.log('Skill deleted', response);
      })
      .catch((error) => {
        console.error('Error deleting skill', error);
      });
  };

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  const userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  const workerAreas = workerProfile?.userProfile?.profile?.areas || [];

  const userAreaIds = userAreas.map(area => area.id);
  const workerAreaIds = workerAreas.map(area => area.id);

  const isAreaManager = () => {
    return userAreaIds.some(areaId => workerAreaIds.includes(areaId));
  };

  return (
    <>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: colors.background }}>
        <Loader
          loading={
            loadingWorkerProfile ||
            loadingWorkerSkills ||
            loadingWokerProjects ||
            loadingTaskList
          }
        />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <Text
                variant="displayMedium"
                className="text-white pl-2 w-full text-4xl font-semibold"
              >
                {workerProfile?.userProfile?.first_name}{" "}
                {workerProfile?.userProfile?.last_name}
              </Text>
              {role === "ADMIN" || isAreaManager() ? (
                fromPage === "workerCard" ? (
                  <Button
                    icon="account-edit"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor="white"
                    onPress={() =>
                      navigation.push("EditWorkerDetails", {
                        workerId: Workerid,
                        userID: userID,
                      })
                    }
                    style={{ borderRadius: 10, marginRight: 20 }}
                    className="flex flex-col justify-end my-2"
                  >
                    <Text className="text-white font-bold">Modifica Lavoratore</Text>
                  </Button>
                ) : null
              ) : null}
            </View>

            <Text
              variant="titleMedium"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {workerProfile?.userProfile?.profile?.qualification} -{" "}
              {workerProfile?.userProfile?.profile?.hourly_wages}
              {APP_CURRENCY}/Ora
            </Text>

            <Text
              variant="titleSmall"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {workerProfile?.userProfile?.profile?.description}
            </Text>
            <Text
              variant="titleSmall"
              className="text-white pl-2 pb-4 font-semibold"
            >
              Tipologia Lavoratore: {workerProfile?.userProfile?.profile?.worker_type}
            </Text>
            {workerProfile?.userProfile?.profile?.worker_type === "FREELANCE" && (
              <Text
                variant="titleSmall"
                className="text-white pl-2 pb-4 font-semibold"
              >
                Percentuale di Overtime: {workerProfile?.userProfile?.profile?.overtimePercent}
              </Text>
            )}
            <View className="flex flex-row w-full p-2">
              <Text
                variant="displaySmall"
                className="text-white font-semibold w-full"
              >
                Skills
              </Text>

              {role === "ADMIN" || role === "AREA_MANAGER" ? (
                <View>
                  <Button
                    icon="plus"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor="white"
                    onPress={() => setisAddSkillModalVisible(true)}
                    className="flex flex-col justify-end my-2"
                    style={{ borderRadius: 10, marginRight: 10 }}
                  >
                    <Text className="text-white font-bold">
                      Aggiungi Skill
                    </Text>
                  </Button>
                </View>
              ) : null}

              <Portal>
                <Dialog
                  visible={isAddSkillModalVisible}
                  onDismiss={() => setisAddSkillModalVisible(false)}
                  style={{
                    height: 720,
                    width: 360,
                    padding: 2,
                    alignSelf: "center",
                    backgroundColor: "#2a2c38",
                  }}
                >
                  <Dialog.ScrollArea>
                    <ScrollView showsHorizontalScrollIndicator={false}>
                      <View className="flex flex-col w-full gap-2 items-start">
                        {nonAlocatedSkills &&
                          nonAlocatedSkills?.getNotAllocatedSkills &&
                          nonAlocatedSkills?.getNotAllocatedSkills?.map(
                            (data, idk) => (
                              <Button
                                key={idk}
                                mode="contained"
                                onPress={async () => {
                                  await linkSkillToWorker({
                                    variables: {
                                      input: {
                                        worker_id:
                                          workerProfile?.userProfile?.profile
                                            ?.id,
                                        skill_id: data.id,
                                      },
                                    },
                                  });
                                  setisAddSkillModalVisible(false);
                                  refetchworkersSkills();
                                  refetchnonAlocatedSkills();
                                }}
                                style={{
                                  backgroundColor: colors.amaranto,
                                  borderRadius: 10,
                                }}
                                className="w-full border-b border-secondary items-start"
                              >
                                <Text
                                  variant="titleSmall"
                                  className="text-white pb-4 font-semibold"
                                >
                                  {data?.name}
                                </Text>
                              </Button>
                            )
                          )}
                      </View>
                    </ScrollView>
                  </Dialog.ScrollArea>
                </Dialog>
              </Portal>
            </View>

            <View className="flex flex-col w-full gap-2 mb-4">
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={true}
                className="flex flex-row gap-2 p-2"
              >
                {workerSkills?.getWorkerSkillByProfileId?.map((skill, idk) => (
                  <View key={idk} className="flex flex-row items-center">
                    <Button
                      mode="contained-tonal"
                      onPress={() => console.log("Skill Pressed", skill?.worker_skill_id?.name)}
                      buttonColor={colors.amaranto}
                      style={{
                        borderRadius: 10,
                      }}
                      className="w-fit"
                    >
                      <Text
                        variant="titleSmall"
                        className="text-white pl-2 pb-4 font-semibold"
                      >
                        {skill?.worker_skill_id?.name}
                      </Text>
                      {role === "ADMIN" || role === "AREA_MANAGER" ? (
                        <View style={{ justifyContent: "flex-end" }}>
                          <IconButton
                            icon="trash-can-outline"
                            //containerColor="#f50c2f"
                            style={{
                              width: 26, // Larghezza fissa
                              height: 23, // Altezza fissa
                              justifyContent: 'center', // Centra la 'X' orizzontalmente
                              alignItems: 'center', // Centra la 'X' verticalmente
                              //backgroundColor: 'red', // Sfondo rosso per il tasto di eliminazione
                              marginLeft: 8, // Aggiungi un margine a sinistra per spaziatura
                            }}
                            iconColor={"white"}
                            size={20}
                            onPress={() => handleDeleteSkill(skill?.worker_skill_id?.id, skill?.worker_id)}
                          />
                        </View>
                      ) : null}
                    </Button>

                  </View>
                ))}
              </ScrollView>
            </View>

            <View className="flex flex-col p-2">
              <Text
                variant="displaySmall"
                className="text-white pb-4 font-semibold "
              >
                Progetti
              </Text>
              <View>
                {workerprojects?.getProjectsByWorkerId?.length ? (
                  <ScrollView showsVerticalScrollIndicator={false}>
                    {workerprojects?.getProjectsByWorkerId.map((data, idk) => (
                      <WorkerProjectCard
                        key={idk}
                        data={data}
                        navigation={navigation}
                      />
                    ))}
                  </ScrollView>
                ) : (
                  <Text className="text-xl font-bold text-white pl-1">
                    Non ci sono Progetti disponibili*
                  </Text>
                )}
              </View>
            </View>

            <View className="flex flex-col p-2">
              <Text
                variant="displaySmall"
                className="text-white pb-4 font-semibold "
              >
                Tasks timeline
              </Text>
              <View>
                {tasklist?.getWorkerTasksOfProjectforAdmin?.tasks?.length ? (
                  <ScrollView showsVerticalScrollIndicator={false}>
                    {tasklist.getWorkerTasksOfProjectforAdmin.tasks.map(
                      (data, idk) => {
                        return (
                          <TaskCard
                            data={data}
                            key={idk}
                            navigation={navigation}
                            refetchTasklist={refetchTasklist}
                          />
                        );
                      }
                    )}
                  </ScrollView>
                ) : (
                  <Text className="text-xl font-bold text-white pl-1">
                    Non ci sono Task disponibili*
                  </Text>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkerDetails;
