import React, { useState } from "react";
import { ScrollView, View, StyleSheet, TouchableOpacity } from "react-native";
import { Button, IconButton, Text } from "react-native-paper";
import { SpendingMode } from "../constants/static-data";
import colors from "../styles/colors";

function WorkerDataSection({ data, role, workerType, lastUpdateData }) {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const getExpenditureType = (ExpenditureMethode): string => {
    let methode = "Not Defined";
    SpendingMode.forEach((element) => {
      if (element.value == ExpenditureMethode) {
        methode = element.label;
      }
    });
    return methode;
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    headerRow: {
      flexDirection: 'row',
      backgroundColor: colors.grey,
      padding: 5,
      marginBottom: 3,
      borderWidth: 3,
      borderColor: "grey",
      borderRadius: 2,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: 'grey',
      padding: 3,
      marginLeft: 3,
    },
    cell: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 300,
    },
    lastCell: {
      padding: 2,
      borderColor: "grey",
      width: 300,
    },
    cellNumber: {
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 200,
    },
    lastCellNumber: {
      padding: 2,
      borderColor: "grey",
      width: 200,
    },
    headerText: {
      fontWeight: 'bold',
      color: colors.white,
      fontSize: 22,
      textAlign: 'center',
    },
    cellText: {
      color: colors.white,
      fontSize: 18,
      textAlign: 'center',
    },
    cellTextButton: {
      color: colors.white,
      fontSize: 18,
      alignContent: "center",
    },
    content: {
      flex: 1,
    },
    actionButton: {
      justifyContent: "center",
      alignItems: 'center',
    },
    button: {
      borderColor: colors.white,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderTopLeftRadius: 10,
      backgroundColor: colors.amaranto,
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
  });

  return (
    <>
      <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderWidth: 1, borderRadius: 10, borderColor: colors.white }}>
        <View className="flex flex-row w-full">
          <View style={styles.button}>
            <Button
              //mode={activeTab === 1 ? "contained" : "outlined"}
              buttonColor={colors.amaranto}
              onPress={() => handleTabClick(1)}
              style={{ borderTopLeftRadius: 10, backgroundColor: colors.amaranto }}
            >
              <Text style={styles.buttonText}>Dettagli progetti</Text>
            </Button>
          </View>
          <Button
            // mode={activeTab === 2 ? "contained" : "outlined"}
            buttonColor={colors.amaranto}
            style={{ borderRadius: 0, backgroundColor: colors.amaranto, borderColor: colors.white, borderBottomWidth: 1, borderRightWidth: 1 }}
            onPress={() => handleTabClick(2)}
          >
            <Text style={styles.buttonText}>Spese risorsa</Text>
          </Button>
        </View>
        <View className="flex flex-col w-full p-2 ">
          <View className="flex flex-col w-full h-96 my-2 overflow-scroll">
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              {activeTab == 1 ? (
                <>
                  <View style={styles.container}>
                    <View style={styles.headerRow}>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Codice commessa</Text></View>
                      <View style={styles.cell}><Text style={styles.headerText}>Cliente</Text></View>
                      <View style={styles.cell}><Text style={styles.headerText}>Progetto</Text></View>
                      <View style={styles.cell}><Text style={styles.headerText}>Nome task</Text></View>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Data task</Text></View>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Data inizio task</Text></View>
                      <View style={[styles.cell]}><Text style={styles.headerText}>Data fine task</Text></View>
                      <View style={[styles.cellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore ordinarie</Text></View>
                      {workerType == "FREELANCE" ? (<View style={[styles.lastCellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore non ordinarie</Text></View>) : null}
                      {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore straordinarie al 15%</Text></View>)}
                      {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore straordinarie al 20%</Text></View>)}
                      {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore straordinarie al 30%</Text></View>)}
                      {workerType == "EMPLOYEE" && (<View style={[styles.lastCellNumber]}><Text style={[styles.headerText, { fontSize: 20 }]}>Ore straordinarie al 50%</Text></View>)}
                    </View>

                    <ScrollView style={styles.content}>
                      {data?.tasks &&
                        data?.tasks?.sort((a, b) => {
                          // Ordina in base alla data di inizio (start_time)
                          return parseInt(a.start_time) - parseInt(b.start_time);
                        })?.map((task) => {
                          const isNew = lastUpdateData && new Date(parseInt(task?.created_at)) > new Date(lastUpdateData.getLastUpdate.lastUpdate);
                          const textColor = isNew ? 'yellow' : 'white'; // Usa il colore giallo se isNew è true, altrimenti usa il colore predefinito
                          return (
                            <View key={task.id} style={styles.row}>
                              <View style={[styles.cell]}><Text style={[styles.cellText, { color: textColor }]}>{task?.projectId}</Text></View>
                              <View style={styles.cell}><Text style={[styles.cellText, { color: textColor }]}>{task?.client}</Text></View>
                              <View style={styles.cell}><Text style={[styles.cellText, { color: textColor }]}>{task?.project_name}</Text></View>
                              <View style={styles.cell}><Text style={[styles.cellText, { color: textColor }]}>{task?.name}</Text></View>
                              <View style={[styles.cell]}>
                                <Text style={[styles.cellText, { color: textColor }]}>{`${new Date(
                                  parseInt(task?.created_at)
                                ).toLocaleDateString("en-GB")} ${new Date(
                                  parseInt(task?.created_at)
                                ).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`}</Text>
                              </View>
                              <View style={[styles.cell]}>
                                <Text style={[styles.cellText, { color: textColor }]}>{`${new Date(
                                  parseInt(task?.start_time)
                                ).toLocaleDateString("en-GB")} ${new Date(
                                  parseInt(task?.start_time)
                                ).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`}</Text>
                              </View>
                              <View style={[styles.cell]}>
                                <Text style={[styles.cellText, { color: textColor }]}>{`${new Date(
                                  parseInt(task?.end_time)
                                ).toLocaleDateString("en-GB")} ${new Date(
                                  parseInt(task?.end_time)
                                ).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`}</Text>
                              </View>
                              <View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.ordinary_hours).toFixed(2)}</Text></View>
                              {workerType == "FREELANCE" ? (<View style={[styles.lastCellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.overtime_hours).toFixed(2)}</Text></View>) : null}
                              {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.hours_overtime_al_15).toFixed(2)}</Text></View>)}
                              {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.hours_overtime_al_20).toFixed(2)}</Text></View>)}
                              {workerType == "EMPLOYEE" && (<View style={[styles.cellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.hours_overtime_al_30).toFixed(2)}</Text></View>)}
                              {workerType == "EMPLOYEE" && (<View style={[styles.lastCellNumber]}><Text style={[styles.cellText, { color: textColor }]}>{parseFloat(task?.hours_overtime_al_50).toFixed(2)}</Text></View>)}
                            </View>
                          );
                        })}
                    </ScrollView>
                  </View>
                </>
              ) : null}
            </ScrollView>
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              {activeTab == 2 ? (
                <View style={styles.container}>
                  <View style={styles.headerRow}>
                    <View style={[styles.cell]}><Text style={styles.headerText}>Codice commessa</Text></View>
                    <View style={[styles.cell]}><Text style={styles.headerText}>Cliente</Text></View>
                    <View style={[styles.cell]}><Text style={styles.headerText}>Lavoratore</Text></View>
                    <View style={styles.cell}><Text style={styles.headerText}>Città</Text></View>
                    <View style={styles.cell}><Text style={styles.headerText}>Data spesa</Text></View>
                    <View style={styles.cell}><Text style={styles.headerText}>Tipologia spesa</Text></View>
                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Km percorsi</Text></View>
                    <View style={[styles.cell]}><Text style={styles.headerText}>Descrizione</Text></View>
                    <View style={[styles.cellNumber]}><Text style={styles.headerText}>Importo</Text></View>
                    <View style={[styles.cell]}><Text style={styles.headerText}>Metodo di pagamento</Text></View>
                    <View style={[styles.lastCellNumber]}><Text style={styles.headerText}>Allegato</Text></View>
                  </View>

                  <ScrollView style={styles.content}>
                    {data?.receipts &&
                      data?.receipts.sort((a, b) => {
                        // Ordina in base alla data del ricevimento (receipt_date)
                        return parseInt(a.receipt_date) - parseInt(b.receipt_date);
                      })
                        .map((receipt) => (
                          <View key={receipt.id} style={styles.row}>
                            <View style={[styles.cell]}><Text style={styles.cellText}>{receipt?.project_id}</Text></View>
                            <View style={[styles.cell]}><Text style={styles.cellText}>{receipt?.client}</Text></View>
                            <View style={[styles.cell]}><Text style={styles.cellText}>{receipt?.created_by}</Text></View>
                            <View style={styles.cell}><Text style={styles.cellText}>{receipt?.receipt_city
                              ? receipt?.receipt_city
                              : "City not selected"}</Text>
                            </View>
                            <View style={styles.cell}><Text style={styles.cellText}>{`${new Date(
                              parseInt(receipt?.receipt_date)
                            ).toLocaleDateString("en-GB")}`}</Text></View>
                            <View style={styles.cell}><Text style={styles.cellText}>{receipt?.receipt_type}</Text></View>
                            <View style={[styles.cellNumber]}><Text style={styles.cellText}>{receipt.km_routes ? receipt.km_routes : "N/A"}</Text></View>
                            <View style={[styles.cell]}><Text style={styles.cellText}>{receipt?.details}</Text></View>
                            <View style={[styles.cellNumber]}><Text style={styles.cellText}>{receipt?.receipt_value}</Text></View>
                            <View style={[styles.cell]}><Text style={styles.cellText}>{getExpenditureType(
                              receipt?.receipt_expenditure_method
                            )}</Text></View>
                            <View style={[styles.lastCellNumber, styles.actionButton]}>{receipt?.fileUrl && (
                              <IconButton
                                icon="download-box-outline"
                                iconColor={"#3b8af7"}
                                size={40}
                                onPress={() => window.open(receipt?.fileUrl, "_blank")}
                              />)}
                            </View>
                          </View>
                        ))}
                  </ScrollView>
                </View>
              ) : null}
            </ScrollView>
          </View>
        </View >

        {activeTab == 1 ? (
          <View className="flex flex-row w-full mb-2 p-2">
            <View className=" mx-4">
              <Text variant="headlineSmall" className="text-white">
                Tempo totale task : {data?.totalTaskTime}
              </Text>
            </View>
          </View>
        ) : null
        }
        {
          activeTab == 2 ? (
            <View className="flex flex-row w-full mb-2 p-2">
              <View className=" mx-4">
                <Text variant="headlineSmall" className="text-white">
                  Costo totale spese risorsa : {data?.totalValueOfReceipt}
                </Text>
              </View>
            </View>
          ) : null
        }
      </View >
    </>
  );
}

export default WorkerDataSection;
