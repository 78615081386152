import React from "react";
import { Pressable, View } from "react-native";
import { Text } from "react-native-paper";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import { AWS_FILE_URL } from "../constants/App.constants";

function PdfBadge(props: any) {
  const downloadFile = (filename) => {
    window.open(`${AWS_FILE_URL}${filename}`, "_blank");
  };

  return (
    <>
      <View className="flex flex-row w-fit mr-2 p-2">
        <View className="w-full">
          <Pressable onPress={() => downloadFile(props?.data?.file_name)}>
            <View className="flex flex-col w-full items-center justify-center text-center gap-2">
              <Icon name={"file-pdf-o"} size={50} color={"#ffffff"} />

              <View className="flex flex-col w-20">
                <Text
                  variant="labelMedium"
                  className="text-white text-center font-semibold"
                >
                  {props?.isexpense
                    ? props?.data?.title
                    : props?.data?.file_name}
                </Text>
              </View>
            </View>
          </Pressable>
        </View>
      </View>
    </>
  );
}

export default PdfBadge;
