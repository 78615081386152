import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import Loader from "../../components/Loader";
import { Button, Text, TextInput, IconButton } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import WorkerAnalysisCharts from "../../components/WorkerAnalysisCharts";
import WorkerDataSection from "../../components/WorkerDataSection";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import WokerDataSectionTwo from "../../components/WokerDataSectionTwo";
import { APP_CURRENCY, PERCENTAGE } from "../../constants/App.constants";
import { use } from "i18next";
import colors from "../../styles/colors";
import { list } from "postcss";

const GET_DASHBOARD_DATA = gql`
  query workerDashboard($input: workerDashboardInput) {
    workerDashboard(input: $input) {
      hourly_wages
      qualification
      worker_name
      worker_type
      totalTaskTime
      totalValueOfReceipt
      tasks {
        id
        name
        client
        project_name
        worker_name
        created_at
        created_by
        start_time
        end_time
        overtime_hours
        ordinary_hours
        totalCost
        projectId
        hours_overtime_al_15
        hours_overtime_al_20
        hours_overtime_al_30
        hours_overtime_al_50
      }
      receipts {
        receipt_title
        receipt_value
        receipt_date
        fileUrl
        id
        created_by
        receipt_city
        receipt_expenditure_method
        details
        client
        km_routes
        project_id
        receipt_type
      }
      overview {
        allTasksOvertimeTotal
        totalActualWorkingHours
        allTasksOvertime {
          overtimePercent
          overtimeHours
        }
        projectGroupByHours {
          project_name
          total_hours
          project_id
        }
      }
      project_details {
        client
        ordinary_hours
        project_name
        overtime_hours
        totalCost
        totalCostOvertime
        totalCostOrdinary
        project_id
      }
    }
  }
`;

const GET_ALL_WORKERS = gql`
  query getAllWorkers {
    getAllWorkers {
      id
      user {
        first_name
        last_name
        id
      }
    }
  }
`;

const EXPORT_DATA_TO_CSV = gql`
  query generateWorkerCSV($input: generateWorkerCSVInput) {
    generateWorkerCSV(input: $input) {
      fileName
      fileUrl
    }
  }
`;

const workerProfileQuery = gql`
  query userProfile($input: userProfileInput) {
    userProfile(input: $input) {
      first_name
      last_name
      email
      id
      profile {
        id
        hourly_wages
        qualification
        description
        worker_type
      }
    }
  }
`;

const getLatestSalary = gql`
  query getLatestSalaryByProfileID($input: getLatestSalaryByProfileIDInput) {
    getLatestSalaryByProfileID(input: $input) {
      id
      created_at
      amount
      profile_id
    }
  }
`;

const getLastUpdate = gql`
  query getLastUpdate {
    getLastUpdate {
      lastUpdate
    }
  }
`;

function WorkerDashboard({ route }) {
  const [selectedWorkerId, setSelectedWorkerId] = useState(null);
  const [formatedWorkerList, setFormatedWorkerList] = useState([]);
  const [showWorkerDropdown, setShowWorkerDropdown] = useState(false);
  const [formatedUserList, setFormatedUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedWorkerLabel, setSelectedWorkerLabel] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [timerId, setTimerId] = useState(null);

  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [open, setOpen] = useState(false);

  const {
    data: dashboardData,
    refetch: refetchDashboard,
    loading: dashboardDataLoading,
  } = useQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        fromDate: range.startDate,
        toDate: range.endDate,
        profile_id: selectedWorkerId,
      },
    },
  });

  const [
    getWorkerList,
    {
      data: workerListData,
      refetch: refetchWorkerListData,
      loading: workerListDataLoading,
    },
  ] = useLazyQuery(GET_ALL_WORKERS);

  const [getLastUpdateData, { data: lastUpdateData, loading: lastUpdateLoading }] = useLazyQuery(getLastUpdate);

  useEffect(() => {
    getLastUpdateData();
  }, [getLastUpdateData]);

  useEffect(() => {
    let FORMATED_WORKER_LIST = [];
    if (workerListData?.getAllWorkers) {
      FORMATED_WORKER_LIST = workerListData?.getAllWorkers?.map((worker) => {
        return {
          label: `${worker?.user?.first_name} ${worker?.user?.last_name} `,
          value: worker?.id,
        };
      }).sort((a, b) => a.label.localeCompare(b.label));
    }
    setFormatedWorkerList(FORMATED_WORKER_LIST);
  }, [workerListData]);

  useEffect(() => {
    if (selectedWorkerId && formatedWorkerList.length > 0) {
      const selectedWorker = formatedWorkerList.find(worker => worker.value === selectedWorkerId);

      if (selectedWorker) {
        setSelectedWorkerLabel(selectedWorker.label);
      }
    }
  }, [selectedWorkerId, formatedWorkerList]);

  useEffect(() => {
    let FORMATED_USER_LIST = [];
    if (workerListData?.getAllWorkers) {
      FORMATED_USER_LIST = workerListData?.getAllWorkers.map((worker) => {
        return {
          label: `${worker?.user?.first_name} ${worker?.user?.last_name} `,
          value: worker?.user?.id,
        };
      });
    }
    setFormatedUserList(FORMATED_USER_LIST);
  }, [workerListData]);

  useEffect(() => {
    const selectedUser = formatedUserList.find(user => user.label === selectedWorkerLabel);

    if (selectedUser) {
      setSelectedUserId(selectedUser.value);
    }
  }, [selectedWorkerLabel, formatedUserList]);

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
  };

  useFocusEffect(
    React.useCallback(() => {
      //if (route?.params?.role == "admin") {
      getWorkerList();
      //}
      refetchDashboard();
    }, [])
  );

  useEffect(() => {
    refetchDashboard();
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardData?.workerDashboard?.worker_name && workerListData?.getAllWorkers) {
      const fullName = dashboardData.workerDashboard.worker_name;
      const matchingWorker = workerListData.getAllWorkers.find(worker => {
        const workerFullName = `${worker.user.first_name} ${worker.user.last_name}`;
        return workerFullName === fullName;
      });

      if (matchingWorker) {
        setSelectedUserId(matchingWorker.id);
      }
    }
  }, [dashboardData, workerListData]);

  const [
    getFile,
    { data: file, loading: fileLoading, error: fileDownloadError },
  ] = useLazyQuery(EXPORT_DATA_TO_CSV, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        fromDate: range.startDate,
        toDate: range.endDate,
        profile_id: selectedUserId,
      },
    },
  });

  const exportDataToCsv = async () => {
    const filedata = await getFile();
    window.open(filedata?.data?.generateWorkerCSV?.fileUrl, "_blank");
  };

  const {
    data: workerProfile,
    refetch: refetchWorkerProfile,
    loading: loadingWorkerProfile,
  } = useQuery(workerProfileQuery, {
    variables: { input: { id: selectedUserId } },
  });

  useFocusEffect(() => {
    refetchWorkerProfile();
    refetchWorkerListData();
  });

  const {
    data: latestSalaryData,
    refetch: refetchLatestSalary,
    loading: latestSalaryLoading,
  } = useQuery(getLatestSalary, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        profile_id: selectedWorkerId,
      },
    },
  });

  useEffect(() => {
    const filtered = formatedWorkerList.filter((project) =>
      project.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredWorkers(filtered);
  }, [searchQuery, formatedWorkerList]);

  const handleSearchChange = (text) => {
    setSearchQuery(text);

    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => {
      if (text) {
        setShowWorkerDropdown(true);
      }
    }, 1000);

    setTimerId(newTimerId);
  };

  const handleDismiss = () => {
    setShowWorkerDropdown(false);
  };

  useEffect(() => {
    // Funzione di pulizia che viene eseguita quando il componente viene smontato
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  const isDataOutdated = dashboardData?.workerDashboard?.tasks?.some(
    (task) => lastUpdateData && new Date(parseInt(task.created_at)) > new Date(lastUpdateData.getLastUpdate.lastUpdate)
  );

  return (
    <>
      <View className="flex flex-auto h-screen p-2" style={{ backgroundColor: colors.background }}>
        <Loader loading={dashboardDataLoading || workerListDataLoading} />
        <Loader loading={loadingWorkerProfile} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-row w-full mt-6">
            <Text
              variant="displayMedium"
              className="text-white my-4 w-full font-semibold"
            >
              {"Workers Dashboard"}
            </Text>
            {route?.params?.role == "admin" ? (
              <View>
                <Dropdown
                  style={{
                    backgroundColor: colors.background,
                    borderTopStartRadius: 10,
                    borderTopEndRadius: 10,
                    borderWidth: 2,
                    borderColor: colors.grey,
                    padding: 15,
                    paddingHorizontal: 30,
                    width: '100%',
                  }}
                  containerStyle={{ backgroundColor: colors.background, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                  data={formatedWorkerList}
                  search
                  searchPlaceholder="Trova lavoratore..."
                  labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                  valueField="value" // Definisce il campo del valore per gli elementi della lista
                  value={selectedWorkerId}
                  placeholder='Seleziona Lavoratore...'
                  onChange={(item) => setSelectedWorkerId(item.value)}
                  selectedTextStyle={{ color: colors.white }}
                  placeholderStyle={{ color: colors.white }}
                  itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, }}
                  inputSearchStyle={{ backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                    overflow: 'hidden',  }}

                  renderItem={(item) => (
                    <Text style={{ color: colors.white, padding: 10 }}>
                      {item.label}
                    </Text>
                  )}
                />
              </View>
            ) : null}
          </View>

          {route?.params?.role == "admin" && !selectedWorkerId ? (
            <View className="flex items-center justify-center h-full w-full">
              <Text
                variant="displayMedium"
                className="text-white w-full text-center font-semibold"
              >
                Seleziona lavoratore
              </Text>
            </View>
          ) : (
            <>
              <View className="flex flex-row w-full my-6">
                <Text
                  variant="headlineMedium"
                  className="text-white my-4 w-full font-bold"
                >
                  {dashboardData?.workerDashboard?.worker_name}
                </Text>

                <Button
                  icon="file-move-outline"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  onPress={exportDataToCsv}
                  className="items-center justify-center my-2 mr-4"
                  loading={fileLoading}
                  style={{ borderRadius: 10, height: 45, marginTop: 11 }}
                >
                  <Text className="text-white font-bold">Esporta in CSV</Text>
                </Button>

                <View className="items-center text-center flex-row justify-center">
                  <Text
                    variant="headlineSmall"
                    className="text-white font-semibold"
                  >
                    Intervallo:{" "}
                  </Text>

                  <Button
                    onPress={() => setOpen(true)}
                    uppercase={false}
                    mode="outlined"
                    textColor="white"
                    style={{ borderRadius: 4, borderWidth: 2, borderColor: colors.grey }}
                  >
                    {range.startDate && range.endDate ? (
                      <Text
                        variant="labelLarge"
                        className="text-white w-full font-semibold"
                      >
                        {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                          }`}
                        {` - `}
                        {`${new Date(range?.endDate).getDate()}/${new Date(range?.endDate).getMonth() + 1
                          }`}
                      </Text>
                    ) : null}

                    {range.startDate && !range.endDate ? (
                      <Text
                        variant="labelLarge"
                        className="text-white w-full font-semibold"
                      >
                        {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                          }`}
                      </Text>
                    ) : null}

                    {!range.startDate && !range.endDate ? (
                      <Text className="text-white w-full font-semibold">
                        Seleziona intervallo
                      </Text>
                    ) : null}
                  </Button>

                  {(range.startDate || range.endDate) && (
                    <IconButton
                      icon="undo-variant"
                      mode="contained"
                      iconColor={"black"}
                      containerColor={"white"}
                      size={16}
                      onPress={() =>
                        setRange({
                          startDate: null,
                          endDate: null,
                        })
                      }
                      className="ml-4"
                    />
                  )}

                  <DatePickerModal
                    locale="en"
                    mode="range"
                    visible={open}
                    onDismiss={onDismiss}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    onConfirm={onConfirm}
                    animationType="fade"
                  />
                </View>
              </View>
              <View className="flex flex-col w-full gap-2 mb-4">
                <View>
                  <Text
                    variant="headlineSmall"
                    className="text-white mb-4 font-bold"
                  >
                    Qualifica :{" "}
                    {dashboardData?.workerDashboard?.qualification}
                  </Text>
                </View>
              </View>
              <View className="flex flex-col w-full gap-2 mb-4">
                <View>
                  <Text
                    variant="headlineSmall"
                    className="text-white mb-4 font-bold"
                  >
                    Salario orario :{" "}
                    {latestSalaryData?.getLatestSalaryByProfileID?.amount || dashboardData?.workerDashboard?.hourly_wages}
                    {APP_CURRENCY}/Ora
                  </Text>
                </View>
              </View>
              <WorkerAnalysisCharts
                data={dashboardData?.workerDashboard?.overview}
                tasktime={dashboardData?.workerDashboard?.totalTaskTime}
                workerType={dashboardData?.workerDashboard?.worker_type}
              />
              <WorkerDataSection
                data={dashboardData?.workerDashboard}
                role={route?.params?.role}
                workerType={dashboardData?.workerDashboard?.worker_type}
                lastUpdateData={lastUpdateData}
              />
              <WokerDataSectionTwo
                data={dashboardData?.workerDashboard}
                refetchDashboard={refetchDashboard}
                lastUpdateData={lastUpdateData}
              />
            </>
          )}
        </ScrollView>

        {selectedWorkerId || selectedUserId ? (
          <View style={{ padding: 10 }}>
            <Text>
              Ultimo aggiornamento :{" "}
              {lastUpdateData ? new Date(lastUpdateData.getLastUpdate.lastUpdate).toLocaleString('it-IT', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              }) : "No data available"}
            </Text>
            {isDataOutdated && (
              <View style={{ paddingTop: 10 }}>
                <Text style={{ color: 'yellow' }}>
                  Attenzione: Non tutti i dati sono aggiornati
                </Text>
              </View>
            )}
          </View>
        ) : null}
      </View>
    </>
  );
}

export default WorkerDashboard;
