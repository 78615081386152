import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { Provider, configureFonts, DefaultTheme } from "react-native-paper";
import { NativeWindStyleSheet } from "nativewind";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { setContext } from "@apollo/client/link/context";
import Login from "./src/views/login";
import { en, registerTranslation } from "react-native-paper-dates";
import { getStorageData } from "./src/libs/storage";
import { USER_TOKEN_KEY } from "./src/constants/App.constants";
import ManagersLayout from "./src/views/layouts/ManagersLayout";
import WorkersLayout from "./src/views/layouts/WorkersLayout";
import { ReceiptProvider } from './src/components/ReceiptContext';
import { DisclaimerProvider } from './src/components/disclaimerContext';
import Disclaimer from './src/components/disclaimer';
import ForgotPassword from "./src/components/ForgotPassword";
import ChangePassword from "./src/components/changePassword";
import { PasswordExpiryProvider } from './src/components/PasswordExpiryContext';
import PasswordExpiryDiscalimer from './src/components/PasswordExpiryDiscalimer';
import { AuthProvider } from './src/views/AuthContext';
import React, { useEffect, useState } from "react";
import * as Font from "expo-font";
import "./App.css";
import "./src/locales/index"
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import colors from "./src/styles/colors";
registerTranslation("en", en);

const authLink = setContext(async (_, { headers }) => {
  const token = await getStorageData(USER_TOKEN_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// PROD URL: https://api.reportone.it/
// tunnel url : http://trackapi.loclx.io
// local IP : http://localhost:4000/

const httpLink = createHttpLink({
  uri: "https://api.reportone.it/",
});

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        getUserProfile: {
          merge(existing = {}, incoming) {
            return { ...existing, ...incoming, role: incoming.role, profile_id: incoming.profile_id, email: incoming.email };
          },
        },
        // getUserProfileRole: {
        //   merge(existing = {}, incoming) {
        //     return { ...existing, ...incoming, role: incoming.role, profile_id: incoming.profile_id, email: incoming.email };
        //   },
        // },
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

const Stack = createNativeStackNavigator();

NativeWindStyleSheet.setOutput({
  default: "native",
});

function animationChange() {
  console.log("animated");
}
const config = {
  screens: {
    ManagersDashboard: "admin",
  },
};

const linking = {
  prefixes: [],
  config,
};

export default function App() {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  const customFonts = {
    Lato: require("./Public/font/Lato-Regular.ttf"),
    Montserrat: require("./Public/font/Montserrat-Bold.ttf"),
    Verdana: require("./Public/font/verdana.ttf"),
    Roboto: require("./Public/font/Roboto-Bold.ttf"),
  };

  const theme = {
    ...DefaultTheme,
    fonts: configureFonts({
      config: {
        fontFamily: "Montserrat",
      },
    }),
  };

  const _loadFontsAsync = async () => {
    await Font.loadAsync(customFonts);
    setIsFontLoaded(true);
  };

  useEffect(() => {
    if (!isFontLoaded) {
      _loadFontsAsync();
    }
  }, [isFontLoaded]);

  const { i18n, t } = useTranslation();
  const setLanguage = async () => {
    await i18n
      .changeLanguage("it")
      .then(() => console.log("language set to italian"))
      .catch((err) => console.log(err));
  };

  return (
    <Provider >
      <ApolloProvider client={client}>
        <AuthProvider>
          <View onLayout={setLanguage} className="flex-1">
            <NavigationContainer linking={linking}>
              <DisclaimerProvider>
                <PasswordExpiryProvider>
                  <ReceiptProvider>
                    <Stack.Navigator
                      screenOptions={{
                        headerStyle: { backgroundColor: colors.background },
                        animation: "none",
                      }}
                      initialRouteName="Login"
                      screenListeners={{ transitionStart: animationChange }}
                    >
                      <Stack.Screen
                        name="Login"
                        component={Login}
                        options={{ headerShown: false }}
                      />
                      <Stack.Screen
                        name="ChangePassword"
                        component={ChangePassword}
                        options={{ headerShown: false }}
                      />
                      <Stack.Screen
                        name="ForgotPassword"
                        component={ForgotPassword}
                        options={{ headerShown: false }}
                      />
                      <Stack.Screen
                        name="ManagersDashboard"
                        component={ManagersLayout}
                        options={{
                          headerShown: false,
                          gestureEnabled: false,
                          animation: "none",
                          animationTypeForReplace: "push",
                        }}
                      />
                      <Stack.Screen
                        name="WorkersDashboard"
                        component={WorkersLayout}
                        options={{ headerShown: false, gestureEnabled: false }}
                      />
                    </Stack.Navigator>
                    <Disclaimer />
                    <PasswordExpiryDiscalimer />
                  </ReceiptProvider>
                </PasswordExpiryProvider>
              </DisclaimerProvider>
            </NavigationContainer>
          </View>
        </AuthProvider>
      </ApolloProvider>
    </Provider >
  );
}