import React from "react";
import { View, Text } from "react-native";
import colors from "../styles/colors";

const ProjectsWorkerCard = ({ data }) => {
  return (
    <View
      className={`w-64 h-fit m-2 p-2 `} style={{backgroundColor: colors.grey, borderColor: colors.white, borderWidth: 1, borderRadius: 10}}
    >
      <View className="w-full h-full flex flex-row mb-2">
        <View className="w-full h-full items-start p-2 gap-2 text-start">
          <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>Nome : {data?.first_name}</Text>
          <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>Cognome : {data?.last_name}</Text>
          <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>Qualifica : {data?.qualification}</Text>
        </View>
      </View>
    </View>
  );
};

export default ProjectsWorkerCard;
