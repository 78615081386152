import { gql, useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { View } from "react-native";
import {
  Badge,
  Button,
  Dialog,
  IconButton,
  Portal,
  Text,
} from "react-native-paper";
import { getStorageData } from "../libs/storage";
import colors from "../styles/colors";

const deleteClientMutation = gql`
  mutation deleteClient($input: deleteClientInput) {
    deleteClient(input: $input) {
      message
      status
    }
  } 
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function ClientCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [role, setRole] = useState(null);
  const handelModalClose = () => setIsDeleteModalVisible(false);

  const getRole = async () => {
    const role = await getStorageData("role");
    setRole(role);
  };

  useEffect(() => {
    getRole();
  }, [props]);

  const [deleteClient, { data, loading, error }] =
    useMutation(deleteClientMutation);

  const navigate = async (e: any) => {
    props.navigation.push("ProjectList", { id: props.data.id });
  };

  const deleteCard = async () => {
    await deleteClient({ variables: { input: { id: props.data.id } } });
    await props.clientListUpdater();
    setIsDeleteModalVisible(false)
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="font-bold" style={{color: colors.white, fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare il Cliente {props?.data?.name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor="white"
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold" style={{fontFamily: "Roboto"}}>
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteCard}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="font-bold" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{borderColor: colors.white, borderRadius: 10, borderWidth: 1, backgroundColor: colors.grey}}>
          <View className="flex flex-col p-2 w-1/3 gap-4">
            <Text className="text-base font-bold" style={{ color: colors.white, fontFamily: "Roboto"}}>
              {props?.data?.name}
            </Text>
            <Text className="text-sm" style={{ color: colors.white, fontFamily: 'Roboto', fontWeight: 'bold'}}>
              Indirizzo : {props?.data?.address}
            </Text>
          </View>

          <View className="flex flex-row items-center justify-center w-1/3 p-2">
            <View className="flex flex-row items-center w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}>
                {props?.data?.projects_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Progetti</Text>
            </View>
            <View className="flex flex-row items-center  w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}>
                {props?.data?.workers_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Lavoratori</Text>
            </View>
          </View>

          <View className="flex flex-row w-2/5 p-4 items-center gap-10 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={colors.white} 
              size={25}
              onPress={navigate}
            />
            {role === "ADMIN" ? (
              <IconButton
                icon="trash-can-outline"
                containerColor={colors.red}
                style={{ borderRadius: 4 }}
                iconColor={colors.white}
                size={25}
                onPress={() => setIsDeleteModalVisible(true)}
              />
            ) : null}
          </View>
        </View>
      </View>
    </>
  );
}

export default ClientCard;
