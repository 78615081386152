import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
import { Button } from "react-native-paper";
import { ResponsivePie } from '@nivo/pie';
import colors from '../styles/colors';

function AnalysisChartTwo({ dashboardData, marginTitle, contractValueTitle }) {

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const [activeTab, setActiveTab] = useState(1);
  const getColorForIndex = (index) => {
    const colors = ["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb", "#97e3d5", "#f94144", "#f3722c", "#f9c74f", "#90be6d"]; // Array di colori
    return colors[index % colors.length]; // Restituisce un colore ciclicamente dall'array
  };

  let MarginData = [];
  let LegendMarginData = [];
  const colorMap = {};

  if (dashboardData?.data) {
    dashboardData?.data?.forEach((element, index) => {
      if (element.margin > 0 && element.project_id != "clpsgs12q000vnx0h8zrsthw0") {
        const color = getColorForIndex(index); // Funzione per determinare un colore basato sull'indice
        colorMap[element.project_id] = color;
        const marginDataEntry = { id: element.project_id, value: element.margin, color: color };
        MarginData.push(marginDataEntry);
        LegendMarginData.push(marginDataEntry);
      }
    });
    LegendMarginData.sort((a, b) => b.value - a.value);
    LegendMarginData = LegendMarginData.slice(0, 10);
  }

  let ContractData = [];
  let LegendContractData = [];

  if (dashboardData?.data) {
    dashboardData?.data?.forEach((element, index) => {
      if (element.margin > 0 && element.project_id != "clpsgs12q000vnx0h8zrsthw0") {
        const color = getColorForIndex(index); // Funzione per determinare un colore basato sull'indice
        colorMap[element.project_id] = color;
        const contractDataEntry = { id: element.project_id, value: element.contract_value, color: color };
        ContractData.push(contractDataEntry);
        LegendContractData.push(contractDataEntry);
      }
    });
    LegendContractData.sort((a, b) => b.value - a.value);
    LegendContractData = LegendContractData.slice(0, 10);
  }

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };


  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderColor: 'white',
      borderWidth: 1,
      backgroundColor: colors.grey, // Fondo scuro per l'intero contenitore
      paddingBottom: 30,
      borderRadius: 10,
      marginTop: 10,
    },
    chartContainer: {
      flexDirection: 'row',
      alignItems: "center",
    },
    chartSection: {
      flex: 1,
    },
    containerText: {
      flexDirection: 'row',
      paddingTop: 20,
      paddingRight: 25,
      alignItems: "center",
      justifyContent: 'center',
    },
    graphContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around', // Distribuisci uniformemente i grafici
      height: 550
    },
    graph: {
      height: 450, // Altezza fissa per il grafico o usa 'flex' se preferisci
      width: '45%', // Assicurati che sia larga quanto il suo contenitore
      flexDirection: 'column',
    },
    button: {
      position: 'absolute',
      backgroundColor: colors.amaranto,
      color: colors.white,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: colors.white,
      shadowOpacity: 0.3,
      shadowRadius: 5,
      elevation: 3,
      width: '10vw',
      top: 0,
      left: 0,
      borderTopLeftRadius: 10,
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    titleContainer: {
      alignItems: 'flex-start',
      paddingTop: 55,
      paddingLeft: 50,
    },
    titleContainer2: {
      // position: 'absolute',
      flexDirection: 'row',
      paddingTop: 20,
      justifyContent: 'space-between', // Distribuisce i figli uniformemente nello spazio disponibile
      width: '67%',
    },
    titleText: {
      color: 'white',
      fontSize: 20,
      fontWeight: 'bold',
      paddingLeft: 20,
    },
    titleValue: {
      color: 'white',
      fontSize: 20,
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: 20,
    },
    chartTitle1: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
      //textAlign: 'center', 
      paddingRight: 140,
    },
    chartTitle2: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
      //textAlign: 'center', 
      paddingLeft: 90,
    },
    textWhiteBold: {
      color: 'white',
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: "center",
      marginLeft: -100,
    },
    textWhiteBold2: {
      color: 'white',
      fontSize: 15,
      fontWeight: 'bold',
      textAlign: "right",
      marginLeft: -300,
      paddingTop: 25,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={styles.button}>
          <Button
            mode={activeTab === 1 ? "contained" : "outlined"}
            buttonColor={colors.amaranto}
            textColor='white'
            onPress={() => handleTabClick(1)}
          >
            <Text style={styles.buttonText}>Overview</Text>
          </Button>
        </View>

        <View style={styles.containerText}>
          <Text style={styles.titleText}>Margine Totale: </Text>
          <Text style={styles.titleValue}>
            {parseFloat(dashboardData?.totalMargin).toFixed(2) || 0}€
          </Text>
          <Text style={styles.titleText}>Fatturato Totale: </Text>
          <Text style={styles.titleValue}>
            {parseFloat(dashboardData?.totalContractValue).toFixed(2) || 0}€
          </Text>
        </View>
        {/* <View style={styles.titleContainer2}>


        </View> */} 

        <View style={styles.graphContainer}>
          <View style={styles.graph}>
            <Text style={styles.textWhiteBold}>Distribuzione del Margine</Text>
            <Text style={styles.textWhiteBold2}>{marginTitle}</Text>
            <ResponsivePie
              data={MarginData}
              margin={{ top: 10, right: 100, bottom: 80, left: -20 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor="#333333"
              radialLabelsLinkColor={{ from: 'color' }}
              sliceLabelsSkipAngle={10}
              sortByValue={true}
              colors={d => colorMap[d.id]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              enableArcLinkLabels={false}
              legends={[
                {
                  data: LegendMarginData.map(item => ({
                    id: item.id, // Assuming this should match the `name` field in your data
                    label: truncateText(item.id, 20), // This will be displayed as the legend label
                    color: colorMap[item.id],
                  })),
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateY: 18,
                  translateX: 20,
                  itemWidth: 60,
                  itemHeight: 18,
                  itemTextColor: '#ffffff',
                  itemsSpacing: 4,
                  symbolSize: 20,
                  symbolShape: 'circle',

                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#fff',
                        itemOpacity: 1,
                      }
                    }
                  ]
                }
              ]}
              theme={{
                background: colors.grey,
                axis: {
                  ticks: {
                    text: {
                      fill: '#ffffff',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  },
                },
                legends: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
                labels: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
              }}
            />
          </View>
          <View style={styles.graph}>
            <Text style={styles.textWhiteBold}>Distribuzione del valore del contratto</Text>
            <Text style={styles.textWhiteBold2}>{contractValueTitle}</Text>
            <ResponsivePie
              data={ContractData}
              margin={{ top: 10, right: 100, bottom: 80, left: -20 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              sortByValue={true}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor="#333333"
              radialLabelsLinkColor={{ from: 'color' }}
              sliceLabelsSkipAngle={10}
              colors={d => colorMap[d.id]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              enableArcLinkLabels={false}
              legends={[
                {
                  data: LegendContractData.map(item => ({
                    id: item.id, // Assuming this should match the `name` field in your data
                    label: truncateText(item.id, 20), // This will be displayed as the legend label
                    color: colorMap[item.id],
                  })),
                  anchor: 'top-right',
                  direction: 'column',
                  translateY: 18,
                  translateX: 20,
                  itemWidth: 60,
                  itemHeight: 18,
                  itemTextColor: '#ffffff',
                  itemsSpacing: 4,
                  symbolSize: 20,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      }
                    }
                  ]
                }
              ]}
              theme={{
                background: colors.grey,
                axis: {
                  ticks: {
                    text: {
                      fill: '#ffffff',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  },
                },
                legends: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
                labels: {
                  text: {
                    fill: '#ffffff',
                    fontWeight: 'bold',
                  }
                },
              }}
            />
          </View>
        </View>
      </View>

    </>
  );
}

export default AnalysisChartTwo;