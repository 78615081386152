import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { SafeAreaView, ScrollView, View, StyleSheet } from "react-native";
import { Button, TextInput, Text, ActivityIndicator, Snackbar } from "react-native-paper";
import { validate } from "../libs/form.validate";
import { Dropdown } from "react-native-element-dropdown";
import { getStorageData, storeData } from "../libs/storage";
import colors from "../styles/colors";

const getProjectList = gql`
  query getAllProject($input: getAllProjectInput) {
    getAllProject(input: $input) {
      name
      year
      id
      end_date
      start_date
      workers_count
      acivities_count
      project_area {
        id
        name
      }
      project_id
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const MOVE_PROJECT_MUTATION = gql`
  mutation moveProject($input: moveProjectInput) {
    moveProject(input: $input) {
      status
      message
    }
  }
`;

function MoveProjects({ navigation, clientList, refetchClientList }) {
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedFromClientID, setSelectedFromClientID] = useState("");
    const [selectedFromProjectID, setSelectedFromProjectID] = useState("");
    const [selectedToClientID, setSelectedToClientID] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [operationCompleted, setOperationCompleted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showError, setShowError] = useState(false);

    const clients = clientList?.getAllClients || [];

    const { data: ProjectList, refetch: refetchProjectList, loading: loadingProjectList } = useQuery(getProjectList, {
        fetchPolicy: "network-only",
        variables: { input: { client_id: selectedFromClientID } },
        skip: !selectedFromClientID,
    });

    const projects = ProjectList?.getAllProject || [];

    const [moveProject] = useMutation(MOVE_PROJECT_MUTATION);

    const handleFromClientChange = (selectedItem) => {
        setSelectedFromClientID(selectedItem.value);
        setFieldValue('fromClientId', selectedItem.value);
        refetchProjectList();
    };

    const handleToClientChange = (selectedItem) => {
        setSelectedToClientID(selectedItem.value);
        setFieldValue('toClientId', selectedItem.value);
    };

    const handleProjectChange = (selectedItem) => {
        setSelectedFromProjectID(selectedItem.value);
        setFieldValue('projectId', selectedItem.value);
    };

    const submitData = async (values, { resetForm }) => {
        setValidationErrors({});

        if (operationCompleted) {
            setValidationErrors({ backendError: 'Operazione duplicata non consentita!' });
            return;
        }

        const isValidForm = await validate(
            values,
            setValidationErrors,
            ['fromClientId', 'toClientId', 'projectId']
        );

        if (isValidForm) {
            try {
                const { data } = await moveProject({
                    variables: {
                        input: {
                            fromClientId: values.fromClientId,
                            toClientId: values.toClientId,
                            projectId: values.projectId,
                        },
                    },
                });

                refetchClientList();
                refetchProjectList();
                resetForm();
                setSelectedFromClientID('');
                setSelectedFromProjectID('');
                setSelectedToClientID('');
                setShowSuccess(true); // Mostra il popup di successo
                setOperationCompleted(true); // Segna l'operazione come completata
                setTimeout(() => setShowSuccess(false), 3000); // Nascondi il popup dopo 3 secondi
            } catch (error) {
                console.error('Error moving project:', error);
                setValidationErrors({ backendError: error.message });
            }
        } else {
            console.log("Errore durante lo spostamento");
        }
    };

    return (
        <View className="flex flex-col w-full my-2" style={{ borderColor: colors.white, borderWidth: 1, borderRadius: 10, backgroundColor: colors.background }}>
            <ScrollView
                contentInsetAdjustmentBehavior="automatic"
                bounces={false}
                showsHorizontalScrollIndicator={false}
            >
                <View className="flex flex-col p-2 pt-4">
                    <View className="items-center justify-center">
                        <Text
                            variant="displayMedium"
                            className="pl-2 pb-4 font-semibold"
                            style={{ color: colors.white, fontSize: 35, fontWeight: "bold" }}
                        >
                            Sposta progetti
                        </Text>
                    </View>

                    <Formik
                        initialValues={{ fromClientId: "", toClientId: "", projectId: "" }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setIsSaving(true);

                            await submitData({
                                fromClientId: selectedFromClientID,
                                toClientId: selectedToClientID,
                                projectId: selectedFromProjectID,
                            }, { resetForm });

                            setTimeout(() => {
                                setIsSaving(false);
                                setSubmitting(false);
                            }, 1000);
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            setFieldValue,
                        }) => (
                            <View className="flex flex-col w-full p-2 gap-2">
                                <View>
                                    <Dropdown
                                        style={styles.dropDownContainer}
                                        containerStyle={styles.listContainer}
                                        data={clients.map(client => ({
                                            label: client.name,  // Campo dell'etichetta
                                            value: client.id.toString(),  // Campo del valore convertito in stringa
                                            key: client.id.toString(),
                                        }))}
                                        search
                                        searchPlaceholder="Cerca cliente A..."  // Imposta il placeholder per la ricerca
                                        labelField="label"  // Specifica il campo per l'etichetta
                                        valueField="value"  // Specifica il campo per il valore
                                        value={selectedFromClientID}  // Valore attualmente selezionato
                                        placeholder='Seleziona cliente A...'  // Placeholder del dropdown
                                        onChange={handleFromClientChange}  // Gestisce la selezione
                                        selectedTextStyle={{ color: colors.white }}
                                        placeholderStyle={{ color: colors.white }}
                                        itemTextStyle={{
                                            backgroundColor: colors.background,
                                            color: colors.white,
                                            padding: 10,
                                        }}
                                        inputSearchStyle={styles.inputStyle}
                                        renderItem={(item) => (
                                            <Text style={{ color: colors.white, padding: 10 }}>
                                                {item.label}
                                            </Text>
                                        )}
                                    />
                                </View>

                                <View>
                                    <Dropdown
                                        style={styles.dropDownContainer}
                                        containerStyle={styles.listContainer}
                                        data={projects.map(project => ({
                                            label: project.name,  // Campo dell'etichetta
                                            value: project.id.toString(),  // Campo del valore convertito in stringa
                                            key: project.id.toString(),
                                        }))}
                                        search
                                        searchPlaceholder="Trova progetto..."  // Imposta il placeholder per la ricerca
                                        labelField="label"  // Specifica il campo per l'etichetta
                                        valueField="value"  // Specifica il campo per il valore
                                        value={selectedFromProjectID}  // Valore attualmente selezionato
                                        placeholder='Seleziona progetto...'  // Placeholder del dropdown
                                        onChange={handleProjectChange}  // Gestisce la selezione
                                        selectedTextStyle={{ color: colors.white }}
                                        placeholderStyle={{ color: colors.white }}
                                        itemTextStyle={{
                                            backgroundColor: colors.background,
                                            color: colors.white,
                                            padding: 10,
                                        }}
                                        inputSearchStyle={styles.inputStyle}
                                        renderItem={(item) => (
                                            <Text style={{ color: colors.white, padding: 10 }}>
                                                {item.label}
                                            </Text>
                                        )}
                                    />
                                </View>

                                <View>
                                    <Dropdown
                                        style={styles.dropDownContainer}
                                        containerStyle={styles.listContainer}
                                        data={clients.map(client => ({
                                            label: client.name,  // Campo dell'etichetta
                                            value: client.id.toString(),  // Campo del valore convertito in stringa
                                            key: client.id.toString(),
                                        }))}
                                        search
                                        searchPlaceholder="Cerca cliente B..."  // Imposta il placeholder per la ricerca
                                        labelField="label"  // Specifica il campo per l'etichetta
                                        valueField="value"  // Specifica il campo per il valore
                                        value={selectedToClientID}  // Valore attualmente selezionato
                                        placeholder='Seleziona cliente B...'  // Placeholder del dropdown
                                        onChange={handleToClientChange}  // Gestisce la selezione
                                        selectedTextStyle={{ color: colors.white }}
                                        placeholderStyle={{ color: colors.white }}
                                        itemTextStyle={{
                                            backgroundColor: colors.background,
                                            color: colors.white,
                                            padding: 10,
                                        }}
                                        inputSearchStyle={styles.inputStyle}
                                        renderItem={(item) => (
                                            <Text style={{ color: colors.white, padding: 10 }}>
                                                {item.label}
                                            </Text>
                                        )}
                                    />
                                </View>

                                <View className="py-4 my-8 w-full items-center justify-center">
                                    <Button
                                        mode="contained"
                                        onPress={handleSubmit as (e: unknown) => void}
                                        style={{ backgroundColor: colors.amaranto, borderRadius: 10 }}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? (
                                            <ActivityIndicator animating={true} color={"red"} />
                                        ) : (
                                            <Text className="text-white font-bold" style={{ fontFamily: "Roboto", fontSize: 16 }}>Sposta</Text>
                                        )}
                                    </Button>
                                </View>

                                {validationErrors.backendError && (
                                    <View>
                                        <Text style={styles.errorText}>
                                            {validationErrors.backendError}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )}
                    </Formik>
                </View>
            </ScrollView>
            <Snackbar
                visible={showSuccess}
                onDismiss={() => setShowSuccess(false)}
                duration={4000}
                style={styles.snackbar}
            >
                <Text style={styles.snackbarText}>Operazione completata con successo!</Text>
            </Snackbar>
        </View>
    );
}

const styles = StyleSheet.create({
    snackbar: {
        backgroundColor: colors.amaranto,
        position: 'absolute',
        bottom: 20,
        width: '80%',
        alignSelf: 'center',
    },
    snackbarText: {
        color: colors.white,
        textAlign: 'center',
        fontFamily: "Roboto"
    },
    errorText: {
        color: 'red',
        padding: 10,
    },
    dropDownContainer: {
        backgroundColor: colors.grey,
        borderTopStartRadius: 10,
        borderTopEndRadius: 10,
        borderWidth: 1,
        borderColor: colors.white,
        padding: 15,
        paddingHorizontal: 30,
        width: '100%',
    },
    inputStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        borderColor: colors.white,
        width: '99%',  // Assicura che l'input di ricerca non fuoriesca
        overflow: 'hidden',
    },
    listContainer: {
        backgroundColor: colors.grey,
        marginBottom: 10,
        borderWidth: 1,
        borderColor: colors.grey,
    }
});

export default MoveProjects;
