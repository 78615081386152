import React, { useState } from "react";
import { View } from "react-native";
import { Button, Dialog, IconButton, Portal, Text } from "react-native-paper";
import colors from "../styles/colors";

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function WorkerActivityCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const navigate = () => {
    props.navigation.navigate("WorkerActivityDetailsScreen", {
      id: props.data.id,
      projectID: props.projectID,
    });
  };

  const deleteActivity = () => {
    console.log("Delete called");
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-white" style={{fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare l'attività {props?.data?.name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{ fontFamily: "Roboto"}}>
              Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteActivity}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{backgroundColor: colors.grey, borderColor: colors.white, borderRadius: 10, borderWidth: 1}}>
          <View className="flex flex-col w-1/3 gap-4 p-2">
            <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
              {props?.data?.name}
            </Text>

            {props.data.end_date && (
              <Text className="text-sm text-white" style={{fontFamily: "Roboto"}}>
                Data Fine :{" "}
                {`${new Date(parseInt(props?.data?.end_date)).toLocaleDateString(
                  "en-GB"
                )}`}
              </Text>
            )}
          </View>

          <View className="flex flex-row items-center justify-center w-1/3 p-2">
            <View className="flex flex-row items-center  w-1/2">
              <Text className="text-white" variant="labelMedium" style={{fontFamily: "Roboto"}}>
                {props?.data?.description.substring(0, 50)}
                {"..."}
              </Text>
            </View>
          </View>

          <View className="flex flex-row w-2/5 p-4 items-center gap-10 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={navigate}
            />
            {/* <IconButton
              icon="trash-can-outline"
              containerColor="#f50c2f"
              style={{ borderRadius: 4 }}
              iconColor={"white"}
              size={25}
              onPress={() => setIsDeleteModalVisible(true)}
            /> */}
          </View>
        </View>
      </View>
    </>
  );
}

export default WorkerActivityCard;
