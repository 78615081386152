import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { View } from "react-native";
import {
  Badge,
  Button,
  Dialog,
  IconButton,
  Portal,
  Text,
} from "react-native-paper";
import {
  USER_PROFILE_ROLE,
} from "../graphql/user.resolver";
import colors from "../styles/colors";

const DELETE_PROJECT = gql`
  mutation deleteProject($input: deleteProjectInput) {
    deleteProject(input: $input) {
      status
      message
    }
  }
`;

const getProjectList = gql`
  query getAllProject($input: getAllProjectInput) {
    getAllProject(input: $input) {
      name
      year
      id
      end_date
      workers_count
      acivities_count
      project_id
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function ProjectCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const [deleteProject, { data, loading, error }] = useMutation(DELETE_PROJECT);

  const navigate = async (e: any) => {
    props.navigation.push("ProjectDetails", { id: props.data.id });
  };

  const deleteCard = async () => {
    await deleteProject({ variables: { input: { id: props.data.id } } });
    props.refetchProjectList();
    setIsDeleteModalVisible(false)
  };

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;

  const isProjectManager = () => {
    const projectManagerName = `${props?.data?.project_manager?.user?.first_name} ${props?.data?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = props?.data?.project_area?.id;

  const isAreaManager = () => {
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-white " style={{ fontFamily: "Roboto" }}>
              Sei sicuro di voler eliminare il progetto {props.data.name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{ fontFamily: "Roboto" }}>
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteCard}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{ fontFamily: "Roboto" }}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{ borderColor: colors.white, borderRadius: 10, borderWidth: 1, backgroundColor: colors.grey }}>
          <View className="flex flex-col w-1/3 p-2 gap-4 items-start">
            <Text className="text-base font-bold" style={{ color: colors.white, fontFamily: "Roboto" }}>
              {props.data.name}
            </Text>
            <Text className="text-base font-bold" style={{ color: colors.white, fontFamily: 'Roboto', fontWeight: 'bold' }}>
              {props.data.project_id}
            </Text>

            <View className="flex flex-row gap-4 items-start justify-start">
              {props.data.start_date && (
                <Text className="text-sm text-white" style={{ fontFamily: "Roboto" }}>
                  Data Inizio :{" "}
                  {`${new Date(parseInt(props.data.start_date)).toLocaleDateString(
                    "en-GB"
                  )}`}
                </Text>
              )}

              {props.data.end_date && (
                <Text className="text-sm text-white" style={{ fontFamily: "Roboto" }}>
                  Data Fine :{" "}
                  {`${new Date(parseInt(props.data.end_date)).toLocaleDateString(
                    "en-GB"
                  )}`}
                </Text>
              )}
            </View>
          </View>

          <View className="flex flex-row items-center justify-center w-1/3 p-2">
            <View className="flex flex-row items-center w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontSize: 12, fontWeight: 'bold' }}>
                {props.data?.acivities_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14 }}>Attività</Text>
            </View>
            <View className="flex flex-row items-center w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontSize: 12, fontWeight: 'bold' }}>
                {props.data?.workers_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14 }}>Lavoratori</Text>
            </View>
          </View>

          <View className="flex flex-row w-2/5 p-4 items-center gap-10 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={colors.white}
              size={25}
              onPress={navigate}
            />
            {isProjectManager() || props.role === "ADMIN" || isAreaManager() ? (
              <IconButton
                icon="trash-can-outline"
                containerColor={colors.red}
                style={{ borderRadius: 4 }}
                iconColor={colors.white}
                size={25}
                onPress={() => setIsDeleteModalVisible(true)}
              />
            ) : null}
          </View>
        </View>
      </View>

    </>
  );
}

export default ProjectCard;
