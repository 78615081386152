import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json"
import it from "./it.json"

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  fallbackLng: "en",
  resources: {
    en : en,
    it : it
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
