import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import UsersList from "../../managers_view/user_list _ admin";
import { APP_SLIDE_ANIMATION } from "../../../constants/App.constants";
import CreateNewUser from "../../managers_view/create_new_user";
import UserDetails from "../../managers_view/user_details";
import colors from "../../../styles/colors";

const Stack = createNativeStackNavigator();

function AdminDashboard() {
  return (
    <Stack.Navigator
      screenOptions={{ headerStyle: { backgroundColor: colors.background } }}
      initialRouteName="Userlist"
    >
      <Stack.Screen
        name="Userlist"
        component={UsersList}
        options={{
          headerShown: false,
          gestureEnabled: false,
          headerBackVisible: false,
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="CreateNewUser"
        component={CreateNewUser}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="UserDetails"
        component={UserDetails}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />
    </Stack.Navigator>
  );
}

export default AdminDashboard;
