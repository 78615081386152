import { gql } from "@apollo/client";

export const GET_WORKER_TASKS_BY_PROJECT_ID = gql`
  query getWorkerTasksOfProject($input: getWorkerTasksOfProjectInput) {
    getWorkerTasksOfProject(input: $input) {
      tasks {
        id
        name
        end_time
        start_time
        created_by
        total_hrs
        client_name
      }
    }
  }
`;
