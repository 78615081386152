import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Button, Dialog, IconButton, Portal, Text } from "react-native-paper";
import { AWS_FILE_URL } from "../constants/App.constants";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import { getStorageData } from "../libs/storage";
import {
  USER_PROFILE_ROLE,
} from "../graphql/user.resolver";
import colors from "../styles/colors";

const DELETE_RECEIPT = gql`
  mutation deleteAttachment($input: deleteAttachmentInput) {
    deleteAttachment(input: $input) {
      message
      status
    }
  }
`;

const getProjectDetailsQuery = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      area_manager {
        id
        user {
          first_name
          last_name
        }
      }
      project_created_by {
        user {
          first_name
          last_name
        }
      }
      project_id
      project_area {
        name
        id
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function ReciptCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [role, setRole] = useState(null);

  const getRole = async () => {
    const role = await getStorageData("role");
    setRole(role);
  };

  useEffect(() => {
    getRole();
  }, [props]);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const [deleteReciptMutation, { data: reciptData, error: reciptDeleteError }] =
    useMutation(DELETE_RECEIPT);

  const deleteRecipt = async () => {
    await deleteReciptMutation({
      variables: { input: { attachment_id: props?.data?.id } },
    });
    await props.getAllReciptsRefetch();
    setIsDeleteModalVisible(false);
  };

  const {
    data,
    refetch,
    loading: projectDetailsLoading,
  } = useQuery(getProjectDetailsQuery, {
    fetchPolicy: "network-only",
    variables: { input: { id: props.projectId } },
  });

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;
  let worker_name = `${props.data?.attachment_created_by?.user?.first_name} ${props.data?.attachment_created_by?.user?.last_name}`;
  //console.log(worker_name, "worker");
  const isWorker = () => {
    return worker_name === full_name;
  };
  const isProjectManager = () => {
    const projectManagerName = `${data?.getProjectById?.project_manager?.user?.first_name} ${data?.getProjectById?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = data?.getProjectById?.project_area?.id;

  const isAreaManager = () => {
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  const downloadRecipt = () => {
    window.open(`${AWS_FILE_URL}${props?.data?.file_name}`, "_blank");
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" className="text-white" style={{fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare la Spesa?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className="text-white" style={{fontFamily: "Roboto"}}>
                Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteRecipt}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" className=" text-white" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View
        className={`w-64 ${isExpanded ? "h-fit" : "h-54"
          }  m-2 p-2 `}
        style={{ backgroundColor: colors.grey, borderColor: colors.white, borderWidth: 1, borderRadius: 10 }}
      >
        <View className="w-full h-5/6 flex flex-row">
          <View className="w-2/3 h-full items-start p-2 gap-2 text-start">
            {props?.data?.worker_name && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Lavoratore :{" "}
                {props?.data?.worker_name.length > 10
                  ? props?.data?.worker_name.substring(0, 10) + "..."
                  : props?.data?.worker_name}
              </Text>
            )}

            {props?.data?.receipt_value && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Importo : {props?.data?.receipt_value}
              </Text>
            )}

            {props?.data?.receipt_date && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Data :{" "}
                {`${new Date(
                  parseInt(props?.data?.receipt_date)
                ).toLocaleDateString("en-GB")}`}
              </Text>
            )}

            {props?.data?.receipt_city && isExpanded && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Città : {props?.data?.receipt_city}
              </Text>
            )}

            {props?.data?.receipt_expenditure_method && isExpanded && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Metodo di Pagamento : {props?.data?.receipt_expenditure_method}
              </Text>
            )}

            {props?.data?.receipt_type && isExpanded && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Tipologia Spesa : {props?.data?.receipt_type}
              </Text>
            )}

            {props?.data?.km_routes && isExpanded && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Km Percorsi : {props?.data?.km_routes}
              </Text>
            )}

            {props?.data?.reimbursed && (
              <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                Spesa da Rimborsare :{" "}
                {props?.data?.reimbursed ? "Yes" : "No"}
              </Text>
            )}
          </View>
          <View className="w-1/3 h-h-44 flex flex-col">
            <View
              className={`w-full ${props?.data?.fileUrl && role != "CONSULTANT" ? "h-1/3" : "h-1/2"
                } items-center justify-center`}
                style={{ padding: 10 }}
            >
              {isWorker() ? (
                <IconButton
                  icon="trash-can-outline"
                  containerColor={colors.red}
                  style={{ borderRadius: 4 }}
                  iconColor={colors.white}
                  size={28}
                  onPress={() => setIsDeleteModalVisible(true)}
                />
              ) : null}
            </View>

            {/* {role != "CONSULTANT" && ( */}
            <View
              className={`w-full ${props?.data?.fileUrl ? "h-1/3" : "h-1/2"
                } items-center justify-center`}
                style={{ padding: 10 }}
            >
              {isWorker() || isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                <IconButton
                  icon="pencil"
                  containerColor={colors.yellow}
                  style={{ borderRadius: 4 }}
                  iconColor={colors.white}
                  size={28}
                  onPress={() => {
                    props.setselectedReciept(props.data);
                    //console.log(props.data);
                  }}
                />
              ) : null}
            </View>
            {/* )} */}

            {props?.data?.fileUrl && props?.data?.file_name && (
              <View
                className={`w-full  ${role != "CONSULTANT" ? "h-1/3" : "h-1/2"
                  } items-center justify-center`}
                  style={{ padding: 10 }}
              >
                <IconButton
                  icon="download-box-outline"
                  containerColor={colors.orange}
                  style={{ borderRadius: 4 }}
                  iconColor={colors.white}
                  size={28}
                  onPress={downloadRecipt}
                />
              </View>
            )}
          </View>
        </View>
        <View className="flex h-1/6 items-center justify-center mb-2">
          <TouchableOpacity
            className="w-full items-center justify-center"
            onPress={() => setIsExpanded(!isExpanded)}
          >
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              size={15}
              color={"white"}
            />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}

export default ReciptCard;
