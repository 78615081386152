import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import WorkersList from "../../managers_view/workers_list";
import CreateNewWorker from "../../managers_view/create_new_worker";
import WorkerDetails from "../../managers_view/worker_details";
import { APP_SLIDE_ANIMATION } from "../../../constants/App.constants";
import ProjectDetails from "../../managers_view/project_details";
import TaskDetails from "../../managers_view/task_details";
import WorkerCreateNewTask from "../../workers_view/create_new_task";
import colors from "../../../styles/colors";

const Stack = createNativeStackNavigator();

function Workerlayout() {
  return (
    <Stack.Navigator
      screenOptions={{ headerStyle: { backgroundColor: colors.background } }}
      initialRouteName="WorkersList"
    >
      <Stack.Screen
        name="WorkersList"
        component={WorkersList}
        options={{
          headerShown: false,
          gestureEnabled: false,
          headerBackVisible: false,
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="WorkerDetails"
        component={WorkerDetails}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="CreateNewWorker"
        component={CreateNewWorker}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="EditWorkerDetails"
        component={CreateNewWorker}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="ProjectDetails"
        component={ProjectDetails}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />

      <Stack.Screen
        name="TaskDetails"
        component={TaskDetails}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />
      <Stack.Screen
        name="EditTask"
        component={WorkerCreateNewTask}
        options={{
          headerBackTitleVisible: false,
          headerTintColor: "#FFFFFF",
          headerShadowVisible: false,
          animation: APP_SLIDE_ANIMATION,
          headerTitle: () => <></>,
        }}
      />
    </Stack.Navigator>
  );
}

export default Workerlayout;
