import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, ScrollView } from "react-native";
import { Dialog, Text, Portal, Modal, Button, IconButton, TextInput, } from "react-native-paper";
import MyWebDatePicker from "../components/MyDatePicker";
import { Formik } from "formik";
import { validate } from "../libs/form.validate";
import {
    USER_PROFILE_NAME,
} from "../graphql/user.resolver";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import colors from "../styles/colors";

const DELETE_SALARY_MUTATION = gql`
  mutation deleteSalaryRecord($input: deleteSalaryRecordInput) {
    deleteSalaryRecord(input: $input) {
      message
      status
    }
  }
`;

const EDIT_SALARY_MUTATION = gql`
  mutation updateSalaryRecord($input: updateSalaryRecordInput) {
    updateSalaryRecord(input: $input) {
      message
      status
    }
  }
`;

const containerStyle = {
    margin: 20,
    backgroundColor: colors.grey,
    padding: 10,
    alignSelf: "center",
    borderRadius: 10,
  };

function formatDate(dateString) {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString("it-IT"); // Formato italiano, ad esempio
}

function SalaryCard(props: any) {
    const [validationErrors, setValidationErrors] = useState({});
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const handelModalClose = () => setIsDeleteModalVisible(false);
    const handleEditModalClose = () => setIsEditModalVisible(false);
    const REQUIRED_FIELDS = ["amount", "created_at"];
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isStartDatePickerVisible, setisStartDatePickerVisible] =
        useState(false);
    const [initialSalaryData, setInitialSalaryData] = useState({
        amount: "",
        created_at: "",
    });
    const [isSaving, setIsSaving] = useState(false);

    const [DeleteSalary, { data: salaryData, error: salaryDeleteError }] =
        useMutation(DELETE_SALARY_MUTATION);

    const [EditSalary, { data: editSalaryData, error: salaryEditError }] =
        useMutation(EDIT_SALARY_MUTATION);

    const deleteSalary = async () => {
        await DeleteSalary({
            variables: { input: { id: props?.data?.id, profile_id: props?.data?.profile_id } },
        });
        props?.refetchSalarylist();
        setIsDeleteModalVisible(false);
    };

    useEffect(() => {
        if (selectedRecord) {
            setInitialSalaryData({
                amount: selectedRecord.amount,
                created_at: selectedRecord.created_at,
            });
            setIsEditModalVisible(true);
        }
    }, [selectedRecord]);

    const handelEditModal = async () => {
        setInitialSalaryData({
            amount: "",
            created_at: "",
        });
        setSelectedRecord(null);
        setValidationErrors({});
        setIsEditModalVisible(false);
        props?.refetchSalarylist();
        await props.refetchSalarylist();
    };

    const saveRecordToDb = async (values) => {
        values = { ...values };
        let isValidForm = await validate(
            values,
            setValidationErrors,
            REQUIRED_FIELDS
        );

        if (isValidForm) {
            try {
                if (selectedRecord) {
                    await EditSalary({
                        variables: {
                            input: {
                                id: selectedRecord.id,
                                amount: parseFloat(values.amount),
                                created_at: values.created_at && typeof values.created_at == "string"
                                    ? new Date(parseInt(values.created_at))
                                    : values.created_at,
                            },
                        },
                    });
                }
                setValidationErrors({});
                handelEditModal();
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    const date = new Date(parseInt(props.data.created_at));

    return (
        <>
            <Portal>
                <Dialog
                    visible={isDeleteModalVisible}
                    onDismiss={handelModalClose}
                    // @ts-ignore
                    style={containerStyle}
                >
                    <Dialog.Content>
                        <Text variant="bodyMedium" className="text-white font-bold" style={{fontFamily: "Roboto"}}>
                           Sei sicuro di voler eliminare questo salario?
                        </Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button
                            onPress={handelModalClose}
                            buttonColor={colors.blue}
                            textColor={colors.white}
                            style={{ borderRadius: 4 }}
                        >
                            <Text variant="bodyMedium" className=" text-white" style={{fontFamily: "Roboto"}}>
                                Annulla
                            </Text>
                        </Button>
                        <Button
                            onPress={deleteSalary}
                            buttonColor={colors.red}
                            textColor={colors.white}
                            style={{ borderRadius: 4 }}
                        >
                            <Text variant="bodyMedium" className=" text-white" style={{fontFamily: "Roboto"}}>
                                Conferma
                            </Text>
                        </Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>

            <Portal>
                <Dialog
                    visible={isEditModalVisible}
                    onDismiss={handleEditModalClose}
                    // @ts-ignore
                    style={{
                        backgroundColor: colors.grey,
                        alignSelf: "center",
                        maxHeight: "80%",
                    }}
                >
                    <Dialog.Title style={{ color: "white" }}>
                        Modifica Salario
                    </Dialog.Title>

                    <Dialog.ScrollArea className="flex flex-col p-2">
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 18 }}>
                            <View>
                                <Formik
                                    initialValues={initialSalaryData}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setIsSaving(true); // Imposta lo stato del salvataggio su true
                                        await saveRecordToDb(values); // Esegui la funzione di salvataggio

                                        // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                                        setTimeout(() => {
                                            setIsSaving(false);
                                            setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                                        }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
                                    }}
                                    enableReinitialize
                                >
                                    {({
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        values,
                                        setFieldValue,
                                    }) => (
                                        <View className="flex flex-col w-full gap-8">
                                            <View className="w-full">
                                                <TextInput
                                                    label="Importo"
                                                    mode="flat"
                                                    onChangeText={handleChange("amount")}
                                                    onBlur={handleBlur("amount")}
                                                    value={values.amount}
                                                    style={{ backgroundColor: colors.grey, fontFamily: "Roboto" }}
                                                    activeUnderlineColor="#53555e"
                                                    placeholderTextColor={colors.white}
                                                    underlineColor="#53555e"
                                                    theme={{
                                                        colors: {
                                                            onSurfaceVariant: "white",
                                                        },
                                                    }}
                                                />
                                                {validationErrors["amount"] && (
                                                    <View>
                                                        <Text
                                                            className="text-red-400 text-sm p-2"
                                                            style={{ color: "red" }}
                                                        >
                                                            {validationErrors["amount"]}
                                                        </Text>
                                                    </View>
                                                )}
                                            </View>

                                            {/* <View className="w-full p-2">
                                                <MyWebDatePicker
                                                    fieldName={"created_at"}
                                                    setFieldValue={setFieldValue}
                                                    dateFill={
                                                        selectedRecord?.created_at
                                                            ? selectedRecord?.created_at
                                                            : null
                                                    }
                                                />
                                            </View> */}

                                            <View className="w-full p-2">
                                                <MyWebDatePicker
                                                    fieldName={"created_at"}
                                                    setFieldValue={setFieldValue}
                                                    dateFill={
                                                        selectedRecord?.created_at
                                                            ? selectedRecord?.created_at
                                                            : null
                                                    }
                                                />
                                            </View>

                                            <View className="flex flex-row w-full justify-end gap-4">
                                                <Button
                                                    onPress={() => {
                                                        values = salaryData;
                                                        handleEditModalClose();
                                                    }}
                                                    buttonColor={colors.blue}
                                                    textColor="white"
                                                    style={{ borderRadius: 4, fontFamily: "Roboto" }}
                                                >
                                                    Annulla
                                                </Button>
                                                <Button
                                                    onPress={handleSubmit as (e: unknown) => void}
                                                    buttonColor={colors.red}
                                                    textColor="white"
                                                    style={{ borderRadius: 4 }}
                                                    disabled={isSaving}
                                                >
                                                    Modifica Record
                                                </Button>
                                            </View>
                                        </View>
                                    )}
                                </Formik>
                            </View>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>


            <View>
                <View className="flex flex-row w-full bg-white h-fit my-1" style={{ borderRadius: 10 }}>
                    <View className="flex flex-col w-1/5 bg-yellow rounded-l-lg items-center justify-center">
                        <View className="items-center justify-center h-20">
                            <Text className="text-white text-4xl" style={{fontFamily: "Roboto"}}>
                                {date.getDate()}
                            </Text>
                            <Text className="text-white" style={{fontFamily: "Roboto"}}>
                                {date.toLocaleString("default", { month: "long" })}
                            </Text>
                        </View>
                    </View>
                    <View className="flex flex-row w-full" style={{backgroundColor: colors.grey}}>
                        <View className="flex flex-1 justify-start justify-center p-2">
                            <Text className=" mt-1 text-xl text-white  px-2" style={{fontFamily: "Roboto"}}>
                                Salario Orario : {`${props.data.amount}`}
                            </Text>
                        </View>


                        <View className="flex flex-1 justify-end items-center p-2">
                            <View className="flex flex-row justify-center">
                                <IconButton
                                    icon="pencil"
                                    containerColor={colors.yellow}
                                    style={{ borderRadius: 4 }}
                                    iconColor={"white"}
                                    size={28}
                                    onPress={() => {
                                        setIsEditModalVisible(true);
                                        setSelectedRecord(props.data);
                                    }}
                                />

                                <IconButton
                                    icon="trash-can-outline"
                                    containerColor={colors.red}
                                    style={{ borderRadius: 4 }}
                                    iconColor={"white"}
                                    size={28}
                                    onPress={() => setIsDeleteModalVisible(true)}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </>
    );
}

export default SalaryCard;
