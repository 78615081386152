import AsyncStorage from "@react-native-async-storage/async-storage";

export async function storeData(key, value) {
  try {
    let data = await AsyncStorage.setItem(key, value);
    return data;
  } catch (error) {
    return new Error(error);
  }
}

export async function getStorageData(key) {
  try {
    let data = await AsyncStorage.getItem(key);
    return data;
  } catch (error) {
    return new Error(error);
  }
}

export async function removeStorageData(key) {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    return new Error(error);
  }
}
