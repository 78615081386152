import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { gql, useQuery} from "@apollo/client";
import { ActivityIndicator, Button, Text, Searchbar } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import ClientCard from "../../components/ClientCard";
import Loader from "../../components/Loader";
import globalStyles from "../../styles/globalStyle";
import colors from "../../styles/colors";

const getClientList = gql`
  query getAllClients {
    getAllClients {
      address
      id
      name
      workers_count
      projects_count
    }
  }
`;

function Clientlist({ navigation }) {
  const [sortedClientList, setSortedClientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data: clientList,
    refetch: refetchClientList,
    loading: clientListLoading,
  } = useQuery(getClientList);

  const [validationErrors, setValidationErrors] = useState({});

  const clientListUpdater = () => {
    refetchClientList();
  };

  useFocusEffect(
    React.useCallback(() => {
      refetchClientList();
    }, [])
  );

  useEffect(() => {
    if (clientList?.getAllClients) {
      const filteredAndSortedClients = clientList.getAllClients
        .filter(client =>
          client.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setSortedClientList(filteredAndSortedClients);
    }
  }, [clientList, searchTerm]);

  return (
    <>
      <View className="flex flex-auto h-screen" style={{ backgroundColor: '#2B2B2B'}}>
        <Loader loading={clientListLoading} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <View className="flex flex-row w-full my-2">
                <Text
                  variant="displayMedium"
                  className="flex font-semibold w-11/12 ml-4"
                  style={{ color: colors.white}}
                >
                  Clienti
                </Text>

                <Button
                  icon="account-plus"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  onPress={() => navigation.push("CreateNewClient")}
                  className="items-center justify-center"
                  style={{ borderRadius: 10 }}
                >
                  <Text className="text-white font-bold">
                    Aggiungi un nuovo cliente
                  </Text>
                </Button>
              </View>
            </View>

            <View className="my-4 p-2">
              <Searchbar
                placeholder="Cerca clienti..."
                onChangeText={setSearchTerm}
                value={searchTerm}
                className="m-2"
                placeholderTextColor={colors.white}
                iconColor={colors.white}
                inputStyle={{ color: colors.white }}
                style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: 'Roboto' }}
              />
            </View>

            <View className="p-2 item-center justify-center">
              {sortedClientList.length > 0 ? (
                sortedClientList.map((page, idk) => (
                  <ClientCard
                    data={page}
                    key={idk}
                    navigation={navigation}
                    clientListUpdater={clientListUpdater}
                  />
                ))
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono clienti disponibili*
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default Clientlist;
