import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import {
  Button,
  TextInput,
  Text,
  Portal,
  Modal,
  ActivityIndicator,
  Checkbox,
} from "react-native-paper";
import MyWebDateTimePicker from "../../components/MyDateTimePicker";
import { validate } from "../../libs/form.validate";
import colors from "../../styles/colors";

const createTask = gql`
  mutation createActivityTask($input: CreateActivityTaskInput) {
    createActivityTask(input: $input) {
      success
    }
  }
`;

const GET_TASK_DETAILS = gql`
  query getTasksByID($input: getTasksByIDInput) {
    getTasksByID(input: $input) {
      end_time
      id
      name
      start_time
      ferie
      isOrdinaryTime
    }
  }
`;

const UPDATE_TASK_MUTATION = gql`
  mutation updateTask($input: updateTaskInput) {
    updateTask(input: $input) {
      message
      status
    }
  } 
`;

function WorkerCreateNewTask({ route, navigation }) {
  const activityId = route?.params?.id;
  const TaskId = route?.params?.TaskId;
  const [isSaving, setIsSaving] = useState(false);

  const [initialTaskDetails, setInitialTaskDetails] = useState({
    name: "",
    start_time: new Date(),
    end_time: new Date(),
    ferie: false,
    isOrdinaryTime: false
  });

  const {
    data: taskData,
    refetch: refetchTaskData,
    loading: taskDataLoading,
    error: errorTaskData,
  } = useQuery(GET_TASK_DETAILS, {
    fetchPolicy: "network-only",
    variables: { input: { task_id: TaskId } },
  });

  useEffect(() => {
    if (TaskId) {
      setInitialTaskDetails({
        name: taskData?.getTasksByID?.name || "",
        start_time:
          new Date(parseInt(taskData?.getTasksByID?.start_time)) || new Date(),
        end_time:
          new Date(parseInt(taskData?.getTasksByID?.end_time)) || new Date(),
        ferie: taskData?.getTasksByID?.ferie == "false" ? false : true,
        isOrdinaryTime: taskData?.getTasksByID?.isOrdinaryTime == "false" ? false : true,
      });
    }
  }, [taskData]);

  const [validationErrors, setValidationErrors] = useState({});
  const REQUIRED_FIELDS = ["name", "start_time", "end_time"];

  const [createNewTask, { data, loading, error }] = useMutation(createTask);
  const [
    updateTaskMutation,
    { data: taskUpdateData, loading: taskUpdateLoading },
  ] = useMutation(UPDATE_TASK_MUTATION);

  const [isStartDatePickerVisible, setisStartDatePickerVisible] =
    useState(false);
  const [isEndDatePickerVisible, setisEndDatePickerVisible] = useState(false);

  const submitData = async (values) => {
    setValidationErrors({});
    const isValidForm = await validate(
      values,
      setValidationErrors,
      REQUIRED_FIELDS
    );

    if (isValidForm) {
      if (TaskId) {
        try {
          await updateTaskMutation({
            variables: {
              input: {
                name: values?.name,
                start_time: values?.start_time,
                end_time: values?.end_time,
                ferie: String(values?.ferie),
                isOrdinaryTime: String(values?.isOrdinaryTime),
                id: taskData?.getTasksByID?.id,
              },
            },
          });
          navigation.goBack();
        } catch (error) {
          setValidationErrors({ backendError: `${error?.message}*` });
        }
      } else {
        try {
          await createNewTask({
            variables: { input: { ...values, activity_id: activityId } },
          });
          navigation.goBack("WorkerActivityDetailsScreen");
        } catch (error) {
          setValidationErrors({ backendError: `${error?.message}*` });
        }
      }
    }
  };

  const containerStyle = {
    backgroundColor: "white",
    top:20,
    padding: 20,
    alignSelf: "center",
  };

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <Text
              variant="displayMedium"
              className="text-white pl-2 pb-4 font-semibold"
            >
              {TaskId ? "Modifica task" : "Nuovo task"}
            </Text>

            {validationErrors["backendError"] && (
              <View>
                <Text
                  className="text-red-400 text-sm p-2"
                  style={{ color: "red" }}
                >
                  {validationErrors["backendError"]}
                </Text>
              </View>
            )}

            <Formik
              initialValues={initialTaskDetails}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSaving(true); // Imposta lo stato del salvataggio su true
                await submitData(values); // Esegui la funzione di salvataggio
              
                // Ripristina lo stato del pulsante dopo un breve ritardo per evitare il doppio clic
                setTimeout(() => {
                  setIsSaving(false);
                  setSubmitting(false); // Se stai utilizzando Formik, potresti voler impostare anche questo su false
                }, 1000); // 1000 millisecondi, puoi regolare questo valore in base alle tue esigenze
              }}
              enableReinitialize
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <View className="flex flex-col w-full p-2 gap-2">
                  <View>
                    <TextInput
                      label="Nome task"
                      onChangeText={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />
                    {validationErrors["name"] && (
                      <View>
                        <Text
                          className="text-red-400 text-sm p-2"
                          style={{ color: "red" }}
                        >
                          {validationErrors["name"]}
                        </Text>
                      </View>
                    )}
                  </View>

                  {/* Start date picker with modal starts */}
                  <View>
                    <TextInput
                      label="Data inzio"
                      showSoftInputOnFocus={false}
                      value={values.start_time.toLocaleTimeString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })} 
                      right={
                        <TextInput.Icon icon="calendar" iconColor="white" />
                      }
                      onFocus={() =>
                        setisStartDatePickerVisible(!isStartDatePickerVisible)
                      }
                      theme={{
                        colors: {
                          onSurfaceVariant: "white",
                        },
                      }}
                      style={{ backgroundColor: colors.grey }}
                      activeUnderlineColor="white"
                      underlineColor="white"
                      underlineStyle={{
                        borderWidth: 1,
                        borderColor: "#53555e",
                      }}
                    />

                    <Portal>
                      <Modal
                        visible={isStartDatePickerVisible}
                        onDismiss={() => setisStartDatePickerVisible(false)}
                        //@ts-ignore
                        contentContainerStyle={containerStyle}
                      >
                        {isStartDatePickerVisible && (
                          <MyWebDateTimePicker
                            fieldName={"start_time"}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </Modal>
                    </Portal>

                    {validationErrors["start_time"] && (
                      <View>
                        <Text
                          className="text-red-400 text-sm p-2"
                          style={{ color: "red" }}
                        >
                          {validationErrors["start_time"]}
                        </Text>
                      </View>
                    )}
                  </View>

                  {/* Start date picker with modal Ends */}

                  {/* End date picker with modal starts */}
                  <TextInput
                    label="Data fine"
                    showSoftInputOnFocus={false}
                    value={values.end_time.toLocaleTimeString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    right={<TextInput.Icon icon="calendar" iconColor="white" />}
                    onFocus={() =>
                      setisEndDatePickerVisible(!isEndDatePickerVisible)
                    }
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                    style={{ backgroundColor: colors.grey }}
                    activeUnderlineColor="white"
                    underlineColor="white"
                    underlineStyle={{ borderWidth: 1, borderColor: "#53555e" }}
                  />

                  <Portal>
                    <Modal
                      visible={isEndDatePickerVisible}
                      onDismiss={() => setisEndDatePickerVisible(false)}
                      //@ts-ignore
                      contentContainerStyle={containerStyle}
                    >
                      {isEndDatePickerVisible && (
                        <MyWebDateTimePicker
                          fieldName={"end_time"}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Modal>
                  </Portal>
                  {/* End date picker with modal Ends */}

                  <TouchableOpacity
                    onPress={() => {
                      setFieldValue("ferie", !values.ferie);
                    }}
                    className="flex flex-row w-3/12 items-center justify-start"
                  >
                    <Text className="text-white m-4 text-xl">A costo 0</Text>
                    <Checkbox.Android
                      status={values.ferie ? "checked" : "unchecked"}
                      color="white"
                      underlayColor="white"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setFieldValue("isOrdinaryTime", !values.isOrdinaryTime);
                    }}
                    className="flex flex-row w-3/12 items-center justify-start"
                  >
                    <Text className="text-white m-4 text-xl">Tempo ordinario</Text>
                    <Checkbox.Android
                      status={values.isOrdinaryTime ? "checked" : "unchecked"}
                      color="white"
                      underlayColor="white"
                    />
                  </TouchableOpacity>

                  <View className="py-4 my-8 w-full items-center justify-center">
                    <Button
                      mode="contained"
                      onPress={handleSubmit as (e: unknown) => void}
                      className="p-2 bg-yellow"
                      style={{backgroundColor: colors.amaranto}}
                      disabled={isSaving}
                    >
                      {loading || taskUpdateLoading ? (
                        <ActivityIndicator animating={true} color={"red"} />
                      ) : (
                        <Text className="text-white font-bold" style={{borderRadius: 10}}>Salva task</Text>
                      )}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default WorkerCreateNewTask;
