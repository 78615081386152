import { shareAsync } from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";

export async function downloadFileToLocal(fileUrl, fileName) {
  console.log(fileUrl, "debug:downloadFileToLocal:fileUrl");
  console.log(fileName, "debug:downloadFileToLocal:fileName");

  const downloadResumable = FileSystem.createDownloadResumable(
    fileUrl,
    `${FileSystem.cacheDirectory}${fileName}`,
    {}
  );

  const { uri, headers } = await downloadResumable.downloadAsync();
  console.log("Finished downloading to ", uri);

  if (Platform.OS === "android") {
    const permissions =
      await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
    if (permissions.granted) {
      const base64 = await FileSystem.readAsStringAsync(uri, {
        encoding: FileSystem.EncodingType.Base64,
      });
      await FileSystem.StorageAccessFramework.createFileAsync(
        permissions.directoryUri,
        fileName,
        headers["Content-Type"]
      )
        .then(async (uri) => {
          await FileSystem.writeAsStringAsync(uri, base64, {
            encoding: FileSystem.EncodingType.Base64,
          });
        })
        .catch((e) => console.log(e));
    } else {
      shareAsync(uri);
    }
  } else {
    shareAsync(uri);
  }
}

export const convertToFloat = async (values, field) => {
  if (!values?.[field] || values?.[field] <= 0) {
    return (values[field] = 0);
  } else {
    return (values[field] = parseFloat(values[field]));
  }
};
