import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { SafeAreaView, ScrollView, View, StyleSheet } from "react-native";
import { Button, TextInput, Text, ActivityIndicator, Portal, Modal, Snackbar } from "react-native-paper";
import { validate } from "../../libs/form.validate";
import { Dropdown } from "react-native-element-dropdown";
import { getStorageData, storeData } from "../../libs/storage";
import colors from "../../styles/colors";

const createCompensation = gql`
  mutation createCompensation($input: createCompensationInput) {
    createCompensation(input: $input) {
      status
      message
    }
  }
`;

const updateCompensation = gql`
  mutation updateCompensation($input: updateCompensationInput) {
    updateCompensation(input: $input) {
      message
      status
    }
  } 
`;

function CompensationBox({ projectList, refetchProjectList, refetchTransactionList }) {
    const [isSaving, setIsSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const REQUIRED_FIELDS_COMPENSATION = ["fromProject", "toProject", "amount"];
    const [selectedFromProjectID, setSelectedFromProjectID] = useState('');
    const [selectedToProjectID, setSelectedToProjectID] = useState('');
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [timerId, setTimerId] = useState(null);
    const [initialCompensationDetails, setInitialCompensationDetails] = useState({
        fromProject: "",
        toProject: "",
        amount: 0,
    });

    const resetDropDowns = () => {
        setSelectedFromProjectID('');
        setSelectedToProjectID('');
    };

    // Funzione per gestire la modifica del progetto "Da"
    const handleFromProjectChange = (selectedItem) => {
        setSelectedFromProjectID(selectedItem.value);
        setInitialCompensationDetails(prevDetails => ({
            ...prevDetails,
            fromProject: selectedItem.value,
        }));
    };

    // Funzione per gestire la modifica del progetto "A"
    const handleToProjectChange = (selectedItem) => {
        setSelectedToProjectID(selectedItem.value);
        setInitialCompensationDetails(prevDetails => ({
            ...prevDetails,
            toProject: selectedItem.value,
        }));
    };

    // Logica del tuo useEffect per pulire il timerId è rimasta invariata
    useEffect(() => {
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [timerId]);

    const [createNewCompensation, { data, loading, error }] = useMutation(createCompensation);
    const [editCompensation, { data: compensationUpdateData, loading: compensationUpdateLoading }] = useMutation(updateCompensation);

    const submitData = async (values, resetForm) => {
        values.amount = parseFloat(values.amount);

        setValidationErrors({});

        const isValidForm = await validate(
            values,
            setValidationErrors,
            REQUIRED_FIELDS_COMPENSATION
        );
        const fromProject = projectList.find(project => project.project_id === values.fromProject);

        if (isValidForm && fromProject) {
            if (values.amount > fromProject.notUsedBudget) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    amount: `La somma seleziona eccede il budget rimanente del progetto. Massimo disponibile: ${fromProject.notUsedBudget}`
                }));
                return;
            }
            try {
                if (values.compensationId) {
                    await editCompensation({
                        variables: {
                            input: {
                                compensationId: values.compensationId,
                                fromProjectId: values.fromProject,
                                toProjectId: values.toProject,
                                amount: values.amount,
                            },
                        },
                    });
                } else {
                    await createNewCompensation({
                        variables: {
                            input: {
                                fromProjectId: values.fromProject,
                                toProjectId: values.toProject,
                                amount: values.amount,
                            },
                        },
                    });
                }
                refetchProjectList();
                refetchTransactionList();
                setSnackbarMessage('Compensazione creata con successo!');
                setSnackbarVisible(true);
                setTimeout(() => {
                    setSnackbarVisible(false);
                }, 4000);
                resetForm(); // Resetta i vari input
                resetDropDowns();

            } catch (error) {
                setValidationErrors({ backendError: error.message });
            }
        }
    };

    const containerStyle = {
        backgroundColor: "#2a2c38",
        padding: 20,
        alignSelf: "center",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "#53555e",
    };

    const styles = StyleSheet.create({
        primaryTextWhiteBold: {
            color: colors.white,
            fontSize: 35,
            fontWeight: 'bold',
            textAlign: "center",
        },
        buttonText: {
            color: colors.white,
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 'bold',
        },
        dropDownContainer: {
            backgroundColor: colors.grey,
            borderTopStartRadius: 10,
            borderTopEndRadius: 10,
            borderWidth: 1,
            borderColor: colors.white,
            padding: 15,
            paddingHorizontal: 30,
            width: '100%',
        },
        inputStyle: {
            backgroundColor: colors.grey,
            color: colors.white,
            borderColor: colors.white,
            width: '99%',  // Assicura che l'input di ricerca non fuoriesca
            overflow: 'hidden',
        },
        listContainer: {
            backgroundColor: colors.grey,
            marginBottom: 10,
            borderWidth: 1,
            borderColor: colors.grey,
        }
    });

    return (
        <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderColor: colors.white, borderRadius: 10, borderWidth: 1 }}>
            <ScrollView
                contentInsetAdjustmentBehavior="automatic"
                bounces={false}
                showsHorizontalScrollIndicator={false}
            >
                <View className="flex flex-col p-2 pt-4">
                    <View className="items-center justify-center">
                        <Text
                            variant="displayMedium"
                            className="text-white pl-2 pb-4 font-semibold "
                            style={styles.primaryTextWhiteBold}
                        >
                            Nuova compensazione
                        </Text>
                    </View>

                    {validationErrors["backendError"] && (
                        <View>
                            <Text
                                className="text-red-400 text-sm p-2"
                                style={{ color: "red" }}
                            >
                                {validationErrors["backendError"]}
                            </Text>
                        </View>
                    )}

                    <Formik
                        initialValues={initialCompensationDetails}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setIsSaving(true);

                            submitData({
                                ...values,
                                fromProject: selectedFromProjectID,
                                toProject: selectedToProjectID,
                            }, resetForm);

                            setTimeout(() => {
                                setIsSaving(false);
                                setSubmitting(false);
                            }, 1000);
                        }}
                        enableReinitialize
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            setFieldValue,
                        }) => (
                            <View className="flex flex-col w-full p-2 gap-2">
                                <View>
                                    <Dropdown
                                        style={styles.dropDownContainer}
                                        containerStyle={styles.listContainer}
                                        data={projectList.map(project => ({
                                            label: project.project_id,  // Campo dell'etichetta
                                            value: project.project_id,  // Campo del valore
                                            key: project.project_id,
                                        }))}
                                        search
                                        searchPlaceholder="Cerca progetto da..."  // Imposta il placeholder per la ricerca
                                        labelField="label"  // Specifica il campo per l'etichetta
                                        valueField="value"  // Specifica il campo per il valore
                                        value={selectedFromProjectID}  // Valore attualmente selezionato
                                        placeholder='Seleziona progetto da...'  // Placeholder del dropdown
                                        onChange={handleFromProjectChange}  // Gestisce la selezione
                                        selectedTextStyle={{ color: colors.white }}
                                        placeholderStyle={{ color: colors.white }}
                                        itemTextStyle={{
                                            backgroundColor: colors.background,
                                            color: colors.white,
                                            padding: 10,
                                        }}
                                        inputSearchStyle={styles.inputStyle}
                                        renderItem={(item) => (
                                            <Text style={{ color: colors.white, padding: 10 }}>
                                                {item.label}
                                            </Text>
                                        )}
                                    />
                                </View>

                                <View>
                                    <Dropdown
                                        style={styles.dropDownContainer}
                                        containerStyle={styles.listContainer}
                                        data={projectList.map(project => ({
                                            label: project.project_id,  // Campo dell'etichetta
                                            value: project.project_id,  // Campo del valore
                                            key: project.project_id,
                                        }))}
                                        search
                                        searchPlaceholder="Cerca progetto a..."  // Imposta il placeholder per la ricerca
                                        labelField="label"  // Specifica il campo per l'etichetta
                                        valueField="value"  // Specifica il campo per il valore
                                        value={selectedToProjectID}  // Valore attualmente selezionato
                                        placeholder='Seleziona progetto a...'  // Placeholder del dropdown
                                        onChange={handleToProjectChange}  // Gestisce la selezione
                                        selectedTextStyle={{ color: colors.white }}
                                        placeholderStyle={{ color: colors.white }}
                                        itemTextStyle={{
                                            backgroundColor: colors.background,
                                            color: colors.white,
                                            padding: 10,
                                        }}
                                        inputSearchStyle={styles.inputStyle}
                                        renderItem={(item) => (
                                            <Text style={{ color: colors.white, padding: 10 }}>
                                                {item.label}
                                            </Text>
                                        )}
                                    />
                                </View>

                                <View className="w-full">
                                    <TextInput
                                        label="Importo"
                                        mode="flat"
                                        onChangeText={handleChange("amount")}
                                        onBlur={handleBlur("amount")}
                                        value={values.amount.toString()}
                                        theme={{
                                            colors: {
                                                onSurfaceVariant: "white",
                                            },
                                        }}
                                        style={{ backgroundColor: colors.grey, borderColor: colors.white, borderBottomWidth: 1, }}
                                        activeUnderlineColor="white"
                                        underlineColor="white"
                                        underlineStyle={{
                                            borderWidth: 1,
                                            borderColor: colors.grey,
                                        }}
                                    />
                                    {validationErrors["amount"] && (
                                        <View>
                                            <Text
                                                className="text-red-400 text-sm p-2"
                                                style={{ color: "red" }}
                                            >
                                                {validationErrors["amount"]}
                                            </Text>
                                        </View>
                                    )}
                                </View>

                                <View className="py-4 my-8 w-full items-center justify-center">
                                    <Button
                                        mode="contained"
                                        onPress={handleSubmit as (e: unknown) => void}
                                        style={{ backgroundColor: colors.amaranto, borderRadius: 10 }}
                                        disabled={isSaving}
                                    >
                                        {loading ? (
                                            <ActivityIndicator animating={true} color={"red"} />
                                        ) : (
                                            <Text style={styles.buttonText}>Crea</Text>
                                        )}
                                    </Button>
                                </View>
                            </View>
                        )}
                    </Formik>
                </View>
            </ScrollView>
            <Snackbar
                visible={snackbarVisible}
                onDismiss={() => setSnackbarVisible(false)}
                duration={4000}
                style={{ backgroundColor: colors.amaranto }}
            >
                <Text style={{ color: colors.white }}>{snackbarMessage}</Text>
            </Snackbar>
        </View>
    );
}

export default CompensationBox;
