import { gql, useQuery } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import React, { useState } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import Loader from "../../components/Loader";
import colors from "../../styles/colors";

const GET_USER_DATA = gql`
  query getUserProfileForAdmin($input: getUserProfileForAdminInput) {
    getUserProfileForAdmin(input: $input) {
      user {
        first_name
        last_name
        email
      }
      qualification
      hourly_wages
      worker_type
      OVERTIME_ALLOWED
      description
      areas {
        id
        name
      }
      role {
        id
        role
      }
      skills
    }
  }
`;

function UserDetails({ route, navigation }) {
  const [userId] = useState(route?.params?.userId);

  const {
    data: userData,
    refetch: refetchUserData,
    loading: loadingUserData,
  } = useQuery(GET_USER_DATA, {
    fetchPolicy: "network-only",
    variables: { input: { profile_id: userId } },
  });

  useFocusEffect(
    React.useCallback(() => {
      refetchUserData();
    }, [])
  );

  const styles = StyleSheet.create({
    container: {
      padding: 16,
      marginLeft: 10,
    },
    areasContainer: {
      flexDirection: 'column',
    },
    title: {
      textAlign: 'left',
      color: 'white',
      fontSize: 22,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    areaItem: {
      textAlign: 'center',
      color: 'white',
      fontSize: 16,
      marginTop: 4,
    },
  });


  return (
    <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
      <Loader loading={loadingUserData} />
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        bounces={false}
        showsHorizontalScrollIndicator={false}
      >
        <View className="flex flex-col p-2 pt-4">
          <View className="flex flex-row w-full p-2">
            <View className="flex flex-row w-full">
              <Text
                variant="displayMedium"
                className="flex text-white font-semibold w-11/12 ml-4"
              >
                Dettagli utente
              </Text>
            </View>
          </View>

          <View style={styles.container}>
            {userId && userData ? (
              <View className="flex flex-col w-full gap-2">
                <View className="flex flex-row">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    Nome :
                  </Text>
                  <Text className="flex w-1/2 text-white text-base items-center">
                    {userData?.getUserProfileForAdmin?.user?.first_name}{" "}
                    {userData?.getUserProfileForAdmin?.user?.last_name}
                  </Text>
                </View>

                <View className="flex flex-row">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    Email :
                  </Text>
                  <Text className="flex w-1/2 text-white text-base items-center">
                    {userData?.getUserProfileForAdmin?.user?.email}
                  </Text>
                </View>

                {userData?.getUserProfileForAdmin?.hourly_wages && (
                  <View className="flex flex-row">
                    <Text className="flex w-1/2 text-white text-xl font-semibold">
                      Salario orario :
                    </Text>
                    <Text className="flex w-1/2 text-white text-base items-center">
                      {userData?.getUserProfileForAdmin?.hourly_wages}
                    </Text>
                  </View>
                )}

                {userData?.getUserProfileForAdmin?.worker_type && (
                  <View className="flex flex-row">
                    <Text className="flex w-1/2 text-white text-xl font-semibold">
                      Tipologia lavoratore :
                    </Text>
                    <Text className="flex w-1/2 text-white text-base items-center">
                      {userData?.getUserProfileForAdmin?.worker_type}
                    </Text>
                  </View>
                )}

                {userData?.getUserProfileForAdmin?.OVERTIME_ALLOWED && (
                  <View className="flex flex-row h-6">
                    <Text className="flex w-1/2 text-white text-xl font-semibold">
                      Overtime permesso :
                    </Text>
                    <Text className="flex w-1/2 text-white text-base items-center">
                      {userData?.getUserProfileForAdmin?.OVERTIME_ALLOWED
                        ? "Yes"
                        : "No"}
                    </Text>
                  </View>
                )}

                {userData?.getUserProfileForAdmin?.description && (
                  <View className="flex flex-row">
                    <Text className="flex w-1/2 text-white text-xl font-semibold">
                      Descrizione :
                    </Text>
                    <Text className="flex w-1/2 text-white text-base items-center">
                      {userData?.getUserProfileForAdmin?.description}
                    </Text>
                  </View>
                )}

                <View className="flex flex-row">
                  <Text className="flex w-1/2 text-white text-xl font-semibold">
                    Ruolo :
                  </Text>
                  <Text className="flex w-1/2 text-white text-base items-center">
                    {userData?.getUserProfileForAdmin?.role?.role}
                  </Text>
                </View>


                {(userData?.getUserProfileForAdmin?.role?.role == "CONSULTANT" || userData?.getUserProfileForAdmin?.role?.role == "AREA_MANAGER") &&
                  userData?.getUserProfileForAdmin?.areas && (
                    <View className="flex flex-row">
                      <Text className="flex w-1/2 text-white text-xl font-semibold">
                        Aree di competenza :
                      </Text>
                      <View className="flex w-1/2">
                        {userData.getUserProfileForAdmin.areas.map((item, index) => (
                          <Text key={index} className="text-white text-base">
                            • {item.name}
                          </Text>
                        ))}
                      </View>
                    </View>
                  )}


                {/* {userData?.getUserProfileForAdmin?.role?.role == "CONSULTANT" && 
                userData?.getUserProfileForAdmin?.skills ? (
                  <View className="flex flex-row">
                    <Text className="flex w-1/2 text-white text-xl font-semibold">
                      Skills :
                    </Text>
                    {userData?.getUserProfileForAdmin?.skills &&
                    userData?.getUserProfileForAdmin?.skills.length ? (
                      <>
                        <Text className="flex w-1/2 text-white text-base items-center">
                          {userData?.getUserProfileForAdmin?.skills.map(
                            (element) => {
                              return `${element},`;
                            }
                          )}
                        </Text>
                      </>
                    ) : (
                      <Text className="flex w-1/2 text-white text-base">
                        Nessuna Skill per il Lavoratore*
                      </Text>
                    )}
                  </View>
                ) : null} */}
              </View>
            ) : null}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

export default UserDetails;
