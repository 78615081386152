import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery, gql } from "@apollo/client";
import { getStorageData } from "../libs/storage";
import { useNavigation } from '@react-navigation/native';

const PasswordExpiryContext = createContext(null);

const CHECK_PASSWORD_EXPIRATION = gql`
  query CheckPasswordExpiration($input: CheckPasswordExpirationInput) {
    CheckPasswordExpiration(input: $input) {
      last_password_change
      isExpired
      daysUntilExpiration
    }
  }
`;

export const usePasswordExpiry = () => useContext(PasswordExpiryContext);

export const PasswordExpiryProvider = ({ children }) => {
    const [isPasswordExpiringSoon, setIsPasswordExpiringSoon] = useState(false);
    const [profileId, setProfileID] = useState("");
    const navigation = useNavigation();
    const [isPasswordExpired, setIsPasswordExpired] = useState(false);

    useEffect(() => {
        const unsubscribe = navigation.addListener('state', e => {
            const fetchProfileID = async () => {
                const storedProfileID = await getStorageData("profileID");
                if (typeof storedProfileID === 'string') {
                    setProfileID(storedProfileID);
                }
            };

            fetchProfileID();
        });

        return unsubscribe;
    }, [navigation]);

    const { data } = useQuery(CHECK_PASSWORD_EXPIRATION, {
        variables: { input: { profileId: profileId } },
        skip: !profileId,
    });
   // console.log(data);

    const handleChangePassword = async () => {
        try {
            setIsPasswordExpiringSoon(false);
            navigation.navigate("ChangePassword");
        } catch (error) {
            console.log("error");
        }
    };
    useEffect(() => {
            const checkPasswordExpiry = () => {
                if (data && data.CheckPasswordExpiration) {
                    const { isExpired, daysUntilExpiration } = data.CheckPasswordExpiration;

                    setIsPasswordExpired(isExpired);

                    if (!isExpired && daysUntilExpiration <= 10) {
                        setIsPasswordExpiringSoon(true);
                    } else {
                        setIsPasswordExpiringSoon(false);
                    }
                }
            };
            if (profileId) {
                checkPasswordExpiry();
            }
            
    }, [data, profileId]);
    
    return (
        <PasswordExpiryContext.Provider value={{ isPasswordExpiringSoon, handleChangePassword }}>
            {children}
        </PasswordExpiryContext.Provider>
    );
};
