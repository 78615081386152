import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { View } from "react-native";
import Loader from "../components/Loader";
import { useFocusEffect } from "@react-navigation/native";
import { getStorageData } from "../libs/storage";
import { useNavigation } from '@react-navigation/native';
import {
  Badge,
  Button,
  Dialog,
  IconButton,
  Portal,
  Text,
} from "react-native-paper";
import {
  USER_PROFILE_NAME,
} from "../graphql/user.resolver";
import colors from "../styles/colors";

const DELETE_WORKER = gql`
  mutation deleteWorker($input: deleteWorkerInput) {
    deleteWorker(input: $input) {
      message
      status
    }
  }
`;
const workerProfileQuery = gql`
  query userProfile($input: userProfileInput) {
    userProfile(input: $input) {
      first_name
      last_name
      email
      id
      profile {
        id
        hourly_wages
        qualification
        description
        worker_type
      }
    }
  }
`;

const containerStyle = {
  margin: 20,
  backgroundColor: colors.grey,
  padding: 10,
  alignSelf: "center",
  borderRadius: 10,
};

function WorkerCard(props: any) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [deleteWorker, { data, loading, error }] = useMutation(DELETE_WORKER);

  const handelModalClose = () => setIsDeleteModalVisible(false);

  const navigate = async (e: any) => {
    props.navigation.push("WorkerDetails", { id: props.data.user.id, workerID: props.data.id, route: "workerCard" });
  };

  const deleteCard = async () => {
    await deleteWorker({
      variables: { input: { profile_id: props.data.id } },
    });
    await props.workerListRefetch();
    setIsDeleteModalVisible(false);
  };

  const {
    data: workerProfile,
    refetch: refetchWorkerProfile,
    loading: loadingWorkerProfile,
  } = useQuery(workerProfileQuery, {
    variables: { input: { id: props.data.user.id } },
  });

  useFocusEffect(() => {
    refetchWorkerProfile();
  });

  return (
    <>
      <Portal>
        <Loader loading={loadingWorkerProfile} />
        <Dialog
          visible={isDeleteModalVisible}
          onDismiss={handelModalClose}
          // @ts-ignore
          style={containerStyle}
        >
          <Dialog.Content>
            <Text variant="bodyMedium" style={{color: colors.white, fontFamily: "Roboto"}}>
              Sei sicuro di voler eliminare l'utente{" "}
              {props?.data?.user?.first_name} {props?.data?.user?.last_name} ?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={handelModalClose}
              buttonColor={colors.blue}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{fontFamily: "Roboto"}}>
              Annulla
              </Text>
            </Button>
            <Button
              onPress={deleteCard}
              buttonColor={colors.red}
              textColor={colors.white}
              style={{ borderRadius: 4 }}
            >
              <Text variant="bodyMedium" style={{fontFamily: "Roboto"}}>
                Conferma
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View className="p-2 my-2">
        <View className="flex flex-row p-4 w-full" style={{borderColor: colors.white, borderRadius: 10, borderWidth: 1, backgroundColor: colors.grey}}>
          <View className="flex flex-col w-1/3 p-2 gap-4">
            <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
              {props.data?.user?.first_name} {props.data?.user?.last_name}
            </Text>
            <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
              {workerProfile?.userProfile?.profile?.worker_type}
            </Text>

            <Text className="text-sm text-white" style={{fontFamily: "Roboto"}}>
              Qualifica : {props.data?.qualification}
            </Text>
          </View>

          <View className="flex flex-row items-center justify-center w-1/3 p-2">
            <View className="flex flex-row items-center w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}>
                {props.data.projects_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Progetti</Text>
            </View>
            <View className="flex flex-row items-center  w-1/2">
              <Badge style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}>
                {props.data.clients_count || 0}
              </Badge>
              <Text className="text-xs pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Aziende collegate</Text>
            </View>
          </View>

          <View className="flex flex-row w-2/5 p-4 items-center gap-10 justify-center">
            <IconButton
              icon="eye"
              containerColor={colors.blue}
              style={{ borderRadius: 4 }}
              iconColor={"white"}
              size={25}
              onPress={navigate}
            />
            {props.role === "ADMIN" || props.role === "AREA_MANAGER" ? (
              <IconButton
                icon="trash-can-outline"
                containerColor={colors.red}
                style={{ borderRadius: 4 }}
                iconColor={"white"}
                size={25}
                onPress={() => setIsDeleteModalVisible(true)}
              />
            ) : null}
          </View>
        </View>
      </View>
    </>
  );
}

export default WorkerCard;
