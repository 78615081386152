import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native";
import { Card, Text } from "react-native-paper";
// @ts-ignore
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import colors from "../styles/colors";

function WorkerProjectCard(props: any) {
  function navigate(e: any) {
    props.navigation.push("WorkerProjectDetailsScreen", {
      id: props.project.id,
    });
  }

  const projectName = props?.project?.name?.substring(0, 20) || "NA";

  return (
    <View>
      <TouchableOpacity onPress={navigate}>
        <Card style={{ backgroundColor: colors.grey, borderColor: colors.white, borderWidth: 1, borderRadius: 10 }}>
          <Card.Content>
            <View className="p-1 flex flex-col w-36 h-52 m-2">
              {props?.project?.project_id && (
                <View className="flex flex-col mb-2">
                  <Text
                    variant="bodySmall"
                    className="text-white"
                    style={{fontFamily: "Roboto"}}
                  >
                    {props?.project?.project_id}
                  </Text>
                </View>
              )}

              <Text
                variant="titleLarge"
                className="text-white my-1"
                style={{fontFamily: "Roboto"}}
              >
                {projectName}
              </Text>

              {props?.project?.description && (
                <View className="flex flex-col mb-1">
                  <Text variant="bodySmall" className="text-white" style={{fontFamily: "Roboto"}}>
                    {props?.project?.description.substring(0, 20) || ""}
                  </Text>
                </View>
              )}

              <View className="flex flex-row items-center gap-2 mb-2">
                <MaterialCommunityIcons
                  name={"calendar-clock-outline"}
                  size={25}
                  color={colors.white}
                />
                <Text className="font-semibold text-white" style={{fontFamily: "Roboto"}}>
                  Data Inizio :{" "}
                  {`${new Date(
                    parseInt(props.project.start_date)
                  ).toLocaleDateString("en-GB")}`}
                </Text>
              </View>

              <View className="flex flex-row items-center gap-2">
                <MaterialCommunityIcons
                  name={"calendar-clock-outline"}
                  size={25}
                  color={colors.white}
                />
                <Text className="font-semibold text-white" style={{ fontFamily: "Roboto" }}>
                  Data Fine :{" "}
                  {`${new Date(
                    parseInt(props.project.end_date)
                  ).toLocaleDateString("en-GB")}`}
                </Text>
              </View>
            </View>
          </Card.Content>
        </Card>
      </TouchableOpacity>
    </View>
  );
}

export default WorkerProjectCard;
