import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { Button, Text, TextInput, IconButton } from "react-native-paper";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import AnalysisCharts from "../../components/AnalysisCharts";
import { Dropdown } from "react-native-element-dropdown";
import DataSection from "../../components/DataSection";
import TotalSpendingSection from "../../components/TotalSpendingSection";
import { useFocusEffect } from "@react-navigation/native";
import Loader from "../../components/Loader";
import UserDetails from "./user_details";
import { DatePickerModal } from "react-native-paper-dates";
import { useReceiptContext } from '../../components/ReceiptContext';
import { uploadAsync } from "expo-file-system";
import colors from "../../styles/colors";

const GET_ALL_PROJECTS = gql`
  query allProjectList {
    allProjectList {
      id
      name
      project_id
    }
  }
`;

const getLastUpdate = gql`
  query getLastUpdate {
    getLastUpdate {
      lastUpdate
    }
  }
`;

const PROJECT_DATA = gql`
  query getCostMarginBudgetDashboardDetails(
    $input: getCostMarginBudgetDashboardDetailsInput
  ) {
    getCostMarginBudgetDashboardDetails(input: $input) {
      name
      project_id
      area_manager {
        user {
          first_name
          last_name
        }
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
      budget
      fee
      margin
      cost
      compensation
      totalCostOfExpences
      totalCostOfWorker
      totalValueOfReceipt
    }
  }
`;

const PROJECT_DATA2 = gql`
  query getProjectDataByDate($input: getProjectDataByDateInput) {
    getProjectDataByDate(input: $input) {
      workers {
        name
        id
        totalCost
        totalTime
        ordinary_hours
        overtime_hours
      }
      receipts {
        id
        type
        file_name
        receipt_title
        fileUrl
        details
        created_by
        created_at
        activity_id 
        receipt_value
        receipt_date
        receipt_type
        client
        project_id
      }
      expences {
        id
        file_name
        reimbursed
        fileUrl
        created_by
        cost
        project_id
        created_at
        title
        type_id
        description
        expense_date
        expense_type
        client
      }
      totalCostOfWorker
      totalCostOfExpences
    }
  }
`;

const EXPORT_DATA_TO_CSV = gql`
  query generateDashboard_1_CSV($input: generateDashboard_1_CSVInput) {
    generateDashboard_1_CSV(input: $input) {
      fileName
      fileUrl 
    }
  }
`;

const TaskDate = gql`
  query getProjectDataByDate($input: getProjectDataByDateInput) {
    getProjectDataByDate(input: $input) {
      workers {
        hasOutdatedTasks
      }
    }
  }
`;

const Dashboard = ({ route }) => {
  const [ProjectId] = useState(route?.params?.id);
  const [selectedProjectID, setSelectedProjectID] = useState(ProjectId || null);
  const [formatedProjectList, setFormatedProjectList] = useState([]);;
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [open, setOpen] = useState(false);

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
  };

  const { data: projectList, refetch: projectListRefetch } =
    useQuery(GET_ALL_PROJECTS);

  const [getFile, { data: file, loading: fileLoading, error: fileDownloadError }] = useLazyQuery(
    EXPORT_DATA_TO_CSV,
    {
      fetchPolicy: "network-only",
      variables: {
        input: {
          project_id: selectedProjectID,
          box_one_fromDate: range?.startDate,
          box_one_toDate: range?.endDate,
          box_two_fromDate: range?.startDate,
          box_two_toDate: range?.endDate,
        },
      },
    }
  );

  const [getLastUpdateData, { data: lastUpdateData, loading: lastUpdateLoading }] = useLazyQuery(getLastUpdate);

  useEffect(() => {
    getLastUpdateData();
  }, [getLastUpdateData]);

  const {
    data: projectDetails,
    refetch: refetchProjectDetails,
    loading: loadingProjectDetails,
  } = useQuery(PROJECT_DATA, {
    variables: { input: { project_id: selectedProjectID } },
    fetchPolicy: "network-only",
    skip: !selectedProjectID,
  });

  const {
    data: projectDetails2,
    refetch: refetchProjectDetails2,
    loading: loadingProjectDetails2,
  } = useQuery(PROJECT_DATA2, {
    variables: {
      input: {
        project_id: selectedProjectID,
        fromDate: range?.startDate,
        toDate: range?.endDate,
        receipt: false,
      }
    },
    fetchPolicy: "network-only",
    skip: !selectedProjectID,
  });

  useEffect(() => {
    let FORMATED_PROJECT_LIST = [];
    if (projectList?.allProjectList) {
      FORMATED_PROJECT_LIST = projectList.allProjectList.map((project) => ({
        label: project?.project_id,
        value: project?.id,
      }))
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
    }
    setFormatedProjectList(FORMATED_PROJECT_LIST);
  }, [projectList]);


  const { updateRefetchHandlers } = useReceiptContext();

  useEffect(() => {
    refetchProjectDetails();
    refetchProjectDetails2();
  }, [selectedProjectID]);

  const exportDataToCsv = async () => {
    const filedata = await getFile();
    console.log("called")
    window.open(filedata?.data?.generateDashboard_1_CSV?.fileUrl, "_blank");
  };

  useEffect(() => {
    const filtered = formatedProjectList.filter((project) =>
      project.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchQuery, formatedProjectList]);

  useEffect(() => {
    // Funzione di pulizia che viene eseguita quando il componente viene smontato
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  const { data, refetch } = useQuery(TaskDate, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        project_id: selectedProjectID,
        fromDate: range.startDate,
        toDate: range.endDate,
        receipt: false,
      },
    },
  });

  return (
    <>
      <View className="flex flex-auto h-screen p-2" style={{ backgroundColor: colors.background }}>
        <Loader loading={loadingProjectDetails} />
        <Loader loading={loadingProjectDetails2} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-row w-full mb-10 gap-4">
            <Text
              variant="displayMedium"
              className="text-white mb-4 w-full font-semibold"
            >
              Dashboard Project Overview
            </Text>

            <View className="flex flex-row items-center">
              {!ProjectId ? (
                <View style={{ flex: 1 }}>
                  <Dropdown
                    style={{
                      backgroundColor: colors.background,
                      borderTopStartRadius: 10,
                      borderTopEndRadius: 10,
                      borderWidth: 2,
                      borderColor: colors.grey,
                      padding: 15,
                      paddingHorizontal: 30,
                      width: '100%',
                    }}
                    containerStyle={{ backgroundColor: colors.background, marginBottom: 10, borderWidth: 1, borderColor: colors.grey }}
                    data={formatedProjectList}
                    search
                    searchPlaceholder="Trova progetto..."
                    labelField="label" // Definisce il campo dell'etichetta per gli elementi della lista
                    valueField="value" // Definisce il campo del valore per gli elementi della lista
                    value={selectedProjectID}
                    placeholder='Seleziona progetto...'
                    onChange={(item) => setSelectedProjectID(item.value)}
                    selectedTextStyle={{ color: colors.white }}
                    placeholderStyle={{ color: colors.white }}
                    itemTextStyle={{ backgroundColor: colors.background, color: colors.white, padding: 10, }}
                    inputSearchStyle={{
                      backgroundColor: colors.background, color: colors.white, borderColor: colors.grey, width: '94%',  // Assicura che l'input di ricerca non fuoriesca
                      overflow: 'hidden',
                    }}

                    renderItem={(item) => (
                      <Text style={{ color: colors.white, padding: 10 }}>
                        {item.label}
                      </Text>
                    )}
                  />
                </View>
              ) : null}
            </View>
          </View>

          {selectedProjectID ? (
            <>
              <View className="items-center text-center flex-row justify-end">
                {selectedProjectID && (
                  <Button
                    icon="file-move-outline"
                    mode="contained"
                    buttonColor={colors.amaranto}
                    textColor={colors.white}
                    onPress={exportDataToCsv}
                    className="items-center justify-center my-2 mr-4"
                    loading={fileLoading}
                    style={{ borderRadius: 10, height: 45, marginTop: 8 }}
                  >
                    <Text className="text-white font-bold">Esporta in CSV</Text>
                  </Button>
                )}

                <Text
                  variant="headlineSmall"
                  className="text-white font-semibold"
                >
                  Intervallo :{" "}
                </Text>

                <Button
                  onPress={() => setOpen(true)}
                  uppercase={false}
                  mode="outlined"
                  textColor="white"
                  style={{ borderRadius: 4, borderWidth: 2, marginRight: 18, borderColor: colors.grey }}
                >
                  {range.startDate && range.endDate ? (
                    <Text
                      variant="labelLarge"
                      className="text-white w-full font-semibold"
                    >
                      {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                        }`}
                      {` - `}
                      {`${new Date(range?.endDate).getDate()}/${new Date(range?.endDate).getMonth() + 1
                        }`}
                    </Text>
                  ) : null}

                  {range.startDate && !range.endDate ? (
                    <Text
                      variant="labelLarge"
                      className="text-white w-full font-semibold"
                    >
                      {`${new Date(range?.startDate).getDate()}/${new Date(range?.startDate).getMonth() + 1
                        }`}
                    </Text>
                  ) : null}

                  {!range.startDate && !range.endDate ? (
                    <Text className="text-white w-full font-semibold">
                      Seleziona intervallo
                    </Text>
                  ) : null}
                </Button>

                {(range.startDate || range.endDate) && (
                  <IconButton
                    icon="undo-variant"
                    mode="contained"
                    iconColor={"black"}
                    containerColor={"white"}
                    size={16}
                    onPress={() =>
                      setRange({
                        startDate: null,
                        endDate: null,
                      })
                    }
                    className="ml-4"
                  />
                )}

                <DatePickerModal
                  locale="it"
                  mode="range"
                  visible={open}
                  onDismiss={onDismiss}
                  startDate={range.startDate}
                  endDate={range.endDate}
                  onConfirm={onConfirm}
                  animationType="fade"
                />
              </View>

              <View className="flex flex-col w-full gap-2 mb-4">
                <View>
                  <Text
                    variant="headlineLarge"
                    className="text-white mb-4 font-bold"
                    style={{marginLeft: -7}}
                  >
                    {projectDetails?.getCostMarginBudgetDashboardDetails?.project_id}
                  </Text>
                </View>
                <View>
                  <Text variant="headlineMedium" className="text-white" style={{marginLeft: 3}}>
                    Project manager :{" "}
                    {
                      projectDetails?.getCostMarginBudgetDashboardDetails
                        ?.project_manager?.user?.first_name
                    }{" "}
                    {
                      projectDetails?.getCostMarginBudgetDashboardDetails
                        ?.project_manager?.user?.last_name
                    }
                  </Text>
                </View>

                <View>
                  <Text variant="headlineMedium" className="text-white" style={{marginLeft: 3}}>
                    Area manager :{" "}
                    {
                      projectDetails?.getCostMarginBudgetDashboardDetails
                        ?.area_manager?.user?.first_name
                    }{" "}
                    {
                      projectDetails?.getCostMarginBudgetDashboardDetails
                        ?.area_manager?.user?.last_name
                    }
                  </Text>
                </View>
              </View>
              <AnalysisCharts
                ProjectData={projectDetails}
                Cost={projectDetails2}
              />
              <DataSection
                ProjectID={selectedProjectID}
                range={range}
                project={projectList}
              />
              <TotalSpendingSection
                ProjectID={selectedProjectID}
                range={
                  range
                }
              />
            </>
          ) : (
            <>
              <View className="flex items-center justify-center h-full w-full">
                <Text
                  variant="displayMedium"
                  className="text-white w-full text-center font-semibold"
                >
                  Seleziona un progetto per i dettagli
                </Text>
              </View>
            </>
          )}
        </ScrollView>

        {selectedProjectID ? (
          <View style={{ padding: 10 }}>
            <Text>
              Ultimo aggiornamento :{" "}
              {lastUpdateData ? new Date(lastUpdateData.getLastUpdate.lastUpdate).toLocaleString('it-IT', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              }) : "No data available"}
            </Text>
            {selectedProjectID && data?.getProjectDataByDate?.workers?.some(worker => worker.hasOutdatedTasks) && (
              <View style={{ paddingTop: 10 }}>
                <Text style={{ color: 'yellow' }}>
                  Attenzione: Non tutti i dati sono aggiornati
                </Text>
              </View>
            )}
          </View>
        ) : null}
      </View>
    </>
  );
};

export default Dashboard;
