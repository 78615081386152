import React, { createContext, useContext, useState } from 'react';

const ReceiptContext = createContext(null);

export const useReceiptContext = () => useContext(ReceiptContext);

export const ReceiptProvider = ({ children }) => {
  const [refetchReceipts, setRefetchReceipts] = useState({ refetchNotInBudget: null, refetchInBudget: null });

  const updateRefetchHandlers = (handlers) => {
    setRefetchReceipts(handlers);
  };

  return (
    <ReceiptContext.Provider value={{ refetchReceipts, updateRefetchHandlers }}>
      {children}
    </ReceiptContext.Provider>
  );
};
