import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import AnalysisChartThree from "../../components/AnalysisChartThree";
import DataSectionThree from "../../components/DataSectionThree";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import CompensationBox from "./compensationBox";
import { getStorageData } from "../../libs/storage";
import { useNavigation } from '@react-navigation/native';
import {
  USER_PROFILE_ROLE,
} from "../../graphql/user.resolver";
import colors from "../../styles/colors";

const GET_ALL_PROJECTS = gql`
  query calculateGeneralClosedProjectCosts {
    calculateGeneralClosedProjectCosts {
      name
      project_id
      area_id
      margin
      fee
      budget
      compensation
      client_group
      contract_value
      cost
      notUsedBudget
      hasOutdatedTasks
      compensatedBudget
      compensationsReceived {
        projectId
        amount
      }
      compensationsSent {
        projectId
        amount
      }
      totalCostOfWorker
      totalCostOfExpences
      totalValueOfReceipt
      client {
        name
      }
      project_sector { 
        name 
      }
      project_area {
        id
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
      area_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const getLastUpdate = gql`
  query getLastUpdate {
    getLastUpdate {
      lastUpdate
    }
  }
`;

const GET_ALL_TRANSACTION = gql`
  query getAllTransaction {
    getAllTransaction {
      fromProjectId
      toProjectId
      fromProject_id
      toProject_id
      created_at
      amount
      id
    }
  }
`;

function DashboardThree() {
  const [isFieldDropDownVisible, setIsFieldDropDownVisible] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [role, setRole] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  const { data: projectList, refetch: refetchProjectList, loading: loadingProjectList } = useQuery(
    GET_ALL_PROJECTS
  );

  const { data: transactionList, refetch: refetchTransactionList, loading: loadingTransactionList } = useQuery(
    GET_ALL_TRANSACTION
  );

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let userAreaIds = userAreas.map(area => area.id);

  //console.log(userProfile, "userAreaIds");
  let formattedProjectList = [];
  //console.log(projectList?.calculateGeneralClosedProjectCosts);

  const [getLastUpdateData, { data: lastUpdateData, loading: lastUpdateLoading }] = useLazyQuery(getLastUpdate);

  useEffect(() => {
    getLastUpdateData();
  }, [getLastUpdateData]);

  if (projectList?.calculateGeneralClosedProjectCosts?.length > 0) {
    formattedProjectList = projectList.calculateGeneralClosedProjectCosts
      .filter(project => role === 'ADMIN' || userAreaIds.includes(project.project_area.id))
      .sort((a, b) => {
        const projectIdComparison = a.project_id.localeCompare(b.project_id);
        if (projectIdComparison !== 0) return projectIdComparison;

        return a.name.localeCompare(b.name);
      });
  }

  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [open, setOpen] = useState(false);

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
  };

  useEffect(() => {
    //refetchDashboardData();
    //refetchProjectList();
  }, [range, projectList]);

  return (
    <>
      <View className="flex flex-auto h-screen p-2" style={{ backgroundColor: colors.background }}>
        <Loader loading={loadingProjectList} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-row w-full mb-10">
            <Text
              variant="displayMedium"
              className="text-white mb-4 w-full font-semibold"
            >
              Compensation Dashboard
            </Text>
          </View>

          <>
            <AnalysisChartThree
              projectList={formattedProjectList}
              refetchProjectList={refetchProjectList}
            />
            {role === "ADMIN" || role === "AREA_MANAGER" ? (
              <CompensationBox
                projectList={formattedProjectList}
                refetchProjectList={refetchProjectList}
                refetchTransactionList={refetchTransactionList}
              />
            ) : null}

            <DataSectionThree
              projectList={formattedProjectList}
              transactionList={transactionList}
              refetchProjectList={refetchProjectList}
              refetchTransactionList={refetchTransactionList}
            />
          </>
        </ScrollView>
        <View style={{ padding: 10 }}>
          <Text>
            Ultimo aggiornamento :{" "}
            {lastUpdateData ? new Date(lastUpdateData.getLastUpdate.lastUpdate).toLocaleString('it-IT', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }) : "No data available"}
          </Text>
          {formattedProjectList.some(project => project.hasOutdatedTasks) && (
            <View style={{ paddingTop: 10 }}>
              <Text style={{ color: 'yellow' }}>
                Attenzione: Non tutti i dati sono aggiornati
              </Text>
            </View>
          )}
        </View>
      </View>
    </>
  );
}

export default DashboardThree;
