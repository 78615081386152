import { gql, useQuery } from "@apollo/client";
import React from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import MoveProjects from "../../components/moveProjects";
import AddNewAreas from "../../components/addNewAreas";
import colors from "../../styles/colors";

const getClientList = gql`
  query getAllClients {
    getAllClients {
      address
      id
      name
      workers_count
      projects_count
    }
  }
`;

function ToolsList({ navigation }) {

    const {
        data: clientList,
        refetch: refetchClientList,
        loading: clientListLoading,
    } = useQuery(getClientList);

    const clientListUpdater = () => {
        refetchClientList();
    };

    useFocusEffect(
        React.useCallback(() => {
            refetchClientList();
        }, [])
    );
    console.log(clientList);
    return (
        <>
            <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
                <ScrollView
                    contentInsetAdjustmentBehavior="automatic"
                    bounces={false}
                    showsHorizontalScrollIndicator={false}
                >
                    <View className="flex flex-col p-2 pt-4">
                        <View className="flex flex-row w-full">
                            <Text
                                variant="displayMedium"
                                className="flex font-semibold w-11/12 ml-4"
                                style={{color: colors.white}}
                            >
                                Tools Manager
                            </Text>
                        </View>

                        <View>
                            <MoveProjects
                                navigation={navigation}
                                clientList={clientList}
                                refetchClientList={refetchClientList}
                            />

                            <AddNewAreas
                                navigation={navigation}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        </>
    );
}

export default ToolsList;
