import { gql, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Searchbar, Text } from "react-native-paper";
import UserCard from "../../components/UserCard";
import { useFocusEffect } from "@react-navigation/native";
import Loader from "../../components/Loader";
import colors from "../../styles/colors";

const GET_USERS = gql`
  query users {
    users {
      id
      user {
        id
        last_name
        first_name
        email
      }
      role {
        role
      }
    }
  }
`;

function UsersList({ navigation }) {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data,
    refetch,
    loading: loadingUserList,
  } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });

  const [filteredArray, setfilteredArray] = useState(data?.users);

  const search = (query: any) => {
    setSearchQuery(query);
    filter(query);
  };

  const filter = (query) => {
    let result = data?.users || []; // Inizia con tutti gli utenti se la query è vuota o se data non è definita
  
    if (query?.length > 1) {
      result = result.filter((user) => {
        // Assicurati di utilizzare 'user', non 'filter' come nome della variabile per evitare confusione con il metodo 'filter'
        return (
          user?.user?.first_name?.toLowerCase()?.includes(query.toLowerCase()) ||
          user?.user?.last_name?.toLowerCase()?.includes(query.toLowerCase()) ||
          user?.user?.email?.toLowerCase()?.includes(query.toLowerCase())
        );
      });
    }
    
    // Ordina il risultato filtrato (o l'array completo se la query è vuota o troppo corta)
    result = result.sort((a, b) => {
      // Scegli il campo su cui vuoi ordinare, qui uso 'first_name' come esempio
      const nameA = a.user.first_name?.toLowerCase() || '';
      const nameB = b.user.first_name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
    
    // Aggiorna lo stato con il risultato filtrato e ordinato
    setfilteredArray(result);
  };


  useFocusEffect(
    React.useCallback(() => {
      refetch();
      filter(searchQuery);
    }, [data?.users])
  );

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <Loader loading={loadingUserList}/>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <View className="flex flex-row w-full">
                <Text
                  variant="displayMedium"
                  className="flex text-white font-semibold w-11/12 ml-4"
                >
                  Utenti
                </Text>

                <Button
                  icon="account-plus"
                  mode="contained"
                  buttonColor={colors.amaranto}
                  textColor={colors.white}
                  onPress={() => navigation.push("CreateNewUser")}
                  className="items-center justify-center mr-4"
                  style={{ borderRadius: 10 }}
                >
                  <Text className="text-white font-bold">Nuovo utente</Text>
                </Button>
              </View>
            </View>

            <View className="my-4 p-2">
              <Searchbar
                placeholder="Cerca utenti..."
                mode="bar"
                onClearIconPress={() => {
                  setSearchQuery("");
                  filter("");
                }}
                onChangeText={search}
                value={searchQuery}
                className="m-2"
                placeholderTextColor={colors.white}
                iconColor={colors.white}
                inputStyle={{ color: colors.white }}
                style={{ borderRadius: 10, backgroundColor: colors.grey, fontFamily: 'Roboto' }}
              />
            </View>

            <View className="p-2 item-center justify-center">
              {filteredArray && filteredArray.length ? (
                filteredArray.map((user, keyid) => {
                  return (
                    <UserCard
                      data={user}
                      key={keyid}
                      navigation={navigation}
                      refetch={refetch}
                    />
                  );
                })
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono utenti disponibili*
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default UsersList;
