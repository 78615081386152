import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Badge } from "react-native-paper";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import colors from "../styles/colors";

function WorkerProjectCard(props: any) {
  function navigate(e: any) {
    props.navigation.push("ProjectDetails", {
      id: props?.data?.project_activity_id?.project_id?.id,
      hideButtons: true,
    });
  }

  return (
    <>
      <View>
        <TouchableOpacity onPress={navigate}>
          <View className="justify-start items-start pb-6 pr-2">
            <View className="flex flex-row pb-2 w-full" style={{borderWidth: 1, borderRadius: 10, borderColor: colors.white, backgroundColor: colors.grey, padding: 10}}>
              <View className="flex flex-col w-1/2">
                <Text className="text-base text-white" style={{fontFamily: "Roboto"}}>
                  {" "}
                  {props.data.project_activity_id.project_id.name}
                </Text>
                <Text className="text-sm text-white">
                  <Text className="text-sm text-white" style={{fontFamily: "Roboto"}}>
                    {" "}
                    Data Fine :{" "}
                    {`${new Date(
                      parseInt(
                        props.data.project_activity_id.project_id.end_date
                      )
                    ).toLocaleDateString("en-GB")}`}
                  </Text>
                </Text>
                <View className="flex flex-row pt-1">
                  <View className="flex flex-row  items-center w-1/2">
                    <Badge
                      style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}
                    >
                      {props.data.activities_count || 0}
                    </Badge>
                    <Text className="text-xs text-white pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Attività</Text>
                  </View>
                  <View className="flex flex-row  items-center w-1/2">
                    <Badge
                      style={{ backgroundColor: colors.white, color: colors.amaranto, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12 }}
                    >
                      {props.data?.workers_count || 0}
                    </Badge>
                    <Text className="text-xs text-white pl-2" style={{ color: colors.white, fontFamily: 'Roboto', fontSize: 14}}>Lavoratori</Text>
                  </View>
                </View>
              </View>

              <View className="flex flex-auto w-1/2 items-end pr-8 justify-center">
                <Icon name={"chevron-right"} size={15} color={"white"} />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </>
  );
}

export default WorkerProjectCard;
