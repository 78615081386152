import { gql, useQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, TouchableOpacity } from "react-native";
import { Button, IconButton, Text } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import { SpendingMode } from "../constants/static-data";
import { useReceiptContext } from './ReceiptContext';
import ExpenseCard from "./ExpenseCard";
import colors from "../styles/colors";

const GET_PROJECT_TOTAL_SPENDING = gql`
  query getNotInBudgetExpences($input: getNotInBudgetExpencesInput) {
    getNotInBudgetExpences(input: $input) {
      expences {
        cost
        created_at
        created_by
        fileUrl
        file_name
        id
        project_id
        expense_date
        reimbursed
        title
        type_id
        description
        expense_type
        expense_city
        expense_expenditure_method
        client_name
      }
      totalCostOfExpences
    }
  }
`;

const GET_PROJECT_RECEIPT_NOT_IN_BUDGET = gql`
  query getNotInBudgetReceipts($input: getNotInBudgetReceiptsInput) {
    getNotInBudgetReceipts(input: $input) {
      receipts {
        id
        type
        file_name
        receipt_title
        fileUrl
        details
        created_by
        created_at
        km_routes
        activity_id
        receipt_value
        receipt_date
        receipt_city
        receipt_expenditure_method
        receipt_type
        client
        project_id
        worker_name
        reimbursed
      }
    }
  }
`;


function TotalSpendingSection({ ProjectID, range }) {
  const [activeTab, setActiveTab] = useState(1);

  const { data: expenceData, refetch: expenceRefetch } = useQuery(GET_PROJECT_TOTAL_SPENDING, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        project_id: ProjectID,
        fromDate: range.startDate,
        toDate: range.endDate,
      },
    },
  });

  const { data: receiptData, refetch: receiptRefetch } = useQuery(GET_PROJECT_RECEIPT_NOT_IN_BUDGET, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        project_id: ProjectID,
        fromDate: range.startDate,
        toDate: range.endDate,
      },
    },
  });

  useEffect(() => {
    receiptRefetch();
  }, [ProjectID, range]);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const getExpenditureType = (ExpenditureMethode): string => {
    let methode = "Not Defined";
    SpendingMode.forEach((element) => {
      if (element.value == ExpenditureMethode) {
        methode = element.label;
      }
    });
    return methode;
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    headerRow: {
      flexDirection: 'row',
      backgroundColor: colors.grey,
      padding: 5,
      marginBottom: 3,
      borderWidth: 3,
      borderColor: "grey",
      borderRadius: 2,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: 'grey',
      padding: 3,
      marginLeft: 3,
    },
    cell: {
      //flex: 1,
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 300,
    },
    cellNumber: {
      //flex: 1,
      padding: 2,
      borderRightWidth: 1,
      borderColor: "grey",
      width: 200,
    },
    headerText: {
      fontWeight: 'bold',
      color: 'white',
      fontSize: 22,
      textAlign: 'center',
    },
    cellText: {
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
    },
    cellTextButton: {
      color: 'white',
      fontSize: 18,
      alignContent: "center",
    },
    content: {
      flex: 1,
    },
    actionButton: {
      justifyContent: "center",
      alignItems: 'center',
    },
    button: {
      borderColor: colors.white,
      borderBottomWidth: 1, 
      borderRightWidth: 1,
      borderTopLeftRadius: 10,
      backgroundColor: colors.amaranto,
    },
    buttonText: {
      color: colors.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
  });

  return (
    <>
      <View className="flex flex-col w-full my-2" style={{ backgroundColor: colors.grey, borderWidth: 1, borderRadius: 10, borderColor: colors.white }}>
        <View className="flex flex-row w-full">
          <View style={styles.button}>
            <Button
              //mode={activeTab === 1 ? "contained" : "outlined"}
              buttonColor={colors.amaranto}
              onPress={() => handleTabClick(1)}
              style={{ borderTopLeftRadius: 10, backgroundColor: colors.amaranto }}
            >
              <Text style={styles.buttonText}>Spese forniture da rimborsare</Text>
            </Button>
          </View>
          <Button
            // mode={activeTab === 2 ? "contained" : "outlined"}
            buttonColor={colors.amaranto}
            style={{ borderRadius: 0, backgroundColor: colors.amaranto, borderColor: colors.white, borderBottomWidth: 1, borderRightWidth: 1 }}
            onPress={() => handleTabClick(2)}
          >
            <Text style={styles.buttonText}>Spese risorse da rimborsare</Text>
          </Button>
        </View>
        <View className="flex flex-col w-full h-96 my-2 overflow-scroll p-2">
          {activeTab == 1 ? (
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <View style={styles.cell}><Text style={styles.headerText}>Titolo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Cliente</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Codice commessa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Data spesa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Descrizione</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Tipologia spesa</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Importo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Metodo di pagamento</Text></View>
                </View>
                <ScrollView style={styles.content}>
                  {expenceData?.getNotInBudgetExpences?.expences &&
                    expenceData?.getNotInBudgetExpences?.expences.map((expence) => (
                      <View key={expence.id} style={styles.row}>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.title}</Text></View>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.client_name}</Text></View>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.project_id}</Text></View>
                        <View style={styles.cell}><Text style={styles.cellText}>{`${new Date(
                          parseInt(expence?.expense_date)
                        ).toLocaleDateString("en-GB")}`}</Text>
                        </View>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.description}</Text></View>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.expense_type}</Text></View>
                        <View style={styles.cellNumber}><Text style={styles.cellText}>{expence?.cost}</Text></View>
                        <View style={styles.cell}><Text style={styles.cellText}>{expence?.expense_expenditure_method}</Text></View>
                      </View>
                    ))}
                </ScrollView>
              </View>
            </ScrollView>
          ) : null}
          {activeTab == 2 ? (
            <ScrollView horizontal={true} style={{ flexGrow: 0 }}>
              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <View style={styles.cell}><Text style={styles.headerText}>Codice commessa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Cliente</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Lavoratore</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Città</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Data spesa</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Tipologia spesa</Text></View>
                  <View style={styles.cellNumber}><Text style={styles.headerText}>Km percorsi</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Descrizione</Text></View>
                  <View style={[styles.cellNumber]}><Text style={styles.headerText}>Importo</Text></View>
                  <View style={styles.cell}><Text style={styles.headerText}>Metodo di Pagamento</Text></View>
                  <View style={[styles.cell]}><Text style={styles.headerText}>Allegato</Text></View>
                </View>
                <ScrollView style={styles.content}>
                  {receiptData?.getNotInBudgetReceipts?.receipts &&
                    receiptData?.getNotInBudgetReceipts?.receipts.map((receipt) => {
                      return (
                        <View key={receipt.id} style={styles.row}>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.project_id}</Text></View>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.client}</Text></View>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.worker_name}</Text></View>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.receipt_city
                            ? receipt?.receipt_city
                            : "City not selected"}</Text>
                          </View>
                          <View style={styles.cell}><Text style={styles.cellText}>{`${new Date(
                            parseInt(receipt?.receipt_date)
                          ).toLocaleDateString("en-GB")}`}</Text>
                          </View>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.receipt_type}</Text></View>
                          <View style={styles.cellNumber}><Text style={styles.cellText}>{receipt.km_routes ? receipt.km_routes : "N/A"}</Text></View>
                          <View style={styles.cell}><Text style={styles.cellText}>{receipt?.details}</Text></View>
                          <View style={[styles.cellNumber]}><Text style={styles.cellText}>{receipt?.receipt_value.toFixed(2)}</Text></View>
                          <View style={styles.cell}><Text style={styles.cellText}>{getExpenditureType(receipt?.mode_of_payment)}</Text></View>
                          <View style={[styles.cell, styles.actionButton]}>{receipt?.fileUrl && (
                            <IconButton
                              icon="download-box-outline"
                              iconColor={"#3b8af7"}
                              size={40}
                              onPress={() => window.open(receipt?.fileUrl, "_blank")}
                            />)}
                          </View>
                        </View>
                      )
                    })}
                </ScrollView>
              </View>
            </ScrollView>
          ) : null}
        </View >

        {activeTab == 1 ? (
          <View className="flex flex-row w-full mb-2 p-2">
            <View className="mx-4">
              <Text variant="headlineSmall" className="text-white">
                Costo totale spese forniture :{" "}
              </Text>
            </View>
            <View className="w-3/12 ">
              <Text variant="headlineSmall" className="text-white">
                {expenceData?.getNotInBudgetExpences?.totalCostOfExpences}
              </Text>
            </View>
          </View>
        ) : null
        }
      </View >
    </>
  );
}

export default TotalSpendingSection;
