import React, { useEffect, useState } from "react";
import { SafeAreaView, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Text } from "react-native-paper";
import { TouchableOpacity } from "react-native";
// @ts-ignore
import Icon from "react-native-vector-icons/FontAwesome";
import { gql, useQuery } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import ActivityCard from "../../components/ActivityCard";
import Loader from "../../components/Loader";
import { getStorageData } from "../../libs/storage";
import {
  USER_PROFILE_ROLE,
} from "../../graphql/user.resolver";
import colors from "../../styles/colors";

const getAllActivitiesQuery = gql`
  query FindActivitiesByProjectId($input: userProfileInput) {
    findActivitiesByProjectId(input: $input) {
      id
      name
      end_date
      workers_count
    }
  }
`;

const getProjectDetails = gql`
  query getProjectById($input: userProfileInput) {
    getProjectById(input: $input) {
      id
      name
      end_date
      project_area {
        name
        id
      }
      project_manager {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

function ActivityList({ navigation, route }) {
  const [ProjectId] = useState(route.params.id);
  const [role, setRole] = useState(null);
  //const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', e => {
      const fetchRole = async () => {
        const storedRole = await getStorageData("role");
        if (storedRole) {
          const role = storedRole;
          setRole(role);
        }
      };
      fetchRole();
    });

    return unsubscribe;
  }, [navigation]);

  const {
    data: activityList,
    error: activityRefetchError,
    loading: loadingActivityList,
    refetch: refetchActivityList,
  } = useQuery(getAllActivitiesQuery, {
    fetchPolicy: "network-only",
    variables: { input: { id: ProjectId } },
  });

  const { data: projectData, refetch: refetchproject, loading: loadingProjectData } = useQuery(
    getProjectDetails,
    { variables: { input: { id: ProjectId } } }
  );

  useEffect(() => {
    if (projectData?.getProjectById) {
      navigation.setOptions({
        headerRight: () => {
          return (
            <View className="justify-end items-end pr-2">
              <Text className="text-white">
                {projectData?.getProjectById?.name}
              </Text>
              <Text className="text-white">
                {" "}
                {projectData &&
                  projectData.getProjectById &&
                  `Data Fine ${new Date(
                    parseInt(projectData?.getProjectById?.end_date)
                  ).toLocaleDateString("en-GB")}`}
              </Text>
            </View>
          );
        },
      });
    }
  }, [projectData]);

  const activityRefetcher = async () => {
    await refetchActivityList();
  };

  useFocusEffect(
    React.useCallback(() => {
      refetchActivityList();
      refetchproject();
    }, [])
  );

  const {
    data: userProfile,
    loading: profileLoading,
    refetch: refetchUserProfile,
    error: profileError,
  } = useQuery(USER_PROFILE_ROLE);

  let full_name = userProfile?.getUserProfileRole?.first_name + " " + userProfile?.getUserProfileRole?.last_name;

  const isProjectManager = () => {
    const projectManagerName = `${projectData?.getProjectById?.project_manager?.user?.first_name} ${projectData?.getProjectById?.project_manager?.user?.last_name}`;

    return full_name === projectManagerName;
  };

  let userAreas = userProfile?.getUserProfileRole?.otherProfiles[0]?.areas || [];
  let areaId = projectData?.getProjectById?.project_area?.id;
  
  const isAreaManager = () => {
    const userAreaIds = userAreas.map(area => area.id);
    return userAreaIds.includes(areaId);
  };

  return (
    <>
      <View className="flex flex-auto h-screen" style={{backgroundColor: colors.background}}>
        <Loader loading={loadingActivityList || loadingProjectData} />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          bounces={false}
          showsHorizontalScrollIndicator={false}
        >
          <View className="flex flex-col p-2 pt-4">
            <View className="flex flex-row w-full">
              <View className="flex flex-row w-full p-4">
                <Text
                  variant="displayMedium"
                  className="flex text-white font-semibold w-11/12"
                >
                  Attività
                </Text>
                {isProjectManager() || role === "ADMIN" || isAreaManager() ? (
                  <Button
                    icon="playlist-plus"
                    mode="contained"
                    buttonColor="#7d0001"
                    textColor="white"
                    onPress={() =>
                      navigation.navigate("CreateNewActivity", { id: ProjectId })
                    }
                    className="items-center justify-center"
                    style={{ borderRadius: 10 }}
                  >
                    <Text className="text-white font-bold">
                      Nuova Attività
                    </Text>
                  </Button>
                ) : null}

              </View>
            </View>

            <View className="p-2 item-center justify-center">
              {activityList?.findActivitiesByProjectId?.length ? (
                activityList?.findActivitiesByProjectId?.map((page, idk) => (
                  <ActivityCard
                    data={page}
                    key={idk}
                    navigation={navigation}
                    refetchActivityList={activityRefetcher}
                    projectID={ProjectId}
                    role={role}
                  />
                ))
              ) : (
                <Text className="text-xl font-bold text-white pl-1">
                  Non ci sono attività disponibili*
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default ActivityList;
