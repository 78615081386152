import React from 'react';
import { usePasswordExpiry } from './PasswordExpiryContext';
import { View, StyleSheet, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { useAuth } from '../views/AuthContext';
import colors from '../styles/colors';

const PasswordExpiryDisclaimer = () => {
    const { isPasswordExpiringSoon, handleChangePassword } = usePasswordExpiry();
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return null;
    }

    if (!isPasswordExpiringSoon) {
        return null;
    }

    //console.log(isPasswordExpiringSoon, "sono il disclaimer");
    const styles = StyleSheet.create({
        smallRectangle: {
            position: 'absolute',
            bottom: 30,
            right: 20,
            width: '90%',
            maxWidth: 290,
            backgroundColor: colors.grey,
            borderColor: colors.white,
            borderWidth: 1,
            borderRadius: 9,
            padding: 20,
            opacity: 0.95,
            alignSelf: 'center',
            alignItems: 'center',
        },
        button: {
            width: '100%',
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.amaranto,
            borderRadius: 10,
            paddingVertical: 10,
            paddingHorizontal: 20,
            marginTop: 10,

        },
        text: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: 10,
        },
        buttonText: {
            fontWeight: 'bold',
            color: colors.white,

        }
    });

    return (
        <View style={styles.smallRectangle}>
            <Text style={styles.text}>La tua password sta per scadere. Considera di cambiarla al più presto.</Text>
            <View style={styles.button}>
                <Button
                    onPress={handleChangePassword}
                >
                    <Text style={styles.buttonText}>Cambia Password</Text>
                </Button>
            </View>
        </View>
    );
};

export default PasswordExpiryDisclaimer;
